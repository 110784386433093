import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { useTrackDtEvent } from '../../DtlTracker';
import {
  ADD_FAVORITE_DENTIST,
  days,
  GET_FAVORITE_DENTISTS,
  GET_CUSTOMER,
  imgLinks,
  REMOVE_FAVORITE_DENTIST,
} from '../../shared';

import { Button, Href } from './Button';
import { BarLoader, Icon, IconButton, Paper } from '../common';
import {
  StyledDentistDetailsAvatar,
  StyledDentistDetailsAvatarContainer,
  StyledDentistDetailsAvatarText,
  StyledDentistDetailsCardContainer,
  StyledDentistDetailsCtaContainer,
  StyledDentistFavButton,
  StyledDentistDetailsCardHeader,
  StyledDentistDetailsCardItem,
  StyledDentistDetailsCardItemText,
  StyledDentistDetailsOpeningHoursContainer,
  StyledDentistDetailsOpeningHoursItem,
  StyledDentistDetailsCardWrapper,
} from '../styled/DentistsDetails.styled';
import { StyledBarLoaderWrapper } from './styled/DentiststFloatCard.styled';

const DentistDetailsUI = (props) => {
  const { dentist, setDentist, maxHeight, detailsPage, hidden } = props;

  const intl = useIntl();

  const [fullOpenHours, setFullOpenHours] = useState(false);
  const referenceKey = dentist?.referenceKey;

  const getFavoriteDentistList = useQuery(GET_FAVORITE_DENTISTS);
  const getCustomer = useQuery(GET_CUSTOMER);
  const favouriteDentistListLoading = getFavoriteDentistList.loading;
  const favouriteDentistList = getFavoriteDentistList.data;

  const [heartIcon, setHeartIcon] = useState('icon.blank.heart');
  const [showLoader, setShowLoader] = useState(false);
  const [sendUserData] = useTrackDtEvent();

  const [removeFavoriteDentistMutation] = useMutation(REMOVE_FAVORITE_DENTIST, {
    refetchQueries: [{ query: GET_FAVORITE_DENTISTS }],
  });

  const [setFavoriteDentistMutation] = useMutation(ADD_FAVORITE_DENTIST, {
    variables: {
      dentistKey: dentist?.referenceKey,
    },
    refetchQueries: [{ query: GET_FAVORITE_DENTISTS }],
  });

  const trackDtEvent = (eventType = '') => {
    const trackData = {
      userData: {
        eventType: eventType || '',
        time: new Date().toISOString(),
        url: window?.location?.href,
        uuid: getCustomer?.data?.customer?.uuid,
        dentist: dentist?.referenceKey,
        website: dentist?.websiteUrl,
      },
    };
    sendUserData(trackData);
  };

  const setFavoriteDentist = () => {
    setFavoriteDentistMutation().then((res) => {
      if (res?.data?.addFavoriteDentist && !res?.data?.addFavoriteDentist?.errors?.length) {
        setHeartIcon('icon.fill.heart');
        setShowLoader(false);
      } else {
        setShowLoader(false);
      }
    });
  };

  const removeFavoriteDentist = (key) => {
    removeFavoriteDentistMutation({ variables: { dentistKey: key } }).then((res) => {
      if (
        res &&
        res.data &&
        res.data.removeFavoriteDentist &&
        !res.data.removeFavoriteDentist.errors.length
      ) {
        setShowLoader(false);
        setHeartIcon('icon.blank.heart');
      } else {
        setShowLoader(false);
      }
    });
  };

  const toggleFavoriteDentist = () => {
    setShowLoader(true);
    if (heartIcon === 'icon.blank.heart') {
      trackDtEvent('click add dentist to favourites');
      setFavoriteDentist();
    } else {
      trackDtEvent('click remove dentist from favourites');
      removeFavoriteDentist(dentist?.referenceKey);
    }
  };

  useEffect(() => {
    if (favouriteDentistList && dentist) {
      const favoriteDentist =
        favouriteDentistList.customer &&
        favouriteDentistList.customer.favoriteDentists.find(
          (favDentist) => dentist.referenceKey === favDentist.dentistKey
        );

      if (favoriteDentist) {
        setHeartIcon('icon.fill.heart');
      } else {
        setHeartIcon('icon.blank.heart');
      }
    }
  }, [dentist, referenceKey, favouriteDentistList]);

  const isInRange = (value, range) => {
    return value >= range[0] && value <= range[1];
  };

  const setHoursText = () => {
    const today = `${dayjs().day() - 1}`;
    const day = Object.entries(dentist.openingHours).filter((item) => item[0] === today);
    let text = 'closed';

    if (!Array.isArray(day) || day.length < 1) return text;

    const shifts = day[0][1];

    shifts.some((shift) => {
      if (!Object.values(shift).filter((item) => item).length) return true;

      const shiftRange = [shift.from, shift.to];
      const now = dayjs().format('HH:mm');

      if (isInRange(now, shiftRange)) {
        const time = shiftRange[1].split(':');

        const thirtyMinsBeforeClosing = dayjs()
          .set('hour', time[0])
          .set('minute', time[1])
          .subtract(30, 'minute')
          .format('HH:mm');

        if (isInRange(now, [thirtyMinsBeforeClosing, shiftRange[1]])) {
          text = 'closing.soon';
        } else {
          text = 'now.open';
        }

        return true;
      }

      return false;
    });

    return text;
  };

  const handleLinkCLick = (event) => {
    event.stopPropagation();
    trackDtEvent('click dentist website');
  };

  if (!dentist || favouriteDentistListLoading || hidden) return null;

  const avatarSrc = dentist?.avatar;
  const isFavourite = heartIcon === 'icon.fill.heart';
  const address = `${dentist?.streetName} ${dentist?.houseNumber}, ${dentist?.postcode} ${dentist?.city}`;

  const avatarContainerAlignItems = dentist?.dentoloPartner ? 'flex-start' : 'center';

  return (
    <StyledDentistDetailsCardContainer
      style={{
        maxHeight: maxHeight ? `${maxHeight}px` : 'auto',
      }}
    >
      <Paper hasShadow={!!detailsPage}>
        <StyledDentistDetailsCardWrapper blurred={showLoader}>
          {!detailsPage && (
            <StyledDentistDetailsCardHeader>
              <div>{dentist?.practiceName}</div>

              <IconButton
                icon="icon.x"
                size="xs"
                onClick={() => setDentist(null)}
                aria-label="Close Dentist"
              />
            </StyledDentistDetailsCardHeader>
          )}

          <StyledDentistDetailsAvatarContainer alignItems={avatarContainerAlignItems}>
            {avatarSrc && <StyledDentistDetailsAvatar src={avatarSrc} alt="dentist avatar" />}

            <div>
              <StyledDentistDetailsAvatarText type="title">
                {dentist?.title} {dentist?.name}
              </StyledDentistDetailsAvatarText>

              {dentist?.dentoloPartner && (
                <StyledDentistDetailsAvatarText type="subtitle">
                  {intl.formatMessage({ id: 'dentist.details.dentolo.dentist' })}
                </StyledDentistDetailsAvatarText>
              )}
            </div>
          </StyledDentistDetailsAvatarContainer>

          <StyledDentistDetailsCardItem>
            <Icon icon="icon.pin" alt="address" />
            <StyledDentistDetailsCardItemText>{address}</StyledDentistDetailsCardItemText>
          </StyledDentistDetailsCardItem>

          {dentist?.phoneNumber && (
            <StyledDentistDetailsCardItem>
              <Icon icon="icon.phone" alt="telephone" />
              <StyledDentistDetailsCardItemText fontWeight={!dentist?.dentoloPartner ? 700 : ''}>
                {dentist?.phoneNumber}
              </StyledDentistDetailsCardItemText>
            </StyledDentistDetailsCardItem>
          )}

          {dentist?.websiteUrl && (
            <StyledDentistDetailsCardItem>
              <Icon icon="icon.globe" alt="website" />
              <StyledDentistDetailsCardItemText fontWeight={!dentist?.dentoloPartner ? 700 : ''}>
                <a
                  href={`${dentist?.websiteUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleLinkCLick}
                >
                  {dentist?.websiteUrl}
                </a>
              </StyledDentistDetailsCardItemText>
            </StyledDentistDetailsCardItem>
          )}

          {dentist?.dentoloPartner && dentist?.openingHours && (
            <>
              <StyledDentistDetailsCardItem>
                <Icon icon="icon.hours" alt="opening hours" />

                <Button variant="nostyle" onClick={() => setFullOpenHours(!fullOpenHours)}>
                  <>
                    <FormattedMessage id={`dentist.details.open.hours.${setHoursText()}`} />
                    <img src={imgLinks['icon.accordion.arrow.down']} alt="" />
                  </>
                </Button>
              </StyledDentistDetailsCardItem>

              {fullOpenHours && (
                <StyledDentistDetailsOpeningHoursContainer>
                  {Object.entries(dentist?.openingHours).map((item) => {
                    const day = days.filter((d) => d.id === item[0]);
                    const today = `${dayjs().day() - 1}`;
                    const dayName = intl.formatMessage({
                      id: `dentist.details.open.hours.day.${day[0].name}.short`,
                    });

                    return (
                      <StyledDentistDetailsOpeningHoursItem key={item[0]} today={today === item[0]}>
                        <div>{day.length ? dayName : ''}:</div>

                        {item[1].length && item[1].filter((open) => open.from && open.to).length ? (
                          item[1].map((shift) => {
                            return (
                              <div key={`${item[0]}-${shift?.from}-${shift?.to}`}>
                                {shift.from} - {shift.to}
                              </div>
                            );
                          })
                        ) : (
                          <div>
                            <FormattedMessage id="dentist.details.open.hours.day.closed" />
                          </div>
                        )}
                      </StyledDentistDetailsOpeningHoursItem>
                    );
                  })}
                </StyledDentistDetailsOpeningHoursContainer>
              )}
            </>
          )}

          {dentist?.dentoloPartner && dentist?.websiteUrl && !detailsPage && (
            <StyledDentistDetailsCtaContainer>
              <Href
                variant="primary"
                href={`${dentist?.websiteUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="dentist.details.book.appointment" />
              </Href>
            </StyledDentistDetailsCtaContainer>
          )}

          {dentist?.phoneNumber && detailsPage && (
            <StyledDentistDetailsCtaContainer>
              <Href href={`tel:${dentist?.phoneNumber}`}>
                <FormattedMessage id="dentist.details.book.appointment" />
              </Href>
            </StyledDentistDetailsCtaContainer>
          )}

          <StyledDentistFavButton favourite={isFavourite} onClick={toggleFavoriteDentist}>
            <Icon icon={heartIcon} alt="heart icon" />
            <FormattedMessage tagName="span" id="dentist.details.common.save.favourites" />
          </StyledDentistFavButton>
        </StyledDentistDetailsCardWrapper>

        {showLoader && (
          <StyledBarLoaderWrapper>
            <BarLoader />
          </StyledBarLoaderWrapper>
        )}
      </Paper>
    </StyledDentistDetailsCardContainer>
  );
};

export default DentistDetailsUI;

DentistDetailsUI.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
  setDentist: PropTypes.func,
  maxHeight: PropTypes.number,
  detailsPage: PropTypes.bool,
  dentist: PropTypes.shape({
    avatar: PropTypes.string,
    city: PropTypes.string,
    dentoloPartner: PropTypes.bool,
    houseNumber: PropTypes.string,
    name: PropTypes.string,
    openingHours: PropTypes.shape({}),
    phoneNumber: PropTypes.string,
    postcode: PropTypes.string,
    practiceName: PropTypes.string,
    referenceKey: PropTypes.string,
    streetName: PropTypes.string,
    title: PropTypes.string,
    websiteUrl: PropTypes.string,
  }),
};
