import React from 'react';

import useInsuranceInfo from '../../../hooks/useInsuranceInfo';
import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO } from '../../../shared';

import DentoloReferral from './DentoloReferral';
import PetoloReferral from './PetoloReferral';

const ReferralPage = () => {
  const { type: insuranceType } = useInsuranceInfo();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return <DentoloReferral />;

    case INSURANCE_TYPE_PETOLO:
      return <PetoloReferral />;

    default:
      return <DentoloReferral />;
  }
};

export default ReferralPage;
