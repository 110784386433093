import { css, styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

const StyledTitle = styled('h1')(
  ({ theme }) => css`
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    font-weight: 700;
    color: ${theme.palette.main.professional_blue};
    line-height: 28px;

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  `
);
export default StyledTitle;
