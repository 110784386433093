import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { GET_CUSTOMER, CREATE_PAYMENT_LINK } from '../../shared';

import { Loading } from '../ui';
import DigitalPaymentInfo from './DigitalPaymentInfo';
import { Separator, Radio, RadioLabel } from '../common';
import {
  StyledForm,
  StyledFormGroup,
  StyledSectionTitle,
  StyledFormFieldset,
  StyledBillingDayRadioInputs,
  StyledBillingDayRadioInputAndLabel,
  StyledDigitalPaymentContainer,
  StyledDigitalPaymentContainerTitle,
} from './CustomerDetailsEditor.styled';

const hasApplePay = false;

function DigitalPaymentEditor() {
  const { data: customerData } = useQuery(GET_CUSTOMER);
  const { customer, digitalPaymentMethod } = customerData || {};
  const { key, contract: { billingDay } = {} } = customer || {};
  const { financialInstrument } = digitalPaymentMethod || {};

  const [billingDayState, setBillingDayState] = useState(billingDay);
  const [iframeUrl, setIframeUrl] = useState('');
  const [iframeLoading, setIframeLoading] = useState(true);
  const [createDigitalPaymentLink] = useMutation(CREATE_PAYMENT_LINK, {
    variables: {
      successUrl: `${window.location.origin}/payment-status/success`,
      failureUrl: `${window.location.origin}/payment-status/fail`,
    },
  });

  const loadInlineNovalnetScripts = () => {
    // load Novalnet script
    const script = document.createElement('script');
    script.src = `https://paygate.novalnet.de/v2/checkout-1.0.0.js?t=${new Date().getTime()}`; // Appending a timestamp to avoid caching
    script.integrity = 'sha384-aDPsa/rYHZHxQVB8g9cvUke1bQgiQSaZM6VLi0L1dudq31rn3JG9T6R/0/OHIn9v';
    script.crossOrigin = 'anonymous';
    script.id = 'novalnet-checkout-js';
    document.head.appendChild(script);
  };

  const setNovalnetParams = (novalnetUrl = '') => {
    const txtSecret = novalnetUrl.split('/nn/')[1]; // txtSecret from the url
    window.Novalnet.setParam('nn_it', 'inline');
    window.Novalnet.setParam('txn_secret', txtSecret);
    window.Novalnet.render('novalnet_payment_form'); // load the digital  payment
  };
  useEffect(() => {
    loadInlineNovalnetScripts();

    createDigitalPaymentLink()
      .then((res) => {
        if (res.data && res.data.createDigitalPaymentLink) {
          const iframeUrlFromResp = res.data.createDigitalPaymentLink.iframeUrl;

          if (iframeUrlFromResp) {
            setIframeUrl(iframeUrlFromResp);
            setNovalnetParams(iframeUrlFromResp);
          }
          setIframeLoading(false);
        }
      })
      .catch(() => {
        setIframeLoading(false);
      });
  }, [createDigitalPaymentLink]);

  useEffect(() => {
    localStorage.setItem(`edit-payment-billing-day-${key}`, billingDayState);
  }, [billingDayState, key]);

  const handleBillingDayChange = (ev, value) => {
    if (ev) ev.stopPropagation();

    setBillingDayState(value);
  };

  return (
    <StyledForm>
      <StyledFormGroup>
        <StyledSectionTitle>
          <FormattedMessage id="customer.details.digital.payment.billing.date.title" />
        </StyledSectionTitle>

        <StyledFormFieldset>
          <StyledBillingDayRadioInputs>
            <StyledBillingDayRadioInputAndLabel>
              <Radio
                id="radio_billing_day_start"
                name="start"
                onChange={(ev) => handleBillingDayChange(ev, 1)}
                value="billing-day-1"
                checked={billingDayState === 1}
              />

              <RadioLabel htmlFor="radio_billing_day_start">
                <FormattedMessage id="customer.details.digital.payment.billing.date.start.label" />
              </RadioLabel>
            </StyledBillingDayRadioInputAndLabel>

            <StyledBillingDayRadioInputAndLabel>
              <Radio
                id="radio_billing_day_middle"
                name="middle"
                onChange={(ev) => handleBillingDayChange(ev, 15)}
                value="billing-day-15"
                checked={billingDayState === 15}
              />

              <RadioLabel htmlFor="radio_billing_day_middle">
                <FormattedMessage id="customer.details.digital.payment.billing.date.middle.label" />
              </RadioLabel>
            </StyledBillingDayRadioInputAndLabel>
          </StyledBillingDayRadioInputs>
        </StyledFormFieldset>
      </StyledFormGroup>

      <Separator />

      <StyledFormGroup>
        <StyledSectionTitle>
          <FormattedMessage id="customer.details.digital.payment.details.title" />
        </StyledSectionTitle>

        <DigitalPaymentInfo financialInstrument={financialInstrument} />
      </StyledFormGroup>

      <StyledFormGroup>
        <StyledDigitalPaymentContainerTitle>
          <FormattedMessage id="customer.details.digital.payment.novalnet.form.title" />
        </StyledDigitalPaymentContainerTitle>

        {iframeLoading && <Loading showLogo />}

        {iframeUrl && (
          <StyledDigitalPaymentContainer hasApplePay={hasApplePay} id="novalnet_payment_form" />
        )}
      </StyledFormGroup>
    </StyledForm>
  );
}

export default DigitalPaymentEditor;
