import React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { styled, css } from '@mui/material';
import { CSSTransition } from 'react-transition-group';

import { GET_DOCUMENTS, useReady, documentsOrder } from '../shared';
import { MIN_WIDTH_MD } from '../theme/breakpoints';

import { BackButton, Container, Paper, Paragraph, PageTitle } from './common';
import { Button, DownloadLink } from './ui';

const StyledButton = styled(Button)`
  margin-top: 2rem;
  margin: 2rem auto 0;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-left: 0;
  }
`;

const StyledInfo = styled('div')(
  ({ theme }) => css`
    margin-top: 1rem;
    font-size: 0.688rem;
    color: ${theme.palette.main.professional_blue};
    text-align: center;

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 0.875rem;
    }
  `
);

const sortDocuments = (data) => {
  if (!Array.isArray(data?.documents) || data?.documents?.length < 1) return [];

  const maxSortNum = documentsOrder.length;

  const documentWithNums = data.documents.map((doc, index) => {
    const docOrder = documentsOrder.find((order) => order.name === doc.name);

    return { ...doc, num: docOrder ? docOrder.numContractDoc : maxSortNum + index };
  });

  return documentWithNums.sort((a, b) => a.num - b.num);
};

const ContractDocs = () => {
  const showZip = false;
  const ready = useReady();
  const { data } = useQuery(GET_DOCUMENTS);

  const documents = sortDocuments(data);

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <BackButton to="/policy-info" />

        <PageTitle>
          <FormattedMessage id="contract.docs.title" />
        </PageTitle>

        <Paragraph>
          <FormattedMessage id="contract.docs.description" />
        </Paragraph>

        <Paper style={{ paddingTop: 0, paddingBottom: 0 }}>
          {documents &&
            documents.map(
              (doc) =>
                doc &&
                doc.url &&
                doc.name && (
                  <DownloadLink link={doc.url} name={doc.name} key={doc.name} target="_blank">
                    <FormattedMessage id={`document.${doc.name}`} />
                  </DownloadLink>
                )
            )}

          {showZip && (
            <>
              <StyledButton variant="outline">
                <FormattedMessage id="contract.docs.download.all" />
              </StyledButton>

              <StyledInfo>
                <FormattedMessage id="contract.docs.download.all.subtext" />
              </StyledInfo>
            </>
          )}
        </Paper>
      </Container>
    </CSSTransition>
  );
};

export default ContractDocs;
