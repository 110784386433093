import { useQuery } from '@apollo/client';
import { GET_VIDEO_CONSULTATIONS } from '../../../../shared';
import {
  CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT,
  useClaimUploadFlowSource,
} from '../../claimUploadFlowSource';
import {
  PARAM_CLAIM_ID,
  PETOLO_EDIT_STEP_DOCUMENT_DATE,
  PETOLO_EDIT_STEP_TREATMENT_DATE,
  PETOLO_EDIT_STEP_CUSTOMER_NOTE,
  PETOLO_EDIT_STEP_REVIEW,
} from '../../edit/editClaimFormSteps';
import {
  PETOLO_DOCUMENT_TYPE_INVOICE,
  PETOLO_DOCUMENT_TYPE_TREATMENT_PLAN,
  PETOLO_TREATMENT_CATEGORY_OTHERS,
} from '../../claimFormData/newClaimFormData';
import {
  PETOLO_STEP_CONSULTATION_QUESTION,
  PETOLO_STEP_DOCUMENT_DATE,
  PETOLO_STEP_TREATMENT_DATE,
  PETOLO_STEP_CUSTOMER_NOTE,
  PETOLO_STEP_REVIEW,
} from '../../new/newClaimsFormSteps';

const getBackButtonLinkForExistingClaim = (documentType) => {
  switch (documentType) {
    case PETOLO_DOCUMENT_TYPE_INVOICE:
      return PETOLO_EDIT_STEP_TREATMENT_DATE;
    case PETOLO_DOCUMENT_TYPE_TREATMENT_PLAN:
      return PETOLO_EDIT_STEP_DOCUMENT_DATE;
    default:
      return PETOLO_EDIT_STEP_DOCUMENT_DATE;
  }
};

const getBackButtonLinkForNewClaim = (documentType) => {
  switch (documentType) {
    case PETOLO_DOCUMENT_TYPE_INVOICE:
      return PETOLO_STEP_TREATMENT_DATE;
    case PETOLO_DOCUMENT_TYPE_TREATMENT_PLAN:
      return PETOLO_STEP_DOCUMENT_DATE;
    default:
      return PETOLO_STEP_DOCUMENT_DATE;
  }
};

export const useTreatmentAmountPetoloBackButtonLink = (claimId, documentType) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (claimId) {
    const backButtonLink = getBackButtonLinkForExistingClaim(documentType);
    const backButtonLinkWithClaimId = backButtonLink.replace(PARAM_CLAIM_ID, claimId);
    return withSource(backButtonLinkWithClaimId);
  }

  const backButtonLink = getBackButtonLinkForNewClaim(documentType);
  return withSource(backButtonLink);
};

export const useTreatmentAmountPetoloNextButtonLink = (claimId, treatmentCategories) => {
  const [source, withSource] = useClaimUploadFlowSource();

  const { data: consultationData } = useQuery(GET_VIDEO_CONSULTATIONS);
  const hasVideoConsultations = Array.isArray(consultationData?.videoConsultations)
    ? consultationData.videoConsultations.length > 0
    : false;

  if (!!claimId && source === CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT) {
    const nextButtonLink = PETOLO_EDIT_STEP_REVIEW.replace(PARAM_CLAIM_ID, claimId);
    return withSource(nextButtonLink);
  }

  if (claimId) {
    const nextButtonLink = PETOLO_EDIT_STEP_CUSTOMER_NOTE.replace(PARAM_CLAIM_ID, claimId);
    return withSource(nextButtonLink);
  }

  if (hasVideoConsultations) {
    return withSource(PETOLO_STEP_CONSULTATION_QUESTION);
  }

  if (
    Array.isArray(treatmentCategories) &&
    treatmentCategories.length === 1 &&
    treatmentCategories[0] !== PETOLO_TREATMENT_CATEGORY_OTHERS
  ) {
    return withSource(PETOLO_STEP_REVIEW);
  }

  return withSource(PETOLO_STEP_CUSTOMER_NOTE);
};
