import React from 'react';
import PropTypes from 'prop-types';

import { imgLinks } from '../../../shared';

import OnboardCard from './OnboardCard';
import {
  StyledClaimOnBoardCardParent,
  StyledClaimOnBoardCardWrapper,
  StyledClaimOnBoardImageSuccess1,
  StyledClaimOnBoardImageSuccess2,
  StyledClaimOnBoardImageSuccess3,
  StyledClaimOnBoardImageSuccessLine1,
  StyledClaimOnBoardImageSuccessLine2,
} from './UserClaimsOnboardDentolo.styled';

const UserClaimsOnboardCard3 = ({ titleHtml, bodyText }) => {
  return (
    <OnboardCard titleHtml={titleHtml} bodyText={bodyText}>
      <StyledClaimOnBoardCardParent>
        <StyledClaimOnBoardCardWrapper style={{ flexDirection: 'row' }}>
          <StyledClaimOnBoardImageSuccess1
            src={imgLinks['icon.user.claim.onboard.success']}
            alt=""
          />

          <StyledClaimOnBoardImageSuccessLine1 />

          <StyledClaimOnBoardImageSuccess2
            src={imgLinks['icon.user.claim.onboard.success']}
            alt=""
          />

          <StyledClaimOnBoardImageSuccessLine2 />

          <StyledClaimOnBoardImageSuccess3
            src={imgLinks['icon.user.claim.onboard.big.success']}
            alt=""
          />
        </StyledClaimOnBoardCardWrapper>
      </StyledClaimOnBoardCardParent>
    </OnboardCard>
  );
};

UserClaimsOnboardCard3.propTypes = {
  titleHtml: PropTypes.string,
  bodyText: PropTypes.string,
};

export default UserClaimsOnboardCard3;
