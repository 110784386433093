import { styled, css } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import { Link } from '../ui';
import { Container } from '../common';
import { variantStyles } from '../ui/styled/Button.styled';

export const StyledPolicyDetailsContainer = styled(Container)`
  max-width: 840px;
`;

export const StyledPolicyDetailsTitle = styled('h4')(
  ({ theme }) => css`
    font-size: 1.1rem;
    font-weight: 700;
    color: ${theme.palette.main.professional_blue};
    opacity: 0.5;

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.25rem;
    }
  `
);

export const StyledPolicyDetailsPolicyName = styled('h3')(
  ({ theme }) => css`
    font-size: 1.5rem;
    font-weight: 700;
    color: ${theme.palette.main.professional_blue};

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.875rem;
    }
  `
);

export const StyledPolicyDetailsLink = styled(Link)`
  margin: 1.5rem auto 0;
`;

export const StyledPolicyDetailsText = styled('div')(
  ({ theme, disabled }) => css`
    font-size: 1.1rem;
    font-weight: 450;
    color: ${theme.palette.text.subtext};

    a {
      ${variantStyles(theme, 'nostyle', disabled)}
    }

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.185rem;
    }
  `
);

export const StyledPolicyDetailsTextLg = styled('div')(
  ({ theme, textAlign }) => css`
    font-size: 1.25rem;
    font-weight: 700;
    color: ${theme.palette.main.professional_blue};
    text-align: ${textAlign || 'left'};
  `
);

export const StyledPolicyDetailsSectionTitle = styled(StyledPolicyDetailsTextLg)`
  margin-bottom: 1rem;
`;

export const StyledPolicyDetailsList = styled('div')`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};

  & + & {
    margin-top: 1rem;
  }
`;

export const StyledPolicyDetailsNote = styled(StyledPolicyDetailsText)(
  ({ theme }) => css`
    margin-top: 1.5rem;
    color: ${theme.palette.main.professional_blue};
  `
);
