import { useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { GET_CLAIM } from '../../../../shared';
import {
  CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT,
  CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT,
  CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT,
  useClaimUploadFlowSource,
} from '../../claimUploadFlowSource';
import { VITOLO_EDIT_STEP_DOCUMENT_DATE, PARAM_CLAIM_ID } from '../../edit/editClaimFormSteps';
import {
  VITOLO_DOCUMENT_TYPE_INVOICE,
  VITOLO_DOCUMENT_TYPE_TREATMENT_PLAN,
  VITOLO_IS_EXISTING_CLAIM_ANSWER_NO,
  VITOLO_IS_EXISTING_CLAIM_ANSWER_YES,
} from '../../claimFormData/newClaimFormData';
import {
  VITOLO_STEP_DOCUMENT_DATE,
  VITOLO_STEP_DOCUMENT_TYPE,
  VITOLO_STEP_EXISTING_CLAIMS,
  VITOLO_STEP_EXISTING_CLAIM_QUESTION,
} from '../../new/newClaimsFormSteps';
import { isReimbursementPromised, isMissingInvoice, isMissingTreatmentPlan } from './claimUtils';

const getBackButtonLinkForNewReimbursement = (documentType, claimsCount, isExistingClaim) => {
  if (documentType === VITOLO_DOCUMENT_TYPE_INVOICE && claimsCount > 0) {
    if (isExistingClaim === VITOLO_IS_EXISTING_CLAIM_ANSWER_YES) {
      return VITOLO_STEP_EXISTING_CLAIMS;
    }

    if (isExistingClaim === VITOLO_IS_EXISTING_CLAIM_ANSWER_NO) {
      return VITOLO_STEP_EXISTING_CLAIM_QUESTION;
    }
  }

  return VITOLO_STEP_DOCUMENT_TYPE;
};

export const useUploadTreatmentDocumentVitoloBackButtonLink = (
  claimId,
  documentType,
  claimsCount,
  isExistingClaim
) => {
  const [source, withSource] = useClaimUploadFlowSource();

  switch (source) {
    case CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT: {
      const backButtonLink = getBackButtonLinkForNewReimbursement(
        documentType,
        claimsCount,
        isExistingClaim
      );
      return withSource(backButtonLink);
    }
    case CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT:
      return withSource(VITOLO_STEP_EXISTING_CLAIMS);
    case CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT:
      return `/user-claims-info/${claimId}`;
    default:
      return '/user-claims-info';
  }
};

const getNextButtonLinkForExistingClaim = (claim, source) => {
  // If the user goes through the New Reimbursement flow, we obtain information regarding the document
  if (
    source === CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT ||
    source === CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT
  ) {
    return VITOLO_EDIT_STEP_DOCUMENT_DATE;
  }

  // If the user goes through the Upload Document flow, we check the status of the claim first
  if (claim) {
    const reimbursementPromised = isReimbursementPromised(claim);
    const missingInvoice = isMissingInvoice(claim);
    const missingTreatmentPlan = isMissingTreatmentPlan(claim);

    if (reimbursementPromised || missingInvoice || missingTreatmentPlan) {
      return VITOLO_EDIT_STEP_DOCUMENT_DATE;
    }
  }
  return '';
};

export const useUploadTreatmentDocumentVitoloNextButtonLink = (claimId) => {
  const [source, withSource] = useClaimUploadFlowSource();

  const { data: claimData } = useQuery(GET_CLAIM, {
    variables: { id: Number(claimId) },
    skip: !claimId,
  });

  if (claimId) {
    const nextButtonLink = getNextButtonLinkForExistingClaim(claimData?.claim, source);
    const nextButtonLinkWithClaimId = nextButtonLink.replace(PARAM_CLAIM_ID, claimId);
    return nextButtonLinkWithClaimId ? withSource(nextButtonLinkWithClaimId) : '';
  }

  return withSource(VITOLO_STEP_DOCUMENT_DATE);
};

export const usePrefillSomeFormFieldsForExistingVitoloClaim = (claimId, setDocumentType) => {
  const [source] = useClaimUploadFlowSource();

  const { data: claimData } = useQuery(GET_CLAIM, {
    variables: { id: Number(claimId) },
    skip: !claimId,
  });
  const claim = claimData?.claim;

  const reimbursementPromised = isReimbursementPromised(claim);
  const missingInvoice = isMissingInvoice(claim);
  const missingTreatmentPlan = isMissingTreatmentPlan(claim);

  useEffect(() => {
    if (source === CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT) {
      if (reimbursementPromised || missingInvoice) {
        setDocumentType(VITOLO_DOCUMENT_TYPE_INVOICE);
      } else if (missingTreatmentPlan) {
        setDocumentType(VITOLO_DOCUMENT_TYPE_TREATMENT_PLAN);
      }
    } else if (source === CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT) {
      setDocumentType(VITOLO_DOCUMENT_TYPE_INVOICE);
    }
  }, [source, reimbursementPromised, missingInvoice, missingTreatmentPlan, setDocumentType]);
};
