import React from 'react';

import ListWithRadioButton from '../common/ListWithRadioButton';
import {
  useNewPetoloFormDataValue,
  PETOLO_LINK_VIDEO_CONSULTATION,
  PETOLO_LINK_VIDEO_CONSULTATION_YES,
  PETOLO_LINK_VIDEO_CONSULTATION_NO,
  PETOLO_CUSTOMER_NOTE,
  PETOLO_VIDEO_CONSULTATION_ID,
  PET_HEALTH_TREATMENT_CATEGORIES,
} from '../../claimFormData/newClaimFormData';
import {
  useLinkVideoConsultationQuestionBackButtonLink,
  useLinkVideoConsultationQuestionNextButtonLink,
} from './linkVideoConsultationQuestionHooks';

const consultationQuestionConfig = [
  {
    label: 'petolo.new_claim.consultation_question.option.yes',
    value: PETOLO_LINK_VIDEO_CONSULTATION_YES,
  },
  {
    label: 'petolo.new_claim.consultation_question.option.no',
    value: PETOLO_LINK_VIDEO_CONSULTATION_NO,
  },
];

export default function LinkVideoConsultationQuestion() {
  const [treatmentCategories] = useNewPetoloFormDataValue(PET_HEALTH_TREATMENT_CATEGORIES);

  const [linkVideoConsultationAnswer, setlinkVideoConsultationAnswer] = useNewPetoloFormDataValue(
    PETOLO_LINK_VIDEO_CONSULTATION
  );
  const [, /* selectedVideoConsultationId */ setVideoConsultationId] = useNewPetoloFormDataValue(
    PETOLO_VIDEO_CONSULTATION_ID
  );
  const [, /* customerNote */ setCustomerNote] = useNewPetoloFormDataValue(PETOLO_CUSTOMER_NOTE);

  const handleLinkVideoConsultationAnswerChange = (value) => {
    if (value !== linkVideoConsultationAnswer) {
      setVideoConsultationId('');
      setCustomerNote('');
      setlinkVideoConsultationAnswer(value);
    }
  };

  const backButtonLink = useLinkVideoConsultationQuestionBackButtonLink();
  const nextButtonLink = useLinkVideoConsultationQuestionNextButtonLink(
    linkVideoConsultationAnswer,
    treatmentCategories
  );

  return (
    <ListWithRadioButton
      name="consultation_question"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId="petolo.new_claim.consultation_question.page.title"
      listConfig={consultationQuestionConfig}
      selected={linkVideoConsultationAnswer}
      setSelected={handleLinkVideoConsultationAnswerChange}
    />
  );
}
