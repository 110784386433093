import React, { useCallback, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import queryString from 'query-string';
import { Route, useHistory, matchPath, Prompt } from 'react-router-dom';

import useBeforeunload from '../../hooks/useBeforeunload';
import { GET_CLAIMS, GET_DENTISTS } from '../../shared';
import {
  clearNewDentoloClaimFormData,
  relevantDentoloClaimsForList,
} from './claimFormData/newClaimFormData';
import { DENTOLO_EDIT_STEP_MAIN, PARAM_CLAIM_ID } from './edit/editClaimFormSteps';
import {
  CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT,
  CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT,
} from './claimUploadFlowSource';
import { DENTOLO_STEP_EXISTING_CLAIMS, DENTOLO_STEP_MAIN } from './new/newClaimsFormSteps';

import EditDentoloClaim from './edit/EditDentoloClaim';
import NewDentoloClaim from './new/NewDentoloClaim';

export default function DentoloClaims() {
  const intl = useIntl();
  const history = useHistory();

  const routeMatch = matchPath(history?.location?.pathname, {
    path: `${DENTOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}`,
  });

  const claimId =
    routeMatch && routeMatch.params[`${PARAM_CLAIM_ID.substring(1)}`]
      ? routeMatch.params[`${PARAM_CLAIM_ID.substring(1)}`]
      : '';

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [claimSubmitted, setClaimSubmitted] = useState(false);
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

  // Preload dentists so that the data is already available when the DentistDetails page is loaded
  useQuery(GET_DENTISTS);

  const { data: claimsData } = useQuery(GET_CLAIMS);

  const relevantClaims = relevantDentoloClaimsForList(claimsData?.claims || []);

  // Prompt for page refresh
  const alertUser = useCallback((ev) => {
    ev.preventDefault();
    ev.returnValue = ''; // eslint-disable-line no-param-reassign
  }, []);

  useBeforeunload(alertUser);

  useEffect(() => {
    if (isInitialPageLoad) {
      setIsInitialPageLoad(false);
      setSelectedDocuments([]);
      clearNewDentoloClaimFormData();

      const newClaimPageUrl = DENTOLO_STEP_MAIN;
      const isNewClaimPage = history?.location?.pathname === newClaimPageUrl;

      const selectExistingClaimPageUrl = DENTOLO_STEP_EXISTING_CLAIMS;
      const isSelectExistingClaimPage = history?.location?.pathname === selectExistingClaimPageUrl;

      const existingPageUrl = `${DENTOLO_EDIT_STEP_MAIN}/${claimId}`;
      const isExistingClaimPage = history?.location?.pathname === existingPageUrl;

      const params = queryString.parse(history?.location?.search);
      const source = params?.source;

      if (!isNewClaimPage && !isExistingClaimPage && !isSelectExistingClaimPage) {
        if (!!claimId && source === CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT) {
          history.push(`${existingPageUrl}${history?.location?.search || ''}`);
        } else if (source === CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT) {
          history.push(`${selectExistingClaimPageUrl}${history?.location?.search || ''}`);
        } else if (source) {
          history.push(`${newClaimPageUrl}${history?.location?.search || ''}`);
        } else {
          history.push('/user-claims-info');
        }
      }
    }
  }, [isInitialPageLoad, history, claimId]);

  const showPrompt = !(claimSubmitted || selectedDocuments?.length < 1);

  return (
    <>
      {/* Prompt user data will be lost if navigate outside of the claims flow */}
      <Prompt
        when={showPrompt}
        message={({ pathname }) =>
          matchPath(pathname, { path: DENTOLO_STEP_MAIN }) ||
          matchPath(pathname, { path: `${DENTOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}` })
            ? true
            : intl.formatMessage({ id: 'new_claim.prompt.message' })
        }
      />

      <Route path={DENTOLO_STEP_MAIN}>
        <NewDentoloClaim
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          setClaimSubmitted={setClaimSubmitted}
          relevantClaims={relevantClaims}
        />
      </Route>

      <Route path={`${DENTOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}`}>
        <EditDentoloClaim
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          setClaimSubmitted={setClaimSubmitted}
          relevantClaims={relevantClaims}
        />
      </Route>
    </>
  );
}
