export const CANCELLATION_TYPE_WITHDRAWAL_BY_CUSTOMER = 'withdrawal_by_customer';
export const CANCELLATION_TYPE_REGULAR = 'regular';

export const WITHDRAWAL_CANCELLATION_NO_PAYMENT_NO_CLAIM =
  'withdrawal_cancellation_no_payment_no_claim';
export const WITHDRAWAL_CANCELLATION_EXPORTED_PAYMENT_NO_CLAIM =
  'withdrawal_cancellation_exported_payment_no_claim';
export const WITHDRAWAL_CANCELLATION_WITH_CLAIM = 'withdrawal_cancellation_with_claim';
export const WITHDRAWAL_CANCELLATION_EXPORTED_PAYMENT_WITH_CLAIM =
  'withdrawal_cancellation_exported_payment_with_claim';

export const REGULAR_CANCELATION_INSIDE_MINIMUM_CONTRACT_DURATION_WITH_PAYOUT =
  'regular_cancelation_inside_minimum_contract_duration_with_payout';
export const REGULAR_CANCELATION_WITHOUT_EXPORTED_PAYMENTS_AND_CLAIMS =
  'regular_cancelation_without_exported_payments_and_claims';
export const REGULAR_CANCELATION_EXPORTED_PAYMENTS_WITHOUT_CLAIMS =
  'regular_cancelation_exported_payments_without_claims';
export const REGULAR_CANCELATION_CLAIMS_WITHOUT_EXPORTED_PAYMENTS =
  'regular_cancelation_claims_without_exported_payments';
export const REGULAR_CANCELATION_WITH_EXPORTED_PAYMENTS_AND_CLAIMS =
  'regular_cancelation_with_exported_payments_and_claims';

export const REGULAR_CANCELATION_WITH_IM_COVERAGE = 'regular_cancelation_with_im_coverage';

export const REGULAR_CANCELATION_WITH_VIDEO_CONSULTATION =
  'regular_cancelation_with_video_consultation';
