import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Paragraph } from '../../../components/ui';
import { imgLinks } from '../../../shared';

import {
  StyledLinkToClaimsPageContents,
  StyledLinkToClaimsPageIcon,
  StyledLinkToClaimsPagePaper,
  StyledLinkToClaimsPageTitle,
} from './LinkToClaimsPage.styled';

export default function LinkToClaimsPage() {
  return (
    <StyledLinkToClaimsPagePaper>
      <StyledLinkToClaimsPageIcon src={imgLinks['icon.user.claim.onboard.euro']} alt="money" />

      <StyledLinkToClaimsPageContents>
        <StyledLinkToClaimsPageTitle fontWeight="700">
          <FormattedMessage id="link_to_claims.title" />
        </StyledLinkToClaimsPageTitle>

        <Paragraph>
          <FormattedMessage id="link_to_claims.description.text1" />{' '}
          <Link to="/user-claims-info">
            <FormattedMessage id="link_to_claims.description.link" />
          </Link>{' '}
          <FormattedMessage id="link_to_claims.description.text2" />
        </Paragraph>
      </StyledLinkToClaimsPageContents>
    </StyledLinkToClaimsPagePaper>
  );
}
