import React from 'react';
import fileDownload from 'js-file-download';
import PropTypes from 'prop-types';

import { fetchFile } from '../../shared';
import { useDownloadIcon } from '../../hooks/useIcons';

import { IconButton } from '../common';
import { StyledDownloadLinks } from './styled/DownloadLink.styled';

const handleDownload = (link, name) => {
  const options = {
    method: 'GET',
    responseType: 'blob',
  };
  const request = fetchFile(link, options);
  request((blob) => fileDownload(blob, `${name}.pdf`));
};

const DownloadLink = (props) => {
  const { children, name, link, target, onLinkClick, onButtonClick } = props;

  const downloadIcon = useDownloadIcon();

  const handleHrefClick = () => {
    if (onLinkClick) onLinkClick();
  };

  const handleButtonClick = () => {
    if (onButtonClick) onButtonClick();
    handleDownload(link, name);
  };

  return (
    <StyledDownloadLinks>
      <a href={link} target={target} onClick={handleHrefClick}>
        {children}
      </a>

      <IconButton icon={downloadIcon} size="sm" alt="Herunterladen" onClick={handleButtonClick} />
    </StyledDownloadLinks>
  );
};

DownloadLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  link: PropTypes.string,
  name: PropTypes.string,
  target: PropTypes.string,
};

export default DownloadLink;
