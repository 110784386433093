import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  PET_LIABILITY_DAMAGE_CAUSED_BY,
  PET_LIABILITY_DAMAGE_CAUSED_BY_INSURED_PET,
  PET_LIABILITY_DAMAGE_CAUSED_BY_OTHER,
  PET_LIABILITY_REASON,
  PET_LIABILITY_REASON_ANOTHER_ANIMAL,
  PET_LIABILITY_REASON_PERSON,
  useNewPetLiabilityFormDataValue,
} from '../../claimFormData/newClaimFormDataPetLiability';
import {
  useDamageCausedByPetLiabilityBackButtonLink,
  useDamageCausedByPetLiabilityNextButtonLink,
} from './useDamageCausedByPetLiability';

import ListWithRadioButton from '../common/ListWithRadioButton';

function DamageCausedBy({ petName }) {
  const [damageCausedBy, setDamagedCausedBy] = useNewPetLiabilityFormDataValue(
    PET_LIABILITY_DAMAGE_CAUSED_BY
  );
  const [liabilityReason] = useNewPetLiabilityFormDataValue(PET_LIABILITY_REASON);

  const backButtonLink = useDamageCausedByPetLiabilityBackButtonLink();
  const nextButtonLink = useDamageCausedByPetLiabilityNextButtonLink();

  const damageCausedByConfig = useMemo(() => {
    return [
      {
        noTranslateLabel: petName,
        value: PET_LIABILITY_DAMAGE_CAUSED_BY_INSURED_PET,
      },
      {
        label: 'pet_liability.new_claim.liability_damage_caused_by.other',
        value: PET_LIABILITY_DAMAGE_CAUSED_BY_OTHER,
      },
    ];
  }, [petName]);

  const pageTitleId =
    liabilityReason === PET_LIABILITY_REASON_ANOTHER_ANIMAL ||
    liabilityReason === PET_LIABILITY_REASON_PERSON
      ? 'pet_liability.new_claim.liability_damage_caused_by.page_title.injury'
      : 'pet_liability.new_claim.liability_damage_caused_by.page_title.damage';

  return (
    <ListWithRadioButton
      name="damage_caused_by"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId={pageTitleId}
      listConfig={damageCausedByConfig}
      selected={damageCausedBy}
      setSelected={setDamagedCausedBy}
    />
  );
}

export default DamageCausedBy;

DamageCausedBy.propTypes = {
  petName: PropTypes.string.isRequired,
};
