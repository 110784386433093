import React from 'react';
import { useParams } from 'react-router-dom';

import {
  DENTOLO_DOCUMENT_TYPE,
  DENTOLO_TREATMENT_AMOUNT,
  useNewDentoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import {
  useTreatmentAmountDentoloBackButtonLink,
  useTreatmentAmountDentoloNextButtonLink,
} from './treatmentAmountDentoloHooks';

import TreatmentAmount from './TreatmentAmount';

export default function TreatmentAmountDentolo() {
  const { claimId } = useParams();

  const [documentType] = useNewDentoloFormDataValue(DENTOLO_DOCUMENT_TYPE);

  const [treatmentAmount, setTreatmentAmount] =
    useNewDentoloFormDataValue(DENTOLO_TREATMENT_AMOUNT);

  const backButtonLink = useTreatmentAmountDentoloBackButtonLink(claimId, documentType);
  const nextButtonLink = useTreatmentAmountDentoloNextButtonLink(claimId);

  return (
    <TreatmentAmount
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId="dentolo.new_claim.treatment_amount.page.title"
      inputLabelId="dentolo.new_claim.treatment_amount.form.label.amount"
      treatmentAmount={treatmentAmount}
      setTreatmentAmount={setTreatmentAmount}
      errorMessageId="dentolo.new_claim.treatment_amount.form.error.invalid_treatment_amount"
    />
  );
}
