import React from 'react';
import { FormattedMessage } from 'react-intl';

import { imgLinks, VITOLO_BETTER_DOC_URL } from '../../../shared';

import {
  StyledVitoloBetterDocCard,
  StyledVitoloBetterDocCardImageContainer,
  StyledVitoloBetterDocCardImageMobile,
  StyledVitoloBetterDocCardImageDesktop,
  StyledVitoloBetterDocCardButton,
  StyledVitoloBetterDocCardText,
  StyledVitoloBetterDocCardTitle,
  StyledVitoloBetterDocCardButtonContainer,
} from './VitoloBetterDoc.styled';

function VitoloBetterDoc() {
  return (
    <StyledVitoloBetterDocCard>
      <StyledVitoloBetterDocCardImageContainer>
        <StyledVitoloBetterDocCardImageDesktop
          src={imgLinks['betterdoc.desktop'] || ''}
          alt="Vitolo Better Doc large"
        />
        <StyledVitoloBetterDocCardImageMobile
          src={imgLinks['betterdoc.mobile'] || ''}
          alt="Vitolo Better Doc small"
        />
      </StyledVitoloBetterDocCardImageContainer>

      <div>
        <StyledVitoloBetterDocCardTitle>
          <FormattedMessage id="promotion.carousel.vitolo.better_doc.vitolo.title" />
        </StyledVitoloBetterDocCardTitle>

        <StyledVitoloBetterDocCardText>
          <FormattedMessage id="promotion.carousel.vitolo.better_doc.vitolo.description" />
        </StyledVitoloBetterDocCardText>

        <StyledVitoloBetterDocCardButtonContainer>
          <StyledVitoloBetterDocCardButton href={VITOLO_BETTER_DOC_URL} target="_blank">
            <FormattedMessage id="promotion.carousel.vitolo.better_doc.vitolo.button.label" />
          </StyledVitoloBetterDocCardButton>
        </StyledVitoloBetterDocCardButtonContainer>
      </div>
    </StyledVitoloBetterDocCard>
  );
}

export default VitoloBetterDoc;
