import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const GATracker = (props) => {
  const { children, location } = props;

  useEffect(() => {
    const prod = process.env.NODE_ENV === 'production';

    if (prod && typeof window !== 'undefined' && typeof window.ga === 'function') {
      window.ga('set', 'page', location.pathname);
      window.ga('send', 'pageview');
    }
  }, [location.pathname]);

  return children;
};

export default withRouter(GATracker);
