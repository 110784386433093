import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { MD5, enc } from 'crypto-js';

import useInsuranceInfo from '../hooks/useInsuranceInfo';
import {
  CLEAR_CERTIF_DOC_NOTIFICATION,
  LOCAL_SHOW_ONBOARDS,
  GET_CCDOCUMENTS,
  DASHBOARD_ONBOARD_KEY,
  CCDOCS_LS_SHOW_KEY,
  PETOLO_DASHBOARD_ONBOARD_KEY,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  GET_CLAIMS,
  claimStatus,
  INSURANCE_TYPE_VITOLO,
} from '../shared';

import PolicyInfo from './PolicyInfo';
import ClaimStatusNotifications from './ClaimStatusNotifications';
import { Link } from './ui';
import { Container, Notification } from './common';
import { StyledNotificationText } from './styled/Notification.styled';

const indentifyHotjarUser = (userId) => {
  const scriptChecker = setInterval(function identifyHotjar() {
    const hotjar = window.hj;
    if (typeof hotjar === 'function') {
      try {
        hotjar('identify', userId, {});
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Could not identify hotjar user');
      }
      clearInterval(scriptChecker);
    }
  }, 1000);
};

const Dashboard = ({ customer }) => {
  const history = useHistory();
  const intl = useIntl();
  const claims = useQuery(GET_CLAIMS);

  const [showContributionNotif, setShowContributionNotif] = useState(false);
  const { data: ccdocsData, loading: ccdocsLoading } = useQuery(GET_CCDOCUMENTS);
  const [ccdocs, setCcdocs] = useState([]);
  const [clearCertifDocNotif] = useMutation(CLEAR_CERTIF_DOC_NOTIFICATION, {
    refetchQueries: [{ query: GET_CCDOCUMENTS }],
  });

  const { type: insuranceType } = useInsuranceInfo();

  const showOnboards = useQuery(LOCAL_SHOW_ONBOARDS);
  const showOnboardsData = showOnboards?.data?.showOnboards;
  const showOnboardForDentoloDashboard = Array.isArray(showOnboardsData)
    ? showOnboardsData.find((item) => item?.name === DASHBOARD_ONBOARD_KEY)
    : null;
  const showOnboardDentoloFromCache = showOnboardForDentoloDashboard?.show;
  const showOnboardForPetoloDashboard = Array.isArray(showOnboardsData)
    ? showOnboardsData.find((item) => item?.name === PETOLO_DASHBOARD_ONBOARD_KEY)
    : null;
  const showOnboardPetoloFromCache = showOnboardForPetoloDashboard?.show;

  const setCcdocsSeen = async () => {
    if (ccdocs?.length) {
      await clearCertifDocNotif();
    }
  };
  const dismissCcdocs = async () => {
    await setCcdocsSeen();
    window.sessionStorage.setItem(CCDOCS_LS_SHOW_KEY, false);
  };

  useEffect(() => {
    if (!ccdocsLoading && ccdocsData) {
      const docs = ccdocsData?.contributionConfirmationsDocuments || [];
      setCcdocs(docs);
      const lastCcdocs = Array.isArray(docs) && docs.length > 0 ? docs[docs.length - 1] : null;
      if (docs?.length && lastCcdocs) {
        const showNotif = !lastCcdocs?.seenByCustomer;
        if (showNotif) {
          window.sessionStorage.setItem(CCDOCS_LS_SHOW_KEY, true);
          setShowContributionNotif(showNotif);
        } else {
          setShowContributionNotif(
            showNotif || JSON.parse(window.sessionStorage.getItem(CCDOCS_LS_SHOW_KEY))
          );
        }
      }
    }
  }, [ccdocsData, ccdocsLoading]);

  useEffect(() => {
    const showOnBoardForDentoloDashboardLocalStorageToken =
      window.localStorage.getItem(DASHBOARD_ONBOARD_KEY);
    const showOnboardDentoloFromLocalStorage =
      showOnBoardForDentoloDashboardLocalStorageToken !== 'skip';
    const showOnboardDentolo = showOnboardDentoloFromCache || showOnboardDentoloFromLocalStorage;

    const showOnBoardForPetoloDashboardLocalStorageToken = window.localStorage.getItem(
      PETOLO_DASHBOARD_ONBOARD_KEY
    );
    const showOnboardPetoloFromLocalStorage =
      showOnBoardForPetoloDashboardLocalStorageToken !== 'skip';
    const showOnboardPetolo = showOnboardPetoloFromCache || showOnboardPetoloFromLocalStorage;

    if (insuranceType === INSURANCE_TYPE_DENTOLO && showOnboardDentolo) {
      history.push('/intro-dentolo/1');
    } else if (insuranceType === INSURANCE_TYPE_PETOLO && showOnboardPetolo) {
      history.push('/intro-petolo/1');
    }
  }, [showOnboardDentoloFromCache, showOnboardPetoloFromCache, insuranceType, history]);

  const hotjarUniqueId = MD5(customer?.uuid).toString(enc.Hex);
  useEffect(() => {
    indentifyHotjarUser(hotjarUniqueId);
  }, [hotjarUniqueId]);

  useEffect(() => {
    if (
      insuranceType === INSURANCE_TYPE_VITOLO &&
      claims?.data?.claims?.find((claim) => claim.status === claimStatus.CLOSED)
    ) {
      window.hj('event', 'NPS_VITOLO_SURVEY');
    }
  }, [claims, insuranceType]);
  return (
    <>
      <Container style={{ marginTop: 0 }}>
        <ClaimStatusNotifications />

        {!!showContributionNotif && (
          <div style={{ marginTop: '1rem' }}>
            <Notification type="success" onDismiss={dismissCcdocs}>
              <Link variant="nostyle" to="/payment-overview" onClick={setCcdocsSeen}>
                <StyledNotificationText $title>
                  {intl.formatMessage({ id: 'payment.overview.notification.title' })}
                </StyledNotificationText>

                <StyledNotificationText>
                  {intl.formatMessage({ id: 'payment.overview.notification.text' })}
                </StyledNotificationText>
              </Link>
            </Notification>
          </div>
        )}
      </Container>

      <PolicyInfo />
    </>
  );
};

Dashboard.propTypes = {
  customer: PropTypes.shape({
    gender: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
};

export default Dashboard;
