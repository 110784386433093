import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { CSSTransition } from 'react-transition-group';

import { GET_CUSTOMER, useReady } from '../../shared';

import { Container } from '../../components/common';
import LandingPage from './LandingPage';

export default function ContractCancellation() {
  const ready = useReady();
  const history = useHistory();

  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const contractNotCancelled = !customerData?.customer?.contract?.cancelationDate;

  useEffect(() => {
    if (!customerLoading && !contractNotCancelled) {
      history.replace('/');
    }
  }, [history, customerLoading, contractNotCancelled]);

  if (!customerLoading && contractNotCancelled) {
    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Container>
          <LandingPage />
        </Container>
      </CSSTransition>
    );
  }

  return null;
}
