import React from 'react';

import Footer from '../../features/footer/Footer';
import HeaderWithActions from '../Header/HeaderWithActions';
import StyledPageContent from './Page.styled';

const PageWithHalfHeader = ({ children }) => {
  return (
    <>
      <StyledPageContent>
        <HeaderWithActions hideNavMenu />
        {children}
      </StyledPageContent>

      <Footer />
    </>
  );
};

export default PageWithHalfHeader;
