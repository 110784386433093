import { css, styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import { Accordion, PageTitle } from '../../components/common';
import { Button } from '../../components/ui';

export const StyledContactFormTitle = styled(PageTitle)`
  margin-top: 2rem;
`;

export const StyledContactFormField = styled('div')(
  ({ theme, sameLine }) => css`
    color: ${theme.palette.main.professional_blue};

    & + & {
      margin-top: 1rem;
    }

    > label,
    > span {
      display: ${sameLine ? 'inline-block' : 'block'};
      margin-bottom: ${sameLine ? 0 : '0.5rem'};
      font-weight: 700;
    }

    > select,
    > textarea {
      width: 100%;
      padding: 0.5rem;
      border: 0.1rem solid ${theme.palette.main.product_blue};
      border-radius: 4px;
      font-size: 1rem;
      color: ${theme.palette.main.professional_blue};
    }

    > select {
      appearance: none;
      padding-right: 2rem;
      background: url(/images/user-area-icons/general/icon.caret-down-24x24.svg) no-repeat #fff;
      background-position: center right 0.375rem;
    }
  `
);

export const StyledContactSubmitButton = styled(Button)`
  margin: 0 auto;
`;

export const StyledAccordion = styled(Accordion)`
  margin-top: 1.75rem;
  margin-bottom: 3rem;
`;

export const StyledContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 3rem;
  row-gap: 0.5rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row;
    padding-left: 3.5rem;
    column-gap: 1.25rem;
  }
`;

export const StyledWorkingTimes = styled('div')`
  display: flex;
  flex-direction: column;

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row;
  }
`;

export const StyledContactInfoBanner = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
    margin: 1rem 0;
    padding: 0.5rem;
    background-color: ${theme.palette.background.info};
    border: 1px solid;
    border-color: ${theme.palette.background.button};
    border-radius: 5px;
  `
);

export const StyledContactInfoBannerIconWrapper = styled('div')`
  flex: 0 0 16px;
  width: 1rem;
  height: 100%;

  svg {
    width: 100%;
    height: auto;
  }
`;

export const StyledContactInfoBannerText = styled('div')(
  ({ theme }) => css`
    font-size: 0.75rem;
    font-weight: 450;
    color: ${theme.palette.main.professional_blue};

    > a {
      color: ${theme.palette.main.professional_blue};
      text-decoration: underline;
    }

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1rem;
    }
  `
);
