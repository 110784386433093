import { css, styled } from '@mui/material';
import PropTypes from 'prop-types';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

const StyledText = styled('p')(
  ({ theme, fontSize, fontWeight, fontColor, lineHeight }) => css`
    color: ${fontColor || theme.palette.main.professional_blue};
    font-size: ${`calc(${fontSize} - 25%)}`};
    font-weight: ${fontWeight};
    line-height: ${lineHeight};

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: ${fontSize};
    }
  `
);

export default StyledText;

StyledText.defaultProps = {
  fontSize: '1.25rem',
  fontWeight: 450,
  lineHeight: '1.5rem',
};

StyledText.propTypes = {
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  lineHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
