import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { GET_CLAIM, UPLOAD_VITOLO_CUSTOMER_CLAIM_DOCUMENTS } from '../../shared';
import { clearNewVitoloClaimFormData } from './claimFormData/newClaimFormData';

const useVitoloClaims = (claimId) => {
  const history = useHistory();

  const [uploadVitoloCustomerClaimDocuments, { data, loading, error }] = useMutation(
    UPLOAD_VITOLO_CUSTOMER_CLAIM_DOCUMENTS,
    {
      refetchQueries: claimId ? [{ query: GET_CLAIM, variables: { id: Number(claimId) } }] : [],
    }
  );

  const submitClaim = async (props) => {
    const { payload, setSelectedDocuments, setClaimSubmitted, clearFormData = true } = props || {};

    if (setClaimSubmitted) {
      setClaimSubmitted(true);
    }

    try {
      const res = await uploadVitoloCustomerClaimDocuments({
        variables: payload,
      });

      if (setSelectedDocuments) {
        setSelectedDocuments([]);
      }

      if (clearFormData) {
        clearNewVitoloClaimFormData();
      }

      const success = res?.data?.claimFilesUpload?.status === 'success';

      if (success) {
        history.push(`/user-claims/file-upload/success?count=${payload?.files?.length}`);
      } else {
        throw new Error();
      }
    } catch (err) {
      history.push('/user-claims/file-upload/failure');
    }
  };

  return [submitClaim, { data, loading, error }];
};

export default useVitoloClaims;
