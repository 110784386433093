import React, { forwardRef } from 'react';
import { css, styled } from '@mui/material';
import PropTypes from 'prop-types';

import { Input } from '../common';

const StyledLabel = styled('label')(
  ({ theme, required }) => css`
    display: block;
    margin-bottom: 0.5rem;
    width: 100%;
    color: ${theme.palette.main.professional_blue};

    ${required &&
    css`
      &::after {
        content: ' *';
        color: ${theme.palette.main.professional_light_blue};
      }
    `}
  `
);

const StyledInput = styled(Input)(
  ({ theme }) => css`
    padding: 0.5rem 1rem;
    height: 3.5rem;
    font-size: 1rem;
    font-weight: 450;
    color: ${theme.palette.main.professional_blue};
    border: 1px solid ${theme.palette.main.professional_blue};
    border-radius: 5px;

    &::placeholder {
      color: ${theme.palette.main.professional_light_blue};
    }

    &[type='number'] {
      appearance: textfield;
      width: 100%;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        margin: 0;
        appearance: none;
      }
    }
  `
);

const InputField = forwardRef((props, ref) => {
  const {
    id,
    placeholder,
    labelText,
    labelStyle,
    required,
    type,
    name,
    onChange,
    onKeyDown,
    onBlur,
    value,
    maxLength,
    minLength,
    pattern,
    ...rest
  } = props;

  return (
    <div>
      {labelText && (
        <StyledLabel style={labelStyle} htmlFor={id} required={required}>
          {labelText || ''}
        </StyledLabel>
      )}

      <StyledInput
        placeholder={placeholder || ''}
        required={!!required}
        type={type || 'text'}
        name={name}
        id={id}
        ref={ref}
        pattern={pattern}
        onKeyDown={onKeyDown}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        maxLength={maxLength}
        minLength={minLength}
        {...rest}
      />
    </div>
  );
});

InputField.defaultProps = {
  onChange: () => {},
  onKeyDown: () => {},
  onBlur: () => {},
};

InputField.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  labelText: PropTypes.string,
  labelStyle: PropTypes.shape({}),
  required: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onBlur: PropTypes.func,
};

export default InputField;
