import { styled, css } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';
import Icon from '../Icon';

export const StyledAccordion = styled('div')(
  ({ theme }) => css`
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: ${theme.palette.common.white};
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    & + & {
      margin-top: 0.5rem;
    }
  `
);

export const StyledAccordionContainer = styled('div')``;

export const StyledAccordionTitle = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
    padding: 1rem;
    color: ${theme.palette.main.professional_blue};
    cursor: pointer;

    > div {
      flex: 1;
      margin-right: 1.5rem;
    }
  `
);

export const StyledAccordionTitleIcon = styled(Icon)`
  width: 2rem;
  height: auto;

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 2.5rem;
    height: auto;
  }
`;

export const StyledAccordionChevron = styled(Icon)(
  ({ expanded }) => css`
    transition: transform 0.3s ease-in-out;

    ${expanded &&
    css`
      transform: rotate(-180deg);
      transform-origin: center;
    `}
  `
);

export const StyledAccordionSummary = styled('div')(
  ({ contentEl }) => css`
    overflow: hidden;
    max-height: 0px;
    transition: max-height 0.4s ease-in-out;

    &[aria-expanded='true'] {
      max-height: ${contentEl?.scrollHeight ? `${contentEl?.scrollHeight}px` : '1000px'};
    }
  `
);

export const StyledAccordionContent = styled('div')(
  ({ theme }) => css`
    padding: 0.5rem 1rem 1.25rem;
    color: ${theme.palette.main.professional_blue};
    opacity: 0;
    transition-delay: 0.2s;
    transition: opacity 0.2s ease-in-out;

    &[aria-expanded='true'] {
      opacity: 1;
    }
  `
);
