import React from 'react';

import CheckIcon from './CheckIcon';
import { Separator } from '../common';
import {
  StyledPolicyDetailsSectionTitle,
  StyledPolicyDetailsList,
  StyledPolicyDetailsText,
  StyledPolicyDetailsTextLg,
} from '../styled/PolicyDetails.styled';

const AddOns = ({ details }) => {
  const contents = details?.contents;

  if (!Array.isArray(contents)) return null;

  return (
    <>
      <StyledPolicyDetailsSectionTitle>{details?.title}</StyledPolicyDetailsSectionTitle>

      {contents.map((item) => (
        <StyledPolicyDetailsList alignItems="flex-start" key={item?.text || item?.subtext}>
          <CheckIcon />

          <div>
            <StyledPolicyDetailsText style={{ marginBottom: '0.5rem' }}>
              {item?.text}
            </StyledPolicyDetailsText>
            <StyledPolicyDetailsTextLg>{item?.subtext}</StyledPolicyDetailsTextLg>
          </div>
        </StyledPolicyDetailsList>
      ))}

      <Separator />
    </>
  );
};

export default AddOns;
