import React from 'react';
import PropTypes from 'prop-types';

import { getInsCategoryImg } from './navMenuUtils';

import {
  StyledNavMenuMobileSelectUserLink,
  StyledNavMenuMobileSelectUserLinkCheckIcon,
  StyledNavMenuMobileSelectUserLinkTitle,
  StyledNavMenuMobileSelectUserLinkTitleWrapper,
} from './styled/NavMenuMobile.styled';

function NavMenuMobileSelectUserLink(props) {
  const {
    tabIndex,
    policyName,
    customerName,
    insuranceCategory,
    isContractEnded,
    isCurrentCustomer,
    onClick,
  } = props;

  const handleSelectUser = () => {
    if (isCurrentCustomer) return;

    onClick();
  };

  return (
    <StyledNavMenuMobileSelectUserLink
      inActive={isContractEnded}
      isCurrentCustomer={isCurrentCustomer}
      onClick={handleSelectUser}
      tabIndex={tabIndex}
    >
      <StyledNavMenuMobileSelectUserLinkTitleWrapper>
        <img src={getInsCategoryImg(insuranceCategory)} alt={customerName} />

        <StyledNavMenuMobileSelectUserLinkTitle>
          <span>{customerName}</span>
          <span>{policyName}</span>
        </StyledNavMenuMobileSelectUserLinkTitle>
      </StyledNavMenuMobileSelectUserLinkTitleWrapper>

      {isCurrentCustomer && (
        <StyledNavMenuMobileSelectUserLinkCheckIcon
          icon="icon.menu.blue.mark"
          alt="selected user"
        />
      )}
    </StyledNavMenuMobileSelectUserLink>
  );
}

export default NavMenuMobileSelectUserLink;

NavMenuMobileSelectUserLink.defaultProps = {
  isContractEnded: false,
  isCurrentCustomer: false,
  onClick: () => null,
};

NavMenuMobileSelectUserLink.defaultProps = {
  tabIndex: PropTypes.number,
  policyName: PropTypes.string,
  customerName: PropTypes.string,
  insuranceCategory: PropTypes.string,
  isContractEnded: PropTypes.bool,
  isCurrentCustomer: PropTypes.bool,
  onClick: PropTypes.func,
};
