import { imgLinks } from '../../../shared';

const getReferralUrlToUse = (refUrl, elementId) => {
  if (!elementId) return refUrl;

  const element = document.getElementById(elementId);
  const referralLinkFromElement = element?.dataset.referralLink;

  return referralLinkFromElement || refUrl;
};

const shareToFacebook = (params) => {
  const { elementIdWithReferralLink, refUrl } = params;

  const urlToUse = getReferralUrlToUse(refUrl, elementIdWithReferralLink);

  window.open(
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToUse)}`,
    'pop',
    'width=600, height=400, scrollbars=no'
  );
};

const shareToWhatsapp = (params) => {
  const { intl, elementIdWithReferralLink, refUrl, waMessageId } = params;

  const urlToUse = getReferralUrlToUse(refUrl, elementIdWithReferralLink);

  const waMessage = intl.formatMessage(
    { id: waMessageId },
    { referralUrl: encodeURIComponent(urlToUse) }
  );

  const URL = `whatsapp://send?text=${waMessage || ''}`;
  window.open(URL, '_blank');
};

const shareToEmail = (params) => {
  const { intl, elementIdWithReferralLink, refUrl, emailContentIds } = params;

  const urlToUse = getReferralUrlToUse(refUrl, elementIdWithReferralLink);

  const { to: emailTo, subjectId, bodyId } = emailContentIds || {};

  const emailSubject = intl.formatMessage({ id: subjectId });
  const emailBody = intl.formatMessage(
    { id: bodyId },
    { referralUrl: encodeURIComponent(urlToUse) }
  );

  const emailLink = `mailto:${emailTo}?subject=${emailSubject}&body=${emailBody}`;
  window.open(emailLink);
};

// eslint-disable-next-line import/prefer-default-export
export const getReferralShareToSocialList = (params) => {
  const { fb, whatsapp, email } = params || {};

  return [
    {
      id: 'fb',
      testId: fb?.testId || '',
      image: imgLinks[fb?.icon],
      click: shareToFacebook,
    },
    {
      id: 'whatsapp',
      testId: whatsapp?.testId || '',
      image: imgLinks[whatsapp?.icon],
      click: shareToWhatsapp,
    },
    {
      id: 'email',
      testId: email?.testId || '',
      image: imgLinks[email?.icon],
      click: shareToEmail,
    },
  ];
};
