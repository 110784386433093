import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const fetchData = (url = '', options = {}) => {
  const controller = new AbortController();
  const { signal } = controller || {};

  return async (onSuccess = () => {}, onError = () => {}) => {
    try {
      const res = await fetch(url, { ...options, signal });
      const json = await res.json();
      onSuccess(json);
    } catch (error) {
      onError(error);
    }

    return () => controller.abort();
  };
};

export const fetchFile = (url = '', options = {}) => {
  const controller = new AbortController();
  const { signal } = controller || {};

  return async (onSuccess = () => {}, onError = () => {}) => {
    try {
      const res = await fetch(url, { ...options, signal });
      const json = await res.blob();
      onSuccess(json);
    } catch (error) {
      onError(error);
    }

    return () => controller.abort();
  };
};

const getLocalStorage = () => window.localStorage;

// export const saveToLocalStorage = (key, value) => {
//   try {
//     const serializedState = JSON.stringify(value);
//     const ls = getLocalStorage();
//     ls.setItem(key, serializedState);
//   } catch (e) {
//     console.error(e.message);
//   }
// };

// export const loadFromLocalStorage = (key) => {
//   try {
//     const ls = getLocalStorage();
//     const serializedState = ls.getItem(key);
//     return serializedState !== null ? JSON.parse(serializedState) : null;
//   } catch (e) {
//     console.error(e.message);
//     return null;
//   }
// };

export const directlyLoadFromLocalStorage = (key) => {
  try {
    const ls = getLocalStorage();
    const serializedState = ls.getItem(key);
    return serializedState;
  } catch (e) {
    console.error(e.message); // eslint-disable-line no-console
    return null;
  }
};

// export const deleteFromLocalStorage = (key) => {
//   const ls = getLocalStorage();
//   ls.removeItem(key);
// };

// export const getCookieValue = (cname) => {
//   var name = cname + '=';
//   var decodedCookie = decodeURIComponent(document.cookie);
//   var ca = decodedCookie.split(';');

//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) === ' ') {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) === 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return '';
// };

export const getFailedPayments = (payments) => {
  if (Array.isArray(payments)) {
    return payments.filter((item) => item?.status === 'failed');
  }

  return [];
};

export const showMoney = (value) => {
  const toLocaleStringOptions = { style: 'currency', currency: 'EUR' };

  if (Number.isNaN(Number(value)) || value === 0) {
    return `0,00 €`;
  }
  return new Intl.NumberFormat('de-DE', toLocaleStringOptions).format(value);
};

export const isValidDateString = (str) => {
  const dateArr = str.split('.');
  return dateArr.length === 3 && dateArr.every((val) => val?.length > 0);
};

export const isValidDate = (year, month, day) => {
  const d = new Date(year, month, day);

  if (d.getFullYear() === year && d.getMonth() === month && d.getDate() === day) {
    return true;
  }

  return false;
};

export const parseFloatToCents = (value) => {
  if (!value || !value?.length) return 0;

  const normalisedValue = value.replace(/[,]/gi, '.');
  const parsedFloat = parseFloat(normalisedValue).toFixed(2);

  if (Number.isNaN(parsedFloat)) return 0;

  const result = Number(parsedFloat);

  return Math.round(result * 100);
};

export const parseDateFormat = (date) => {
  if (!date) return null;
  return new Date(date?.split('.').reverse().join('-')).toISOString();
};

// export function uuidV4() {
//   let uuid = '';
//   let i = null;
//   let random = null;

//   for (i = 0; i < 32; i++) {
//     random = (Math.random() * 16) | 0;

//     if (i === 8 || i === 12 || i === 16 || i === 20) {
//       uuid += '-';
//     }
//     uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
//   }
//   return uuid;
// }

export function formatPolicyName(name) {
  if (typeof name === 'string') {
    return name.split(' ').join('-').toLowerCase();
  }
  return name;
}

// export const setFaircareLink = (policyName) => {
//   return `//www.faircare.de/policy-${policyName}.html#after-header`;
// };

export function formatDate(date, format = 'DD.MM.YYYY') {
  if (!date) return '';

  const removeTimezone = date.indexOf('+') >= 0 ? date.substring(0, date.indexOf('+')) : date;

  return dayjs(removeTimezone, 'YYYY-MM-DD').format(format);
}

export const documentsOrder = [
  {
    name: 'contract',
    numContractDoc: 0,
    num: 0,
  },
  {
    name: 'protocol',
    numContractDoc: 1,
    num: 1,
  },
  {
    name: 'Produktinformationsblatt',
    numContractDoc: 8,
    num: 2,
  },
  {
    name: 'Kundeninformationsblatt',
    numContractDoc: 9,
    num: 3,
  },
  {
    name: 'Einwilligungserklärung',
    numContractDoc: 10,
    num: 4,
  },
  {
    name: 'Datenschutzerklärung',
    numContractDoc: 11,
    num: 5,
  },
  {
    name: 'sepa',
    numContractDoc: 6,
    num: 6,
  },
  {
    name: 'Allgemeine_Versicherungsbedingungen',
    numContractDoc: 12,
    num: 7,
  },
  {
    name: 'Versicherungsbedingungen',
    numContractDoc: 13,
    num: 8,
  },
  {
    name: 'Nutzungsbedingungen Kundenkonto',
    numContractDoc: 14,
    num: 9,
  },
  {
    name: 'Weitere Verbraucherinformationen (IZVID, Dienstleisterliste)',
    numContractDoc: 15,
    num: 10,
  },
  {
    name: 'Extra Vorteile',
    numContractDoc: 16,
    num: 11,
  },
  {
    name: 'dentolo Zahnarztnetzwerk',
    numContractDoc: 17,
    num: 12,
  },
  {
    name: 'Kundeninformation',
    numContractDoc: 18,
    num: 13,
  },
  {
    name: 'opt-in_document',
    numContractDoc: 7,
    num: 14,
  },
  {
    name: 'Sondervereinbarung Probemonat',
    numContractDoc: 3,
    num: 15,
  },
  {
    name: 'Vertragsunterlagen',
    numContractDoc: 4,
    num: 16,
  },
  {
    name: 'Weitere Verbraucherinformationen',
    numContractDoc: 5,
    num: 17,
  },
  {
    name: 'Tarifblatt Akutschutz Modul',
    numContractDoc: 2,
    num: 18,
  },
];

export const germanMonths = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

export const days = [
  {
    id: '0',
    name: 'monday',
  },
  {
    id: '1',
    name: 'tuesday',
  },
  {
    id: '2',
    name: 'wednesday',
  },
  {
    id: '3',
    name: 'thursday',
  },
  {
    id: '4',
    name: 'friday',
  },
  {
    id: '5',
    name: 'saturday',
  },
  {
    id: '6',
    name: 'sunday',
  },
];

export const getBillingDays = (intl) => {
  return [
    { label: intl.formatMessage({ id: 'policy.details.billing_day_1' }), value: 1 },
    { label: intl.formatMessage({ id: 'policy.details.billing_day_15' }), value: 15 },
  ];
};

export const promiseWithRetry = (fn, retriesLeft = 5, interval = 500) => {
  if (typeof fn === 'function') {
    return new Promise((resolve, reject) => {
      fn()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (retriesLeft === 1) {
              reject({ error, retried: true }); // eslint-disable-line prefer-promise-reject-errors
              return;
            }
            promiseWithRetry(fn, retriesLeft - 1, interval).then(resolve, reject);
          }, interval);
        });
    });
  }

  return Promise.resolve(null);
};

export const dateDiff = (unit, date1, date2) => {
  const dateToCompare = dayjs(date1);
  const dateToCompareWith = dayjs(date2) || dayjs();

  return dateToCompareWith.diff(dateToCompare, unit);
};

// To check if a contract is in trial month
export const contractInTrialMonth = (trialMonthStatus, trialMonthStartingAt, contractStartDate) => {
  const trialMonthStarted = dayjs(trialMonthStartingAt).isBefore(dayjs());
  const contractStarted = dayjs(contractStartDate).isBefore(dayjs());

  return trialMonthStatus === 'active' && trialMonthStarted && !contractStarted;
};
