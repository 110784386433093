import React from 'react';
import PropTypes from 'prop-types';

import { imgLinks } from '../../../shared';

import OnboardCard from './OnboardCard';
import {
  StyledClaimOnBoardCardParent,
  StyledClaimOnBoardCardWrapper,
  StyledClaimOnBoardImageRechnung,
  StyledClaimOnBoardImagePhone,
  StyledClaimOnBoardImageTablet,
  StyledClaimOnBoardImageLabtop,
} from './UserClaimsOnboardDentolo.styled';

const UserClaimsOnboardCard2 = ({ titleHtml, bodyText }) => {
  return (
    <OnboardCard titleHtml={titleHtml} bodyText={bodyText}>
      <StyledClaimOnBoardCardParent>
        <StyledClaimOnBoardCardWrapper>
          <StyledClaimOnBoardImageRechnung
            src={imgLinks['icon.user.claim.onboard.rechnung']}
            alt=""
          />

          <StyledClaimOnBoardImageRechnung
            src={imgLinks['icon.user.claim.onboard.rechnung']}
            alt=""
          />

          <StyledClaimOnBoardImageRechnung
            src={imgLinks['icon.user.claim.onboard.rechnung']}
            alt=""
          />

          <StyledClaimOnBoardImagePhone src={imgLinks['icon.user.claim.onboard.phone']} alt="" />
          <StyledClaimOnBoardImageTablet src={imgLinks['icon.user.claim.onboard.tablet']} alt="" />
          <StyledClaimOnBoardImageLabtop src={imgLinks['icon.user.claim.onboard.laptop']} alt="" />
        </StyledClaimOnBoardCardWrapper>
      </StyledClaimOnBoardCardParent>
    </OnboardCard>
  );
};

UserClaimsOnboardCard2.propTypes = {
  titleHtml: PropTypes.string,
  bodyText: PropTypes.string,
};

export default UserClaimsOnboardCard2;
