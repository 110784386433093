import { styled } from '@mui/material';

// eslint-disable-next-line import/prefer-default-export
export const StyledCheckboxContainer = styled('div')`
  display: flex;
  margin-top: 1em;

  span[data-label] {
    font-size: 0.875rem;
  }
`;
