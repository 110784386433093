import React from 'react';
import { useQuery } from '@apollo/client';

import {
  GET_CUSTOMER,
  INSURANCE_CATEGORY_PET_HEALTH,
  DA_DIREKT_SOURCE_FIRST_VET,
  KAUFLAND_SOURCE_FIRST_VET,
} from '../../shared';

import Fressnapf from '../Fressnapf';
import FirstVet from '../FirstVet';
import Petolo404Page from '../../features/404/Petolo404Page/Petolo404Page';

const OnlineVets = () => {
  const { data: customerData } = useQuery(GET_CUSTOMER);
  const customerInsuranceCategory = customerData.customer?.insuranceCategory;
  const { key } = customerData.customer?.signUpSource || {};

  if (key === DA_DIREKT_SOURCE_FIRST_VET || KAUFLAND_SOURCE_FIRST_VET.includes(key)) {
    return <FirstVet />;
  }

  if (customerInsuranceCategory === INSURANCE_CATEGORY_PET_HEALTH) {
    return <Fressnapf />;
  }

  return <Petolo404Page />;
};

export default OnlineVets;
