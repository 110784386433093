const breakpoints = {
  max_width_xs: '480px',
  min_width_sm: '480px',
  max_width_sm: '768px',
  min_width_md: '768px',
  max_width_md: '992px',
  min_width_lg: '992px',
};

export default breakpoints;

export const MAX_WIDTH_XS = '480px';
export const MIN_WIDTH_SM = '480px';
export const MAX_WIDTH_SM = '768px';
export const MIN_WIDTH_MD = '768px';
export const MAX_WIDTH_MD = '992px';
export const MIN_WIDTH_LG = '992px';
