import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  PET_LIABILITY_DAMAGED_ITEM,
  useNewPetLiabilityFormDataValue,
} from '../../claimFormData/newClaimFormDataPetLiability';
import {
  useDamagedItemPetLiabilityBackButtonLink,
  useDamagedItemPetLiabilityNextButtonLink,
} from './useDamagedItemPetLiability';

import { Button } from '../../../../components/ui';
import {
  BackButton,
  ButtonContainer,
  FormLabel,
  FormSectionContainer,
  Input,
  InputCurrency,
  PageTitle,
} from '../../../../components/common';

function DamagedItemPetLiability() {
  const [damagedItem, setDamagedItem] = useNewPetLiabilityFormDataValue(PET_LIABILITY_DAMAGED_ITEM);

  const { item, age, price } = damagedItem || {};

  const history = useHistory();

  const backButtonLink = useDamagedItemPetLiabilityBackButtonLink();
  const nextButtonLink = useDamagedItemPetLiabilityNextButtonLink();

  const handleItemChange = (ev) => setDamagedItem({ ...damagedItem, item: ev?.target?.value });
  const handleAgeChange = (ev) => setDamagedItem({ ...damagedItem, age: ev?.target?.value });
  const handlePriceChange = (value) => setDamagedItem({ ...damagedItem, price: value });

  const isFormValid = !!item && !!age && !!price;

  return (
    <>
      <BackButton to={backButtonLink}>
        <FormattedMessage id="common.button.back" />
      </BackButton>

      <PageTitle>
        <FormattedMessage id="pet_liability.new_claim.damaged_item.page_title" />
      </PageTitle>

      <FormSectionContainer>
        <FormLabel>
          <FormattedMessage id="pet_liability.new_claim.damaged_item.form.label.damaged_item" />
        </FormLabel>
        <Input
          type="text"
          name="item"
          placeholder="Laptop"
          value={item}
          onChange={handleItemChange}
        />

        <FormLabel style={{ marginTop: '1rem' }}>
          <FormattedMessage id="pet_liability.new_claim.damaged_item.form.label.damaged_item_age" />
        </FormLabel>
        <Input type="text" name="age" placeholder="1 Jahr" value={age} onChange={handleAgeChange} />

        <FormLabel style={{ marginTop: '1rem' }}>
          <FormattedMessage id="pet_liability.new_claim.damaged_item.form.label.damaged_item_price" />
        </FormLabel>
        <InputCurrency
          name="item-price"
          placeholder="2000"
          value={price}
          onChange={handlePriceChange}
          onBlur={handlePriceChange}
        />
      </FormSectionContainer>

      <ButtonContainer>
        <Button
          onClick={() => history.push(nextButtonLink)}
          disabled={!isFormValid}
          data-testid="next-button"
        >
          <FormattedMessage id="common.button.next" />
        </Button>
      </ButtonContainer>
    </>
  );
}

export default DamagedItemPetLiability;
