import React from 'react';
import { FormattedMessage } from 'react-intl';

import { formatPolicyName } from '../../shared';

const PolicyAndAddonName = ({ customer, addons, shouldFormatPolicyName }) => {
  const addOnTitle = addons?.title || '';
  const { policyName } = customer?.contract || {};

  if (shouldFormatPolicyName) {
    if (addOnTitle) {
      return (
        <>
          <FormattedMessage id={`policies.${formatPolicyName(policyName)}`} />
          <span>{` + ${addOnTitle}`}</span>
        </>
      );
    }

    return <FormattedMessage id={`policies.${formatPolicyName(policyName)}`} />;
  }

  return <span>{policyName}</span>;
};

export default PolicyAndAddonName;
