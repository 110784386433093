import { styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../../theme/breakpoints';

// eslint-disable-next-line import/prefer-default-export
export const StyledDaDirektPetCrossSellingCardImage = styled('img')`
  width: 150px;
  height: auto;
  margin-top: initial;

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 230px;
    margin-top: -1rem;
  }
`;
