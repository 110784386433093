import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO, INSURANCE_TYPE_VITOLO } from '../shared';

export const showRimburseMap = {
  [INSURANCE_TYPE_DENTOLO]: true,
  [INSURANCE_TYPE_PETOLO]: true,
  [INSURANCE_TYPE_VITOLO]: false,
};

export const showSelfCancellationMap = {
  [INSURANCE_TYPE_DENTOLO]: true,
  [INSURANCE_TYPE_PETOLO]: true,
  [INSURANCE_TYPE_VITOLO]: true,
};

export const DEFAULT_SUPPORT_PHONE_NUMBER = '030 959 99 33 00';
export const supportPhoneNumberMap = {
  [INSURANCE_TYPE_DENTOLO]: '030 959 99 33 00',
  [INSURANCE_TYPE_PETOLO]: '030 814 58 62 90',
  [INSURANCE_TYPE_VITOLO]: '030 417 35010',
};
