import React from 'react';
import { FormattedMessage } from 'react-intl';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { INSURANCE_TYPE_DENTOLO, INSURANCE_TYPE_PETOLO, INSURANCE_TYPE_VITOLO } from '../../shared';

import { Paper } from '../common';
import {
  Premium,
  Conditions,
  AdditionalAdvantagesPetolo,
  AdditionalAdvantages,
  MainDetails,
  AddOns,
} from '../PolicyDetailsItems';
import { StyledPolicyDetailsLink, StyledPolicyDetailsNote } from '../styled/PolicyDetails.styled';

const PolicyDetailsCard = ({ policyCoverageInfo, customer }) => {
  const { type: insuranceType } = useInsuranceInfo();
  const isDentolo = insuranceType === INSURANCE_TYPE_DENTOLO;
  const isPetolo = insuranceType === INSURANCE_TYPE_PETOLO;
  const isVitolo = insuranceType === INSURANCE_TYPE_VITOLO;

  const {
    contractPremium,
    contractBudgetSchedule,
    addonBudgedSchedule: budgetScheduleAddon,
  } = customer?.contract || {};

  const budgetAmountInfo = budgetScheduleAddon
    ? budgetScheduleAddon?.limits
    : contractBudgetSchedule;

  const {
    single_page: showInSinglePage,
    addons: policyCoverageAddons,
    budget: policyCoverageBudget,
    conditions: policyCoverageConditions,
    main: policyCoverageMain,
    premium: policyCoveragePremium,
    endNote: policyCoverageEndNote,
    additional_advantages: policyCoverageAdditionalAdvantages,
    additional_advantages_petolo: policyCoverageAdditionalAdvantagesPetolo,
    additional_advantages_vitolo: policyCoverageAdditionalAdvantagesVitolo,
  } = policyCoverageInfo || {};

  return (
    <Paper>
      <AddOns details={policyCoverageAddons} />
      <MainDetails details={policyCoverageMain} />

      {isDentolo && <AdditionalAdvantages details={policyCoverageAdditionalAdvantages} />}

      {isPetolo && (
        <AdditionalAdvantagesPetolo details={policyCoverageAdditionalAdvantagesPetolo} />
      )}

      {isVitolo && (
        <AdditionalAdvantagesPetolo details={policyCoverageAdditionalAdvantagesVitolo} />
      )}

      {showInSinglePage && (
        <>
          <Conditions
            conditions={policyCoverageConditions}
            budgetInfo={policyCoverageBudget}
            budgetAmountInfo={budgetAmountInfo}
            hasAddon={!!budgetScheduleAddon}
          />

          <Premium premiumInfo={policyCoveragePremium} contractPremium={contractPremium} />
        </>
      )}

      {!showInSinglePage && isDentolo && (
        <StyledPolicyDetailsLink to="/policy-details" variant="outline">
          <FormattedMessage id="policy.covergae_info.all_details.button" />
        </StyledPolicyDetailsLink>
      )}

      {policyCoverageEndNote && (
        <StyledPolicyDetailsNote>{policyCoverageEndNote}</StyledPolicyDetailsNote>
      )}
    </Paper>
  );
};

export default PolicyDetailsCard;
