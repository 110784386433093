import React from 'react';
import { FormattedMessage } from 'react-intl';

import { EmptyCardIcon } from './DigitalPaymentsIcon';
import { StyledPaymentDetailsItem, StyledLoadingStatus } from '../styled/CustomerDetails.styled';

const DigitalPaymentLoading = () => {
  return (
    <>
      <StyledLoadingStatus>
        <FormattedMessage id="policy.details.my.digital.payment.loading.status" tagName="span" />
      </StyledLoadingStatus>

      <StyledPaymentDetailsItem>
        <div data-label>
          <EmptyCardIcon />
        </div>

        <div data-pending>
          <p data-pending-bold>
            <FormattedMessage id="policy.details.account.digital.payment.card.numper.placeholder" />
          </p>

          <p data-pending-normal>
            <FormattedMessage id="policy.details.account.digital.payment.expire" />
            <FormattedMessage id="policy.details.account.digital.payment.card.numper.expire.placeholder" />
          </p>
        </div>
      </StyledPaymentDetailsItem>
    </>
  );
};

export default DigitalPaymentLoading;
