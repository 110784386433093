import React from 'react';

import {
  useNewDentoloFormDataValue,
  DENTOLO_IS_EXISTING_CLAIM_ANSWER,
  DENTOLO_IS_EXISTING_CLAIM_ANSWER_YES,
  DENTOLO_IS_EXISTING_CLAIM_ANSWER_NO,
} from '../../claimFormData/newClaimFormData';
import {
  useExistingClaimQuestionDentoloBackButtonLink,
  useExistingClaimQuestionDentoloNextButtonLink,
} from './existingClaimQuestionDentoloHooks';

import ListWithRadioButton from '../common/ListWithRadioButton';

const existingClaimQuestionConfig = [
  {
    label: 'dentolo.new_claim.existing_claim_question.option.yes',
    value: DENTOLO_IS_EXISTING_CLAIM_ANSWER_YES,
  },
  {
    label: 'dentolo.new_claim.existing_claim_question.option.no',
    value: DENTOLO_IS_EXISTING_CLAIM_ANSWER_NO,
  },
];

export default function ExistingClaimQuestionDentolo() {
  const [isExistingClaim, setIsExistingClaim] = useNewDentoloFormDataValue(
    DENTOLO_IS_EXISTING_CLAIM_ANSWER
  );

  const backButtonLink = useExistingClaimQuestionDentoloBackButtonLink();
  const nextButtonLink = useExistingClaimQuestionDentoloNextButtonLink(isExistingClaim);

  return (
    <ListWithRadioButton
      name="is_existing_claim_question"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId="dentolo.new_claim.existing_claim_question.page.title"
      listConfig={existingClaimQuestionConfig}
      selected={isExistingClaim}
      setSelected={setIsExistingClaim}
    />
  );
}
