import React from 'react';

export default function IllustrationDesktopDentolo() {
  return (
    <svg
      width="660"
      height="280"
      viewBox="0 0 660 280"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1037_6046)">
        <path
          d="M10 15C10 12.2386 12.2386 10 15 10H645C647.761 10 650 12.2386 650 15V265C650 267.761 647.761 270 645 270H15C12.2386 270 10 267.761 10 265V140V15Z"
          fill="white"
        />
        <path
          d="M10 176H650V265C650 267.761 647.761 270 645 270H15C12.2386 270 10 267.761 10 265V176Z"
          fill="#F7F7F8"
        />
        <ellipse cx="333.5" cy="246.5" rx="112.5" ry="8.5" fill="#E7E7E8" />
        <path
          d="M299.374 35.1918C286.258 36.7185 275.616 48.5197 257.901 50.5829C240.186 52.6254 227.234 44.3109 214.097 45.817C188.153 48.8292 164.065 86.9151 171.304 116.191C179.388 148.83 226.306 238.681 251.776 235.73C264.335 234.286 261.386 209.322 264.727 181.779C266.253 169.194 271.945 167.048 276.586 181.408C285.268 208.27 292.733 230.985 304.509 229.624C329.958 226.673 356.768 121.7 357.202 94.6725C357.717 62.5079 324.411 32.3033 299.395 35.1918"
          fill="white"
        />
        <path
          d="M233.793 57.5366C240.928 62.0756 249.59 62.5707 257.612 60.1775C267.202 57.3097 277.452 50.852 280.112 42.0423C273.698 45.7766 266.707 49.5522 257.922 50.5631C241.134 52.5025 228.637 45.364 216.18 45.6735C222.161 50.3362 227.337 53.4516 233.793 57.5366Z"
          fill="#DDF1FB"
        />
        <path
          d="M327.113 175.88C323.153 183.555 311.253 194.077 300.983 192.53C290.878 191.003 288.444 177.345 285.66 169.526C284.34 165.853 282.979 159.251 278.689 157.91C274.4 156.569 269.265 157.477 265.676 160.159C258.458 165.544 253.282 175.426 252.952 184.463C252.642 192.901 252.498 204.826 241.836 206.291C236.226 207.054 232.638 201.216 228.905 197.791C222.862 192.241 195.681 158.694 172.645 120.918C183.678 157.167 227.461 238.538 251.797 235.732C264.356 234.268 261.407 209.324 264.748 181.781C266.274 169.196 271.966 167.05 276.606 181.409C285.289 208.272 292.754 230.987 304.53 229.625C322.926 227.5 342.745 174.89 351.654 132.43C344.745 152.03 335.836 159.065 327.154 175.88"
          fill="#DDF1FB"
        />
        <path
          d="M299.374 35.1918C286.258 36.7185 275.616 48.5197 257.901 50.5829C240.186 52.6254 227.234 44.3109 214.097 45.817C188.153 48.8292 164.065 86.9151 171.304 116.191C179.388 148.83 226.306 238.681 251.776 235.73C264.335 234.286 261.386 209.322 264.727 181.779C266.253 169.194 271.945 167.048 276.586 181.408C285.268 208.27 292.733 230.985 304.509 229.624C329.958 226.673 356.768 121.7 357.202 94.6725C357.717 62.5079 324.411 32.3033 299.395 35.1918H299.374Z"
          stroke="#003363"
          strokeMiterlimit="10"
        />
        <path
          d="M276.895 94.7154C275.987 92.384 274.008 90.8366 271.925 91.0842C269.821 91.3318 268.254 93.2711 267.903 95.7676M254.663 97.2943C253.756 94.9629 251.776 93.4155 249.693 93.6631C247.589 93.9107 246.022 95.8501 245.671 98.3465"
          stroke="#003363"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M266.666 143.818C268.048 143.55 268.336 143.715 269.697 143.983C270.089 144.065 272.378 144.973 272.523 144.767C272.729 144.478 270.667 143.137 269.945 142.931C268.357 142.456 267.161 142.518 265.903 142.889C264.335 143.364 264.913 144.169 266.666 143.818Z"
          fill="#001C36"
        />
        <path
          d="M246.847 116.771C246.847 116.771 246.847 116.895 246.847 116.957C248.208 128.696 258.458 137.155 269.759 135.855C281.04 134.555 289.104 123.971 287.743 112.232C287.743 112.17 287.743 112.108 287.722 112.046L246.847 116.771Z"
          fill="#33FF95"
        />
        <path
          d="M242.702 114.996C242.702 114.996 242.702 115.12 242.702 115.182C244.063 126.921 254.313 135.38 265.614 134.08C276.895 132.78 284.959 122.196 283.597 110.457C283.597 110.395 283.597 110.333 283.577 110.271L242.702 114.996Z"
          stroke="#003363"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M453.589 50.3182C441.917 48.2345 429.749 55.6618 413.993 52.8559C398.257 50.05 389.266 39.5486 377.593 37.4854C354.516 33.3797 324.014 59.8913 322.715 86.9186C321.251 117.061 338.45 206.334 361.074 210.357C372.252 212.338 376.129 190.138 386.09 167.34C390.627 156.922 396.092 156.55 396.381 170.064C396.917 195.379 397.494 216.836 407.95 218.692C430.574 222.716 480.647 139.426 487.968 116.319C496.692 88.8167 475.842 54.2795 453.61 50.3182"
          fill="white"
        />
        <path
          d="M415.457 68.598C422.944 70.7643 429.625 70.0422 437.277 69.2582C444.701 68.4949 452.682 68.6599 458.869 73.4465C471.511 83.2052 474.914 102.413 474.955 117.454C474.976 126.49 474.502 135.63 471.614 144.254C470.727 146.874 469.655 149.391 468.438 151.846C476.193 140.21 485.679 124.736 487.907 113.719C490.711 99.7723 487.845 83.494 480.173 70.4548C475.306 62.2022 463.427 52.0309 454.229 50.7311C442.866 49.1425 440.308 50.3185 426.408 53.4132C411.848 56.6523 403.826 54.5067 392.751 49.2869C391.473 48.6886 390.173 48.1728 388.895 47.7395C394.937 57.3126 404.898 65.5446 415.478 68.598"
          fill="white"
        />
        <path
          d="M391.473 52.6281C396.443 58.3637 403.743 61.0252 411.25 61.0252C420.242 61.0252 430.698 58.1368 435.256 51.2458C428.78 52.7932 421.789 54.2374 413.993 52.8551C399.062 50.1936 390.173 40.8269 379.387 37.8972C383.326 43.4471 386.977 47.4496 391.473 52.6281Z"
          fill="#DDF1FB"
        />
        <path
          d="M441.216 178.358C435.833 183.928 422.902 189.911 414.467 185.929C406.177 182.03 407.62 169.651 407.229 162.224C407.043 158.716 407.579 152.713 404.238 150.464C400.897 148.215 396.257 147.658 392.462 149.04C384.873 151.805 377.882 158.964 375.263 166.639C372.829 173.798 369.633 184.011 360.084 182.525C355.073 181.741 353.485 175.799 351.154 171.879C347.38 165.566 332.676 129.708 322.612 91.3338C322.756 125.335 339.44 206.52 361.053 210.378C372.231 212.358 376.108 190.159 386.069 167.361C390.606 156.942 396.071 156.571 396.36 170.084C396.896 195.399 397.474 216.856 407.93 218.713C424.284 221.622 454.848 181.514 473.429 147.328C462.458 162.389 452.991 166.144 441.195 178.358"
          fill="#DDF1FB"
        />
        <path
          d="M431.894 53.3707C431.894 53.3707 431.337 53.4532 430.388 53.5976C429.914 53.6802 429.316 53.7214 428.677 53.8865C428.037 54.0309 427.295 54.1754 426.511 54.361C424.903 54.6911 423.005 55.1863 420.943 55.5576C419.912 55.7433 418.86 55.9909 417.767 56.1353C416.674 56.2798 415.56 56.4242 414.467 56.548C413.374 56.7337 412.24 56.6924 411.147 56.7749C410.054 56.7956 408.982 56.94 407.93 56.8574C406.878 56.8162 405.888 56.7543 404.919 56.713C403.97 56.6511 403.063 56.6718 402.238 56.5067C398.938 56.0115 396.752 55.6815 396.752 55.6815C396.752 55.6815 398.959 55.9084 402.258 56.2385C403.083 56.3623 403.991 56.2797 404.939 56.3004C405.888 56.3004 406.878 56.3004 407.93 56.3004C408.961 56.3004 410.033 56.1353 411.106 56.0528C412.178 55.929 413.292 55.9084 414.364 55.6815C415.457 55.4958 416.53 55.2895 417.602 55.1038C418.674 54.8975 419.706 54.6086 420.696 54.3817C422.717 53.9278 424.552 53.3294 426.181 52.9168C427.006 52.6899 427.749 52.5042 428.429 52.3185C429.089 52.1328 429.667 52.0503 430.141 51.9471C431.09 51.7614 431.626 51.6377 431.626 51.6377C432.1 51.5345 432.575 51.844 432.657 52.3391C432.76 52.8136 432.451 53.2882 431.956 53.3707H431.894Z"
          fill="#001C36"
        />
        <path
          d="M453.589 50.3182C441.917 48.2345 429.749 55.6618 413.993 52.8559C398.257 50.05 389.266 39.5486 377.593 37.4854C354.516 33.3797 324.014 59.8913 322.715 86.9186C321.251 117.061 338.45 206.334 361.074 210.357C372.252 212.338 376.129 190.138 386.09 167.34C390.627 156.921 396.092 156.55 396.381 170.064C396.917 195.379 397.494 216.836 407.95 218.692C430.574 222.716 480.647 139.426 487.968 116.319C496.692 88.8167 475.842 54.2795 453.61 50.3182H453.589Z"
          stroke="#003363"
          strokeMiterlimit="10"
        />
        <path
          d="M418.963 95.6869C418.777 93.4381 417.478 91.6019 415.622 91.2718C413.766 90.9417 411.91 92.2002 410.961 94.2633M399.185 92.1589C399 89.9101 397.701 88.0945 395.844 87.7438C393.988 87.4136 392.132 88.6722 391.184 90.7353"
          stroke="#003363"
          strokeWidth="2.02"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M397.515 135.218C398.773 135.341 398.979 135.568 400.093 136.146C400.402 136.311 402.134 137.693 402.32 137.549C402.568 137.363 401.145 135.671 400.588 135.3C399.33 134.475 398.319 134.227 397.123 134.227C395.638 134.227 395.948 135.073 397.536 135.218"
          fill="#001C36"
        />
        <path
          d="M387.471 106.89C387.471 106.89 387.451 106.994 387.43 107.055C385.574 117.495 392.215 127.398 402.238 129.193C412.281 130.988 421.912 123.973 423.768 113.513C423.768 113.451 423.768 113.41 423.789 113.348L387.451 106.89H387.471Z"
          fill="#33FF95"
        />
        <path
          d="M384.357 104.312C384.357 104.312 384.337 104.415 384.316 104.477C382.46 114.916 389.101 124.819 399.124 126.614C409.167 128.409 418.798 121.394 420.654 110.955C420.654 110.893 420.654 110.852 420.675 110.79L384.337 104.332L384.357 104.312Z"
          stroke="#003363"
          strokeWidth="2.02"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M377.397 73.2778L366.673 123.75L428.744 136.949L439.467 86.4767L377.397 73.2778Z"
          fill="white"
        />
        <path
          d="M422.799 119.205C421.665 126.405 412.261 130.511 401.887 128.324C389.287 125.642 385.203 116.048 385.884 111.943C387.183 104.082 395.886 109.529 406.342 111.365C416.777 113.222 423.81 112.768 422.799 119.226"
          fill="#33FF95"
        />
        <path
          d="M404.63 108.332C399.268 107.383 394.36 105.547 390.627 104.969C391.782 108.064 396.732 111.241 403.125 112.665C409.518 114.088 415.354 113.325 417.726 111.014C414.53 109.838 409.869 109.26 404.63 108.332Z"
          fill="white"
        />
        <path
          d="M421.046 116.483C419.912 123.683 410.508 127.789 400.134 125.602C387.534 122.92 383.45 113.326 384.131 109.221C385.43 101.36 394.133 106.807 404.589 108.643C415.024 110.5 422.057 110.046 421.046 116.504"
          stroke="#003363"
          strokeMiterlimit="10"
        />
        <path
          d="M396.175 91.3114C395.948 92.8587 394.483 93.9316 392.937 93.684C391.39 93.4571 390.318 91.9922 390.565 90.4449C390.792 88.8975 392.256 87.8247 393.803 88.0722C395.35 88.2992 396.422 89.764 396.175 91.3114Z"
          fill="#003363"
        />
        <path
          d="M392.813 88.9391C392.731 89.4962 392.215 89.8675 391.679 89.785C391.122 89.7025 390.751 89.1867 390.834 88.6503C390.916 88.0932 391.432 87.7218 391.968 87.8044C392.525 87.8869 392.896 88.4027 392.813 88.9391Z"
          fill="white"
        />
        <path
          d="M421.706 95.1698C421.479 96.7171 420.015 97.7899 418.468 97.5424C416.922 97.3154 415.849 95.8506 416.097 94.3032C416.324 92.7559 417.788 91.683 419.335 91.9306C420.881 92.1575 421.954 93.6224 421.706 95.1698Z"
          fill="#003363"
        />
        <path
          d="M418.345 92.8176C418.262 93.3747 417.747 93.746 417.211 93.6635C416.654 93.581 416.282 93.0652 416.365 92.5288C416.447 91.9717 416.963 91.6003 417.499 91.6829C418.056 91.7654 418.427 92.2812 418.345 92.8176Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1037_6046"
          x="0"
          y="0"
          width="660"
          height="280"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1037_6046" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1037_6046"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
