import React from 'react';
import PropTypes from 'prop-types';

import {
  PET_LIABILITY_ACCOMPANIED_BY,
  PET_LIABILITY_ACCOMPANIED_BY_WORK_COLLEAGUE,
  PET_LIABILITY_ACCOMPANIED_BY_FAMILY,
  PET_LIABILITY_ACCOMPANIED_BY_MYSELF,
  PET_LIABILITY_ACCOMPANIED_BY_NO_RELATION,
  PET_LIABILITY_ACCOMPANIED_BY_PARTNER,
  useNewPetLiabilityFormDataValue,
  PET_LIABILITY_ACCOMPANIED_BY_ROOMMATE,
} from '../../claimFormData/newClaimFormDataPetLiability';
import {
  useAccompaniedByPetLiabilityBackButtonLink,
  useAccompaniedByPetLiabilityNextButtonLink,
} from './useAccompaniedByPetLiability';

import ListWithRadioButton from '../common/ListWithRadioButton';

const accompaniedByConfig = [
  {
    label: 'pet_liability.new_claim.pet_accompanied_by.myself',
    value: PET_LIABILITY_ACCOMPANIED_BY_MYSELF,
  },
  {
    label: 'pet_liability.new_claim.pet_accompanied_by.partner',
    value: PET_LIABILITY_ACCOMPANIED_BY_PARTNER,
  },
  {
    label: 'pet_liability.new_claim.pet_accompanied_by.family',
    value: PET_LIABILITY_ACCOMPANIED_BY_FAMILY,
  },
  {
    label: 'pet_liability.new_claim.pet_accompanied_by.flatmate',
    value: PET_LIABILITY_ACCOMPANIED_BY_ROOMMATE,
  },
  {
    label: 'pet_liability.new_claim.pet_accompanied_by.work_colleague',
    value: PET_LIABILITY_ACCOMPANIED_BY_WORK_COLLEAGUE,
  },
  {
    label: 'pet_liability.new_claim.pet_accompanied_by.no_relation',
    value: PET_LIABILITY_ACCOMPANIED_BY_NO_RELATION,
  },
];

function AccompaniedByPetLiability({ petName }) {
  const [accompaniedBy, setAccompaniedBy] = useNewPetLiabilityFormDataValue(
    PET_LIABILITY_ACCOMPANIED_BY
  );

  const backButtonLink = useAccompaniedByPetLiabilityBackButtonLink();
  const nextButtonLink = useAccompaniedByPetLiabilityNextButtonLink();

  return (
    <ListWithRadioButton
      name="pet_liability_reason"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId="pet_liability.new_claim.pet_accompanied_by.page_title"
      pageTitleValues={{ petName }}
      listConfig={accompaniedByConfig}
      selected={accompaniedBy}
      setSelected={setAccompaniedBy}
    />
  );
}

export default AccompaniedByPetLiability;

AccompaniedByPetLiability.propTypes = {
  petName: PropTypes.string,
};
