import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  StyledAccordion,
  StyledAccordionChevron,
  StyledAccordionContent,
  StyledAccordionSummary,
  StyledAccordionTitle,
  StyledAccordionTitleIcon,
} from './styled/Accordion.styled';

function Accordion(props) {
  const {
    children,
    defaultExpanded,
    collapsible,
    titleIcon,
    title,
    // expandText,
    // collapseText,
    multiple,
    accordionIdx,
    clickedIdx,
    setExpandedAccordionIndex,
    ...rest
  } = props;

  // #TODO: if available, use expanded/collapse text instead of chevron

  const [expanded, setExpanded] = useState(defaultExpanded);

  const contentRef = useRef(null);

  useEffect(() => {
    if (!multiple && clickedIdx !== null && accordionIdx !== clickedIdx) {
      setExpanded(false);
    }
  }, [accordionIdx, clickedIdx, multiple]);

  const onToggle = () => {
    if (!collapsible && expanded) return;

    setExpanded(!expanded);

    if (setExpandedAccordionIndex) {
      setExpandedAccordionIndex(accordionIdx);
    }
  };

  return (
    <StyledAccordion {...rest}>
      <StyledAccordionTitle onClick={onToggle}>
        {titleIcon && <StyledAccordionTitleIcon icon={titleIcon} alt={title} />}

        <div>{title}</div>

        <StyledAccordionChevron
          icon="icon.menu.accordion.arrow.down"
          expanded={expanded}
          style={{ width: '1.5rem', height: 'auto' }}
          alt="accordion chevron"
        />
      </StyledAccordionTitle>

      <StyledAccordionSummary contentEl={contentRef?.current} aria-expanded={expanded}>
        <StyledAccordionContent ref={contentRef} aria-expanded={expanded}>
          {children}
        </StyledAccordionContent>
      </StyledAccordionSummary>
    </StyledAccordion>
  );
}

export default Accordion;

Accordion.defaultProps = {
  defaultExpanded: false,
  multiple: true,
  collapsible: true,
};

Accordion.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  defaultExpanded: PropTypes.bool,
  collapsible: PropTypes.bool,
  multiple: PropTypes.bool,
  titleIcon: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  accordionIdx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clickedIdx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setExpandedAccordionIndex: PropTypes.func,
};
