import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import {
  StyledAccordionToggle,
  StyledAccordionTitle,
  StyledAccordionButton,
  StyledAccordionContent,
} from './styled/Accordion.styled';

const Accordion = (props) => {
  const { children, toggleTitle, showText, hideText, onExpand, onCollapse } = props;
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    if (open) {
      if (onCollapse) onCollapse();
    } else if (onExpand) {
      onExpand();
    }

    setOpen(!open);
  };

  return (
    <>
      <StyledAccordionToggle>
        <StyledAccordionTitle>{toggleTitle}</StyledAccordionTitle>

        <StyledAccordionButton variant="nostyle" onClick={handleToggle}>
          {open ? hideText : showText}
        </StyledAccordionButton>
      </StyledAccordionToggle>

      <CSSTransition in={open} timeout={450} classNames="slide-down" unmountOnExit>
        <StyledAccordionContent>{children}</StyledAccordionContent>
      </CSSTransition>
    </>
  );
};

Accordion.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  toggleTitle: PropTypes.string,
  showText: PropTypes.string,
  hideText: PropTypes.string,
  onExpand: PropTypes.func,
  onCollapse: PropTypes.func,
};

export default Accordion;
