import { css, styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../../theme/breakpoints';
import { ButtonContainer, Icon, Paper, Paragraph, Title } from '../../../../components/common';
import { PALETTE } from '../../../../theme/palette';

export const StyledUploadButtonContainer = styled('div')`
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  > button,
  > img {
    margin-right: 1rem;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    > button {
      width: 72px;
      height: 72px;
    }
  }
`;

export const StyledUploadButtonLabel = styled('h3')`
  font-size: 1.25rem;
  font-weight: 700;
`;

export const StyledFileList = styled(Paper)`
  margin-bottom: 1rem;
`;

export const StyledFileItem = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid ${theme.palette.gray.light_gray};
    word-break: break-word;

    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: none;
    }
  `
);

export const StyledFileName = styled('div')(
  ({ theme, disabled }) => css`
    margin-bottom: 0.625rem;
    padding-right: 2rem;
    font-size: 1rem;
    font-weight: 700;
    color: ${disabled
      ? theme.palette.main.professional_blue
      : theme.palette.main.professional_light_blue};
  `
);

export const StyledButtonContainer = styled(ButtonContainer)`
  margin-bottom: 0;

  ${({ hasFiles }) => !hasFiles && `display: none`}
`;

export const StyledUploadDocumentHintMainInformationContainer = styled('div')`
  width: 100%;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    width: 50%;
  }
`;

export const StyledUploadDocumentHintsListOuterContainer = styled('div')`
  display: flex;
`;

export const StyledUploadDocumentHintsListInnerContainer = styled('div')``;

export const StyledUploadDocumentHintDesktopIllustrationContainer = styled('div')`
  display: none;
  width: 50%;
  align-self: center;
  text-align: center;

  svg {
    width: 60%;
    height: 60%;
  }

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    display: block;
  }
`;

export const StyledUploadDocumentHintMobileIllustrationContainer = styled('div')`
  display: block;
  flex-grow: 1;

  svg {
    width: auto;
    height: auto;
  }

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    display: none;
  }
`;

export const StyledUploadDocumentHintsList = styled('div')`
  display: flex;
  font-size: 1rem;

  & + & {
    margin-top: 0.5rem;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.25rem;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

export const StyledUploadDocumentsHintBadStuffTitle = styled(Title)`
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.25rem;
  }
`;

export const StyledReminderParagraph = styled(Paragraph)(
  ({ theme }) => css`
    background: ${theme.palette.background.info};
    padding: 1rem;
    border-radius: 2px;
  `
);

export const StyledConfirmRefundParagraph = styled(Paragraph)`
  margin-top: 1rem;
  background: ${PALETTE.ULTRA_LIGHT_PEACH};
  padding: 1rem;
  border-radius: 2px;
`;
