import { datadogLogs } from '@datadog/browser-logs';

import {
  APP_ENV,
  DATADOG_CLIENT_TOKEN,
  DATADOG_ENV,
  DATADOG_SERVICE,
  DATADOG_SITE,
  NODE_ENV,
} from '../shared/constants';

if ((APP_ENV === 'beta' || NODE_ENV === 'production') && DATADOG_CLIENT_TOKEN) {
  // # init Datadog Logs
  datadogLogs.init({
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: DATADOG_ENV,
    clientToken: DATADOG_CLIENT_TOKEN,
    forwardReports: 'all', // ['intervention', 'csp_violation', 'deprecation']
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error', 'warn'],
    sessionSampleRate: 100,
  });
}
