import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { Container } from '../../components/common';
import { InfoWindow } from '../../components/ui';

const ContractCancellationStatus = () => {
  const intl = useIntl();
  const { status } = useParams();

  return (
    <Container>
      {status === 'success' ? (
        <InfoWindow
          mode="claim-success"
          titleHtml={intl.formatMessage({
            id: 'contract.cancellation.success.title',
          })}
          textHtml={null}
          button={{
            text: intl.formatMessage({
              id: 'contract.cancellation.success.button',
            }),
            to: '/',
            style: 'outline',
          }}
        />
      ) : (
        <InfoWindow
          mode="failure"
          titleHtml={intl.formatMessage({
            id: 'contract.cancellation.failure.title',
          })}
          textHtml={intl.formatMessage({
            id: 'contract.cancellation.failure.text',
          })}
          buttons={[
            {
              text: intl.formatMessage({
                id: 'contract.cancellation.failure.button',
              }),
              to: '/contact',
              style: 'outline',
            },
          ]}
        />
      )}
    </Container>
  );
};

export default ContractCancellationStatus;
