import React, { useCallback, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  VALID_DATE,
  NOT_VALID_DATE,
  VALID_TIME,
  INVALID_TIME,
  IN_PAST_DATE,
} from '../../../../shared/constants';
import {
  combineFullDateFromInputs,
  getInputFieldValuesFromDate,
  validateMonthRange,
} from '../SelectDocumentCreationDate/dateUtils';

import { Button } from '../../../../components/ui';
import {
  BackButton,
  DatePicker,
  FormSectionContainer,
  ButtonContainer,
  ErrorMessage,
  PageTitle,
  FormLabel,
  Input,
  Checkbox,
} from '../../../../components/common';
import { StyledCheckboxContainer } from './DateAndTime.styled';
import { dateDiff } from '../../../../shared';

function DateAndTime(props) {
  const {
    backButtonLink,
    nextButtonLink,
    pageTitleId,
    dateLabelId,
    timeLabelId,
    selectedDate,
    selectedTime,
    timeNotAvailable,
    onDateChange,
    onTimeChange,
    onTimeNotAvailable,
  } = props;

  const history = useHistory();

  const dateInputFieldValues = getInputFieldValuesFromDate(selectedDate);
  const [date, setDate] = useState(dateInputFieldValues);

  const [liveValidation, setLiveValidation] = useState(false);

  const { day, month, year } = date;
  const setDay = (newDay) => setDate({ day: newDay, month, year });
  const setMonth = (newMonth) => setDate({ day, month: newMonth, year });
  const setYear = (newYear) => setDate({ day, month, year: newYear });

  const [dateStatus, setDateStatus] = useState(VALID_DATE);
  const [timeStatus, setTimeStatus] = useState(VALID_TIME);

  useEffect(() => {
    return () => {
      setLiveValidation(false);
    };
  }, []);

  useEffect(() => {
    const newDate = combineFullDateFromInputs(day, month, year);
    const newDateObject = new Date(newDate);
    const currentDate = new Date();
    const isValidDate = !Number.isNaN(Number(newDateObject));
    if (isValidDate) {
      setDateStatus(VALID_DATE);
    }

    const notInTheFuture = isValidDate ? newDateObject < currentDate : false;
    if (notInTheFuture && validateMonthRange(newDate)) {
      onDateChange(newDate);
    } else {
      onDateChange('');
    }
  }, [day, month, year, onDateChange]);

  const validateDate = useCallback(() => {
    if (!selectedDate || !validateMonthRange(selectedDate)) {
      setDateStatus(NOT_VALID_DATE);
      return false;
    }

    if (
      selectedDate &&
      validateMonthRange(selectedDate) &&
      dateDiff('year', new Date(selectedDate)) >= 5
    ) {
      setDateStatus(IN_PAST_DATE);
      return false;
    }

    return true;
  }, [selectedDate]);

  const validateTime = useCallback(() => {
    if (timeNotAvailable) return true;

    const [hour, minute] = selectedTime?.split(':') || [];

    const hourInNumber = Number(hour);
    const minuteInNumber = Number(minute);

    const validHour = !Number.isNaN(hourInNumber) && hourInNumber >= 0 && hourInNumber <= 23;
    const validMinute =
      !Number.isNaN(minuteInNumber) && minuteInNumber >= 0 && minuteInNumber <= 59;

    if (validHour && validMinute) {
      setTimeStatus(VALID_TIME);
      return true;
    }

    setTimeStatus(INVALID_TIME);
    return false;
  }, [selectedTime, timeNotAvailable]);

  useEffect(() => {
    if (!selectedTime || !liveValidation || !selectedDate) return;

    validateTime();
    validateDate();
  }, [liveValidation, selectedTime, selectedDate, validateTime, validateDate]);

  const handleButtonClick = () => {
    setLiveValidation(true);
    if (!validateDate() || !validateTime()) return;

    history.push(nextButtonLink);
  };

  const handleBlurTimeInput = () => {
    const [hour, minute] = selectedTime?.split(':') || [];

    if (hour && !minute) {
      onTimeChange(`${hour}:00`);
    }
  };

  const handleCheckboxChange = (ev) => {
    const checked = ev?.target.checked;

    if (checked) {
      onTimeChange('');
      setTimeStatus(VALID_TIME);
    }

    onTimeNotAvailable(checked);
  };

  const validDate = dateStatus === VALID_DATE && !!day && !!month && !!year && year.length === 4;

  const validTime = !!timeNotAvailable || (!!selectedTime && timeStatus === VALID_TIME);

  const validForm = validDate && validTime;

  return (
    <>
      <BackButton to={backButtonLink}>
        <FormattedMessage id="common.button.back" />
      </BackButton>

      <PageTitle>
        <FormattedMessage id={pageTitleId} />
      </PageTitle>

      <FormSectionContainer>
        <FormLabel>
          <FormattedMessage id={dateLabelId} />
        </FormLabel>

        <DatePicker
          day={day}
          month={month}
          year={year}
          setDay={setDay}
          setMonth={setMonth}
          setYear={setYear}
        />
        {dateStatus === NOT_VALID_DATE && (
          <ErrorMessage data-testid="error-date">
            <FormattedMessage id="new_claim.invalid.date" />
          </ErrorMessage>
        )}
        {dateStatus === IN_PAST_DATE && (
          <ErrorMessage data-testid="error-time">
            <FormattedMessage id="new_claim.invalid.date_past" />
          </ErrorMessage>
        )}

        <FormLabel style={{ marginTop: '1rem' }}>
          <FormattedMessage id={timeLabelId} />
        </FormLabel>
        <Input
          type="text"
          placeholder="HH:MM"
          value={selectedTime}
          onChange={(ev) => onTimeChange(ev?.target?.value)}
          onBlur={handleBlurTimeInput}
          disabled={timeNotAvailable}
        />
        {timeStatus === INVALID_TIME && (
          <ErrorMessage data-testid="error-time">
            <FormattedMessage id="common.form.error.invalid_time" />
          </ErrorMessage>
        )}
      </FormSectionContainer>

      <StyledCheckboxContainer>
        <Checkbox
          type="checkbox"
          checked={!!timeNotAvailable}
          onChange={handleCheckboxChange}
          label={
            <FormattedMessage id="pet_liability.new_claim.incident_date_time.checkbox.label.dont_know_time" />
          }
        />
      </StyledCheckboxContainer>

      <ButtonContainer>
        <Button
          data-testid="submit-date-time"
          variant="primary"
          onClick={handleButtonClick}
          disabled={!validForm}
        >
          <FormattedMessage id="common.button.next" />
        </Button>
      </ButtonContainer>
    </>
  );
}

export default DateAndTime;

DateAndTime.defaultProps = {
  dateLabelId: 'new_claim.form.label.date',
  timeLabelId: 'new_claim.form.label.time',
};

DateAndTime.propTypes = {
  backButtonLink: PropTypes.string,
  nextButtonLink: PropTypes.string,
  pageTitleId: PropTypes.string,
  dateLabelId: PropTypes.string,
  timeLabelId: PropTypes.string,
  selectedDate: PropTypes.string,
  selectedTime: PropTypes.string,
  onDateChange: PropTypes.func,
  onTimeChange: PropTypes.func,
};
