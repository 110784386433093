import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import {
  BackButton,
  ButtonContainer,
  PageTitle,
  Paper,
  Radio,
  RadioLabel,
  Text,
} from '../../../../../components/common';
import { Button } from '../../../../../components/ui';
import { StyledRadioContainer, StyledListItem } from './ListWithRadioButton.styled';

function ListWithRadioButton(props) {
  const {
    name,
    backButtonLink,
    nextButtonLink,
    pageTitleId,
    pageTitleValues,
    listConfig,
    selected,
    setSelected,
  } = props;

  const history = useHistory();

  const questions = Array.isArray(listConfig) ? listConfig : [];

  const handleRadioBtnClick = (value) => (ev) => {
    if (ev) ev.stopPropagation();
    setSelected(value);
  };

  return (
    <>
      <BackButton to={backButtonLink}>
        <FormattedMessage id="common.button.back" />
      </BackButton>

      <PageTitle>
        <FormattedMessage id={pageTitleId} values={pageTitleValues} />
      </PageTitle>

      {questions.map((question) => {
        const { label, noTranslateLabel, value } = question || {};

        return (
          <StyledRadioContainer key={value}>
            <Paper>
              <StyledListItem onClick={handleRadioBtnClick(value)}>
                <Radio
                  name={name}
                  onChange={handleRadioBtnClick(value)}
                  value={value}
                  checked={value === selected}
                />

                <RadioLabel style={{ marginLeft: '1rem' }}>
                  {noTranslateLabel ? (
                    <Text>{noTranslateLabel}</Text>
                  ) : (
                    <Text>
                      <FormattedMessage id={label} />
                    </Text>
                  )}
                </RadioLabel>
              </StyledListItem>
            </Paper>
          </StyledRadioContainer>
        );
      })}

      <ButtonContainer>
        <Button disabled={!selected} onClick={() => history.push(nextButtonLink)}>
          <FormattedMessage id="common.button.next" />
        </Button>
      </ButtonContainer>
    </>
  );
}

export default ListWithRadioButton;

ListWithRadioButton.defaultProps = {
  pageTitleValues: {},
};

ListWithRadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  backButtonLink: PropTypes.string,
  nextButtonLink: PropTypes.string,
  pageTitleId: PropTypes.string,
  pageTitleValues: PropTypes.instanceOf(Object),
  listConfig: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      noTranslateLabel: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  selected: PropTypes.string,
  setSelected: PropTypes.func,
};
