import React from 'react';
import { styled } from '@mui/material';

import { useCheckIcon } from '../../hooks/useIcons';
import { Icon } from '../common';
import { MIN_WIDTH_MD } from '../../theme/breakpoints';

const StyledIcon = styled(Icon)`
  margin-right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const CheckIcon = () => {
  const checkIcon = useCheckIcon();

  return <StyledIcon icon={checkIcon} alt="check" />;
};

export default CheckIcon;
