import React from 'react';
import { css, styled } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

const typeStyles = (type) =>
  ({
    normal: css`
      font-size: 1rem;
      line-height: 22px;

      @media (min-width: ${MIN_WIDTH_MD}) {
        font-size: 1.25rem;
        line-height: 26px;
      }
    `,
    subtext: css`
      font-size: 1rem;
      line-height: 24px;

      @media (min-width: ${MIN_WIDTH_MD}) {
        line-height: 22px;
      }
    `,
  })[`${type}`];

const StyledParagraph = styled('p')(
  ({ theme, fontWeight, textAlign, type }) => css`
    font-weight: ${fontWeight || 450};
    text-align: ${textAlign || 'inherit'};

    ${typeStyles(type)}

    & + & {
      margin-top: 1.5rem;
    }

    button {
      padding: 0;
      margin: 0;
      background: transparent;
      border: none;
      font-size: inherit;
      cursor: pointer;
    }

    a,
    button {
      position: relative;
      display: inline-block;
      color: ${theme.palette.main.professional_blue};
      text-decoration: none;
      transition: color 0.2s ease-out;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: ${theme.palette.main.professional_blue};
        transform-origin: bottom left;
        transition: transform 0.2s ease-out;
      }

      &:hover {
        color: ${theme.palette.main.professional_light_blue};

        &::after {
          transform: scaleX(0);
          transform-origin: bottom right;
        }
      }

      &:active,
      &:focus {
        color: ${theme.palette.main.professional_light_blue};

        &::after {
          background-color: ${theme.palette.main.professional_light_blue};
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  `
);

// NOTE: for <br />, there needs to be double space before \n. Eg. `  \n`
export default function Paragraph({ children, ...rest }) {
  return (
    <StyledParagraph {...rest}>
      {typeof children === 'string' ? (
        <ReactMarkdown components={{ p: 'span' }}>{children}</ReactMarkdown>
      ) : (
        children
      )}
    </StyledParagraph>
  );
}

Paragraph.defaultProps = {
  type: 'normal',
  fontWeight: 450,
  textAlign: 'left',
};

Paragraph.propTypes = {
  type: PropTypes.oneOf(['normal', 'subtext']),
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textAlign: PropTypes.string,
};
