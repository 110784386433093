import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@mui/material';

import { Radio } from '../../components/common';
import { Button } from '../../components/ui';

import {
  StyledChooseCancellationContainer,
  StyledChooseCancellationRadioInputs,
  StyledChooseCancellationRadioInputAndLabel,
  StyledChooseCancellationRadioLabel,
  StyledChooseCancellationButtonContainer,
} from './ChooseCancellation.styled';

const CANCELLATION_TYPE = {
  CANCEL_ONLY_AKUTSCHUTZ: 'cancel_only_akutschutz',
  CANCEL_CONTRACT: 'cancel_contract',
};

export default function ChooseCancellation(props) {
  const { imCoverage, addonImCoverage, cancelationDate } = props;

  const [cancellationType, setCancellationType] = useState(null);

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (cancellationType === CANCELLATION_TYPE.CANCEL_ONLY_AKUTSCHUTZ) {
      history.push('/akutschutz-cancellation');
    } else if (cancellationType === CANCELLATION_TYPE.CANCEL_CONTRACT) {
      history.push('/contract-cancellation');
    }
  };

  if (imCoverage) {
    return (
      <StyledChooseCancellationContainer>
        <Typography variant="h2">
          <FormattedMessage id="choose_cancellation.title" />
        </Typography>

        <form onSubmit={handleSubmit}>
          <StyledChooseCancellationRadioInputs>
            <StyledChooseCancellationRadioInputAndLabel>
              <Radio
                id="radio_cancel_only_akutschutz"
                name="cancel_akutschutz"
                onChange={(e) => {
                  e.stopPropagation();
                  setCancellationType(CANCELLATION_TYPE.CANCEL_ONLY_AKUTSCHUTZ);
                }}
                value={CANCELLATION_TYPE.CANCEL_ONLY_AKUTSCHUTZ}
                checked={cancellationType === CANCELLATION_TYPE.CANCEL_ONLY_AKUTSCHUTZ}
                disabled={Boolean(addonImCoverage?.endingAt)}
              />

              <StyledChooseCancellationRadioLabel
                htmlFor="radio_cancel_only_akutschutz"
                disabled={Boolean(addonImCoverage?.endingAt)}
              >
                <Typography variant="h3">
                  <FormattedMessage id="choose_cancellation.cancel_only_akutschutz.title" />
                </Typography>
                <Typography variant="p">
                  <FormattedMessage id="choose_cancellation.cancel_only_akutschutz.description" />
                </Typography>
              </StyledChooseCancellationRadioLabel>
            </StyledChooseCancellationRadioInputAndLabel>

            <StyledChooseCancellationRadioInputAndLabel>
              <Radio
                id="radio_cancel_contract"
                name="cancel_akutschutz"
                onChange={(e) => {
                  e.stopPropagation();
                  setCancellationType(CANCELLATION_TYPE.CANCEL_CONTRACT);
                }}
                value={CANCELLATION_TYPE.CANCEL_CONTRACT}
                checked={cancellationType === CANCELLATION_TYPE.CANCEL_CONTRACT}
                disabled={Boolean(cancelationDate)}
              />

              <StyledChooseCancellationRadioLabel
                htmlFor="radio_cancel_contract"
                disabled={Boolean(cancelationDate)}
              >
                <Typography variant="h3">
                  <FormattedMessage id="choose_cancellation.radio_cancel_contract.title" />
                </Typography>
                <Typography variant="p">
                  <FormattedMessage id="choose_cancellation.radio_cancel_contract.description" />
                </Typography>
              </StyledChooseCancellationRadioLabel>
            </StyledChooseCancellationRadioInputAndLabel>
          </StyledChooseCancellationRadioInputs>

          <StyledChooseCancellationButtonContainer>
            <Button type="submit" disabled={!cancellationType}>
              <FormattedMessage id="choose_cancellation.next_button" />
            </Button>
          </StyledChooseCancellationButtonContainer>
        </form>
      </StyledChooseCancellationContainer>
    );
  }
  return null;
}
