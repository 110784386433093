import { css, styled, keyframes } from '@mui/material';

import { MIN_WIDTH_LG, MIN_WIDTH_MD } from '../../../theme/breakpoints';

const fadeIn = keyframes`
  0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const userClaimsCoinSlideDown = keyframes`
    0% {
        transform: translate3d(1.3em, -60vh, 0);
    }
    5% {
        transform: translate3d(1.3em, -60vh, 0);
        opacity: 1;
    }
    100% {
        transform: translate3d(1.3em, 0vh, 0);
        opacity: 1;
    }
`;

const userClaimsRechnungInStay = keyframes`
    0% {
        transform: translateX(100vw) translateY(-0.3125em) scale(2.2);
        opacity: 1;
    }
    100% {
        transform: translateX(0vw) translateY(-0.3125em) scale(1);
        opacity: 1;
    }
`;

const userClaimsRechnungInHide = keyframes`

    0% {
        transform: translateX(100vw) translateY(-0.3125em) scale(2.2);
        opacity: 1;
    }
    100% {
        transform: translateX(0vw) translateY(-0.3125em) scale(1);
        opacity: 0;
    }
`;

const scaleIn = keyframes`

    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
`;

const userClaimsCoinDrop = keyframes`

    0% {
        transform: translate3d(0, -18.75em, 0);
    }
    100% {
        transform: translate3d(0, 0, 0);
    }
`;

const userClaimsLinesLoad = keyframes`
    0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;

export const StyledClaimOnBoardCard = styled('div')`
  display: flex;
  justify-content: flex-start !important;
  flex-direction: column !important;
  text-align: center;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
`;

export const StyledClaimOnBoardCardTitle = styled('span')(
  ({ theme }) => css`
    color: ${theme.palette.main.professional_blue};
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.75rem;
    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }
    @media (max-width: ${MIN_WIDTH_MD - 1}) and (max-height: '500px') {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  `
);

export const StyledClaimOnBoardCardText = styled('p')(
  ({ theme }) => css`
    color: ${theme.palette.main.professional_blue};
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.25rem;
    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.4rem;
      line-height: 1.875rem;
    }
    @media (max-width: ${MIN_WIDTH_MD - 1}) and (max-height: '500px') {
      font-size: 0.9rem;
      line-height: 1.2rem;
    }
  `
);

export const StyledClaimOnBoardCardParent = styled('div')`
  width: 100%;
  min-height: 12.25rem;
  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1rem;
  overflow: hidden;

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.1rem;
    min-height: 19rem;
  }

  @media (max-width: ${MIN_WIDTH_MD - 1}) and (max-height: 500px) {
    min-height: 8rem;
    font-size: 0.7rem;
  }
`;

export const StyledClaimOnBoardCardWrapper = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledClaimOnBoardImageEuro = styled('img')`
  width: 3.75em;
  transform: translate3d(1.3em, -60vh, 0);
  animation: 0.3s ${userClaimsCoinSlideDown} 1.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 4.7269rem;
  }
`;

export const StyledClaimOnBoardImageHand = styled('img')`
  width: 7.4375em;
  transform: scaleX(-1);
  opacity: 0;
  opacity: 0;
  animation: 0.7s ${fadeIn} 0.5s linear forwards;
  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 10.1725rem;
  }
`;

export const StyledClaimOnBoardImageRechnung = styled('img')`
  width: 2.5625em;
  height: 2.5625em;
  z-index: 5;
  transform: translateX(100vw) translateY(-0.3125em) scale(2.2);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;

  &:nth-child(1) {
    animation: 1s ${userClaimsRechnungInStay} 0.7s ease-in-out forwards;
  }
  &:nth-child(2) {
    animation: 1s ${userClaimsRechnungInHide} 0.8s ease-in-out forwards;
  }
  &:nth-child(3) {
    animation: 1s ${userClaimsRechnungInHide} 0.9s ease-in-out forwards;
  }
  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 5.2112rem;
    height: 5.1638rem;
    left: 0.25rem;
  }
`;

export const StyledClaimOnBoardImagePhone = styled('img')`
  width: 3.75em;
  height: 6.5625em;
  opacity: 0;
  animation: 0.7s ${fadeIn} 0.5s linear forwards;
  @media (min-width: ${MIN_WIDTH_MD}) {
    display: none !important;
  }
`;

export const StyledClaimOnBoardImageTablet = styled('img')`
  display: none !important;
  opacity: 0;
  animation: 0.7s ${fadeIn} 0.5s linear forwards;
  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 5em;
    display: inline-block !important;
  }
  @media (min-width: ${MIN_WIDTH_LG}) {
    display: none !important;
  }
`;

export const StyledClaimOnBoardImageLabtop = styled('img')`
  opacity: 0;
  display: none !important;
  animation: 0.7s ${fadeIn} 0.5s linear forwards;
  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 17.25rem;
    display: inline-block !important;
  }
`;

export const StyledClaimOnBoardImageSuccess = styled('img')`
  width: 1.3125em;
  height: 1.3125em;
  position: relative;
  transform: scale(0);
  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 2.1875rem;
    height: 2.1875rem;
  }
`;

export const StyledClaimOnBoardImageSuccess1 = styled(StyledClaimOnBoardImageSuccess)`
  animation: 0.2s ${scaleIn} 0.9s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
`;

export const StyledClaimOnBoardImageSuccess2 = styled(StyledClaimOnBoardImageSuccess)`
  animation: 0.2s ${scaleIn} 1.1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
`;

export const StyledClaimOnBoardImageSuccess3 = styled('img')`
  width: 2.75em;
  height: 2.75em;
  transform: scale(0);
  animation: 0.2s ${scaleIn} 1.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 4.625rem;
    height: 4.625rem;
  }
`;

export const StyledClaimOnBoardImageSuccessLine = styled('span')(
  ({ theme }) => css`
    width: 6.25em;
    height: 0.1875em;
    flex: 0 0 auto;
    position: relative;
    &:before {
      content: '';
      width: 0%;
      height: 100%;
      background-color: ${theme.palette.main.professional_blue};
      position: absolute;
      top: 0;
      left: 0;
    }

    @media (min-width: ${MIN_WIDTH_MD}) {
      height: 0.25rem;
    }
  `
);

export const StyledClaimOnBoardImageSuccessLine1 = styled(StyledClaimOnBoardImageSuccessLine)`
  &:before {
    animation: 0.2s ${userClaimsLinesLoad} 0.9s ease-in-out forwards;
  }
  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 8.25em;
  }
`;

export const StyledClaimOnBoardImageSuccessLine2 = styled(StyledClaimOnBoardImageSuccessLine)`
  &:before {
    animation: 0.2s ${userClaimsLinesLoad} 1.1s ease-in-out forwards;
  }
`;

export const StyledClaimOnBoardImageCoinListContainer = styled('div')`
  position: relative;
  z-index: 2;
  max-height: 3.75em;
  transform: translate3d(0.375em, 0, 0);
`;

export const StyledClaimOnBoardImageCoin = styled('img')`
  position: relative;
  animation: 0.2s ${userClaimsCoinDrop} ease-out forwards;
  width: 3.375em;
  height: 1.9375em;
  &:nth-child(1) {
    z-index: 8;
    top: 0;
    left: 0;
    transform: translate3d(0, -18.75em, 0);
    animation-delay: 1.2s;
  }
  &:nth-child(2) {
    z-index: 7;
    top: -1.1875em;
    left: 0;
    transform: translate3d(0, -18.75em, 0);
    animation-delay: 1.15s;
  }
  &:nth-child(3) {
    z-index: 6;
    top: -2.375em;
    left: 0.25em;
    transform: translate3d(0, -18.75em, 0);
    animation-delay: 1.1s;
  }
  &:nth-child(4) {
    z-index: 5;
    top: -3.5625em;
    left: -0.1875em;
    transform: translate3d(0, -18.75em, 0);
    animation-delay: 1.05s;
  }
  &:nth-child(5) {
    z-index: 4;
    top: -4.75em;
    left: -0.375em;
    transform: translate3d(0, -18.75em, 0);
    animation-delay: 1s;
  }
`;

export const StyledClaimOnBoardImageCoins = styled('img')`
  width: 4.8125em;
  height: 5.1875em;
  position: relative;
  z-index: 1;
  transform: translate3d(-0.625em, 0, 0);
  opacity: 0;
  animation: 0.7s ${fadeIn} 0.5s linear forwards;
`;

export const StyledClaimOnBoardImageStars = styled('img')`
  width: 2.6875em;
  height: 2.25em;
  position: absolute;
  top: -8.875em;
  left: 0;
  right: -5.6875em;
  bottom: 0;
  margin: auto;
  opacity: 0;
  animation: 0.2s ${fadeIn} 1.5s linear forwards;
`;
