import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledDropdownMenuItem,
  StyledDropdownMenuItemExternal,
} from './styled/NavLinkDropDown.styled';

const LinkContents = ({ icon, text }) => {
  return (
    <>
      <img src={icon} alt="" />
      <span>{text}</span>
    </>
  );
};

function NavMenuLink(props) {
  const { tabIndex, text, link, icon, onClick, externalUrl } = props;

  if (externalUrl) {
    return (
      <StyledDropdownMenuItemExternal href={link} onClick={onClick}>
        <LinkContents icon={icon} text={text} />
      </StyledDropdownMenuItemExternal>
    );
  }

  return (
    <StyledDropdownMenuItem to={link} onClick={onClick} tabIndex={tabIndex}>
      <LinkContents icon={icon} text={text} />
    </StyledDropdownMenuItem>
  );
}

export default NavMenuLink;

NavMenuLink.propTypes = {
  tabIndex: PropTypes.number,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  link: PropTypes.string,
  icon: PropTypes.string,
  externalUrl: PropTypes.bool,
  onClick: PropTypes.func,
};
