import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PETOLO_STEP_DOCUMENT_TYPE,
  PETOLO_STEP_REASON_FOR_TREATMENT,
} from '../../new/newClaimsFormSteps';
import { PET_HEALTH_TREATMENT_CATEGORIES_WITH_BUDGET } from '../../claimFormData/newClaimFormData';

export const useSelectTreatmentCategoriesPetHealthBackButtonLink = () => {
  return '/user-claims-info';
};

export const useSelectTreatmentCategoriesPetHealthNextButtonLink = (treatmentCategories) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (treatmentCategories?.length === 1) {
    const treatmentCategory = treatmentCategories[0];

    if (PET_HEALTH_TREATMENT_CATEGORIES_WITH_BUDGET.includes(treatmentCategory)) {
      return withSource(PETOLO_STEP_REASON_FOR_TREATMENT);
    }
  }
  return withSource(PETOLO_STEP_DOCUMENT_TYPE);
};
