export const { NODE_ENV } = process.env;
export const APP_ENV = process.env.REACT_APP_ENV;

export const PARTNER_DA_DIREKT = 'da-direkt';
export const PARTNER_FRESSNAPF = 'fressnapf';

export const RISK_CARRIER_AMTRUST = 'amtrust';

export const INSURANCE_CATEGORY_DENTAL = 'dental';
export const INSURANCE_CATEGORY_PET_HEALTH = 'pet_health';
export const INSURANCE_CATEGORY_PET_LIABILITY = 'pet_liability';
export const INSURANCE_CATEGORY_HEALTH = 'health';
export const INSURANCE_CATEGORIES = [INSURANCE_CATEGORY_PET_HEALTH, INSURANCE_CATEGORY_DENTAL];

export const DASHBOARD_ONBOARD_KEY = 'home-onboard';
export const USER_CLAIMS_ONBOARD_KEY = 'user-claims-onboard';
export const PETOLO_DASHBOARD_ONBOARD_KEY = 'petolo-home-onboard';
export const CCDOCS_LS_SHOW_KEY = 'ccdocs-ls-show';
export const PETOLO_CROSS_SELLING_BANNER_KEY = 'hide-petolo-cross-selling-banner';
export const BANNER_INSURE_ANOTHER_PET_KEY = 'banner-insure-another-pet';

export const DENTAL_GREEN_COLOR = 'green';
export const PET_ORANGE_COLOR = 'orange';

export const DOMAIN_DENTOLO = 'dentolo.de';
export const DOMAIN_PETOLO = 'petolo.de';
export const DOMAIN_VITOLO = 'vitolo.de';

export const INSURANCE_TYPE_DENTOLO = 'dentolo';
export const INSURANCE_TYPE_PETOLO = 'petolo';
export const INSURANCE_TYPE_VITOLO = 'vitolo';

export const INSURANCE_TYPES = [
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
];
export const DEFAULT_INSURANCE_TYPE = INSURANCE_TYPE_DENTOLO;

export const THEME_DENTOLO = 'dentolo';
export const THEME_PETOLO = 'petolo';
export const THEME_VITOLO = 'vitolo';

export const AVAILABLE_THEMES = [THEME_DENTOLO, THEME_PETOLO, THEME_VITOLO];

export const EDITOR_MODAL_MODE_FORM = 'form';
export const EDITOR_MODAL_MODE_SUCCESS = 'success';
export const EDITOR_MODAL_MODE_FAILURE = 'failure';

export const DENTOLO_DENTAL_POLICY_CATEGORIES = [6, 7, 8, 13];
export const PETOLO_PET_HEALTH_POLICY_CATEGORIES = [14, 15, 16, 23, 24, 25];
export const PETOLO_PET_LIABILITY_POLICY_CATEGORIES = [26, 27];
export const VITOLO_HEALTH_POLICY_CATEGORIES_V1 = [30, 31, 32];
export const VITOLO_HEALTH_POLICY_CATEGORIES_V2 = [36, 37, 38];
export const VITOLO_HEALTH_POLICY_CATEGORIES = [
  ...VITOLO_HEALTH_POLICY_CATEGORIES_V1,
  ...VITOLO_HEALTH_POLICY_CATEGORIES_V2,
];

export const DA_DIREKT_DENTAL_POLICY_CATEGORIES = [9, 10, 11, 12];
export const DA_DIREKT_PET_HEALTH_POLICY_CATEGORIES = [17, 18, 19, 20, 21, 22];
export const DA_DIREKT_HEALTH_POLICY_CATEGORIES_V1 = [33, 34, 35];
export const DA_DIREKT_HEALTH_POLICY_CATEGORIES_V2 = [39, 40, 41];
export const DA_DIREKT_HEALTH_POLICY_CATEGORIES = [
  ...DA_DIREKT_HEALTH_POLICY_CATEGORIES_V1,
  ...DA_DIREKT_HEALTH_POLICY_CATEGORIES_V2,
];
export const DA_DIREKT_POLICY_CATEGORIES = [
  ...DA_DIREKT_DENTAL_POLICY_CATEGORIES,
  ...DA_DIREKT_PET_HEALTH_POLICY_CATEGORIES,
  ...DA_DIREKT_HEALTH_POLICY_CATEGORIES,
];

export const SPECIAL_TARIFF_PET_POLICY_CATEGORIES = [28, 29];
export const SPECIAL_TARIFF_POLICY_CATEGORIES = [...SPECIAL_TARIFF_PET_POLICY_CATEGORIES];

export const CAT_POLICY_CATEGORIES = [20, 21, 22, 23, 24, 25];

export const VALID_DATE = 'valid_date';
export const NOT_VALID_DATE = 'not_valid_date';
export const IN_PAST_DATE = 'in_past_date';
export const VALID_TIME = 'valid_time';
export const INVALID_TIME = 'invalid_time';

export const VALID_FIELD = 'valid_field';
export const INVALID_FIELD = 'invalid_field';

export const SERVICE_EMAIL_DENTOLO = 'service@dentolo.de';
export const SERVICE_EMAIL_PETOLO = 'service@petolo.de';
export const SERVICE_EMAIL_VITOLO = 'service@vitolo.de';

export const CREDIT_CARD = 'CREDIT_CARD';
export const PAYPAL = 'PAYPAL';
export const GOOGLEPAY = 'GOOGLEPAY';
export const APPLEPAY = 'APPLEPAY';
export const MASTERCARD = 'MASTERCARD';
export const VISA = 'VISA';
export const PAYMENT_SUCCESS_STATUS = 'success';
export const PAYMENT_FAILURE_STATUS = 'fail';

export const PAYMENT_EDIT_STATUS_PENDING = 'pending';
export const PAYMENT_EDIT_STATUS_FAILED = 'failed';

// Datadog variables
export const DATADOG_ENV = process.env.REACT_APP_DATADOG_ENV;
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
export const DATADOG_SITE = 'datadoghq.eu';
export const DATADOG_SERVICE = 'customer-account';
export const DATADOG_404_ERROR_MESSAGE = '404 Error: Page not found';

export const LOCAL_STORAGE_EXPERIMENT_TARGET_ID_KEY = 'experimentTargetId';

export const DA_DIREKT_SOURCE_FIRST_VET = 'da_direkt_026077070';
export const KAUFLAND_SOURCE_FIRST_VET = [
  'da_direkt_026077128',
  'da_direkt_026077129',
  'da_direkt_026077130',
];
