import { styled, css } from '@mui/material';

const Separator = styled('hr')(
  ({ theme }) => css`
    border: none;
    height: 1px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: ${theme.palette.main.product_blue};
  `
);

export default Separator;
