import React from 'react';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import useDynamicLink from '../../../../hooks/useDynamicLink';
import { MIN_WIDTH_MD } from '../../../../theme/breakpoints';
import { DA_DIREKT_CROSS_SELLING_PET_URL } from '../../../../shared';

import DaDirektPetCardDesktop from './DaDirektPetCardDesktop';
import DaDirektPetCardMobile from './DaDirektPetCardMobile';

const daFont = [
  {
    href: 'https://www.da-direkt.de/-/media/Themes/DADirekt/Global/DADirektTheme/fonts/centrano2-medium-woff2.woff2',
    rel: 'preload',
    as: 'font',
    type: 'font/woff2',
  },
];

function DaDirektPetCard({ trackingparams }) {
  useDynamicLink(daFont);

  const isDesktop = useMediaQuery(`(min-width: ${MIN_WIDTH_MD})`);

  const petCrossSellingUrl = `${DA_DIREKT_CROSS_SELLING_PET_URL}?${trackingparams}`;

  const goToCrossSellingLink = () => {
    window.open(petCrossSellingUrl, '_blank');
  };

  return isDesktop ? (
    <DaDirektPetCardDesktop onGoToCrossSellingLink={goToCrossSellingLink} />
  ) : (
    <DaDirektPetCardMobile onGoToCrossSellingLink={goToCrossSellingLink} />
  );
}

export default DaDirektPetCard;
