import { styled } from '@mui/material';
import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import { PALETTE } from '../../theme/palette';

export const StyledAkutschutzCancellationContentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  row-gap: 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 1.5rem;
    row-gap: 1.5rem;
  }
`;

export const StyledAkutschutzCancellationDateContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  row-gap: 0.5rem;
  text-align: center;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 1.5rem;
  }
`;

export const StyledAkutschutzCancellationDate = styled('div')`
  background-color: ${PALETTE.VITALIZED_GREEN};
  margin: 0 auto;
  padding: 0.125rem 0.5rem;
  width: fit-content;
`;

export const StyledAkutschutzCancellationButtonContainer = styled('div')`
  column-gap: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row;
    width: auto;
  }
`;

export const StyledAkutschutzCancellationCompleteContainer = styled(
  StyledAkutschutzCancellationContentContainer
)`
  text-align: center;
`;

export const StyledAkutschutzCancellationCompleteButtonContainer = styled(
  StyledAkutschutzCancellationButtonContainer
)`
  @media (min-width: ${MIN_WIDTH_MD}) {
    margin: 0 auto;
    max-width: 21.375rem;
  }
`;
