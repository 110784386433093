import { styled } from '@mui/material';

import { PALETTE } from '../../../theme/palette';
import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

const cardBackground = (product) =>
  ({
    dental: {
      backgroundColor: PALETTE.DA_DIREKT_LIGHT_ORANGE,
    },
    pet: {
      backgroundColor: PALETTE.DA_DIREKT_LIGHT_BLUE,
    },
    health: {
      backgroundColor: PALETTE.DA_DIREKT_LIGHT_GREEN,
    },
  })[`${product}`];

export const StyledDaDirektCrossSellingCard = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 1.125rem;
  width: 100%;
  height: 220px;

  ${({ product }) => cardBackground(product)};

  @media (min-width: ${MIN_WIDTH_MD}) {
    padding-left: 1.25rem;
    height: 300px;
  }
`;

export const StyledDaDirektCrossSellingCardContent = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  margin-top: 1.5rem;
  max-width: initial;
  z-index: 1;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 2.5rem;
    max-width: 400px;
  }
`;

export const StyledDaDirektCrossSellingCardLogoAndImage = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0;
  align-items: flex-end;
  justify-content: space-between;
  width: 142px;

  @media (min-width: ${MIN_WIDTH_MD}) {
    row-gap: 1.5rem;
    width: 230px;
  }
`;

export const StyledDaDirektCrossSellingCardTitle = styled('h2')`
  max-width: 185px;
  font-family: ${({ theme }) => theme.daDirekt.fontFamily};
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 18px;

  & + & {
    margin-top: 0.8rem;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    max-width: 100%;
    font-size: 1.25rem;
    line-height: 26px;

    & + & {
      margin-top: 0;
    }
  }
`;

export const StyledDaDirektCrossSellingCardText = styled('p')`
  /* font-family: ${({ theme }) => theme.typography.daDirektFontFamily}; */
  font-family: ${({ theme }) => theme.daDirekt.fontFamily};
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 18px;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 2.75rem;
    font-size: 0.875rem;
    max-width: 350px;
  }
`;

export const StyledDaDirektCrossSellingCardUl = styled('ul')`
  margin: 0;
  padding-left: 1.125rem; // same as the container's padding-left
  list-style-image: ${({ bulletimage }) => `url(${bulletimage})`};
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 22px;

  > li {
    font-family: ${({ theme }) => theme.daDirekt.fontFamily};
  }
`;

export const StyledDaDirektCrossSellingCardDaDirektLogo = styled('img')`
  margin-right: 0.875rem;
  margin-top: initial;
  margin-bottom: 1.5rem;
  width: 67px;
  height: auto;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: initial;
    width: 92px;
  }
`;

export const StyledDaDirektCrossSellingCardButton = styled('button')`
  display: block;
  all: unset;
  margin-top: 0.5rem;
  padding: 0.688rem 0;
  width: 186px;
  font-family: ${({ theme }) => theme.daDirekt.fontFamily};
  font-size: 0.875rem;
  font-weight: 700;
  text-align: center;
  color: ${PALETTE.WHITE};
  background-color: ${({ theme }) => theme.palette.main.professional_blue};
  border-radius: 4px;
  cursor: pointer;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 1.125rem;
    padding: 0.75rem;
  }
`;
