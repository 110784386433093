import React from 'react';

import {
  PET_LIABILITY_DAMAGE_ESTIMATE,
  PET_LIABILITY_DAMAGE_ESTIMATE_1001_5000,
  PET_LIABILITY_DAMAGE_ESTIMATE_5001_10000,
  PET_LIABILITY_DAMAGE_ESTIMATE_501_1000,
  PET_LIABILITY_DAMAGE_ESTIMATE_UNKNOWN,
  PET_LIABILITY_DAMAGE_ESTIMATE_UPTO_500,
  useNewPetLiabilityFormDataValue,
} from '../../claimFormData/newClaimFormDataPetLiability';
import {
  useDamageEstimatePetLiabilityBackButtonLink,
  useDamageEstimatePetLiabilityNextButtonLink,
} from './useDamageEstimatePetLiability';

import ListWithRadioButton from '../common/ListWithRadioButton';

const damageEstimateConfig = [
  {
    label: 'pet_liability.new_claim.damage_estimate.upto_500',
    value: PET_LIABILITY_DAMAGE_ESTIMATE_UPTO_500,
  },
  {
    label: 'pet_liability.new_claim.damage_estimate.501_1000',
    value: PET_LIABILITY_DAMAGE_ESTIMATE_501_1000,
  },
  {
    label: 'pet_liability.new_claim.damage_estimate.1001_5000',
    value: PET_LIABILITY_DAMAGE_ESTIMATE_1001_5000,
  },
  {
    label: 'pet_liability.new_claim.damage_estimate.5001_10000',
    value: PET_LIABILITY_DAMAGE_ESTIMATE_5001_10000,
  },
  {
    label: 'pet_liability.new_claim.damage_estimate.unknown',
    value: PET_LIABILITY_DAMAGE_ESTIMATE_UNKNOWN,
  },
];

export default function DamageEstimatePetLiability() {
  const [damageEstimate, setDamageEstimate] = useNewPetLiabilityFormDataValue(
    PET_LIABILITY_DAMAGE_ESTIMATE
  );

  const backButtonLink = useDamageEstimatePetLiabilityBackButtonLink();
  const nextButtonLink = useDamageEstimatePetLiabilityNextButtonLink();

  return (
    <ListWithRadioButton
      name="damage_estimate_pet_liability"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId="pet_liability.new_claim.damage_estimate.page_title"
      listConfig={damageEstimateConfig}
      selected={damageEstimate}
      setSelected={setDamageEstimate}
    />
  );
}
