import { css } from '@mui/material';
// TODO: Replace with @mui styled.
import styled from '@emotion/styled/macro';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';
import { Icon } from '../../common';
import {
  navMenuUnderlineActiveBaseStyle,
  navMenuUnderlineBaseStyle,
} from './NavMenuDesktop.styled';

export const StyledNavMenuAccordionToggleLabel = styled('div')(
  ({ theme }) => css`
    position: relative;
    font-size: 1rem;
    font-weight: 700;
    color: ${theme.palette.main.professional_blue};

    ${navMenuUnderlineBaseStyle({ theme })};

    &::after {
      bottom: -1px;
    }
  `
);

export const StyledNavMenuAccordionToggleArrowIcon = styled(Icon)(
  ({ rotate }) => css`
    width: 1.25rem;
    height: auto;
    transition:
      transform 0.15s ease-out,
      opacity 0.2s ease-out;

    @media (min-width: ${MIN_WIDTH_MD}) {
      width: 1.25rem;
      height: auto;
    }

    ${rotate &&
    css`
      transform: rotate(180deg);
    `}
  `
);

export const StyledNavMenuAccordionToggle = styled('div')(
  ({ theme, expanded, active }) => css`
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.4rem 1rem;
    min-height: 3rem;
    cursor: pointer;

    ${(expanded || active) &&
    css`
      ${StyledNavMenuAccordionToggleLabel} {
        color: ${theme.palette.main.professional_light_blue};
        ${navMenuUnderlineActiveBaseStyle};
      }

      ${StyledNavMenuAccordionToggleArrowIcon} {
        opacity: 0.5;
      }
    `}
  `
);

export const StyledNavMenuAccordion = styled('div')(
  ({ theme, expanded, type }) => css`
    margin: 0;
    background-color: ${theme.palette.common.white};
    border-bottom: 1px solid ${theme.palette.main.product_blue};
    max-height: 6rem;
    transition: max-height 0.35s ease-in;
    overflow: hidden;

    ul {
      margin: 0;
      padding-left: 0;
    }

    ${expanded &&
    css`
      max-height: 70rem;
    `}

    ${type === 'user' &&
    css`
      background: transparent;

      ${StyledNavMenuAccordionToggle} {
        padding-top: 0.875rem;
        padding-bottom: 0.875rem;
      }
    `}
  `
);

export const StyledNavMenuAccordionContent = styled('div')`
  padding: 1rem;
`;

export const StyledUserContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
`;
