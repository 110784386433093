import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useIntl, FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';

import { GET_CUSTOMER, formatPolicyName, showMoney, useReady } from '../shared';
import fetchPolicyCoverageInfo from '../actions/PolicyActions';

import { Premium, Conditions, MainDetails, AddOns } from './PolicyDetailsItems';
import { ErrorPlaceholder } from './ui';
import { BackButton, Paper, Separator } from './common';
import { StyledFlexContainer } from './styled/PolicyInfo.styled';
import {
  StyledPolicyDetailsContainer,
  StyledPolicyDetailsTitle,
  StyledPolicyDetailsPolicyName,
  StyledPolicyDetailsTextLg,
  StyledPolicyDetailsText,
} from './styled/PolicyDetails.styled';

const PriceBreakup = ({ addons, monthlyPremium, policyName }) => {
  const addOnTitle = addons?.title || '';
  const addOnPrice = addons?.price || 0;
  const premiumPrice = monthlyPremium - addOnPrice;

  return (
    <>
      <StyledFlexContainer margin="1.5rem 0">
        <StyledPolicyDetailsText>
          <FormattedMessage id={`policies.${formatPolicyName(policyName)}`} />
        </StyledPolicyDetailsText>

        <StyledPolicyDetailsTextLg style={{ fontSize: '1rem' }}>
          {showMoney(premiumPrice)}
        </StyledPolicyDetailsTextLg>
      </StyledFlexContainer>

      <StyledFlexContainer margin="1.5rem 0">
        <StyledPolicyDetailsText>{addOnTitle}</StyledPolicyDetailsText>

        <StyledPolicyDetailsTextLg style={{ fontSize: '1rem' }}>
          {showMoney(addOnPrice)}
        </StyledPolicyDetailsTextLg>
      </StyledFlexContainer>
    </>
  );
};

const PolicyDetails = () => {
  const ready = useReady();
  const intl = useIntl();

  const [policyCoverageInfo, setPolicyCoverageInfo] = useState(null);
  const { loading, data, error } = useQuery(GET_CUSTOMER);
  const { customer } = data;
  const policyName = customer?.contract?.policyName;
  const policyCategory = customer?.contract?.policyCategory;
  const imCoverage = customer?.contract?.imCoverage;
  const monthlyPremium = customer?.contract?.monthlyPremium;

  const contractPremium = customer?.contract?.contractPremium;
  const contractBudgetSchedule = customer?.contract?.contractBudgetSchedule;
  const budgetScheduleAddon = customer?.contract?.addonBudgedSchedule;

  const budgetAmountInfo = budgetScheduleAddon
    ? budgetScheduleAddon?.limits
    : contractBudgetSchedule;

  const locale = intl?.locale;

  useEffect(() => {
    if (!loading && !!customer) {
      fetchPolicyCoverageInfo(policyCategory, imCoverage, locale).then(setPolicyCoverageInfo);
    }
  }, [loading, customer, policyCategory, imCoverage, locale]);

  if (customer?.contract) {
    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <StyledPolicyDetailsContainer>
          <BackButton to="/policy-info" />

          <Paper>
            <div style={{ marginBottom: '1.5rem' }}>
              <StyledPolicyDetailsTitle>
                <FormattedMessage id="policy.all_details.title" />
              </StyledPolicyDetailsTitle>

              <StyledPolicyDetailsPolicyName>
                <FormattedMessage id={`policies.${formatPolicyName(policyName)}`} />
              </StyledPolicyDetailsPolicyName>
            </div>

            {customer?.contract?.status !== 'ended' && (
              <>
                <Separator />

                <StyledFlexContainer margin="1.5rem 0">
                  <StyledPolicyDetailsTextLg>
                    <FormattedMessage id="policy.card.label.monthly_premium" />
                  </StyledPolicyDetailsTextLg>

                  <StyledPolicyDetailsTextLg>{showMoney(monthlyPremium)}</StyledPolicyDetailsTextLg>
                </StyledFlexContainer>
              </>
            )}

            {imCoverage && !!policyCoverageInfo && (
              <PriceBreakup
                addons={policyCoverageInfo?.addons}
                monthlyPremium={monthlyPremium}
                policyName={policyName}
              />
            )}

            <Separator />

            <AddOns details={policyCoverageInfo?.addons} flipStyling />

            <MainDetails details={policyCoverageInfo?.main} />

            <Conditions
              conditions={policyCoverageInfo?.conditions}
              budgetInfo={policyCoverageInfo?.budget}
              budgetAmountInfo={budgetAmountInfo}
              hasAddon={!!budgetScheduleAddon}
            />

            <Premium premiumInfo={policyCoverageInfo?.premium} contractPremium={contractPremium} />
          </Paper>
        </StyledPolicyDetailsContainer>
      </CSSTransition>
    );
  }

  if (error) {
    return <ErrorPlaceholder />;
  }

  return null;
};

export default PolicyDetails;
