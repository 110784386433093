import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import { MIN_WIDTH_MD } from '../../../../theme/breakpoints';

import { Paper, Paragraph } from '../../../../components/common';
import { Button } from '../../../../components/ui';
import FileUploader from './FileUploader';
import FileList from './FileList';
import {
  StyledButtonContainer,
  StyledFileList,
  StyledConfirmRefundParagraph,
} from './FileUploader.styled';

export default function UploadOtherTreatmentDocumentVitolo(props) {
  const { selectedDocuments, setSelectedDocuments, nextButtonLink } = props;

  const isDesktop = useMediaQuery(`(min-width: ${MIN_WIDTH_MD})`);

  const validSelectedDocuments = Array.isArray(selectedDocuments)
    ? selectedDocuments.filter((d) => d?.valid)
    : [];

  const history = useHistory();
  const addFileHandler = (currFiles) => {
    setSelectedDocuments((state) => [...state, ...currFiles]);
  };

  const removeFileHandler = (ev, id) => {
    ev?.preventDefault();
    ev?.stopPropagation();

    const updatedFileList = selectedDocuments?.filter((item) => item?.fileId !== id);
    setSelectedDocuments(updatedFileList);
  };

  const nextStepHandler = () => {
    history.push(nextButtonLink);
  };

  const hasFiles = selectedDocuments?.length > 0;

  return (
    <>
      <StyledConfirmRefundParagraph>
        <FormattedMessage id="new_claim.upload.page.received_refund" />
      </StyledConfirmRefundParagraph>

      {!hasFiles && (
        <Paragraph>
          <FormattedMessage id="new_claim.upload.page.description" />
        </Paragraph>
      )}

      {hasFiles && (
        <StyledFileList>
          <FileList files={selectedDocuments} onRemove={removeFileHandler} />
        </StyledFileList>
      )}

      <Paper>
        <FileUploader selectedFiles={selectedDocuments} setSelectedFiles={addFileHandler} />

        {isDesktop && (
          <StyledButtonContainer hasFiles={hasFiles}>
            <Button
              onClick={nextStepHandler}
              disabled={!validSelectedDocuments.length}
              data-testid="next_button"
            >
              <FormattedMessage id="common.button.next" />
            </Button>
          </StyledButtonContainer>
        )}
      </Paper>

      {!isDesktop && (
        <StyledButtonContainer hasFiles={hasFiles}>
          <Button
            onClick={nextStepHandler}
            disabled={!validSelectedDocuments.length}
            data-testid="next_button"
          >
            <FormattedMessage id="common.button.next" />
          </Button>
        </StyledButtonContainer>
      )}
    </>
  );
}

UploadOtherTreatmentDocumentVitolo.propTypes = {
  selectedDocuments: PropTypes.arrayOf(
    PropTypes.shape({
      fileId: PropTypes.string,
      valid: PropTypes.bool,
    })
  ),
  setSelectedDocuments: PropTypes.func,
};
