import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  ButtonContainer,
  BackButton,
  PageTitle,
  Paper,
  Radio,
  RadioLabel,
  Text,
} from '../../../../components/common';
import { Button } from '../../../../components/ui';
import {
  StyledTreatmentCategoryContainer,
  StyledTreatmentCategory,
  StyledTreatmentCategoryImage,
  RadioOptionContainer,
} from './SelectTreatmentCategory.styled';

const SelectTreatmentCategory = ({
  backButtonLink,
  nextButtonLink,
  treatmentCategory,
  setTreatmentCategory,
  pageTitleId,
  pageDescriptionId,
  treatmentCategoryConfig,
}) => {
  const history = useHistory();

  const validatedTreatmentCategoryConfig = Array.isArray(treatmentCategoryConfig)
    ? treatmentCategoryConfig
    : [];

  return (
    <>
      <BackButton to={backButtonLink}>
        <FormattedMessage id="common.button.back" />
      </BackButton>

      {pageTitleId && (
        <PageTitle>
          <FormattedMessage id={pageTitleId} />
        </PageTitle>
      )}

      {pageDescriptionId && (
        <Text style={{ marginBottom: '1.5rem' }}>
          <FormattedMessage id={pageDescriptionId} />
        </Text>
      )}

      {validatedTreatmentCategoryConfig.map((item) => {
        const { label, value, image } = item || {};
        return (
          <StyledTreatmentCategoryContainer key={value}>
            <Paper>
              <StyledTreatmentCategory>
                <RadioOptionContainer onClick={() => setTreatmentCategory(value)}>
                  <Radio
                    name="treatment_category"
                    value={value}
                    checked={value === treatmentCategory}
                    onChange={(e) => {
                      e.stopPropagation();
                      setTreatmentCategory(value);
                    }}
                  />

                  <StyledTreatmentCategoryImage src={image} alt={value} />
                  <RadioLabel>
                    <Text>
                      <FormattedMessage id={label} />
                    </Text>
                  </RadioLabel>
                </RadioOptionContainer>
              </StyledTreatmentCategory>
            </Paper>
          </StyledTreatmentCategoryContainer>
        );
      })}

      <ButtonContainer>
        <Button
          variant="primary"
          disabled={!treatmentCategory}
          onClick={() => history.push(nextButtonLink)}
          data-testid="btn_treatment_cat_next"
        >
          <FormattedMessage id="common.button.next" />
        </Button>
      </ButtonContainer>
    </>
  );
};

export default SelectTreatmentCategory;
