import React from 'react';
import { imgLinks } from '../../shared';

export default function IllustrationDesktopVitolo() {
  return (
    <div style={{ alignItems: 'center', display: 'flex', height: '16.25rem' }}>
      <img
        src={imgLinks['illustration.pending_approval.vitolo.desktop']}
        alt="Vitolo"
        style={{ height: 'fit-content', margin: '0 auto' }}
      />
    </div>
  );
}
