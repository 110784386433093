import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Container } from '../../../components/common';
import { useReady } from '../../../shared';
import SelectTreatmentCategoriesPetHealth from '../components/SelectTreatmentCategory/SelectTreatmentCategoriesPetHealth';
import SelectReasonForTreatment from '../components/SelectReasonForTreatment/SelectReasonForTreatment';
import SelectDocumentTypePetolo from '../components/SelectDocumentType/SelectDocumentTypePetolo';
import ExistingClaimQuestionPetolo from '../components/ExistingClaimQuestion/ExistingClaimQuestionPetolo';
import SelectExistingClaimPetolo from '../components/SelectExistingClaim/SelectExistingClaimPetolo';
import UploadTreatmentDocumentPetolo from '../components/UploadDocument/UploadTreatmentDocumentPetolo';
import SelectDocumentCreationDatePetolo from '../components/SelectDocumentCreationDate/SelectDocumentCreationDatePetolo';
import SelectTreatmentDatePetolo from '../components/SelectTreatmentDate/SelectTreatmentDatePetolo';
import TreatmentAmountPetolo from '../components/TreatmentAmount/TreatmentAmountPetolo';
import LinkVideoConsultationQuestion from '../components/LinkVideoConsultationQuestion/LinkVideoConsultationQuestion';
import CustomerNotePetolo from '../components/CustomerNote/CustomerNotePetolo';
import SubmitClaimFormPetolo from '../components/SubmitClaimForm/SubmitClaimFormPetolo';
import SelectVideoConsultation from '../components/SelectVideoConsultation/SelectVideoConsultation';
import {
  PET_HEALTH_STEP_TREATMENT_CATEGORIES,
  PETOLO_STEP_DOCUMENT_TYPE,
  PETOLO_STEP_EXISTING_CLAIM_QUESTION,
  PETOLO_STEP_EXISTING_CLAIMS,
  PETOLO_STEP_UPLOAD_DOCUMENTS,
  PETOLO_STEP_DOCUMENT_DATE,
  PETOLO_STEP_TREATMENT_DATE,
  PETOLO_STEP_TREATMENT_PRICE,
  PETOLO_STEP_CONSULTATION_QUESTION,
  PETOLO_STEP_CUSTOMER_NOTE,
  PETOLO_STEP_VIDEO_CONSULTATION,
  PETOLO_STEP_REVIEW,
  PETOLO_STEP_REASON_FOR_TREATMENT,
} from './newClaimsFormSteps';

const NewPetoloClaim = ({
  selectedDocuments,
  setSelectedDocuments,
  setClaimSubmitted,
  relevantClaims,
}) => {
  const ready = useReady();
  const { path } = useRouteMatch();

  const relevantClaimsCount = relevantClaims?.length || 0;

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <Route
          render={({ location }) => (
            <Switch location={location}>
              <Route
                exact
                path={path}
                render={() => (
                  <Redirect
                    to={{
                      pathname: PET_HEALTH_STEP_TREATMENT_CATEGORIES,
                      search: location.search,
                    }}
                  />
                )}
              />

              <Route exact path={PET_HEALTH_STEP_TREATMENT_CATEGORIES}>
                <SelectTreatmentCategoriesPetHealth />
              </Route>

              <Route exact path={PETOLO_STEP_REASON_FOR_TREATMENT}>
                <SelectReasonForTreatment />
              </Route>

              <Route exact path={PETOLO_STEP_DOCUMENT_TYPE}>
                <SelectDocumentTypePetolo />
              </Route>

              <Route exact path={PETOLO_STEP_EXISTING_CLAIM_QUESTION}>
                <ExistingClaimQuestionPetolo />
              </Route>

              <Route exact path={PETOLO_STEP_EXISTING_CLAIMS}>
                <SelectExistingClaimPetolo claims={relevantClaims} />
              </Route>

              <Route exact path={PETOLO_STEP_UPLOAD_DOCUMENTS}>
                <UploadTreatmentDocumentPetolo
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                  setClaimSubmitted={setClaimSubmitted}
                  claimsCount={relevantClaimsCount}
                />
              </Route>

              <Route exact path={PETOLO_STEP_DOCUMENT_DATE}>
                <SelectDocumentCreationDatePetolo />
              </Route>

              <Route exact path={PETOLO_STEP_TREATMENT_DATE}>
                <SelectTreatmentDatePetolo />
              </Route>

              <Route exact path={PETOLO_STEP_TREATMENT_PRICE}>
                <TreatmentAmountPetolo />
              </Route>

              <Route exact path={PETOLO_STEP_CONSULTATION_QUESTION}>
                <LinkVideoConsultationQuestion />
              </Route>

              <Route exact path={PETOLO_STEP_VIDEO_CONSULTATION}>
                <SelectVideoConsultation />
              </Route>

              <Route exact path={PETOLO_STEP_CUSTOMER_NOTE}>
                <CustomerNotePetolo />
              </Route>

              <Route exact path={PETOLO_STEP_REVIEW}>
                <SubmitClaimFormPetolo
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                  setClaimSubmitted={setClaimSubmitted}
                />
              </Route>
            </Switch>
          )}
        />
      </Container>
    </CSSTransition>
  );
};

export default NewPetoloClaim;
