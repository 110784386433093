import { css, styled } from '@mui/material';

import { MIN_WIDTH_LG, MIN_WIDTH_MD } from '../../../theme/breakpoints';
import { Button } from '../Button';

export const StyledAccordionToggle = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAccordionTitle = styled('h3')(
  ({ theme }) => css`
    font-size: 0.875rem;
    color: ${theme.palette.main.professional_blue};

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1rem;
      line-height: 1.25rem;
    }
  `
);

export const StyledAccordionButton = styled(Button)`
  font-size: 0.8125rem;
  font-weight: 300;
  line-height: 1.25rem;
  text-decoration: underline;

  @media (min-width: ${MIN_WIDTH_LG}) {
    &:hover {
      text-decoration: none;
    }
  }
`;

export const StyledAccordionContent = styled('div')`
  margin-top: 2rem;
`;
