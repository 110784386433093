import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { matchPath, Prompt, Route, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useIntl } from 'react-intl';

import useBeforeunload from '../../hooks/useBeforeunload';
import { GET_CUSTOMER } from '../../shared';
import { PET_LIABILITY_STEP_MAIN } from './new/newClaimsFormSteps';
import { clearNewPetLiabilityClaimFormData } from './claimFormData/newClaimFormDataPetLiability';

import NewPetLiabilityClaim from './new/NewPetLiabilityClaim';

export default function PetLiabilityClaims() {
  const intl = useIntl();
  const history = useHistory();

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [claimSubmitted, setClaimSubmitted] = useState(false);
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

  const { data: customerData } = useQuery(GET_CUSTOMER);
  const { customer } = customerData;
  const petName = customer?.insuredPet?.name;
  const tarif = customer?.contract?.policyName;

  // Prompt for page refresh
  const alertUser = useCallback((ev) => {
    ev.preventDefault();
    ev.returnValue = ''; // eslint-disable-line no-param-reassign
  }, []);

  useBeforeunload(alertUser);

  useEffect(() => {
    if (!isInitialPageLoad) return;

    setIsInitialPageLoad(false);
    setSelectedDocuments([]);
    clearNewPetLiabilityClaimFormData();

    const newClaimPageUrl = PET_LIABILITY_STEP_MAIN;
    const isNewClaimPage = history?.location?.pathname === newClaimPageUrl;

    const params = queryString.parse(history?.location?.search);
    const source = params?.source;

    if (!isNewClaimPage) {
      if (source) {
        history.push(`${newClaimPageUrl}${history?.location?.search || ''}`);
      } else {
        history.push('/user-claims-info');
      }
    }
  }, [history, isInitialPageLoad]);

  const showPrompt = !(claimSubmitted || selectedDocuments?.length < 1);
  return (
    <>
      {/* Prompt user data will be lost if navigate outside of the claims flow */}
      <Prompt
        when={showPrompt}
        message={({ pathname }) =>
          matchPath(pathname, { path: PET_LIABILITY_STEP_MAIN })
            ? true
            : intl.formatMessage({ id: 'new_claim.prompt.message' })
        }
      />

      <Route path={PET_LIABILITY_STEP_MAIN}>
        <NewPetLiabilityClaim
          petName={petName}
          tarif={tarif}
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          setClaimSubmitted={setClaimSubmitted}
        />
      </Route>
    </>
  );
}
