import { css, styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

const StyledPageTitle = styled('h1')(
  ({ theme }) => css`
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    color: ${theme.palette.main.professional_blue};
    line-height: 1.75rem;
    word-break: break-word;

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }
  `
);

export default StyledPageTitle;
