import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { imgLinks } from '../../../shared';

import {
  StyledReferralLinkCardDentolo,
  StyledReferralLinkCardDentoloButton,
  StyledReferralLinkCardDentoloContent,
  StyledReferralLinkCardDentoloText,
} from './ReferralLinkCardDentolo.styled';

const renderSpanTag = (chunk) => <span>{chunk}</span>;

const ReferralLinkCardDentolo = () => {
  const history = useHistory();

  const handleGotoReferralPage = () => {
    history.push('/referral');
  };

  return (
    <StyledReferralLinkCardDentolo
      backgroundImgDesktop={imgLinks['carousel.referral.dentolo.bg.desktop']}
      backgroundImgMobile={imgLinks['carousel.referral.dentolo.bg.mobile']}
      imgDesktop={imgLinks['carousel.referral.dentolo.image.desktop']}
      imgMobile={imgLinks['carousel.referral.dentolo.image.mobile']}
    >
      <StyledReferralLinkCardDentoloContent>
        <StyledReferralLinkCardDentoloText>
          <FormattedMessage
            id="promotion.carousel.dentolo.referral_link.title"
            values={{
              span: renderSpanTag,
            }}
          />
        </StyledReferralLinkCardDentoloText>

        <StyledReferralLinkCardDentoloButton onClick={handleGotoReferralPage}>
          <FormattedMessage id="promotion.carousel.dentolo.referral_link.button.label" />
        </StyledReferralLinkCardDentoloButton>
      </StyledReferralLinkCardDentoloContent>
    </StyledReferralLinkCardDentolo>
  );
};

export default ReferralLinkCardDentolo;
