export const INCOMPLETE = 'incomplete_information';
export const PENDING = 'pending';
export const ACCEPTED = 'accepted';
export const DECLINED = 'declined';
export const ACTIVE = 'active';
export const SUSPENDED = 'suspended';
export const ENDED = 'ended';

export const TITLE_INS_BEGIN = 'begin';
export const TITLE_INS_END = 'end';
export const LABEL_START = 'start';
export const LABEL_WILL_START = 'will.start';
export const LABEL_REVOKED = 'revoked';
export const LABEL_WILL_END = 'will.end';
export const LABEL_END = 'end';
export const LABEL_ACTIVE_TRIAL_MONTH = 'active.trial_month';
