import { css, styled } from '@mui/material';
import PropTypes, { oneOfType } from 'prop-types';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

const StyledParagraph = styled('p')(
  ({ theme, fontWeight, textAlign }) => css`
    margin-bottom: 1.5rem;
    font-size: 1rem;
    font-weight: ${fontWeight};
    color: ${theme.palette.main.professional_blue}
    text-align: ${textAlign};
    line-height: 24px;

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.25rem;
    }
  }
`
);

StyledParagraph.defaultProps = {
  fontWeight: 450,
  textAlign: 'left',
};

StyledParagraph.propTypes = {
  fontWeight: oneOfType([PropTypes.number, PropTypes.string]),
  textAlign: PropTypes.string,
};

export default StyledParagraph;
