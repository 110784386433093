import { css, styled, keyframes } from '@mui/material';

const animationDentolo = keyframes`
  0% {
    height: 100%;
  }

  70% {
    height: 0%;
  }

  100% {
    height: 0%;
  }
  `;

const animationPetolo = keyframes`
  0% {
    height: 1.5rem;
  }

  70% {
    height: 0%;
  }

  100% {
    height: 0%;
  }
  `;

export const StyledLoadingContainer = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
`;

export const StyledLoadingText = styled('div')(
  ({ theme }) => css`
    margin-top: 1rem;
    color: ${theme.palette.main.professional_blue};
  `
);

export const StyledLoadingSvgPetolo = styled('svg')(
  ({ theme }) => css`
    fill: ${theme.palette.background.button};
  `
);

export const StyledLoadingSvgDentolo = styled('svg')`
  width: 3.75rem;
  height: 3.75rem;
`;

export const StyledSvgRectangleDentolo = styled('rect')(
  ({ theme }) => css`
    fill: ${theme.palette.background.viewport};
    animation: ${animationDentolo} 3.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
  `
);

export const StyledSvgRectanglePetolo = styled('rect')(
  ({ theme }) => css`
    fill: ${theme.palette.background.viewport};
    animation: ${animationPetolo} 3.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
  `
);
