import { css, styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

import { BackButton } from '../../../components/common';

export const StyledButtonContainer = styled('div')`
  margin-top: 2em;
  text-align: center;
  display: none;

  > button {
    margin: auto;
  }

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    display: block;
  }
`;

export const StyledImg = styled('img')`
  width: 100%;
  margin-left: 0;
  margin-top: 5em;
  margin-bottom: 1em;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    margin-left: -1em;
    margin-top: 0;
  }
`;
export const StyledBackButton = styled(BackButton)`
  display: flex;
  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    display: none;
  }
`;
export const StyledText = styled('p')(
  ({ theme }) => css`
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: ${theme.palette.main.professional_blue} !important;
    @media only screen and (min-width: ${MIN_WIDTH_MD}) {
      font-size: 30px;
      font-weight: 700;
    }
  `
);
