import { useCallback } from 'react';

const sendDataToDtlTracker = (initialParameters, method, methodArguments) => {
  const scriptChecker = setInterval(() => {
    const { dtlTracker: DtlTracker } = window;

    if (typeof DtlTracker === 'function') {
      const dentoloTracker = new DtlTracker(initialParameters);

      if (!!dentoloTracker && typeof dentoloTracker?.[`${method}`] === 'function') {
        dentoloTracker[`${method}`](methodArguments);
      }

      clearInterval(scriptChecker);
    }
  }, 1000);
};

export const trackPageVisit = (uuid, previousUrl) => {
  const initialParameters = {
    dtlUtoken: uuid,
    referrer: previousUrl,
  };

  sendDataToDtlTracker(initialParameters, 'collectAndSendSurveyData', null);
};

export const useTrackDtEvent = (uuid = '') => {
  const sendUserData = useCallback(
    (payload = {}) => {
      const initialParameters = {
        dtlUtoken: uuid,
        originatingService: 'dentolo-account-area',
        environment: process.env.NODE_ENV === 'development' ? 'staging' : 'production',
      };

      sendDataToDtlTracker(initialParameters, 'sendUserData', JSON.stringify(payload));
    },
    [uuid]
  );

  return [sendUserData];
};
