import { useQuery } from '@apollo/client';
import { formatDate, GET_CANCELLATION_AVAILABLE_DATES } from '../../../shared';
import { CANCELLATION_TYPE_REGULAR } from '../ContractCancellationConstants';

export default function useAvailableCancellationDate() {
  const { data } = useQuery(GET_CANCELLATION_AVAILABLE_DATES, {
    variables: { cancellationType: CANCELLATION_TYPE_REGULAR },
    fetchPolicy: 'network-only',
  });
  const earliestAvailableDate = data?.cancellationAvailableDates?.earliestAvailableDate;
  const formattedDate = formatDate(earliestAvailableDate);

  return formattedDate;
}
