import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';

import { showMoney, formatDate } from '../../shared';

import {
  StyledClaimAdditionalPaymentsCard,
  StyledPayoutDetailsContainer,
  StyledPayoutDate,
  StyledPayoutDetails,
} from './styled/ClaimDetailsAdditionalPayments.styled';

const ClaimDetailsAdditionalPayments = ({ otherPayouts, customer, isSameIban, maskIban }) => {
  const getPaymentDescription = (payout) => {
    const descriptionKeys = {
      confirmed: 'confirmed',
      notConfirmed: 'notConfirmed',
      paidCustomer: 'paidCustomer',
      paidOther: 'paidOther',
    };
    const descriptionMap = {
      [descriptionKeys.confirmed]: {
        [descriptionKeys.paidCustomer]: ({ payoutAmount, confirmedDate }) =>
          `Die Erstattung in Höhe von ${showMoney(payoutAmount / 100)} wurde am ${formatDate(
            confirmedDate,
            'DD.MM.YY'
          )} auf Ihr Konto überwiesen.`,
        [descriptionKeys.paidOther]: ({
          payoutAmount,
          confirmedDate,
          recepientName,
          recepientIban,
        }) =>
          `Die Erstattung in Höhe von ${showMoney(payoutAmount / 100)} wurde am ${formatDate(
            confirmedDate,
            'DD.MM.YY'
          )} auf das Konto von ${recepientName} mit der IBAN ${recepientIban} überwiesen.`,
      },
      [descriptionKeys.notConfirmed]: {
        [descriptionKeys.paidCustomer]: ({ payoutAmount }) =>
          `Die Erstattung in Höhe von ${showMoney(
            payoutAmount / 100
          )} wird auf Ihr Konto überwiesen.`,
        [descriptionKeys.paidOther]: ({ payoutAmount, recepientName, recepientIban }) =>
          `Die Erstattung in Höhe von ${showMoney(
            payoutAmount / 100
          )} wird auf das Konto von ${recepientName} mit der IBAN ${recepientIban} überwiesen.`,
      },
    };
    const isConfirmed = !!payout?.confirmedAt;
    const isCustomerPaid = isSameIban(customer?.iban, payout?.recipientIban);
    const renderMessage =
      descriptionMap[isConfirmed ? descriptionKeys.confirmed : descriptionKeys.notConfirmed][
        isCustomerPaid ? descriptionKeys.paidCustomer : descriptionKeys.paidOther
      ];

    return (
      renderMessage &&
      renderMessage({
        payoutAmount: payout?.amountCents,
        confirmedDate: payout?.confirmedAt,
        recepientName: payout?.recipientName,
        recepientIban: maskIban(payout?.recipientIban),
      })
    );
  };

  return (
    <StyledClaimAdditionalPaymentsCard>
      <FormattedMessage id="claim.status.details.other.payouts" tagName="h5" />

      {Array.isArray(otherPayouts) &&
        otherPayouts.map((payout) => {
          return (
            <StyledPayoutDetailsContainer key={payout?.key}>
              <StyledPayoutDate>
                <FormattedMessage id="claim.status.details.invoice.from" />{' '}
                {formatDate(payout?.createdAt, 'DD.MM.YY')}
              </StyledPayoutDate>

              <StyledPayoutDetails>{getPaymentDescription(payout)}</StyledPayoutDetails>
            </StyledPayoutDetailsContainer>
          );
        })}
    </StyledClaimAdditionalPaymentsCard>
  );
};

export default injectIntl(ClaimDetailsAdditionalPayments);
