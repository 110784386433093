import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import useForm from '../../shared/useForm';
import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { UPDATE_CUSTOMER_DETAILS, GET_CUSTOMER } from '../../shared';
import { getServiceEmail } from '../../utils/customerUtils';
import { isValidEmail } from '../../utils/emailValidationUtils';

import { Input, Radio, RadioLabel } from '../common';
import { ErrorMessage } from '../common/FormElements';
import { Button } from '../ui';
import {
  StyledForm,
  StyledFormLegend,
  StyledFormLabel,
  StyledFormFieldset,
  StyledGenderRadioInputs,
  StyledGenderRadioInputAndLabel,
  StyledFormGroup,
  StyledInputGroup,
  StyledStreetInput,
  StyledHouseNumberInput,
  StyledPostCodeInput,
  StyledCityInput,
  StyledAdditionalDetailsText,
  StyledButtonContainer,
} from './CustomerDetailsEditor.styled';

function validateForm(values) {
  const errors = {};

  if (!values?.email || !isValidEmail(values?.email)) {
    errors.email = 'personal.details.form.email.error.message';
  }

  if (
    !values?.phoneNumber ||
    !(values?.phoneNumber?.length >= 6 && values?.phoneNumber?.length <= 14)
  ) {
    errors.phoneNumber = 'personal.details.form.phone.error.message';
  }

  if (!values?.streetName) {
    errors.streetName = 'personal.details.form.address.error.message';
  }

  if (!values?.houseNumber) {
    errors.houseNumber = 'personal.details.form.address.error.message';
  }

  if (!values?.postcode || !(values?.postcode.length === 5)) {
    errors.postcode = 'personal.details.form.address.error.message';
  }

  if (!values?.city) {
    errors.city = 'personal.details.form.address.error.message';
  }

  return errors;
}

function CustomerDetailsEditor({ data, onSuccess, onError }) {
  const [isProcessing, setIsProcessing] = useState(false);

  const { type: insuranceType } = useInsuranceInfo();

  const {
    values,
    errors: formErrors,
    isDirty,
    handleChange,
    handleSubmit,
  } = useForm(data, handleFormSubmit, validateForm);

  const { firstName, lastName, ...otherValues } = values;

  const [updateCustomerDetails] = useMutation(UPDATE_CUSTOMER_DETAILS, {
    variables: {
      ...otherValues,
    },
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  async function handleFormSubmit(ev) {
    if (ev) ev.preventDefault();

    try {
      setIsProcessing(true);

      const res = await updateCustomerDetails();
      const { customer } = res?.data || {};

      if (customer?.status !== 'success') throw new Error(customer?.errors);

      onSuccess();
    } catch (err) {
      onError();
    } finally {
      setIsProcessing(false);
    }
  }

  const isBtnDisabled = !isDirty || Object.keys(formErrors).length > 0 || isProcessing;

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormFieldset>
        <StyledFormLegend>
          <FormattedMessage id="personal.details.form.gender.label" />
        </StyledFormLegend>

        <StyledGenderRadioInputs>
          <StyledGenderRadioInputAndLabel>
            <Radio
              id="radio_gender_male"
              name="gender"
              onChange={(e) => {
                e.stopPropagation();
                handleChange(e);
              }}
              value="male"
              checked={values?.gender === 'male'}
            />

            <RadioLabel htmlFor="radio_gender_male">
              <FormattedMessage id="personal.details.form.gender.male.label" />
            </RadioLabel>
          </StyledGenderRadioInputAndLabel>

          <StyledGenderRadioInputAndLabel>
            <Radio
              id="radio_gender_female"
              name="gender"
              onChange={(e) => {
                e.stopPropagation();
                handleChange(e);
              }}
              value="female"
              checked={values?.gender === 'female'}
            />

            <RadioLabel htmlFor="radio_gender_female">
              <FormattedMessage id="personal.details.form.gender.female.label" />
            </RadioLabel>
          </StyledGenderRadioInputAndLabel>
        </StyledGenderRadioInputs>
      </StyledFormFieldset>

      <StyledFormGroup>
        <StyledFormLabel htmlFor="firstName" disabled>
          <FormattedMessage id="common.first.name" />
        </StyledFormLabel>

        <Input
          id="firstName"
          name="firstName"
          placeholder="Alex"
          value={values?.firstName || ''}
          onChange={handleChange}
          disabled
        />
      </StyledFormGroup>

      <StyledFormGroup>
        <StyledFormLabel htmlFor="lastName" disabled>
          <FormattedMessage id="common.last.name" />
        </StyledFormLabel>

        <Input
          id="lastName"
          name="lastName"
          placeholder="Müller"
          value={values?.lastName || ''}
          onChange={handleChange}
          disabled
        />
      </StyledFormGroup>

      <StyledFormGroup>
        <StyledFormLabel htmlFor="email">
          <FormattedMessage id="personal.details.form.email.label" />
        </StyledFormLabel>

        <Input
          type="email"
          id="email"
          name="email"
          placeholder="alex.mueller@email.de"
          value={values?.email || ''}
          onChange={handleChange}
        />

        {formErrors?.email && (
          <ErrorMessage>
            <FormattedMessage id={formErrors?.email} />
          </ErrorMessage>
        )}
      </StyledFormGroup>

      <StyledFormGroup>
        <StyledFormLabel htmlFor="phoneNumber">
          <FormattedMessage id="personal.details.form.phone.label" />
        </StyledFormLabel>

        <Input
          type="tel"
          minLength="6"
          maxLength="14"
          id="phoneNumber"
          name="phoneNumber"
          placeholder="0123 33959473"
          value={values?.phoneNumber || ''}
          onChange={handleChange}
        />

        {formErrors?.phoneNumber && (
          <ErrorMessage>
            <FormattedMessage id={formErrors?.phoneNumber} />
          </ErrorMessage>
        )}
      </StyledFormGroup>

      <StyledFormFieldset>
        <StyledFormLegend>
          <FormattedMessage id="personal.details.form.street.house.number.label" />
        </StyledFormLegend>

        <StyledInputGroup>
          <StyledStreetInput
            id="streetName"
            name="streetName"
            placeholder="Sonnenstraße"
            value={values?.streetName || ''}
            onChange={handleChange}
            data-error={formErrors?.streetName && ''}
          />

          <StyledHouseNumberInput
            id="houseNumber"
            name="houseNumber"
            placeholder="5"
            value={values?.houseNumber || ''}
            onChange={handleChange}
            data-error={formErrors?.houseNumber && ''}
          />
        </StyledInputGroup>
      </StyledFormFieldset>

      <StyledFormFieldset>
        <StyledFormLegend>
          <FormattedMessage id="personal.details.form.postcode.city.label" />
        </StyledFormLegend>

        <StyledInputGroup>
          <StyledPostCodeInput
            id="postcode"
            name="postcode"
            placeholder="10234"
            value={values?.postcode || ''}
            onChange={handleChange}
            data-error={formErrors?.postcode && ''}
          />

          <StyledCityInput
            id="city"
            name="city"
            placeholder="Berlin"
            value={values?.city || ''}
            onChange={handleChange}
            data-error={formErrors?.city && ''}
          />
        </StyledInputGroup>

        {(formErrors?.streetName ||
          formErrors?.houseNumber ||
          formErrors?.postcode ||
          formErrors?.city) && (
          <ErrorMessage>
            <FormattedMessage id="personal.details.form.address.error.message" />
          </ErrorMessage>
        )}
      </StyledFormFieldset>

      <StyledFormGroup>
        <StyledFormLabel htmlFor="addressDetails">
          <FormattedMessage id="personal.details.form.address.details.label" />
        </StyledFormLabel>

        <Input
          id="addressDetails"
          name="addressDetails"
          placeholder="z.B. Vorderhaus"
          value={values?.addressDetails || ''}
          onChange={handleChange}
        />
      </StyledFormGroup>

      <StyledAdditionalDetailsText>
        <FormattedMessage
          id="personal.details.form.additional.details"
          values={{ serviceEmail: getServiceEmail(insuranceType) }}
        />
      </StyledAdditionalDetailsText>

      <StyledButtonContainer>
        <Button type="submit" variant="primary" disabled={isBtnDisabled}>
          <FormattedMessage id="personal.details.form.submit.button" />
        </Button>
      </StyledButtonContainer>
    </StyledForm>
  );
}

export default CustomerDetailsEditor;
