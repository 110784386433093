import { createTheme } from '@mui/material';

import { PALETTE } from './palette';
import { muiLinkComponent } from './overrides/link';
import { muiTypographyComponent, muiTypographyOptions } from './overrides/typography';
import { muiButtonComponent } from './overrides/button';

// eslint-disable-next-line import/prefer-default-export
export const defaultTheme = createTheme({
  palette: {
    main: {
      professional_blue: PALETTE.PROFESSIONAL_BLUE,
      professional_light_blue: PALETTE.PROFESSIONAL_LIGHT_BLUE,
      product_blue: PALETTE.PRODUCT_BLUE,
    },
    gray: {
      gray: PALETTE.GRAY,
      light_gray: PALETTE.LIGHT_GRAY,
      ultra_light_gray: PALETTE.ULTRA_LIGHT_GRAY,
    },
    error: {
      main: PALETTE.ERROR_RED,
    },
    success: {
      main: PALETTE.SUCCESS_GREEN,
    },
  },
  shadows: [
    'none',
    '0px 0px 10px rgba(0, 0, 0, 0.1)',
    '0px 2px 6px rgba(0, 0, 0, 0.1)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  shape: {
    container: '43.75rem',
    headerDesktop: '4rem',
    headerMobile: '4rem',
    navMenuDesktop: '3.1rem',
  },
  typography: muiTypographyOptions,
  components: {
    MuiButton: muiButtonComponent,
    MuiLink: muiLinkComponent,
    MuiTypography: muiTypographyComponent,
  },
  daDirekt: {
    fontFamily: 'CentraNo2-Medium, Circular TT ,sans-serif',
  },
});
