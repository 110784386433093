import { css, styled } from '@mui/material';

export const StyledClaimDetailsStatusHistoryItem = styled('div')`
  display: flex;
  flex-direction: row;
`;

export const StyledProgressTimeline = styled('div')`
  position: relative;
  min-width: 1.5em;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: flex-start;
  margin-right: 0.5em;
`;

export const StyledProgressTimelineVertex = styled('div')(
  ({ theme }) => css`
    width: 1.5em;
    height: 1.5em;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 0.75em;
      height: 0.75em;
      margin: auto;
      background-color: ${theme.palette.main.product_blue};
      border-radius: 100%;
      z-index: 1;
    }
  `
);

const timelineEdgeFirstCss = css`
  height: 3em;
  top: auto;
`;

const timelineEdgeLastCss = css`
  height: 0.75em;
  bottom: auto;
`;

const timelineEdgeOneCss = css`
  height: 0;
`;

export const StyledProgressTimelineEdge = styled('div')(
  ({ theme, first, last, one }) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0.125em;
    height: 100%;
    margin: auto;
    background-color: ${theme.palette.main.product_blue};
    z-index: 1;

    ${first && timelineEdgeFirstCss};
    ${last && timelineEdgeLastCss};
    ${one && timelineEdgeOneCss};
  `
);

const statusHistoryItemDetailsMargin = ({ first, last }) => css`
  margin-bottom: ${!!first || !!last ? '0' : '1.5em'};
`;

export const StyledClaimDetailsStatusHistoryItemDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${statusHistoryItemDetailsMargin};

  h6 {
    font-size: 1rem;
    line-height: 1.25rem;
  }
`;

export const StyledStatusDate = styled('div')(
  ({ theme }) => css`
    font-size: 1em;
    font-weight: 300;
    line-height: 1.25em;
    color: ${theme.palette.main.product_blue};
    opacity: 0.9;
  `
);
