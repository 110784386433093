import { styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

import { ButtonContainer, Paper } from '../../../components/common';
import { Button } from '../../../components/ui';

export const StyledImageContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin: 0 0 1.5em;
`;

export const StyledCancellationReasonsTitle = styled('h1')`
  text-align: ${({ paper }) => (paper ? 'center' : 'left')};
  font-size: ${({ paper }) => (paper ? '16px' : '24px')};
  line-height: ${({ paper }) => (paper ? '22px;' : '36px')};
  font-weight: 700;
  margin-bottom: 1.5rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: ${({ paper }) => (paper ? '20px' : '28px')};
    line-height: ${({ paper }) => (paper ? '26px;' : '42px')};
  }
`;

export const StyledCancellationReasonsDescription = styled('p')`
  text-align: ${({ paper }) => (paper ? 'center' : 'left')};
  font-size: 16px;
  line-height: ${({ paper }) => (paper ? '22px;' : '24px')};
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 20px;
    line-height: ${({ paper }) => (paper ? '26px;' : '30px')};
  }
`;

export const StyledButtonContainer = styled(ButtonContainer)`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row-reverse;
    column-gap: 1em;
  }
`;

export const StyledButton = styled(Button)`
  margin: 0.5em auto;
  width: 100%;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin: 0.5em;
  }
`;

export const StyledRadioContainer = styled('div')`
  margin-top: 1.5rem;
`;

export const StyledReason = styled(Paper)`
  display: flex;
  word-break: break-word;
  margin-bottom: ${({ transparent }) => (transparent ? '0' : '0.5em')};
  padding: ${({ transparent }) => (transparent ? '1em 0' : '1em')};
`;
