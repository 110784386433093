import { css, styled } from '@mui/material';

import { MIN_WIDTH_LG } from '../../theme/breakpoints';
import { Input } from '../common';

export const StyledForm = styled('form')(
  ({ theme }) => css`
    background-color: ${theme.palette.common.white};
  `
);

export const StyledFormLegend = styled('legend')(
  ({ theme }) => css`
    display: block;
    margin-bottom: 0.5em;
    color: ${theme.palette.main.professional_blue};
    font-size: 1em;
    font-weight: 300;
    line-height: 1.25em;
  `
);

export const StyledFormLabel = styled('label')(
  ({ theme, disabled }) => css`
    display: block;
    margin-bottom: 0.5em;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.25em;
    color: ${disabled ? theme.palette.text.disabled : theme.palette.main.professional_blue};
  `
);

export const StyledFormFieldset = styled('fieldset')`
  margin-bottom: 1.5em;
`;

export const StyledGenderRadioInputs = styled('div')`
  display: flex;
`;

export const StyledGenderRadioInputAndLabel = styled('div')`
  display: flex;
  margin-right: 0.5em;
`;

export const StyledFormGroup = styled('div')`
  margin-bottom: 1.5em;
`;

export const StyledInputGroup = styled('div')`
  display: flex;
`;

export const StyledStreetInput = styled(Input)`
  flex: 4 1 0;
  margin-right: 1em;
`;

export const StyledHouseNumberInput = styled(Input)`
  flex: 1 1 0;
  margin-left: 1em;
`;

export const StyledPostCodeInput = styled(Input)`
  flex: 1 1 0;
  margin-right: 1em;
`;

export const StyledCityInput = styled(Input)`
  flex: 4 1 0;
  margin-left: 1em;
`;

export const StyledAdditionalDetailsText = styled('p')`
  margin-top: 1.5em;
  margin-bottom: 1.5em;
`;

export const StyledButtonContainer = styled('div')`
  margin-top: 1.5em;
  width: 100%;

  button {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledSectionTitle = styled('p')`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  margin-bottom: 1rem;
`;

export const StyledDigitalPaymentContainerTitle = styled(StyledSectionTitle)`
  margin-top: 3rem;
`;

export const StyledBillingDayRadioInputs = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const StyledBillingDayRadioInputAndLabel = styled('div')`
  display: flex;
  margin-right: 0.5em;
  &:first-child {
    margin-bottom: 1rem;
  }
`;

export const StyledDigitalPaymentContainer = styled('div')(
  ({ hasApplePay }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: ${hasApplePay ? '49rem' : '45rem'};
    border-radius: 5px;

    @media only screen and (min-width: ${MIN_WIDTH_LG}) {
      height: ${hasApplePay ? '48rem ' : '44rem'};
    }
  `
);
