import React from 'react';
import PropTypes from 'prop-types';

import { imgLinks } from '../../../shared';

import OnboardCard from './OnboardCard';
import {
  StyledOnboardCardParent,
  StyledOnboardCardWrapper,
  StyledLaptopImage,
  StyledTabletImage,
  StyledPhoneImage,
} from './HomeOnboardDentolo.styled';

const HomeOnboardCard1 = ({ titleHtml, bodyText }) => {
  return (
    <OnboardCard titleHtml={titleHtml} bodyText={bodyText}>
      <StyledOnboardCardParent>
        <StyledOnboardCardWrapper>
          <StyledLaptopImage src={imgLinks['icon.home.onboard.laptop.logo']} alt="" />
          <StyledTabletImage src={imgLinks['icon.home.onboard.tablet.logo']} alt="" />
          <StyledPhoneImage src={imgLinks['icon.home.onboard.phone.logo']} alt="" />
        </StyledOnboardCardWrapper>
      </StyledOnboardCardParent>
    </OnboardCard>
  );
};

HomeOnboardCard1.propTypes = {
  titleHtml: PropTypes.string,
  bodyText: PropTypes.string,
};

export default HomeOnboardCard1;
