import React from 'react';

import { imgLinks } from '../../../../shared';
import {
  DENTOLO_DOCUMENT_TYPE,
  DENTOLO_DOCUMENT_TYPE_INVOICE,
  DENTOLO_TREATMENT_CATEGORY,
  DENTOLO_TREATMENT_CATEGORY_FILLING,
  DENTOLO_TREATMENT_CATEGORY_OTHERS,
  DENTOLO_TREATMENT_CATEGORY_TEETH_CLEANING,
  useNewDentoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import {
  useSelectTreatmentCategoryDentoloBackButtonLink,
  useSelectTreatmentCategoryDentoloNextButtonLink,
} from './selectTreatmentCategoryDentoloHooks';

import SelectTreatmentCategory from './SelectTreatmentCategory';

const treatmentCategoryConfig = [
  {
    label: 'dentolo.new_claim.select_treatment_category.teeth_cleaning',
    value: DENTOLO_TREATMENT_CATEGORY_TEETH_CLEANING,
    image: imgLinks['icon.teeth.cleaning'],
  },
  {
    label: 'dentolo.new_claim.select_treatment_category.tooth_filling',
    value: DENTOLO_TREATMENT_CATEGORY_FILLING,
    image: imgLinks['icon.teeth.filling'],
  },
  {
    label: 'dentolo.new_claim.select_treatment_category.other_treatments',
    value: DENTOLO_TREATMENT_CATEGORY_OTHERS,
    image: imgLinks['icon.other.treatments'],
  },
];

const SelectTreatmentCategoryDentolo = () => {
  const backButtonLink = useSelectTreatmentCategoryDentoloBackButtonLink();
  const nextButtonLink = useSelectTreatmentCategoryDentoloNextButtonLink();

  const [, /* documentType */ setDocumentType] = useNewDentoloFormDataValue(DENTOLO_DOCUMENT_TYPE);

  const [treatmentCategory, setTreatmentCategory] = useNewDentoloFormDataValue(
    DENTOLO_TREATMENT_CATEGORY
  );

  const setTreatmentCategoryAndDocumentTypeIfNeeded = (selectedTreatmentCategory) => {
    setTreatmentCategory(selectedTreatmentCategory);

    if (selectedTreatmentCategory === DENTOLO_TREATMENT_CATEGORY_TEETH_CLEANING) {
      setDocumentType(DENTOLO_DOCUMENT_TYPE_INVOICE);
    } else {
      setDocumentType('');
    }
  };

  return (
    <SelectTreatmentCategory
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      treatmentCategory={treatmentCategory}
      setTreatmentCategory={setTreatmentCategoryAndDocumentTypeIfNeeded}
      pageTitleId="dentolo.new_claim.select_treatment_category.page_title"
      pageDescriptionId="dentolo.new_claim.select_treatment_category.page_description"
      treatmentCategoryConfig={treatmentCategoryConfig}
    />
  );
};

export default SelectTreatmentCategoryDentolo;
