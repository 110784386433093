import React from 'react';
import { useIntl } from 'react-intl';

import { useDownloadIcon } from '../../hooks/useIcons';

import { IconButton } from '../common';
import { StyledDownloadLinks } from '../ui/styled/DownloadLink.styled';

function DownloadContributionConfirmation({ year, url, openNewTab }) {
  const intl = useIntl();
  const downloadIcon = useDownloadIcon();

  const name = `${intl.formatMessage({ id: 'payment.overview.contribution.document' })} ${year}`;

  return (
    <StyledDownloadLinks>
      <a href={url} target="_blank" rel="noreferrer" onClick={openNewTab(url)}>
        {name}
      </a>

      <IconButton icon={downloadIcon} size="sm" alt="Herunterladen" onClick={openNewTab(url)} />
    </StyledDownloadLinks>
  );
}

export default DownloadContributionConfirmation;
