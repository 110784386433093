import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/client';
import { CSSTransition } from 'react-transition-group';

import { GET_PAYMENT } from '../../shared';

import PaymentStatusBlock from './PaymentStatusBlock';
import { Container, BackButton, PageTitle } from '../common';
import { Paragraph } from '../ui';

// sort the array based on the date
const sortFilterArray = (arr) => {
  if (!Array.isArray(arr)) return [];

  return arr.sort((a, b) => {
    return new Date(b.billingMonth) - new Date(a.billingMonth);
  });
};

function PaymentDetails() {
  const getPayments = useQuery(GET_PAYMENT);
  const paymentsData = getPayments?.data?.payments;
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    if (paymentsData) {
      const failedAndBlockedPayments = paymentsData.filter((item) => item.status === 'failed');
      const paidAndOtherPayments = paymentsData.filter((item) => item.status !== 'failed');
      const sortedFailedAndBlockedPayments = sortFilterArray(failedAndBlockedPayments);
      const sortedPaidAndOtherPayments = sortFilterArray(paidAndOtherPayments);
      const finalPaymentsArray = [...sortedFailedAndBlockedPayments, ...sortedPaidAndOtherPayments];

      setPayments(finalPaymentsArray);
    }
  }, [paymentsData]);

  if (getPayments?.error)
    return (
      <Container>
        <BackButton to="/policy-info" />

        <Paragraph style={{ textAlign: 'center' }}>
          <FormattedMessage id="payment.page.network.error" />
        </Paragraph>
      </Container>
    );

  if (getPayments?.data?.payments) {
    return (
      <CSSTransition in={payments?.length >= 0} timeout={400} classNames="slow-fade" unmountOnExit>
        <Container>
          <BackButton to="/policy-info" />

          <PageTitle>
            <FormattedMessage id="payment.page.main.heading" />
          </PageTitle>

          <Paragraph style={{ marginBottom: '1.5rem' }}>
            <FormattedMessage id="payment.page.main.sub.heading" />
          </Paragraph>

          <div>
            {payments &&
              payments.length > 0 &&
              payments.map((item) => <PaymentStatusBlock key={item?.billingMonth} data={item} />)}

            {payments && payments.length === 0 && (
              <Paragraph style={{ textAlign: 'center' }}>
                <FormattedMessage id="payment.page.no.payments" />
              </Paragraph>
            )}
          </div>
        </Container>
      </CSSTransition>
    );
  }

  return null;
}

export default PaymentDetails;
