import React from 'react';
import PropTypes from 'prop-types';

import {
  StyledClaimOnBoardCard,
  StyledClaimOnBoardCardTitle,
  StyledClaimOnBoardCardText,
} from './UserClaimsOnboardDentolo.styled';

const OnboardCard = ({ titleHtml, bodyText, children }) => {
  return (
    <StyledClaimOnBoardCard>
      <StyledClaimOnBoardCardTitle dangerouslySetInnerHTML={{ __html: titleHtml || '' }} />
      {children}
      <StyledClaimOnBoardCardText>{bodyText || ``}</StyledClaimOnBoardCardText>
    </StyledClaimOnBoardCard>
  );
};

OnboardCard.propTypes = {
  titleHtml: PropTypes.string,
  bodyText: PropTypes.string,
  children: PropTypes.node,
};

export default OnboardCard;
