import React from 'react';
import { useQuery } from '@apollo/client';
import { Redirect } from 'react-router-dom';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { GET_CUSTOMER, RISK_CARRIER_AMTRUST } from '../../shared';

const PageRenderForInsuranceType = ({
  insuranceType: givenInsuranceType,
  excludePolicyCategories,
  excludeAmtrust,
  children,
}) => {
  const { type: insuranceType } = useInsuranceInfo();
  const { data: customerData } = useQuery(GET_CUSTOMER);

  const policyCategory = customerData?.customer?.contract?.policyCategory;
  const riskCarrier = customerData?.customer?.contract?.riskCarrier;

  const excludeCurrentPolicyCategory =
    Array.isArray(excludePolicyCategories) && excludePolicyCategories.includes(policyCategory);

  const isAmtrustCustomer = riskCarrier === RISK_CARRIER_AMTRUST;

  const show404Page = excludeCurrentPolicyCategory || (excludeAmtrust && isAmtrustCustomer);

  if (show404Page) {
    return <Redirect to="/404" />;
  }

  if (insuranceType === givenInsuranceType || !givenInsuranceType) {
    return children;
  }

  return null;
};

export default PageRenderForInsuranceType;
