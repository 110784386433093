import { styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../../theme/breakpoints';

// eslint-disable-next-line import/prefer-default-export
export const StyledDaDirektHealthCrossSellingCardImage = styled('img')`
  width: 145px;
  height: auto;
  margin-bottom: initial;

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 192px;
    margin-bottom: 1.25rem;
  }
`;
