import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Container } from '../../../components/common';
import { useReady } from '../../../shared';
import SelectDocumentTypeDentolo from '../components/SelectDocumentType/SelectDocumentTypeDentolo';
import ExistingClaimQuestionDentolo from '../components/ExistingClaimQuestion/ExistingClaimQuestionDentolo';
import SelectExistingClaimDentolo from '../components/SelectExistingClaim/SelectExistingClaimDentolo';
import SelectTreatmentCategoryDentolo from '../components/SelectTreatmentCategory/SelectTreatmentCategoryDentolo';
import SelectDocumentCreationDateDentolo from '../components/SelectDocumentCreationDate/SelectDocumentCreationDateDentolo';
import SelectTreatmentDateDentolo from '../components/SelectTreatmentDate/SelectTreatmentDateDentolo';
import UploadTreatmentDocumentDentolo from '../components/UploadDocument/UploadTreatmentDocumentDentolo';
import TreatmentAmountDentolo from '../components/TreatmentAmount/TreatmentAmountDentolo';
import DentistDetails from '../components/DentistDetails';
import FillingCount from '../components/FillingCount';
import SubmitClaimFormDentolo from '../components/SubmitClaimForm/SubmitClaimFormDentolo';
import {
  DENTOLO_STEP_TREATMENT_CATEGORY,
  DENTOLO_STEP_DOCUMENT_TYPE,
  DENTOLO_STEP_EXISTING_CLAIM_QUESTION,
  DENTOLO_STEP_EXISTING_CLAIMS,
  DENTOLO_STEP_UPLOAD_DOCUMENTS,
  DENTOLO_STEP_DOCUMENT_DATE,
  DENTOLO_STEP_TREATMENT_DATE,
  DENTOLO_STEP_TREATMENT_PRICE,
  DENTOLO_STEP_DENTIST_DETAILS,
  DENTOLO_STEP_FILLING_COUNT,
  DENTOLO_STEP_REVIEW,
} from './newClaimsFormSteps';

const NewDentoloClaim = ({
  selectedDocuments,
  setSelectedDocuments,
  setClaimSubmitted,
  relevantClaims,
}) => {
  const ready = useReady();
  const { path } = useRouteMatch();

  const relevantClaimsCount = relevantClaims?.length || 0;

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <Route
          render={({ location }) => (
            <Switch location={location}>
              <Route
                exact
                path={path}
                render={() => (
                  <Redirect
                    to={{
                      pathname: DENTOLO_STEP_TREATMENT_CATEGORY,
                      search: location.search,
                    }}
                  />
                )}
              />

              <Route exact path={DENTOLO_STEP_TREATMENT_CATEGORY}>
                <SelectTreatmentCategoryDentolo />
              </Route>

              <Route exact path={DENTOLO_STEP_DOCUMENT_TYPE}>
                <SelectDocumentTypeDentolo />
              </Route>

              <Route exact path={DENTOLO_STEP_EXISTING_CLAIM_QUESTION}>
                <ExistingClaimQuestionDentolo />
              </Route>

              <Route exact path={DENTOLO_STEP_EXISTING_CLAIMS}>
                <SelectExistingClaimDentolo claims={relevantClaims} />
              </Route>

              <Route exact path={DENTOLO_STEP_UPLOAD_DOCUMENTS}>
                <UploadTreatmentDocumentDentolo
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                  claimsCount={relevantClaimsCount}
                />
              </Route>

              <Route exact path={DENTOLO_STEP_DOCUMENT_DATE}>
                <SelectDocumentCreationDateDentolo />
              </Route>

              <Route exact path={DENTOLO_STEP_TREATMENT_DATE}>
                <SelectTreatmentDateDentolo />
              </Route>

              <Route exact path={DENTOLO_STEP_TREATMENT_PRICE}>
                <TreatmentAmountDentolo />
              </Route>

              <Route exact path={DENTOLO_STEP_DENTIST_DETAILS}>
                <DentistDetails />
              </Route>

              <Route exact path={DENTOLO_STEP_FILLING_COUNT}>
                <FillingCount />
              </Route>

              <Route exact path={DENTOLO_STEP_REVIEW}>
                <SubmitClaimFormDentolo
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                  setClaimSubmitted={setClaimSubmitted}
                />
              </Route>
            </Switch>
          )}
        />
      </Container>
    </CSSTransition>
  );
};

export default NewDentoloClaim;
