import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import { useWindowSize } from '../../shared';
import { MIN_WIDTH_LG } from '../../theme/breakpoints';

import NavMenuLink from './NavMenuLink';
import {
  StyledNavDropdown,
  StyledDropdownIcon,
  StyledDropdownMenu,
  StyledDropdownMenuPopup,
  StyledDropdownMenuPopupCard,
} from './styled/NavLinkDropDown.styled';

function NavLinkDropdown({ caption, linkItems }) {
  const [width] = useWindowSize();
  const [isDropdownMenuShown, setIsDropdownMenuShown] = useState(false);
  const minWidthLgInt = parseInt(MIN_WIDTH_LG.slice(0, MIN_WIDTH_LG.length - 2), 10);
  const isDesktop = width >= minWidthLgInt;
  const { type: insuranceType } = useInsuranceInfo();

  const popupNode = useRef();
  const btnNode = useRef();

  const onNavlinkDropdownClick = () => {
    if (!isDesktop) setIsDropdownMenuShown(!isDropdownMenuShown);
  };

  const onNavlinkDropdownEnter = () => {
    if (isDesktop) setIsDropdownMenuShown(true);
  };

  const onNavlinkDropdownLeave = () => {
    if (isDesktop) setIsDropdownMenuShown(false);
  };

  useEffect(() => {
    const handleClick = (e) => {
      if (
        !isDropdownMenuShown ||
        popupNode?.current?.contains(e.target) ||
        btnNode?.current?.contains(e.target)
      ) {
        return;
      }

      setIsDropdownMenuShown(false);
    };

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.addEventListener('touchstart', handleClick);
    };
  }, [isDropdownMenuShown]);

  return (
    <StyledNavDropdown>
      <StyledDropdownMenu
        onMouseEnter={onNavlinkDropdownEnter}
        onMouseLeave={onNavlinkDropdownLeave}
        onClick={onNavlinkDropdownClick}
        ref={btnNode}
      >
        <div>{caption}</div>

        <StyledDropdownIcon icon="icon.accordion.arrow.down" alt="^" open={isDropdownMenuShown} />
      </StyledDropdownMenu>

      <CSSTransition in={isDropdownMenuShown} timeout={300} classNames="quick-fade" unmountOnExit>
        <StyledDropdownMenuPopup
          ref={popupNode}
          onMouseEnter={onNavlinkDropdownEnter}
          onMouseLeave={onNavlinkDropdownLeave}
        >
          <StyledDropdownMenuPopupCard>
            {linkItems.map((item) => {
              const { link, text, icon, externalUrl, type } = item;

              if (!!type && type !== insuranceType) return null;

              return (
                <NavMenuLink
                  key={link}
                  text={text}
                  link={link}
                  icon={icon}
                  externalUrl={externalUrl}
                  onClick={() => {
                    setIsDropdownMenuShown(!isDropdownMenuShown);
                  }}
                />
              );
            })}
          </StyledDropdownMenuPopupCard>
        </StyledDropdownMenuPopup>
      </CSSTransition>
    </StyledNavDropdown>
  );
}

export default NavLinkDropdown;

NavLinkDropdown.propTypes = {
  caption: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  linkItems: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      icon: PropTypes.string,
      externalUrl: PropTypes.bool,
      type: PropTypes.string,
    })
  ),
};
