import useQueryParams from '../../hooks/useQueryParams';

export const CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT = 'new_reimbursement';
export const CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT = 'existing_claim';
export const CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT = 'upload_document';

export const useClaimUploadFlowSource = () => {
  const { source = CLAIM_UPLOAD_FLOW_SOURCE_NEW_CLAIM_DOCUMENT } = useQueryParams();

  const withSource = (path) => {
    if (typeof path === 'string' && !!path && path.indexOf('?') === -1) {
      return `${path}?source=${source}`;
    }
    return path;
  };

  return [source, withSource];
};
