import React from 'react';
import { FormattedMessage } from 'react-intl';

import { imgLinks } from '../../../../shared';

import { StyledDaDirektDentalCrossSellingCardImage } from './DaDirektDentalCard.styled';
import {
  StyledDaDirektCrossSellingCard,
  StyledDaDirektCrossSellingCardButton,
  StyledDaDirektCrossSellingCardContent,
  StyledDaDirektCrossSellingCardDaDirektLogo,
  StyledDaDirektCrossSellingCardLogoAndImage,
  StyledDaDirektCrossSellingCardText,
  StyledDaDirektCrossSellingCardTitle,
} from '../DaDirektCrossSellingCard.styled';

const DA_DIREKT_LOGO_LINK = imgLinks['icon.menu.dadirekt.dark'];
const DA_DIREKT_DENTAL_BG_IMAGE_DESKTOP = imgLinks['image-da-dental-cross-selling-desktop'];

function DaDirektDentalCardDesktop({ onGoToCrossSellingLink }) {
  return (
    <StyledDaDirektCrossSellingCard product="dental">
      <StyledDaDirektCrossSellingCardContent>
        <StyledDaDirektCrossSellingCardTitle>
          <FormattedMessage id="promotion.carousel.da_direkt.dental.cross_selling.title.desktop" />
        </StyledDaDirektCrossSellingCardTitle>

        <div>
          <StyledDaDirektCrossSellingCardText>
            <FormattedMessage id="promotion.carousel.da_direkt.dental.cross_selling.text.desktop" />
          </StyledDaDirektCrossSellingCardText>

          <StyledDaDirektCrossSellingCardButton onClick={onGoToCrossSellingLink}>
            <FormattedMessage id="promotion.carousel.da_direkt.cross_selling.button.label" />
          </StyledDaDirektCrossSellingCardButton>
        </div>
      </StyledDaDirektCrossSellingCardContent>

      <StyledDaDirektCrossSellingCardLogoAndImage>
        <StyledDaDirektCrossSellingCardDaDirektLogo
          src={DA_DIREKT_LOGO_LINK}
          alt="DA Direkt logo"
        />

        <StyledDaDirektDentalCrossSellingCardImage
          src={DA_DIREKT_DENTAL_BG_IMAGE_DESKTOP}
          alt="DA Direkt dental"
        />
      </StyledDaDirektCrossSellingCardLogoAndImage>
    </StyledDaDirektCrossSellingCard>
  );
}

export default DaDirektDentalCardDesktop;
