import { css, styled } from '@mui/material';

import { MIN_WIDTH_LG } from '../../theme/breakpoints';

const StyledInput = styled('input')(
  ({ theme }) => css`
    box-sizing: border-box;
    width: 100%;
    height: 3.5em;
    margin: 0;
    padding: 0.5em;
    font-size: 1em;
    font-weight: 300;
    color: ${theme.palette.main.professional_blue};
    background-color: ${theme.palette.common.white};
    border: ${theme.palette.main.professional_blue} 1px solid;
    border-radius: 4px;
    appearance: none;

    &[disabled] {
      background: ${theme.palette.gray.ultra_light_gray};
      border-color: ${theme.palette.text.disabled};
      color: ${theme.palette.text.disabled};
      cursor: not-allowed;
    }

    &:has(+ div) {
      border-color: ${theme.palette.error.main} !important;
      box-shadow: none !important;
    }
    &[data-error] {
      border-color: ${theme.palette.error.main} !important;
      box-shadow: none !important;
    }
    @media only screen and (min-width: ${MIN_WIDTH_LG}) {
      padding: 0.5em 1em;
    }

    &::placeholder {
      color: ${theme.palette.main.professional_blue};
      opacity: 0.6;
    }

    &:focus {
      outline: none;
      box-shadow: ${theme.shadows[3]};
      border-color: ${theme.palette.main.professional_blue};
      color: ${theme.palette.main.professional_blue};

      ::placeholder {
        color: ${theme.palette.main.professional_blue};
      }
    }

    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      appearance: none;
      margin: 0;
    }
  `
);

export default StyledInput;
