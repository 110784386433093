import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import GATracker from './GATracker';
import {
  DA_DIREKT_POLICY_CATEGORIES,
  PETOLO_PET_LIABILITY_POLICY_CATEGORIES,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
  VITOLO_HEALTH_POLICY_CATEGORIES,
  SPECIAL_TARIFF_POLICY_CATEGORIES,
} from './shared';

import PageWithMinimalHeader from './components/Page/PageWithMinimalHeader';
import PageWithFullHeader from './components/Page/PageWithFullHeader';
import PageWithHalfHeader from './components/Page/PageWithHalfHeader';
import PageWithFullHeaderWithoutFooter from './components/Page/PageWithFullHeaderWithoutFooter';
import PageWithFullHeaderAndFailedPaymentNotification from './components/Page/PageWithFullHeaderAndFailedPaymentNotification';
import PleaseSignIn from './components/ui/PleaseSignIn';
import PageRenderForInsuranceType from './components/Page/PageRenderForInsuranceType';
import Login from './components/Login/Login';
import LoginSuccess from './components/Login/LoginSuccess';
import Authorisation from './components/Login/Authorisation';
import SecondAuthStatus from './components/Login/SecondAuthStatus';
import SignDocs from './components/SignDocs';
import SelectUser from './components/SelectUser';
import HomeOnboardDentolo from './components/onboard/HomeOnboardDentolo';
import HomeOnboardPetolo from './components/onboard/HomeOnboardPetolo';
import Homepage from './components/Homepage';
import PolicyInfo from './components/PolicyInfo';
import PolicyDetails from './components/PolicyDetails';
import CustomerDetails from './components/CustomerDetails';
import ContractDocs from './components/ContractDocs';
import DentistsMap from './components/DentistsMap';
import DentistsDetails from './components/DentistsDetails';
import UserClaims from './components/UserClaims';
import DentoloClaims from './features/claims/DentoloClaims';
import VitoloClaims from './features/claims/VitoloClaims';
import PetoloClaims from './features/claims/PetoloClaims';
import PetLiabilityClaims from './features/claims/PetLiabilityClaims';
import UserClaimsOnboardDentolo from './components/onboard/UserClaimsOnboardDentolo';
import FileUploadStatus from './components/FileUploadStatus';
import Contact from './features/contact/Contact';
import ContactStatus from './features/contact/ContactStatus';
import ReferralPage from './features/promotions/ReferralPage';
import PaymentDetails from './components/PaymentDetails';
import PaymentOverview from './components/PaymentOverview';
import ErrorPlaceholder from './components/ui/ErrorPlaceholder';
import OnlineVets from './components/OnlineVets';
import ContractCancellation from './features/contractCancellation/ContractCancellation';
import ContractCancellationReasons from './features/contractCancellation/ContractCancellationReasons';
import ContractCancellationStatus from './features/contractCancellation/ContractCancellationStatus';
import AkutschutzCancellation from './features/akutschutzCancellation/AkutschutzCancellation';
import AkutschutzCancellationSuccess from './features/akutschutzCancellation/AkutschutzCancellationSuccess';
import AkutschutzCancellationAbort from './features/akutschutzCancellation/AkutschutzCancellationAbort';
import PageNotFound from './features/404/PageNotFound';
import ContractPendingApproval from './features/contractPendingApproval';
import PaymentStatus from './components/PaymentStatus';
import AkutschutzCancellationError from './features/akutschutzCancellation/AkutschutzCancellationError';

const excludeReferralPage = [
  ...DA_DIREKT_POLICY_CATEGORIES,
  ...PETOLO_PET_LIABILITY_POLICY_CATEGORIES,
  ...VITOLO_HEALTH_POLICY_CATEGORIES,
  ...SPECIAL_TARIFF_POLICY_CATEGORIES,
];

const Routes = () => {
  return (
    <GATracker>
      <Switch>
        <Route exact path="/login">
          <PageWithMinimalHeader>
            <Login />
          </PageWithMinimalHeader>
        </Route>

        <Route exact path="/login/success">
          <PageWithMinimalHeader>
            <LoginSuccess />
          </PageWithMinimalHeader>
        </Route>

        <Route exact path="/authorization">
          <PageWithMinimalHeader>
            <Authorisation />
          </PageWithMinimalHeader>
        </Route>

        <Route exact path="/authorization/:status">
          <PageWithMinimalHeader>
            <SecondAuthStatus />
          </PageWithMinimalHeader>
        </Route>

        <Route exact path="/confirm-documents">
          <PageWithMinimalHeader>
            <SignDocs />
          </PageWithMinimalHeader>
        </Route>

        <Route exact path="/pending-approval">
          <PageWithHalfHeader>
            <ContractPendingApproval />
          </PageWithHalfHeader>
        </Route>

        <Route exact path="/select-customer">
          <PageWithMinimalHeader>
            <SelectUser />
          </PageWithMinimalHeader>
        </Route>

        <Route exact path="/intro-dentolo/:id">
          <PageWithMinimalHeader>
            <PleaseSignIn>
              <HomeOnboardDentolo />
            </PleaseSignIn>
          </PageWithMinimalHeader>
        </Route>

        <Route exact path="/intro-petolo/:id">
          <PageWithMinimalHeader>
            <PleaseSignIn>
              <PageRenderForInsuranceType insuranceType={INSURANCE_TYPE_PETOLO}>
                <HomeOnboardPetolo />
              </PageRenderForInsuranceType>
            </PleaseSignIn>
          </PageWithMinimalHeader>
        </Route>

        <Route exact path="/">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <Homepage />
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route exact path="/dashboard">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <Homepage />
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route exact path="/policy-info">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <PolicyInfo />
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route exact path="/policy-details">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <PageRenderForInsuranceType insuranceType={INSURANCE_TYPE_DENTOLO}>
              <PolicyDetails />
            </PageRenderForInsuranceType>
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route exact path="/user-info">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <CustomerDetails />
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route exact path="/contract-docs">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <ContractDocs />
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route exact path="/dentists-map">
          <PageWithFullHeaderWithoutFooter>
            <PageRenderForInsuranceType insuranceType={INSURANCE_TYPE_DENTOLO}>
              <DentistsMap />
            </PageRenderForInsuranceType>
          </PageWithFullHeaderWithoutFooter>
        </Route>

        <Route exact path="/dentists-details/:id">
          <PageWithFullHeader>
            <PageRenderForInsuranceType insuranceType={INSURANCE_TYPE_DENTOLO}>
              <DentistsDetails />
            </PageRenderForInsuranceType>
          </PageWithFullHeader>
        </Route>

        <Route path="/user-claims-info">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <UserClaims />
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route path="/user-claims/dentolo">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <PageRenderForInsuranceType insuranceType={INSURANCE_TYPE_DENTOLO}>
              <DentoloClaims />
            </PageRenderForInsuranceType>
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route path="/user-claims/petolo">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <PageRenderForInsuranceType insuranceType={INSURANCE_TYPE_PETOLO}>
              <PetoloClaims />
            </PageRenderForInsuranceType>
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route path="/user-claims/health">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <PageRenderForInsuranceType insuranceType={INSURANCE_TYPE_VITOLO}>
              <VitoloClaims />
            </PageRenderForInsuranceType>
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route path="/user-claims/pet-liability">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <PageRenderForInsuranceType insuranceType={INSURANCE_TYPE_PETOLO}>
              <PetLiabilityClaims />
            </PageRenderForInsuranceType>
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route exact path="/user-claims/onboarding-dentolo/:id">
          <PageWithFullHeader>
            <PageRenderForInsuranceType insuranceType={INSURANCE_TYPE_DENTOLO}>
              <UserClaimsOnboardDentolo />
            </PageRenderForInsuranceType>
          </PageWithFullHeader>
        </Route>

        <Route exact path="/user-claims/file-upload/:status">
          <PageWithFullHeader>
            <FileUploadStatus />
          </PageWithFullHeader>
        </Route>

        <Route exact path="/contact">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <Contact />
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route exact path="/contact/:status">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <ContactStatus />
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route exact path="/referral">
          <PageWithFullHeaderAndFailedPaymentNotification>
            <PageRenderForInsuranceType
              excludePolicyCategories={excludeReferralPage}
              excludeAmtrust
            >
              <ReferralPage />
            </PageRenderForInsuranceType>
          </PageWithFullHeaderAndFailedPaymentNotification>
        </Route>

        <Route exact path="/payment-details">
          <PageWithFullHeader>
            <PaymentDetails />
          </PageWithFullHeader>
        </Route>

        <Route exact path="/payment-overview">
          <PageWithFullHeader>
            <PaymentOverview />
          </PageWithFullHeader>
        </Route>

        <Route exact path="/online-vets">
          <PageWithFullHeader>
            <PageRenderForInsuranceType insuranceType={INSURANCE_TYPE_PETOLO}>
              <OnlineVets />
            </PageRenderForInsuranceType>
          </PageWithFullHeader>
        </Route>

        <Route exact path="/contract-cancellation">
          <PageWithFullHeader>
            <ContractCancellation />
          </PageWithFullHeader>
        </Route>

        <Route exact path="/cancellation-reason">
          <PageWithFullHeader>
            <ContractCancellationReasons />
          </PageWithFullHeader>
        </Route>

        <Route exact path="/contract-cancellation/:status">
          <PageWithFullHeader>
            <ContractCancellationStatus />
          </PageWithFullHeader>
        </Route>

        <Route exact path="/akutschutz-cancellation">
          <PageWithFullHeader>
            <AkutschutzCancellation />
          </PageWithFullHeader>
        </Route>

        <Route exact path="/akutschutz-cancellation/success">
          <PageWithFullHeader>
            <AkutschutzCancellationSuccess />
          </PageWithFullHeader>
        </Route>

        <Route exact path="/akutschutz-cancellation/abort">
          <PageWithFullHeader>
            <AkutschutzCancellationAbort />
          </PageWithFullHeader>
        </Route>

        <Route exact path="/akutschutz-cancellation/error">
          <PageWithFullHeader>
            <AkutschutzCancellationError />
          </PageWithFullHeader>
        </Route>

        <Route exact path="/payment-status/:status">
          <PageWithFullHeader>
            <PaymentStatus />
          </PageWithFullHeader>
        </Route>

        <Route exact path="/error">
          <PageWithMinimalHeader>
            <ErrorPlaceholder />
          </PageWithMinimalHeader>
        </Route>

        <Route exact path="/404">
          <PageWithFullHeader>
            <PageNotFound />
          </PageWithFullHeader>
        </Route>

        <Redirect to="/404" />
      </Switch>
    </GATracker>
  );
};

export default Routes;
