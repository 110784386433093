import { css, styled } from '@mui/material';
import { MIN_WIDTH_MD } from '../../theme/breakpoints';

const StyledContainer = styled('div')(
  ({ theme }) => css`
    margin: 1.5rem auto 0;
    padding: 0 1rem;
    width: 100%;
    max-width: ${theme.shape.container};

    @media (min-width: ${MIN_WIDTH_MD}) {
      margin-top: 4rem;
      padding: 0;
    }
  `
);

export default StyledContainer;
