import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { UPLOAD_PET_LIABILITY_CUSTOMER_CLAIM_DOCUMENTS } from '../../shared';
import { clearNewPetLiabilityClaimFormData } from './claimFormData/newClaimFormDataPetLiability';

const usePetLiabilityClaims = () => {
  const history = useHistory();

  const [uploadPetLiabilityCustomerClaimDocument, { data, loading, error }] = useMutation(
    UPLOAD_PET_LIABILITY_CUSTOMER_CLAIM_DOCUMENTS,
    {
      refetchQueries: [],
    }
  );

  const submitClaim = async (props) => {
    const { payload, setSelectedDocuments, setClaimSubmitted, clearFormData = true } = props || {};

    if (setClaimSubmitted) {
      setClaimSubmitted(true);
    }

    try {
      const resp = await uploadPetLiabilityCustomerClaimDocument({ variables: payload });

      if (setSelectedDocuments) {
        setSelectedDocuments([]);
      }

      if (clearFormData) {
        clearNewPetLiabilityClaimFormData();
      }

      const success = resp?.data?.createPetLiabilityReport?.status === 'success';

      if (success) {
        history.push(`/user-claims/file-upload/success`);
      } else {
        throw new Error();
      }
    } catch {
      history.push(`/user-claims/file-upload/failure`);
    }
  };

  return [submitClaim, { data, loading, error }];
};

export default usePetLiabilityClaims;
