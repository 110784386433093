import { styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../../theme/breakpoints';

// eslint-disable-next-line import/prefer-default-export
export const StyledDaDirektDentalCrossSellingCardImage = styled('img')`
  width: 140px;
  height: auto;
  margin-bottom: initial;

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 200px;
    margin-bottom: 1rem;
  }
`;
