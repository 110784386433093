import { styled, css } from '@mui/material';

import { MAX_WIDTH_SM, MIN_WIDTH_MD } from '../../../theme/breakpoints';
import { Icon } from '../../common';

export const StyledNavMenuMobileOverlay = styled('div')(
  ({ theme, open }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${theme.palette.background.viewport};
    overflow-y: hidden;
    transform: translate3d(0%, 0, 0);
    transition: transform 0.3s ease-in-out;
    z-index: 90;

    transform: translate3d(${open ? '0%' : '100%'}, 0, 0);

    @media (min-width: ${MIN_WIDTH_MD}) {
      display: none;
    }
  `
);

export const StyledNavMenuMobileContainer = styled('div')`
  height: calc(100% - 4rem);
  margin-top: 4.5rem;
  overflow-y: auto;
`;

export const StyledNavMenuMobileUserInfo = styled('div')(
  ({ theme }) => css`
    > h5 {
      font-size: 1.125rem;
      font-weight: 700;
      color: ${theme.palette.main.professional_blue};
    }

    > p {
      margin-top: 0.5rem;
      font-size: 1rem;
      font-weight: 450;
      color: ${theme.palette.main.professional_blue};
    }
  `
);

export const StyledUserLogo = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    font-size: 1.2rem;
    font-weight: 700;
    color: ${theme.palette.background.radio};
    background-color: ${theme.palette.main.professional_blue};
    border-radius: 50%;
  `
);

export const StyledSignoutButtonContainer = styled('div')(
  ({ theme }) => css`
    position: absolute;
    bottom: 1rem;
    right: 1rem;

    button {
      font-size: 1rem;
      font-weight: 700;
      background-color: ${theme.palette.common.white};
      border: none;
      border-radius: 5px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
    }
  `
);

export const StyledNavMenuMobileSelectUserLink = styled('li')(
  ({ theme, isCurrentCustomer, inActive }) => css`
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.5rem;
    background-color: ${theme.palette.common.white};
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    cursor: pointer;

    &:not(:first-of-type) {
      margin-top: 0.5rem;
    }

    &:focus {
      box-shadow: ${theme.shadows[4]};
    }

    ${isCurrentCustomer &&
    css`
      cursor: default;
    `}

    ${inActive &&
    css`
      opacity: 0.4;
    `}
  `
);

export const StyledNavMenuMobileSelectUserLinkTitleWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 0.5rem;

  @media (max-width: ${MAX_WIDTH_SM}) {
    > img {
      width: 2rem;
      height: auto;
    }
  }
`;

export const StyledNavMenuMobileSelectUserLinkTitle = styled('div')`
  font-size: 1rem;

  > span {
    display: block;

    &:first-child {
      font-weight: 700;
    }

    &:last-child {
      font-size: 0.8rem;
    }
  }
`;

export const StyledNavMenuMobileSelectUserLinkCheckIcon = styled(Icon)`
  width: 2rem;
  height: auto;
`;
