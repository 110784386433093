import { useEffect, useState } from 'react';

const useForm = (initialValues, callback, validate) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [startLiveValidation, setStartLiveValidation] = useState(false);

  // This dirty state for the form
  const [isDirty, setIsDirty] = useState(false);
  // This contains dirty state for each item in the `initialValue`
  const [dirty, setDirty] = useState({});

  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length < 1) return;

    let formStatus = {};
    Object.keys(initialValues).forEach((key) => {
      formStatus = { ...formStatus, [key]: false };
    });

    setDirty(formStatus);
  }, [initialValues]);

  useEffect(() => {
    if (!startLiveValidation) return;

    setErrors(validate(values, dirty, isDirty));
  }, [values, startLiveValidation, validate, dirty, isDirty]);

  const handleSubmit = (ev) => {
    if (ev) ev.preventDefault();

    setStartLiveValidation(true);

    const errs = validate(values, dirty, isDirty);
    setErrors(errs);

    if (Object.keys(errs).length > 0) return;

    callback();
  };

  const handleChange = (ev) => {
    if (ev) ev.persist();

    const target = ev?.target?.name;

    setValues((state) => ({ ...state, [target]: ev.target.value }));

    if (!isDirty) {
      setIsDirty(true);
    }
    if (!dirty[`${target}`]) {
      setDirty((state) => ({ ...state, [target]: true }));
    }
  };

  /*
    This was required for the `DropdownSearch` component
    The `DropdownSearch` component returns selected object instead of an event
  */
  const handleCustomChange = (which, value) => {
    setValues((val) => ({ ...val, [which]: value }));

    if (!isDirty) {
      setIsDirty(true);
    }
    if (!dirty[`${which}`]) {
      setDirty((state) => ({ ...state, [which]: true }));
    }
  };

  const updateValue = (which, value) => {
    setValues((val) => ({ ...val, [which]: value }));
  };

  return {
    values, // values from form fields
    errors, // errors from validate()
    isDirty, // form dirty state
    dirty, // dirty state for each form fields
    handleChange,
    handleCustomChange,
    handleSubmit,
    updateValue, // to update value in the form without dirtying it.
  };
};

export default useForm;
