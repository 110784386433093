import React from 'react';
import { FormattedMessage } from 'react-intl';

import { imgLinks } from '../../../../shared';

import { StyledDaDirektHealthCrossSellingCardImage } from './DaDirektHealthCard.styled';
import {
  StyledDaDirektCrossSellingCard,
  StyledDaDirektCrossSellingCardButton,
  StyledDaDirektCrossSellingCardContent,
  StyledDaDirektCrossSellingCardDaDirektLogo,
  StyledDaDirektCrossSellingCardLogoAndImage,
  StyledDaDirektCrossSellingCardTitle,
  StyledDaDirektCrossSellingCardUl,
} from '../DaDirektCrossSellingCard.styled';

const DA_DIREKT_LOGO_LINK = imgLinks['icon.menu.dadirekt.dark'];
const DA_DIREKT_HEALTH_BG_IMAGE_MOBILE = imgLinks['image-da-health-cross-selling-mobile'];
const DA_DIREKT_BULLET_IMAGE = imgLinks['icon-da-direkt-check-professional-blue'];

function DaDirektHealthCardMobile({ onGoToCrossSellingLink }) {
  return (
    <StyledDaDirektCrossSellingCard product="health">
      <StyledDaDirektCrossSellingCardContent>
        <StyledDaDirektCrossSellingCardTitle>
          <FormattedMessage id="promotion.carousel.da_direkt.health.cross_selling.title.mobile" />
        </StyledDaDirektCrossSellingCardTitle>

        <StyledDaDirektCrossSellingCardTitle>
          <FormattedMessage id="promotion.carousel.da_direkt.health.cross_selling.list.title.mobile" />
        </StyledDaDirektCrossSellingCardTitle>

        <StyledDaDirektCrossSellingCardUl bulletimage={DA_DIREKT_BULLET_IMAGE}>
          <li>
            <FormattedMessage id="promotion.carousel.da_direkt.health.cross_selling.list.item_1.mobile" />
          </li>

          <li>
            <FormattedMessage id="promotion.carousel.da_direkt.health.cross_selling.list.item_2.mobile" />
          </li>

          <li>
            <FormattedMessage id="promotion.carousel.da_direkt.health.cross_selling.list.item_3.mobile" />
          </li>
        </StyledDaDirektCrossSellingCardUl>

        <StyledDaDirektCrossSellingCardButton onClick={onGoToCrossSellingLink}>
          <FormattedMessage id="promotion.carousel.da_direkt.cross_selling.button.label" />
        </StyledDaDirektCrossSellingCardButton>
      </StyledDaDirektCrossSellingCardContent>

      <StyledDaDirektCrossSellingCardLogoAndImage>
        <StyledDaDirektHealthCrossSellingCardImage
          src={DA_DIREKT_HEALTH_BG_IMAGE_MOBILE}
          alt="DA Direkt health"
        />

        <StyledDaDirektCrossSellingCardDaDirektLogo
          src={DA_DIREKT_LOGO_LINK}
          alt="DA Direkt logo"
        />
      </StyledDaDirektCrossSellingCardLogoAndImage>
    </StyledDaDirektCrossSellingCard>
  );
}

export default DaDirektHealthCardMobile;
