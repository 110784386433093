import { styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../../theme/breakpoints';

import { Paper } from '../../../../components/common';

export const StyledPetoloReferralStatusEmptyContainer = styled(Paper)`
  text-align: center;
`;

export const StyledPetoloReferralStatusEmptyTitle = styled('div')`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const StyledPetoloReferralStatusEmptyDescription = styled('div')`
  font-size: 1rem;
  font-weight: 450;
`;

export const StyledPetoloReferralStatusEmptyImageContainer = styled('div')`
  margin: 3rem auto 3rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 3.75rem;
    margin-bottom: 4rem;
  }
`;

export const StyledPetoloReferralStatusEmptyImg = styled('img')`
  max-width: 100%;
  width: 10.625rem;
  transform-origin: center;
  transform: rotate(-10.437deg);
  border-radius: 6px;
  box-shadow: -8.126px 8.126px 9.752px rgba(27, 53, 98, 0.25);

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 19rem;
  }
`;
