import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PET_LIABILITY_STEP_INCIDENT_LOCATION,
  PET_LIABILITY_STEP_INCIDENT_NOTE,
} from '../../new/newClaimsFormSteps';

export const useAccompaniedByPetLiabilityBackButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_INCIDENT_LOCATION);
};

export const useAccompaniedByPetLiabilityNextButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  const nextButtonLink = PET_LIABILITY_STEP_INCIDENT_NOTE;
  return withSource(nextButtonLink);
};
