import { PETOLO_TREATMENT_CATEGORY_OTHERS } from '../../claimFormData/newClaimFormData';
import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PETOLO_STEP_CONSULTATION_QUESTION,
  PETOLO_STEP_REVIEW,
  PETOLO_STEP_CUSTOMER_NOTE,
} from '../../new/newClaimsFormSteps';

export const useSelectVideoConsultationBackButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();
  return withSource(PETOLO_STEP_CONSULTATION_QUESTION);
};

export const useSelectVideoConsultationNextButtonLink = (
  videoConsultationId,
  treatmentCategories
) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (videoConsultationId) {
    return withSource(PETOLO_STEP_REVIEW);
  }

  if (
    Array.isArray(treatmentCategories) &&
    treatmentCategories.length === 1 &&
    treatmentCategories[0] !== PETOLO_TREATMENT_CATEGORY_OTHERS
  ) {
    return withSource(PETOLO_STEP_REVIEW);
  }

  return withSource(PETOLO_STEP_CUSTOMER_NOTE);
};
