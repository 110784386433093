import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PET_LIABILITY_REASON_ANOTHER_ANIMAL,
  PET_LIABILITY_REASON_PERSON,
} from '../../claimFormData/newClaimFormDataPetLiability';
import {
  PET_LIABILITY_STEP_AFFECTED_PARTY_DETAILS,
  PET_LIABILITY_STEP_MEDICAL_EXAM,
  PET_LIABILITY_STEP_UPLOAD_DOCUMENTS,
} from '../../new/newClaimsFormSteps';

export const useRelationWithAffectedPartyPetLiabilityBackButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_AFFECTED_PARTY_DETAILS);
};

export const useRelationWithAffectedPartyPetLiabilityNextButtonLink = (liabilityReason) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  const nextButtonLink =
    liabilityReason === PET_LIABILITY_REASON_ANOTHER_ANIMAL ||
    liabilityReason === PET_LIABILITY_REASON_PERSON
      ? PET_LIABILITY_STEP_MEDICAL_EXAM
      : PET_LIABILITY_STEP_UPLOAD_DOCUMENTS;

  return withSource(nextButtonLink);
};
