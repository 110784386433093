import { dateFormatRegex } from '../../../../shared/regex';

export const combineFullDateFromInputs = (day, month, year) => {
  const monthNum = month.length === 1 ? `0${month}` : month;
  const dayNum = day.length === 1 ? `0${day}` : day;
  const dateISOString = `${year}-${monthNum}-${dayNum}`;

  return dateISOString;
};

export const getInputFieldValuesFromDate = (date) => {
  const regex = new RegExp(dateFormatRegex);
  const isValidDate = regex.test(date);

  // #TODO: I've added replaced '-' with '/' to fix the date format issue
  // The reason: https://github.com/dentolo/customer-account-frontend/pull/432
  // This is a quick fix for date being converted to UTC and possibly incorrect date getting returned. We need to check sideeffects and implement a proper solution if needed
  const dateObject = isValidDate ? new Date(date?.replace('-', '/')) : '';

  if (dateObject && !Number.isNaN(Number(dateObject))) {
    const dayValue = dateObject.getDate();
    const monthValue = dateObject.getMonth() + 1;
    const yearValue = dateObject.getFullYear();

    return {
      day: dayValue < 10 ? `0${dayValue}` : `${dayValue}`,
      month: monthValue < 10 ? `0${monthValue}` : `${monthValue}`,
      year: `${yearValue}`,
    };
  }

  return { day: '', month: '', year: '' };
};

export const validateMonthRange = (date) => {
  const [yearInString, monthInString, dayInString] = date.split('-');

  // Convert date to integers
  const year = parseInt(yearInString, 10);
  const month = parseInt(monthInString, 10) - 1; // months are 0-based
  const day = parseInt(dayInString, 10);

  // Check if year, month, and day are numbers
  if (Number.isNaN(yearInString) || Number.isNaN(monthInString) || Number.isNaN(dayInString)) {
    return false;
  }

  const dateObject = new Date(year, month, day);

  return (
    dateObject.getFullYear() === year &&
    dateObject.getMonth() === month &&
    dateObject.getDate() === day
  );
};
