import { styled, css } from '@mui/material';

import { MAX_WIDTH_SM, MIN_WIDTH_MD } from '../../theme/breakpoints';
import { IconButton, Paper } from '../common';
import { Link } from '../ui';

export const StyledCustomerDetailsCard = styled(Paper)`
  & + & {
    margin-top: 1rem;
  }
`;

export const StyledCustomerDetailsCardTitle = styled('h5')`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1rem;

  ${({ noSpacing }) =>
    noSpacing &&
    css`
      margin-bottom: 0;
    `}
`;

export const StyledCustomerDetailsCardTitleButtonWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const StyledCustomerDetailsModalButton = styled(IconButton)`
  @media (max-width: ${MAX_WIDTH_SM}) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const StyledCustomerDetailsContactUsContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.background.info};
    padding: 1rem;
    margin-top: 1.25rem;
  `
);

export const StyledCustomerDetailsContactUsLink = styled(Link)`
  font-size: 1.125rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.25rem;
  }
`;

export const StyledCustomerDetailsItem = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  column-gap: 2rem;
  font-size: 1rem;

  & + & {
    margin-top: 0.5rem;
  }

  > div[data-value] {
    font-weight: 700;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row;

    > div[data-label] {
      flex: 0 1 130px;
    }

    & + & {
      margin-top: 1rem;
    }
  }
`;

export const StyledPaymentDetailsItem = styled(StyledCustomerDetailsItem)(
  ({ theme }) => css`
    flex-direction: row;
    margin-bottom: 1rem;
    column-gap: 0.5rem;
    align-items: center;

    > div[data-label] {
      flex: none;
    }
    > div[data-pending] {
      color: ${theme.palette.gray.gray};
      font-size: 1rem;
      > p[data-pending-bold] {
        font-weight: 700;
      }
      > p[data-pending-normal] {
        font-weight: 450;
      }
    }

    > div[data-value] {
      > p[data-label] {
        font-weight: 100;
      }
    }
  `
);

export const StyledPaymentErrorContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.gray.ultra_light_gray};
    padding: 0.5rem;
    border-radius: 0.313rem;
    color: ${theme.palette.gray.gray};
    margin-bottom: 1rem;
    font-size: 0.875rem;
  `
);

export const StyledLoadingStatus = styled('div')(
  ({ theme }) => css`
    align-self: flex-start;
    width: fit-content;
    padding: 0.25em 0.375em;
    font-weight: 700;
    line-height: 1em;
    color: ${theme.palette.main.professional_blue};
    background-color: ${theme.palette.main.product_blue};
    border-radius: 5px;
    margin-bottom: 1rem;
    span {
      font-size: 0.75em;
    }
  `
);
