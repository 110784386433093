import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { useTrackDtEvent } from '../../DtlTracker';
import { days, GET_FAVORITE_DENTISTS, REMOVE_FAVORITE_DENTIST, imgLinks } from '../../shared';

import { Button } from './Button';
import { Icon, Separator } from '../common';
import {
  StyledDentistDetailsAvatar,
  StyledDentistDetailsAvatarContainer,
  StyledDentistDetailsAvatarText,
  StyledDentistDetailsCardItem,
  StyledDentistDetailsCardItemText,
  StyledDentistDetailsCardWrapper,
  StyledDentistDetailsOpeningHoursContainer,
  StyledDentistDetailsOpeningHoursItem,
} from '../styled/DentistsDetails.styled';
import {
  StyledFavoriteDentistBox,
  StyledFavoriteDentistCard,
  StyledFavoriteDentistCardHeader,
  StyledFavoriteDentistDetailsButtonWrapper,
  StyledFavoriteDentistFavButton,
} from './styled/FavoriteDentist.styled';

// GET_FAVORITE_DENTISTS
const FavoriteDentistBlock = ({ customer, favouriteDentists }) => {
  const intl = useIntl();

  const [fullOpenHours, setFullOpenHours] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showDescription, setShowDescription] = useState(null);
  const [sendUserData] = useTrackDtEvent();
  const setHeartIcon = useState('icon.blank.heart');

  const [removeFavoriteDentistMutation] = useMutation(REMOVE_FAVORITE_DENTIST, {
    refetchQueries: [{ query: GET_FAVORITE_DENTISTS }],
  });

  const trackDtEvent = (dentist = '', eventType = '') => {
    const trackData = {
      userData: {
        eventType: eventType || '',
        time: new Date().toISOString(),
        url: window?.location?.href,
        uuid: customer?.uuid,
        dentist: dentist?.referenceKey,
        website: dentist?.websiteUrl,
      },
    };
    sendUserData(trackData);
  };

  const handleLinkCLick = (dentist) => (event) => {
    event.stopPropagation();
    trackDtEvent(dentist, 'click dentist website');
  };

  const removeFavoriteDentist = (favouriteDentist) => {
    const { referenceKey } = favouriteDentist;
    trackDtEvent(favouriteDentist, 'click remove dentist from favourites');
    removeFavoriteDentistMutation({ variables: { dentistKey: referenceKey } }).then((res) => {
      if (res?.data?.addFavoriteDentist && !res?.data?.addFavoriteDentist?.errors?.length) {
        setShowLoader(false);
        setHeartIcon('icon.blank.heart');
      } else {
        setShowLoader(false);
      }
    });
  };

  const triggerFavoriteDentist = (favouriteDentist) => {
    setShowLoader(true);
    removeFavoriteDentist(favouriteDentist);
  };

  const showDes = (index) => {
    // toggle item if user clicked on the same details button again
    // else show the item matched with index
    if (showDescription === index) {
      setShowDescription(null);
    } else {
      setShowDescription(index);
    }
  };

  const isInRange = (value, range) => {
    return value >= range[0] && value <= range[1];
  };

  // #TODO: same function is defined in `DentistDetailsUI.js`
  // create a shared function
  const setHoursText = (dentist) => {
    const today = `${dayjs().day() - 1}`;
    const day = Object.entries(dentist.openingHours).filter((item) => item[0] === today);
    let text = 'closed';

    if (!Array.isArray(day) || day.length < 1) return text;

    const shifts = day[0][1];

    shifts.some((shift) => {
      if (!Object.values(shift).filter((item) => item).length) return true;

      const shiftRange = [shift.from, shift.to];
      const now = dayjs().format('HH:mm');

      if (isInRange(now, shiftRange)) {
        const time = shiftRange[1].split(':');

        const thirtyMinsBeforeClosing = dayjs()
          .set('hour', time[0])
          .set('minute', time[1])
          .subtract(30, 'minute')
          .format('HH:mm');

        if (isInRange(now, [thirtyMinsBeforeClosing, shiftRange[1]])) {
          text = 'closing.soon';
        } else {
          text = 'now.open';
        }

        return true;
      }

      return false;
    });

    return text;
  };

  return (
    <StyledFavoriteDentistBox>
      <StyledDentistDetailsCardWrapper blurred={showLoader}>
        {favouriteDentists?.map((favDentist, i, { length }) => {
          const avatarSrc = favDentist?.avatar;
          const address = `${favDentist?.streetName.trim()} ${favDentist?.houseNumber},${
            favDentist?.postcode
          }`;

          return (
            <React.Fragment key={favDentist?.practiceName}>
              <StyledFavoriteDentistCard>
                <StyledFavoriteDentistCardHeader>
                  <div>{favDentist?.practiceName}</div>

                  <StyledFavoriteDentistFavButton
                    icon="icon.fill.heart"
                    onClick={() => triggerFavoriteDentist(favDentist)}
                    aria-label="Favourite Dentist"
                  />
                </StyledFavoriteDentistCardHeader>

                <StyledDentistDetailsAvatarContainer alignItems="center">
                  {avatarSrc && (
                    <StyledDentistDetailsAvatar size="sm" src={avatarSrc} alt="dentist avatar" />
                  )}

                  <div>
                    <StyledDentistDetailsAvatarText type="title">
                      {favDentist?.title} {favDentist?.name}
                    </StyledDentistDetailsAvatarText>
                  </div>
                </StyledDentistDetailsAvatarContainer>

                <StyledDentistDetailsCardItem>
                  <Icon icon="icon.pin" alt="address" />
                  <StyledDentistDetailsCardItemText>{address}</StyledDentistDetailsCardItemText>
                </StyledDentistDetailsCardItem>

                <StyledFavoriteDentistDetailsButtonWrapper>
                  <Button variant="nostyle" onClick={() => showDes(i)}>
                    Details
                  </Button>
                </StyledFavoriteDentistDetailsButtonWrapper>

                {showDescription === i && (
                  <>
                    {favDentist?.phoneNumber && (
                      <StyledDentistDetailsCardItem>
                        <Icon icon="icon.phone" alt="phone number" />
                        <StyledDentistDetailsCardItemText>
                          {favDentist?.phoneNumber}
                        </StyledDentistDetailsCardItemText>
                      </StyledDentistDetailsCardItem>
                    )}

                    {favDentist?.websiteUrl && (
                      <StyledDentistDetailsCardItem>
                        <Icon icon="icon.globe" alt="website" />
                        <StyledDentistDetailsCardItemText>
                          <a
                            href={`${favDentist?.websiteUrl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleLinkCLick(favDentist)}
                          >
                            {favDentist?.websiteUrl}
                          </a>
                        </StyledDentistDetailsCardItemText>
                      </StyledDentistDetailsCardItem>
                    )}

                    {favDentist?.openingHours && (
                      <>
                        <StyledDentistDetailsCardItem>
                          <Icon icon="icon.hours" alt="opening hours" />

                          <Button
                            variant="nostyle"
                            onClick={() => setFullOpenHours(!fullOpenHours)}
                          >
                            <>
                              <FormattedMessage
                                id={`dentist.details.open.hours.${setHoursText(favDentist)}`}
                              />
                              <img src={imgLinks['icon.accordion.arrow.down']} alt="" />
                            </>
                          </Button>
                        </StyledDentistDetailsCardItem>

                        {fullOpenHours && (
                          <StyledDentistDetailsOpeningHoursContainer>
                            {Object.entries(favDentist?.openingHours).map((item) => {
                              const day = days.filter((d) => d.id === item[0]);
                              const today = `${dayjs().day() - 1}`;
                              const dayName = intl.formatMessage({
                                id: `dentist.details.open.hours.day.${day[0].name}.short`,
                              });

                              return (
                                <StyledDentistDetailsOpeningHoursItem
                                  key={item[0]}
                                  today={today === item[0]}
                                >
                                  <div>{day.length ? dayName : ''}:</div>

                                  {item[1].length &&
                                  item[1].filter((open) => open.from && open.to).length ? (
                                    item[1].map((shift) => (
                                      <div key={`${item[0]}-${shift?.from}-${shift?.to}`}>
                                        {shift.from} - {shift.to}
                                      </div>
                                    ))
                                  ) : (
                                    <div>
                                      <FormattedMessage id="dentist.details.open.hours.day.closed" />
                                    </div>
                                  )}
                                </StyledDentistDetailsOpeningHoursItem>
                              );
                            })}
                          </StyledDentistDetailsOpeningHoursContainer>
                        )}
                      </>
                    )}
                  </>
                )}
              </StyledFavoriteDentistCard>

              {i < length - 1 && <Separator />}
            </React.Fragment>
          );
        })}
      </StyledDentistDetailsCardWrapper>
    </StyledFavoriteDentistBox>
  );
};

FavoriteDentistBlock.propTypes = {
  customer: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  favouriteDentists: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      practiceName: PropTypes.string,
      title: PropTypes.string,
      name: PropTypes.string,
      streetName: PropTypes.string,
      houseNumber: PropTypes.string,
      postcode: PropTypes.string,
      phoneNumber: PropTypes.string,
      websiteUrl: PropTypes.string,
      openingHours: PropTypes.shape({}),
      referenceKey: PropTypes.string,
    })
  ).isRequired,
};

export default FavoriteDentistBlock;
