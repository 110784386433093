// `DENTOLO` new claims form steps
export const DENTOLO_STEP_MAIN = '/user-claims/dentolo/new';
export const DENTOLO_STEP_TREATMENT_CATEGORY = '/user-claims/dentolo/new/select-treatment-category';
export const DENTOLO_STEP_DOCUMENT_TYPE = '/user-claims/dentolo/new/select-document-type';
export const DENTOLO_STEP_EXISTING_CLAIM_QUESTION =
  '/user-claims/dentolo/new/existing-claim-question';
export const DENTOLO_STEP_EXISTING_CLAIMS = '/user-claims/dentolo/new/select-existing-claims';
export const DENTOLO_STEP_UPLOAD_DOCUMENTS = '/user-claims/dentolo/new/upload-documents';
export const DENTOLO_STEP_DOCUMENT_DATE = '/user-claims/dentolo/new/document-date';
export const DENTOLO_STEP_TREATMENT_DATE = '/user-claims/dentolo/new/treatment-date';
export const DENTOLO_STEP_TREATMENT_PRICE = '/user-claims/dentolo/new/treatment-price-amount';
export const DENTOLO_STEP_DENTIST_DETAILS = '/user-claims/dentolo/new/dentist-details';
export const DENTOLO_STEP_FILLING_COUNT = '/user-claims/dentolo/new/filling-count';
export const DENTOLO_STEP_REVIEW = '/user-claims/dentolo/new/review-details';

// `PETOLO` new claims form steps
export const PETOLO_STEP_MAIN = '/user-claims/petolo/new';
export const PET_HEALTH_STEP_TREATMENT_CATEGORIES =
  '/user-claims/petolo/new/select-treatment-categories';
export const PETOLO_STEP_REASON_FOR_TREATMENT = '/user-claims/petolo/new/reason-for-treatment';
export const PETOLO_STEP_DOCUMENT_TYPE = '/user-claims/petolo/new/select-document-type';
export const PETOLO_STEP_EXISTING_CLAIM_QUESTION =
  '/user-claims/petolo/new/existing-claim-question';
export const PETOLO_STEP_EXISTING_CLAIMS = '/user-claims/petolo/new/select-existing-claims';
export const PETOLO_STEP_UPLOAD_DOCUMENTS = '/user-claims/petolo/new/upload-documents';
export const PETOLO_STEP_DOCUMENT_DATE = '/user-claims/petolo/new/document-date';
export const PETOLO_STEP_TREATMENT_DATE = '/user-claims/petolo/new/treatment-date';
export const PETOLO_STEP_TREATMENT_PRICE = '/user-claims/petolo/new/treatment-price-amount';
export const PETOLO_STEP_CONSULTATION_QUESTION = '/user-claims/petolo/new/consultation-question';
export const PETOLO_STEP_CUSTOMER_NOTE = '/user-claims/petolo/new/treatment-necessary-feedback';
export const PETOLO_STEP_VIDEO_CONSULTATION = '/user-claims/petolo/new/select-video-consultation';
export const PETOLO_STEP_REVIEW = '/user-claims/petolo/new/review-details';

// `PET LIABILITY` new claims form steps
export const PET_LIABILITY_STEP_MAIN = '/user-claims/pet-liability/new';
export const PET_LIABILITY_STEP_LIABILITY_REASON =
  '/user-claims/pet-liability/new/liability-reason';
export const PET_LIABILITY_STEP_DAMAGE_CAUSED_BY =
  '/user-claims/pet-liability/new/damage-caused-by';
export const PET_LIABILITY_STEP_INCIDENT_DATE_TIME =
  '/user-claims/pet-liability/new/incident-date-time';
export const PET_LIABILITY_STEP_ACCOMPANIED_BY = '/user-claims/pet-liability/new/accompanied-by';
export const PET_LIABILITY_STEP_DAMAGE_ESTIMATE = '/user-claims/pet-liability/new/damage-estimate';
export const PET_LIABILITY_STEP_AFFECTED_PARTY = '/user-claims/pet-liability/new/who-was-affected';
export const PET_LIABILITY_STEP_RELATION_WITH_AFFECTED_PARTY =
  '/user-claims/pet-liability/new/relation-with-affected';
export const PET_LIABILITY_STEP_MEDICAL_EXAM = '/user-claims/pet-liability/new/medical-exam';
export const PET_LIABILITY_STEP_INCIDENT_LOCATION =
  '/user-claims/pet-liability/new/incident-location';
export const PET_LIABILITY_STEP_AFFECTED_PARTY_DETAILS =
  '/user-claims/pet-liability/new/affected-party-details';
export const PET_LIABILITY_STEP_INCIDENT_NOTE = '/user-claims/pet-liability/new/incident-note';
export const PET_LIABILITY_STEP_DAMAGED_ITEM = '/user-claims/pet-liability/new/damaged-item';
export const PET_LIABILITY_STEP_UPLOAD_DOCUMENTS =
  '/user-claims/pet-liability/new/upload-documents';
export const PET_LIABILITY_STEP_REVIEW = '/user-claims/pet-liability/new/review-details';

// `VITOLO` new claims form steps
export const VITOLO_STEP_MAIN = '/user-claims/health/new';
export const VITOLO_STEP_EXISTING_CLAIMS = '/user-claims/health/new/select-existing-claims';
export const VITOLO_STEP_DOCUMENT_TYPE = '/user-claims/health/new/select-document-type';
export const VITOLO_STEP_UPLOAD_DOCUMENTS = '/user-claims/health/new/upload-documents';
export const VITOLO_STEP_DOCUMENT_DATE = '/user-claims/health/new/document-date';
export const VITOLO_STEP_EXISTING_CLAIM_QUESTION =
  '/user-claims/health/new/existing-claim-question';
export const VITOLO_STEP_TREATMENT_PRICE = '/user-claims/health/new/treatment-price-amount';
export const VITOLO_STEP_CUSTOMER_NOTE = '/user-claims/health/new/treatment-feedback';
export const VITOLO_STEP_REVIEW = '/user-claims/health/new/review-details';
