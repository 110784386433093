import { css, styled } from '@mui/material';
import { Link } from 'react-router-dom';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

const baseButtonStyles = ({ variant, disabled }) => css`
  padding: 0;
  width: fit-content;
  font-size: 1rem;
  border: none;
  cursor: pointer;

  ${disabled &&
  css`
    opacity: 0.3;
    cursor: default;
  `}

  ${!disabled &&
  variant === 'primary' &&
  css`
    &:hover {
      opacity: 0.9;
    }
  `}
`;

const baseVariantStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 2rem;
  height: 3rem;
  font-weight: 700;
  border-radius: 7px;
  transition: opacity 0.3s ease-in-out;

  @media (min-width: ${MIN_WIDTH_MD}) {
    height: 3.5rem;
    font-size: 1.25rem;
  }
`;

export const variantStyles = (theme, variant, disabled) =>
  ({
    primary: css`
      ${baseButtonStyles({ variant, disabled })};
      ${baseVariantStyles};
      color: ${theme.palette.text.button};
      background-color: ${theme.palette.background.button};
    `,
    outline: css`
      ${baseButtonStyles({ variant, disabled })}
      ${baseVariantStyles}
      color: ${theme.palette.main.professional_blue};
      border: 2px solid ${theme.palette.main.professional_blue};
      background: transparent;
    `,
    nostyle: css`
      ${baseButtonStyles({ variant, disabled })}
      position: relative;
      height: auto;
      font-weight: normal;
      color: ${theme.palette.main.professional_blue};
      background: transparent;
      appearance: none;
      text-decoration: none;

      &::after {
        content: '';
        position: absolute;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: ${theme.palette.main.professional_blue};
        transform-origin: bottom left;
        transition: transform 0.2s ease-out;
      }

      &:hover {
        color: ${theme.palette.main.professional_light_blue};

        &::after {
          transform: scaleX(0);
          transform-origin: bottom right;
        }
      }

      &:active,
      &:focus {
        color: ${theme.palette.main.professional_light_blue};

        &::after {
          background-color: ${theme.palette.main.professional_light_blue};
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    `,
  })[`${variant}`];

export const StyledButton = styled('button')(
  ({ theme, variant, disabled }) => css`
    ${variantStyles(theme, variant, disabled)}
  `
);

export const StyledLink = styled(Link)(
  ({ theme, variant, disabled }) => css`
    text-decoration: none;

    ${variantStyles(theme, variant, disabled)}
  `
);

export const StyledHref = styled('a')(
  ({ theme, variant, disabled }) => css`
    ${variantStyles(theme, variant, disabled)}
  `
);

export const StyledButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;

  > button {
    & + button {
      margin-top: 1rem;
    }
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    flex-direction: row;
    justify-content: flex-end;

    margin-top: 2rem;

    > button {
      & + button {
        margin-top: 0;
        margin-left: 1rem;
      }
    }
  }
`;
