import { css, styled } from '@mui/material';
import { TabList } from 'react-tabs';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';
import { Paper } from '../../common';
import { Button } from '../Button';

export const StyledDentistsMapTitle = styled(Paper)`
  width: 100%;
  /* max-width: 450px; */
  padding: 0;
`;

export const StyledTabList = styled(TabList)(
  ({ theme }) => css`
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid ${theme.palette.main.product_blue};
    background-color: ${theme.palette.background.viewport};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    & [role='tab'] {
      flex: 1;
      padding: 1rem;
      font-size: 0.8rem;
      font-weight: 450;
      border-bottom: 2px solid transparent;
      box-shadow: none;
      cursor: pointer;
      transition: border 0.25s ease-in-out;

      &.selected-dentist-tab,
      &:not(.selected-dentist-tab):hover {
        font-weight: 700;
        border-bottom-color: ${theme.palette.main.professional_blue};
      }

      @media (min-width: ${MIN_WIDTH_MD}) {
        font-size: 1rem;
      }
    }
  `
);

export const StyledDentistsMapToggleHelpButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: 700;
  box-shadow: none;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  > img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
