import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import { useQuery } from '@apollo/client';

import { InfoCard } from '../ui';
import { LOCAL_CONFIRM_EMAIL, GET_CUSTOMER, useReady } from '../../shared';

import { AuthContainer, PageTitle } from './Login.styled';

const LoginSuccess = ({ intl }) => {
  const history = useHistory();

  const { data: confirmedEmailData } = useQuery(LOCAL_CONFIRM_EMAIL);
  const confirmEmail = confirmedEmailData?.confirmEmail;

  const { data: customerData } = useQuery(GET_CUSTOMER);
  const customerKey = customerData?.customer?.key;

  const ready = useReady();

  useEffect(() => {
    if (customerKey) {
      history.push('/');
    }
  }, [customerKey, history]);

  return (
    <AuthContainer>
      <PageTitle>
        <FormattedMessage id="login.common.title" />
      </PageTitle>

      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <InfoCard
          mode="success"
          title={intl.formatMessage({ id: 'login.status.email.sent' }, { confirmEmail })}
          description={intl.formatMessage({ id: 'login.status.success.text_1' })}
          description2={intl.formatMessage({ id: 'login.status.success.text_2' })}
          button={{
            text: intl.formatMessage({ id: 'login.status.success.try_again' }),
            to: '/login',
          }}
          tipQuestion={intl.formatMessage({ id: 'login.status.success.tip.question' })}
          tipDescription={intl.formatMessage({ id: 'login.status.success.tip.description' })}
          tipListTitle={intl.formatMessage({ id: 'login.status.success.tip.list.title' })}
          tipItem1={intl.formatMessage({ id: 'login.status.success.tip.list.item_1' })}
          tipItem2={intl.formatMessage({ id: 'login.status.success.tip.list.item_2' })}
        />
      </CSSTransition>
    </AuthContainer>
  );
};

export default injectIntl(LoginSuccess);
