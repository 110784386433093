import { css, styled } from '@mui/material';

import { MIN_WIDTH_LG } from '../../../theme/breakpoints';
import { Paper } from '../../common';

export const StyledClaimAdditionalPaymentsCard = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 1em;
  margin-bottom: 1em;

  h5 {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  @media (min-width: ${MIN_WIDTH_LG}) {
    h5 {
      line-height: 1.5rem;
    }
  }
`;

export const StyledPayoutDetailsContainer = styled('div')`
  margin-top: 1em;
`;

export const StyledPayoutDate = styled('div')(
  ({ theme }) => css`
    margin-bottom: 1em;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.25em;
    color: ${theme.palette.main.product_blue};
    opacity: 0.9;
  `
);

export const StyledPayoutDetails = styled('p')(
  ({ theme }) => css`
    font-size: 1em;
    font-weight: 300;
    line-height: 1.25em;
    color: ${theme.palette.main.professional_blue};
  `
);
