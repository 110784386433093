import { useEffect } from 'react';

import { LOCAL_STORAGE_EXPERIMENT_TARGET_ID_KEY } from '../shared';

const useOptimizely = (experimentTargetId) => {
  useEffect(() => {
    const localExperimentTargetId = window.localStorage.getItem(
      LOCAL_STORAGE_EXPERIMENT_TARGET_ID_KEY
    );

    if (!experimentTargetId || localExperimentTargetId === experimentTargetId) return;

    // set `experimentTargetId` to localStorage
    window.localStorage.setItem(LOCAL_STORAGE_EXPERIMENT_TARGET_ID_KEY, experimentTargetId);

    // activate optimizely experiment
    window.optimizely.push({ type: 'activate' });
  }, [experimentTargetId]);
};

export default useOptimizely;
