const termsToExclude = ['Dr', 'Dr.', 'Prof', 'Prof.', 'Zahnarzt', 'Praxis', 'Zahnarztpraxis'];

const regexForExcludedTerms = new RegExp(termsToExclude.join('|'), 'i');
const regexForWhitespaces = /\s\s+/g;

const accents = [
  { glyph: 'ä', replacement: 'ae' },
  { glyph: 'ö', replacement: 'oe' },
  { glyph: 'ü', replacement: 'ue' },
  { glyph: 'ß', replacement: 'ss' },
];
const accentsToReplace = accents.map((accent) => accent?.glyph);
const accentsWithRegex = accents.map(({ glyph, replacement }) => ({
  glyph,
  replacement,
  regex: new RegExp(glyph, 'i'),
}));

export const fuzzyDentistOptions = {
  includeScore: true,
  ignoreLocation: true,
  valueKey: 'label',
  threshold: 0.3,
  keys: ['label', 'labelWithoutAccents'],
};

export const dentistSearchTermCleaner = (text = '') => {
  const firstWord = text.split(' ')[0];
  const containsExcludedString = regexForExcludedTerms.test(firstWord);

  return containsExcludedString
    ? text
        .substring(text.indexOf(' ') + 1)
        .replace(regexForWhitespaces, ' ')
        .trim()
    : text;
};

const replaceAccents = (text) => {
  let replacedText = text;

  accentsToReplace.forEach((accent) => {
    const currAccent = accentsWithRegex.find((item) => item?.glyph === accent);
    replacedText = replacedText.replace(currAccent?.regex, currAccent?.replacement);
  });

  return replacedText;
};

export const createSelectOptions = (dentists) => {
  if (!Array.isArray(dentists)) return [];

  return dentists.map((dentist) => {
    const label = `${dentist?.title || ''} ${dentist?.name || ''}${
      dentist?.practiceName ? `, ${dentist?.practiceName}` : ''
    }, ${dentist?.city || ''}`?.trim();

    return {
      value: dentist?.referenceKey,
      isDentoloDentist: !!dentist?.dentoloPartner,
      label,
      labelWithoutAccents: replaceAccents(label),
    };
  });
};
