import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PET_LIABILITY_STEP_AFFECTED_PARTY,
  PET_LIABILITY_STEP_RELATION_WITH_AFFECTED_PARTY,
} from '../../new/newClaimsFormSteps';

export const useDetailsOfAffectedPartyPetLiabilityBackButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_AFFECTED_PARTY);
};

export const useDetailsOfAffectedPartyPetLiabilityNextButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_RELATION_WITH_AFFECTED_PARTY);
};
