import React, { forwardRef } from 'react';
import { styled, css } from '@mui/material';
import PropTypes from 'prop-types';

import { imgLinks } from '../../shared';

const sizeStyles = (size) =>
  ({
    lg: css`
      width: 72px;
      height: 72px;
    `,
    md: css`
      width: 56px;
      height: 56px;
    `,
    sm: css`
      width: 32px;
      height: 32px;
    `,
    xs: css`
      width: 16px;
      height: 16px;
    `,
  })[`${size}`];

const StyledIconButton = styled('button')`
  flex-shrink: 0;
  padding: 0;
  width: 56px;
  height: 56px;
  background: transparent;
  box-shadow: none;
  border: none;
  outline: none;
  border-radius: 0.5rem;
  cursor: pointer;
  background: transparent;

  ${({ size }) => sizeStyles(size)}

  > img {
    width: 100%;
    height: 100%;
  }
`;

const IconButton = forwardRef(({ icon, alt, ...rest }, ref) => {
  return (
    <StyledIconButton ref={ref} {...rest}>
      <img src={imgLinks[`${icon}`]} alt={`${alt} || Icon button`} />
    </StyledIconButton>
  );
});

export default IconButton;

IconButton.defaultProps = {
  size: 'md',
};

IconButton.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string,
};
