import React from 'react';

import {
  StyledSplashImageBottomLeftCorner,
  StyledSplashImageBottomRight,
  StyledSplashImageTopCenter,
  StyledSplashImageTopRightCorner,
  StyledSplashImageTopRightCornerMobile,
} from './SplashImages.styled';

export const SplashImageBottomLeftCorner = () => {
  return (
    <StyledSplashImageBottomLeftCorner
      width="139"
      height="172"
      viewBox="0 0 139 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.6371 54.2958C36.5041 53.7804 39.3785 53.413 42.3379 53.2638C63.8493 51.8085 84.4338 64.493 90.098 85.8662C93.126 97.5072 91.3005 109.986 87.3443 121.386C74.4952 158.23 28.4076 199.347 -13.3512 184.466C-24.0008 180.626 -33.3699 172.717 -38.4734 162.59C-45.389 148.846 -44.1363 132.316 -39.2216 117.827C-28.5521 86.3585 0.550063 60.1174 33.6371 54.2958Z"
        fill="#FFF4D9"
      />
      <path
        d="M32.4809 46.5307L32.4863 46.5297C35.3034 46.0233 38.1133 45.6648 40.9973 45.5194L41.0101 45.5188L41.023 45.5179C61.9141 44.1046 81.7736 56.4182 87.2311 77.0047C90.1566 88.2555 88.4113 100.412 84.5119 111.649C78.1978 129.755 63.6677 148.998 45.5533 161.857C27.4394 174.716 5.99428 181.027 -14.2597 173.812C-24.5739 170.093 -33.6309 162.433 -38.5496 152.673C-45.2159 139.425 -44.062 123.349 -39.2173 109.066L-39.2172 109.066C-28.7139 78.088 -0.0372794 52.2522 32.4809 46.5307Z"
        stroke="#003264"
        strokeWidth="3"
      />
    </StyledSplashImageBottomLeftCorner>
  );
};

export const SplashImageTopCenter = () => {
  return (
    <StyledSplashImageTopCenter>
      <svg
        width="185"
        height="75"
        viewBox="0 0 185 75"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M163.851 17.1955C156.574 43.1447 130.087 53.5347 105.039 50.6626C72.8794 46.9961 42.233 28.3214 24.9451 0.834902C10.0064 -22.7503 5.95007 -56.4913 31.4127 -74.8489C41.4552 -82.128 54.1014 -85.0412 66.5024 -84.8349C103.176 -84.2102 138.153 -62.6845 156.607 -31.2526C165.247 -16.512 168.254 1.56323 163.851 17.1955Z"
          fill="#FFF4D9"
        />
        <path
          d="M167.835 16.7888L167.835 16.7905C160.833 41.7561 135.279 51.9978 110.638 49.1724L110.637 49.1723C78.9134 45.5555 48.6794 27.1232 31.6428 0.0362848L31.6402 0.0322737C24.297 -11.5612 19.6623 -25.6061 19.956 -38.9343C20.2487 -52.2164 25.4247 -64.7692 37.7179 -73.6321L37.721 -73.6344C47.4342 -80.6748 59.7361 -83.5375 71.9048 -83.3351C108.028 -82.7198 142.538 -61.4988 160.742 -30.4932C169.203 -16.0562 172.114 1.60046 167.835 16.7888Z"
          stroke="#003264"
          strokeWidth="3"
        />
      </svg>
    </StyledSplashImageTopCenter>
  );
};

export const SplashImageTopRightCorner = () => {
  return (
    <StyledSplashImageTopRightCorner
      width="115"
      height="114"
      viewBox="0 0 115 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.1042 -9.81186C91.1169 -10.1737 93.1348 -10.4316 95.2124 -10.5364C110.314 -11.558 124.765 -2.65322 128.741 12.3512C130.867 20.5234 129.585 29.284 126.808 37.2868C117.787 63.1524 85.433 92.0168 56.1174 81.5702C48.6412 78.8749 42.0639 73.3224 38.4811 66.213C33.6262 56.5641 34.5056 44.9599 37.9559 34.7883C45.4461 12.6968 65.8764 -5.72494 89.1042 -9.81186Z"
        fill="#FFF4D9"
      />
      <path
        d="M88.37 -14.8227L88.3755 -14.8237C90.3382 -15.1765 92.2917 -15.4255 94.2938 -15.5264L94.3066 -15.5271L94.3195 -15.5279C108.801 -16.5076 122.526 -7.97374 126.296 6.24392C128.319 14.026 127.118 22.4644 124.397 30.3047C119.998 42.9205 109.858 56.3565 97.211 65.3343C84.564 74.3119 69.6624 78.6694 55.6296 73.6701C48.4894 71.0952 42.2247 65.7923 38.827 59.0506C34.2214 49.8973 35.002 38.7474 38.3823 28.7819L38.3823 28.7817C45.7063 7.18066 65.7111 -10.8359 88.37 -14.8227Z"
        stroke="#003264"
        strokeWidth="3"
      />
    </StyledSplashImageTopRightCorner>
  );
};

export const SplashImageBottomRight = () => {
  return (
    <StyledSplashImageBottomRight
      width="206"
      height="102"
      viewBox="0 0 206 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M159.351 74.2243C172.262 91.2716 166.124 112.997 152.294 127.451C134.55 146.02 108.046 156.432 82.3794 154.175C60.3061 152.304 37.0337 138.683 37.6444 113.783C37.8607 103.943 42.0855 94.5527 48.1645 86.8134C66.1509 63.9329 96.5348 52.1501 125.254 55.5633C138.715 57.1732 151.583 63.9427 159.351 74.2243Z"
        fill="#FFF4D9"
      />
      <path
        d="M160.758 71.6977L160.759 71.6991C173.053 87.932 167.361 108.825 153.813 122.984L153.813 122.984C136.372 141.235 110.31 151.466 85.1144 149.25L85.1097 149.249C74.3079 148.334 63.2687 144.543 55.0419 138.009C46.8511 131.504 41.4554 122.299 41.7475 110.389L41.7476 110.385C41.955 100.952 46.0121 91.8658 51.9473 84.3095C69.5931 61.8623 99.4515 50.2673 127.679 53.6217C140.792 55.19 153.267 61.7837 160.758 71.6977Z"
        stroke="#003264"
        strokeWidth="3"
      />
    </StyledSplashImageBottomRight>
  );
};

export const SplashImageTopRightCornerMobile = () => {
  return (
    <StyledSplashImageTopRightCornerMobile
      width="131"
      height="109"
      viewBox="0 0 131 109"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.5446 22.0595C57.0928 20.3447 55.7369 18.5678 54.4938 16.6498C45.2104 2.85162 45.7246 -15.7913 57.8042 -27.831C64.4055 -34.3504 73.4416 -37.9535 82.5803 -39.7172C112.142 -45.3759 157.398 -30.4958 163.599 3.13119C165.151 11.7241 163.491 21.0348 158.702 28.3548C152.199 38.2822 140.68 43.8329 129.108 46.1504C103.978 51.1868 75.2156 41.8982 58.5446 22.0595Z"
        fill="#FFF4D9"
      />
      <path
        d="M54.0704 25.6114L54.0669 25.6071C52.6478 23.9311 51.3319 22.2054 50.1299 20.3509L50.1229 20.34L50.1157 20.3294C41.1795 7.04699 41.7358 -10.7826 53.2381 -22.2493C59.5533 -28.4849 68.2709 -31.9961 77.241 -33.7274C91.6917 -36.4932 110.08 -34.2409 125.603 -27.1441C141.127 -20.0471 153.519 -8.24318 156.501 7.91719C157.989 16.1589 156.388 25.074 151.825 32.0497C145.626 41.5131 134.549 46.9217 123.191 49.1965L123.191 49.1965C98.5687 54.1312 70.3698 45.0078 54.0704 25.6114Z"
        stroke="#003264"
        strokeWidth="3"
      />
    </StyledSplashImageTopRightCornerMobile>
  );
};
