import React from 'react';
import { css, styled, keyframes } from '@mui/material';

const loaderAnimation = keyframes`
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
`;

const StyledBarLoader = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.5rem;
    margin: auto;
    width: 80px;
    height: 80px;

    > div {
      width: 16px;
      background: ${theme.palette.main.professional_blue};
      animation: ${loaderAnimation} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

      &:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
      }

      &:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
      }

      &:nth-child(3) {
        left: 56px;
        animation-delay: 0;
      }
    }
  `
);

const BarLoader = () => {
  return (
    <StyledBarLoader>
      <div />
      <div />
      <div />
    </StyledBarLoader>
  );
};

export default BarLoader;
