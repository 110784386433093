import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  ButtonContainer,
  BackButton,
  PageTitle,
  Paper,
  RadioLabel,
  Text,
} from '../../../../components/common';
import { StyledCheckbox } from '../../../../components/common/Checkbox';
import { Button } from '../../../../components/ui';
import {
  StyledTreatmentCategoryContainer,
  StyledTreatmentCategory,
  CheckboxOptionContainer,
} from './SelectTreatmentCategory.styled';

const SelectTreatmentCategories = ({
  backButtonLink,
  nextButtonLink,
  treatmentCategories,
  setTreatmentCategories,
  pageTitleId,
  pageDescriptionId,
  treatmentCategoryConfig,
}) => {
  const history = useHistory();

  const validatedTreatmentCategories = Array.isArray(treatmentCategories)
    ? treatmentCategories
    : [];

  const validatedTreatmentCategoryConfig = Array.isArray(treatmentCategoryConfig)
    ? treatmentCategoryConfig
    : [];

  const handleTreatmentCategorySelection = (value) => {
    if (validatedTreatmentCategories.includes(value)) {
      setTreatmentCategories(validatedTreatmentCategories.filter((tc) => tc !== value));
    } else {
      setTreatmentCategories([...validatedTreatmentCategories, value]);
    }
  };

  return (
    <>
      <BackButton to={backButtonLink}>
        <FormattedMessage id="common.button.back" />
      </BackButton>

      {pageTitleId && (
        <PageTitle>
          <FormattedMessage id={pageTitleId} />
        </PageTitle>
      )}

      {pageDescriptionId && (
        <Text style={{ marginBottom: '1.5rem' }}>
          <FormattedMessage id={pageDescriptionId} />
        </Text>
      )}

      {validatedTreatmentCategoryConfig.map((item) => {
        const { label, value } = item || {};
        return (
          <StyledTreatmentCategoryContainer key={value}>
            <Paper>
              <StyledTreatmentCategory>
                <CheckboxOptionContainer onClick={() => handleTreatmentCategorySelection(value)}>
                  <StyledCheckbox
                    type="checkbox"
                    name="treatment_category"
                    value={value}
                    checked={validatedTreatmentCategories.includes(value)}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleTreatmentCategorySelection(value);
                    }}
                  />

                  <RadioLabel>
                    <Text>
                      <FormattedMessage id={label} />
                    </Text>
                  </RadioLabel>
                </CheckboxOptionContainer>
              </StyledTreatmentCategory>
            </Paper>
          </StyledTreatmentCategoryContainer>
        );
      })}

      <ButtonContainer>
        <Button
          variant="primary"
          disabled={validatedTreatmentCategories.length === 0}
          onClick={() => history.push(nextButtonLink)}
          data-testid="btn_treatment_cat_next"
        >
          <FormattedMessage id="common.button.next" />
        </Button>
      </ButtonContainer>
    </>
  );
};

export default SelectTreatmentCategories;
