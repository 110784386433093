import { styled, css } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import { Icon, Paper } from '../common';

export const StyledMissingIbanBannerContainer = styled(Paper)(
  ({ theme, dismissable }) => css`
    position: relative;
    background-color: ${theme.palette.background.info};

    ${dismissable &&
    css`
      border-left: 5px solid;
      border-color: ${theme.palette.main.professional_blue};
      background-color: transparent;
    `};
  `
);

export const StyledMissingIbanBannerBody = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  ${({ dismissable }) =>
    dismissable &&
    css`
      margin-right: 1.75rem;
      flex-direction: row;
      text-align: left;
    `}
`;

export const StyledMissingIbanBannerTitle = styled('div')(
  ({ theme }) => css`
    font-size: 1rem;
    color: ${theme.palette.main.professional_blue};
    margin-bottom: 0.5rem;
    font-weight: 700;
    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.188rem;
    }
  `
);

export const StyledMissingIbanBannerText = styled('span')(
  ({ theme, dismissable, title, noSpacing }) => css`
    font-size: 1rem;
    color: ${theme.palette.main.professional_blue};

    ${dismissable &&
    css`
      color: ${theme.palette.main.professional_light_blue};
    `}

    ${title &&
    css`
      margin-bottom: 0.5rem;
      font-weight: 700;
      color: ${theme.palette.main.professional_blue};
    `}

  ${noSpacing &&
    css`
      margin-bottom: 0;
    `}

  @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.188rem;
    }
  `
);

export const StyledMissingIbanBannerIcon = styled(Icon)`
  width: 1.75rem;
  height: 1.75rem;
  margin-right: 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 1.75rem;
    height: 1.75rem;
  }
`;

export const StyledDissmissButton = styled('button')`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;

  > img {
    width: 0.625rem;
    height: 0.625rem;
  }
`;
