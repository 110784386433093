import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import { imgLinks, useReady } from '../../shared';

import { Container, PageTitle } from '../common';
import FressnapfDesktopSlides from './FressnapfDesktopSlides';
import FressnapfMobileSlides from './FressnapfMobileSlides';
import {
  StyledOnlineVetCoverImage,
  StyledOnlineVetFlexContainer,
} from '../OnlineVets/OnlineVets.styled';
import {
  StyledFressnapfDesktopButton,
  StyledFressnapfMobileButton,
  StyledFressnapfSlideContainer,
} from './Fressnapf.styled';

const Fressnapf = () => {
  const ready = useReady();
  const fressnapfAccount = 'https://dr.fressnapf.de/tierkrankenversicherung-petolo/';

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <StyledFressnapfSlideContainer>
        <Container>
          <PageTitle>
            <FormattedMessage id="fressnapf.header" />
          </PageTitle>

          <StyledOnlineVetFlexContainer>
            <StyledOnlineVetCoverImage src={imgLinks['fressnapf.banner']} alt="Dr. Fressnapf" />

            <StyledFressnapfMobileButton
              variant="primary"
              onClick={() => window.open(fressnapfAccount, '_blank')}
            >
              <FormattedMessage id="fressnapf.button.text" />
            </StyledFressnapfMobileButton>
          </StyledOnlineVetFlexContainer>
        </Container>

        <FressnapfDesktopSlides />

        <Container>
          <StyledFressnapfDesktopButton
            variant="primary"
            onClick={() => window.open(fressnapfAccount, '_blank')}
          >
            <FormattedMessage id="fressnapf.button.text" />
          </StyledFressnapfDesktopButton>

          <FressnapfMobileSlides />
        </Container>
      </StyledFressnapfSlideContainer>
    </CSSTransition>
  );
};

export default Fressnapf;
