import { css, styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

import { Button } from '../Button';

export const StyledSelectUserItemButton = styled(Button)(
  ({ theme, active }) => css`
    display: flex;
    align-items: center;
    padding: 1rem;
    width: 100%;
    background-color: ${theme.palette.common.white};
    box-shadow: ${theme.shadows[1]};
    border-radius: 5px;
    transition: opacity 0.3s ease-in-out;

    & + & {
      margin-top: 1rem;
    }

    ${!active &&
    css`
      opacity: 0.5;
    `}

    &::after {
      content: none;
    }
  `
);

export const StyledUserItemLogo = styled('div')`
  width: 4.5rem;
  height: 4.5rem;
  margin-right: 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 4.5rem;
    height: 4.5rem;
    margin-right: 1.5rem;
  }
`;

export const StyledUserItemName = styled('div')`
  font-size: 1.25rem;
  font-weight: 600;
  text-decoration: underline;
`;

export const StyledUserItemPolicy = styled('div')`
  margin-top: 0.5rem;
  font-size: 1rem;
`;
