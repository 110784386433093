import React from 'react';
import { useTheme } from '@mui/material';

function IconInfoRoundedOutline(props) {
  const { borderColor, infoColor } = props;

  const theme = useTheme();

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4999 8.03618C15.4999 12.1562 12.1442 15.5 7.99988 15.5C3.8556 15.5 0.499878 12.1562 0.499878 8.03618C0.499878 3.91615 3.8556 0.572342 7.99988 0.572342C12.1442 0.572342 15.4999 3.91615 15.4999 8.03618Z"
        stroke={borderColor || theme.palette.main.professional_blue}
      />
      <path
        d="M7.44434 12V6.25409H8.5553V12H7.44434ZM7.19348 4.07997C7.19348 3.84901 7.26914 3.65788 7.42045 3.50657C7.57973 3.34729 7.77086 3.26765 7.99385 3.26765C8.2248 3.26765 8.41593 3.34729 8.56724 3.50657C8.72652 3.65788 8.80616 3.84901 8.80616 4.07997C8.80616 4.30295 8.72652 4.49408 8.56724 4.65336C8.41593 4.80467 8.2248 4.88033 7.99385 4.88033C7.77086 4.88033 7.57973 4.80467 7.42045 4.65336C7.26914 4.49408 7.19348 4.30295 7.19348 4.07997Z"
        fill={infoColor || theme.palette.main.professional_blue}
      />
    </svg>
  );
}

export default IconInfoRoundedOutline;
