import React from 'react';
import { useParams } from 'react-router-dom';
import TreatmentAmount from './TreatmentAmount';
import {
  VITOLO_TREATMENT_AMOUNT,
  useNewVitoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import {
  useTreatmentAmountVitoloBackButtonLink,
  useTreatmentAmountVitoloNextButtonLink,
} from './treatmentAmountVitoloHooks';

export default function TreatmentAmountVitolo() {
  const { claimId } = useParams();

  const [treatmentAmount, setTreatmentAmount] = useNewVitoloFormDataValue(VITOLO_TREATMENT_AMOUNT);

  const backButtonLink = useTreatmentAmountVitoloBackButtonLink(claimId);
  const nextButtonLink = useTreatmentAmountVitoloNextButtonLink(claimId);

  return (
    <TreatmentAmount
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId="vitolo.new_claim.treatment_amount.page.title"
      inputLabelId="vitolo.new_claim.treatment_amount.form.label.amount"
      treatmentAmount={treatmentAmount}
      setTreatmentAmount={setTreatmentAmount}
      errorMessageId="vitolo.new_claim.treatment_amount.form.error.invalid_treatment_amount"
    />
  );
}
