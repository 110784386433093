import React from 'react';
import { FormattedMessage } from 'react-intl';
import SelectExistingClaim from './SelectExistingClaim';
import { formatDate } from '../../../../shared';
import {
  DENTOLO_EXISTING_CLAIM_OPTION_NOT_SELECTED,
  DENTOLO_SELECTED_EXISTING_CLAIM,
  useNewDentoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import {
  useSelectExistingClaimDentoloBackButtonLink,
  useSelectExistingClaimDentoloNextButtonLink,
} from './selectExistingClaimDentoloHooks';

export default function SelectExistingClaimDentolo({ claims }) {
  const [selectedClaimId, setSelectedClaimId] = useNewDentoloFormDataValue(
    DENTOLO_SELECTED_EXISTING_CLAIM
  );

  const backButtonLink = useSelectExistingClaimDentoloBackButtonLink();
  const nextButtonLink = useSelectExistingClaimDentoloNextButtonLink(selectedClaimId);

  const claimsToList =
    Array.isArray(claims) &&
    claims.map((claim) => ({
      id: claim?.id,
      label: claim?.treatmentCategory,
      labelId: `treatment.category.${claim?.treatmentCategory || 'null'}`,
      subLabel: formatDate(claim?.lossDate),
    }));

  return (
    <SelectExistingClaim
      name="existing_claim"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId="dentolo.new_claim.existing_claims.page.title"
      claims={claimsToList}
      onSelectClaim={setSelectedClaimId}
      selectedClaimId={selectedClaimId}
      existingClaimNotSelectedKey={DENTOLO_EXISTING_CLAIM_OPTION_NOT_SELECTED}
      checkboxLabel={
        <FormattedMessage id="dentolo.new_claim.existing_claims.form.label.not_selected" />
      }
    />
  );
}
