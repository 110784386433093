import React from 'react';
import { FormattedMessage } from 'react-intl';
import { StyledMaintenanceBanner, StyledMaintenanceBannerContent } from './Header.styled';

const deadline = Date.parse('2024-02-07T07:30:00.000+01:00');

export default function MaintenanceBanner() {
  const isBeforeDeadline = Date.now() < deadline;

  if (isBeforeDeadline) {
    return (
      <StyledMaintenanceBanner>
        <StyledMaintenanceBannerContent>
          <FormattedMessage id="maintenance_banner.text" />
        </StyledMaintenanceBannerContent>
      </StyledMaintenanceBanner>
    );
  }
  return null;
}
