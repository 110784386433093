import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  VITOLO_EDIT_STEP_REVIEW,
  VITOLO_EDIT_STEP_DOCUMENT_DATE,
  PARAM_CLAIM_ID,
} from '../../edit/editClaimFormSteps';

import { VITOLO_STEP_CUSTOMER_NOTE, VITOLO_STEP_DOCUMENT_DATE } from '../../new/newClaimsFormSteps';

const getBackButtonLinkForExistingClaim = () => {
  return VITOLO_EDIT_STEP_DOCUMENT_DATE;
};

const getBackButtonLinkForNewClaim = () => {
  return VITOLO_STEP_DOCUMENT_DATE;
};

export const useTreatmentAmountVitoloBackButtonLink = (claimId) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (claimId) {
    const backButtonLink = getBackButtonLinkForExistingClaim();
    const backButtonLinkWithClaimId = backButtonLink.replace(PARAM_CLAIM_ID, claimId);
    return withSource(backButtonLinkWithClaimId);
  }

  const backButtonLink = getBackButtonLinkForNewClaim();
  return withSource(backButtonLink);
};

export const useTreatmentAmountVitoloNextButtonLink = (claimId) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (claimId) {
    const nextButtonLink = VITOLO_EDIT_STEP_REVIEW.replace(PARAM_CLAIM_ID, claimId);
    return withSource(nextButtonLink);
  }

  return withSource(VITOLO_STEP_CUSTOMER_NOTE);
};
