import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  DENTOLO_EDIT_STEP_DENTIST_DETAILS,
  DENTOLO_EDIT_STEP_DOCUMENT_DATE,
  DENTOLO_EDIT_STEP_TREATMENT_DATE,
  PARAM_CLAIM_ID,
} from '../../edit/editClaimFormSteps';
import {
  DENTOLO_DOCUMENT_TYPE_INVOICE,
  DENTOLO_DOCUMENT_TYPE_TREATMENT_PLAN,
} from '../../claimFormData/newClaimFormData';
import {
  DENTOLO_STEP_DENTIST_DETAILS,
  DENTOLO_STEP_DOCUMENT_DATE,
  DENTOLO_STEP_TREATMENT_DATE,
} from '../../new/newClaimsFormSteps';

const getBackButtonLinkForExistingClaim = (documentType) => {
  switch (documentType) {
    case DENTOLO_DOCUMENT_TYPE_INVOICE:
      return DENTOLO_EDIT_STEP_TREATMENT_DATE;
    case DENTOLO_DOCUMENT_TYPE_TREATMENT_PLAN:
      return DENTOLO_EDIT_STEP_DOCUMENT_DATE;
    default:
      return DENTOLO_EDIT_STEP_DOCUMENT_DATE;
  }
};

const getBackButtonLinkForNewClaim = (documentType) => {
  switch (documentType) {
    case DENTOLO_DOCUMENT_TYPE_INVOICE:
      return DENTOLO_STEP_TREATMENT_DATE;
    case DENTOLO_DOCUMENT_TYPE_TREATMENT_PLAN:
      return DENTOLO_STEP_DOCUMENT_DATE;
    default:
      return DENTOLO_STEP_DOCUMENT_DATE;
  }
};

export const useTreatmentAmountDentoloBackButtonLink = (claimId, documentType) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (claimId) {
    const backButtonLink = getBackButtonLinkForExistingClaim(documentType);
    const backButtonLinkWithClaimId = backButtonLink.replace(PARAM_CLAIM_ID, claimId);
    return withSource(backButtonLinkWithClaimId);
  }

  const backButtonLink = getBackButtonLinkForNewClaim(documentType);
  return withSource(backButtonLink);
};

export const useTreatmentAmountDentoloNextButtonLink = (claimId) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (claimId) {
    const nextButtonLink = DENTOLO_EDIT_STEP_DENTIST_DETAILS.replace(PARAM_CLAIM_ID, claimId);
    return withSource(nextButtonLink);
  }

  return withSource(DENTOLO_STEP_DENTIST_DETAILS);
};
