/* eslint-disable no-console */

import { useCallback } from 'react';

const useConsole = () => {
  const consoleLog = useCallback((...args) => {
    console.log(...args);
  }, []);

  const consoleError = useCallback((...args) => {
    console.error(...args);
  }, []);

  const consoleWarn = useCallback((...args) => {
    console.warn(...args);
  }, []);

  return { consoleError, consoleLog, consoleWarn };
};

export default useConsole;
