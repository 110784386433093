import { PET_HEALTH_TREATMENT_CATEGORIES_WITH_BUDGET } from '../../claimFormData/newClaimFormData';
import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PETOLO_STEP_REASON_FOR_TREATMENT,
  PETOLO_STEP_UPLOAD_DOCUMENTS,
  PET_HEALTH_STEP_TREATMENT_CATEGORIES,
} from '../../new/newClaimsFormSteps';

export const useSelectDocumentTypePetoloBackButtonLink = (treatmentCategories) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (treatmentCategories?.length === 1) {
    const treatmentCategory = treatmentCategories[0];

    if (PET_HEALTH_TREATMENT_CATEGORIES_WITH_BUDGET.includes(treatmentCategory)) {
      return withSource(PETOLO_STEP_REASON_FOR_TREATMENT);
    }
  }

  return withSource(PET_HEALTH_STEP_TREATMENT_CATEGORIES);
};

export const useSelectDocumentTypePetoloNextButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();
  return withSource(PETOLO_STEP_UPLOAD_DOCUMENTS);
};
