import React from 'react';

import {
  PET_LIABILITY_REASON_ITEM,
  PET_LIABILITY_REASON_ANOTHER_ANIMAL,
  PET_LIABILITY_REASON_VEHICLE,
  PET_LIABILITY_REASON_PROPERTY,
  PET_LIABILITY_REASON_PERSON,
  PET_LIABILITY_REASON_OTHER,
  useNewPetLiabilityFormDataValue,
  PET_LIABILITY_REASON,
} from '../../claimFormData/newClaimFormDataPetLiability';
import {
  useLiabilityReasonPetLiabilityBackButtonLink,
  useLiabilityReasonPetLiabilityNextButtonLink,
} from './useLiabilityReasonPetLiability';

import ListWithRadioButton from '../common/ListWithRadioButton';

const petLiabilityReasonConfig = [
  {
    label: 'pet_liability.new_claim.liability_reason.item',
    value: PET_LIABILITY_REASON_ITEM,
  },
  {
    label: 'pet_liability.new_claim.liability_reason.another_animal',
    value: PET_LIABILITY_REASON_ANOTHER_ANIMAL,
  },
  {
    label: 'pet_liability.new_claim.liability_reason.vehicle',
    value: PET_LIABILITY_REASON_VEHICLE,
  },
  {
    label: 'pet_liability.new_claim.liability_reason.property',
    value: PET_LIABILITY_REASON_PROPERTY,
  },
  {
    label: 'pet_liability.new_claim.liability_reason.person',
    value: PET_LIABILITY_REASON_PERSON,
  },
  {
    label: 'pet_liability.new_claim.liability_reason.other',
    value: PET_LIABILITY_REASON_OTHER,
  },
];

function LiabilityReasonPetLiability() {
  const [liabilityReason, setLiabilityReason] =
    useNewPetLiabilityFormDataValue(PET_LIABILITY_REASON);

  const backButtonLink = useLiabilityReasonPetLiabilityBackButtonLink();
  const nextButtonLink = useLiabilityReasonPetLiabilityNextButtonLink();

  return (
    <ListWithRadioButton
      name="reason_pet_liability"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId="pet_liability.new_claim.liability_reason.page_title"
      listConfig={petLiabilityReasonConfig}
      selected={liabilityReason}
      setSelected={setLiabilityReason}
    />
  );
}

export default LiabilityReasonPetLiability;
