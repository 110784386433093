import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown';

import { showMoney } from '../../shared';

import CheckIcon from './CheckIcon';
import { StyledFlexContainer } from '../styled/PolicyInfo.styled';
import {
  StyledPolicyDetailsList,
  StyledPolicyDetailsTextLg,
  StyledPolicyDetailsText,
} from '../styled/PolicyDetails.styled';

const getBudgetLabel = (year, firstYear, isFirst, isLast, intl) => {
  const prefix = intl.formatMessage({ id: 'policy.all_details.budget.prefix' });
  const suffix = intl.formatMessage({ id: 'policy.all_details.budget.suffix' });

  if (isFirst && isLast) {
    const onlyPrefix = intl.formatMessage({ id: 'policy.all_details.budget.only_prefix' });
    return `${onlyPrefix} ${year}. ${suffix}`;
  }

  if (isFirst) {
    return `${prefix} ${year}. ${suffix}`;
  }

  if (isLast) {
    const lastPrefix = intl.formatMessage({ id: 'policy.all_details.budget.last_prefix' });
    return `${lastPrefix} ${year}. ${suffix}`;
  }

  return `${prefix} ${firstYear}.-${year}. ${suffix}`;
};

const Budget = ({ budgetInfo, budgetAmountInfo, hasAddon }) => {
  const intl = useIntl();

  if (!budgetInfo) return null;

  const { title, text, text_addon: textAddon } = budgetInfo || {};

  return (
    <StyledPolicyDetailsList alignItems="flex-start" style={{ marginBottom: '1rem' }}>
      <CheckIcon />
      <div style={{ width: '100%' }}>
        <StyledPolicyDetailsTextLg>{title || ''}</StyledPolicyDetailsTextLg>

        <div>
          {Array.isArray(budgetAmountInfo) &&
            budgetAmountInfo.map(({ year, amountCents }, index) => {
              const { year: firstYear } = budgetAmountInfo[0];
              const isFirst = index === 0;
              const isLast = index === budgetAmountInfo.length - 1;
              const amountCentsInt = parseInt(amountCents, 10);
              const unlimited = Number.isNaN(amountCentsInt);

              return (
                <StyledFlexContainer marginTop="1rem" key={year}>
                  <StyledPolicyDetailsText>
                    {getBudgetLabel(year, firstYear, isFirst, isLast, intl)}
                  </StyledPolicyDetailsText>

                  <StyledPolicyDetailsTextLg>
                    {unlimited ? (
                      <FormattedMessage id="policy.all_details.budget.unlimited" />
                    ) : (
                      showMoney(amountCentsInt / 100)
                    )}
                  </StyledPolicyDetailsTextLg>
                </StyledFlexContainer>
              );
            })}
        </div>

        <StyledPolicyDetailsText style={{ marginTop: '1rem' }}>
          <ReactMarkdown linkTarget="_blank">
            {!hasAddon ? text || '' : textAddon || ''}
          </ReactMarkdown>
        </StyledPolicyDetailsText>
      </div>
    </StyledPolicyDetailsList>
  );
};

export default Budget;
