import React from 'react';

export default function IllustrationMobileDentolo() {
  return (
    <svg
      width="310"
      height="174"
      viewBox="0 0 310 174"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1036_4227)" filter="url(#filter0_d_1036_4227)">
        <path
          d="M30.1093 162C30.1093 162 5.76648 163 14.396 140.146C27.7657 101.457 46.6433 84.7508 89.6982 80.5303C116.23 77.9276 125.047 61.2538 156.508 36.7476C223.799 -15.6683 315.244 5.69041 297.839 124.489C294.175 149.5 294.175 150.5 293.646 162H30.1093Z"
          fill="white"
        />
        <path
          d="M14.2335 141C16.3503 135 19.0827 135 23.7589 135H296.292L296.821 159C296.821 161.761 294.452 164 291.529 164H14.2335C11.467 164 10.1446 161.994 10.0113 159.438C9.9961 159.147 10.0254 158.856 10.076 158.569L11.5876 150L14.2335 141Z"
          fill="#F7F7F8"
        />
        <ellipse cx="152.352" cy="150" rx="64.032" ry="7" fill="#E7E7E8" />
        <path
          d="M126.424 18.1206C118.169 19.0811 111.472 26.5055 100.322 27.8035C89.1728 29.0884 81.0216 23.8576 72.7535 24.8052C56.4251 26.7002 41.2649 50.6606 45.8208 69.0787C50.9088 89.6125 80.4375 146.139 96.4673 144.283C104.372 143.374 102.516 127.669 104.619 110.341C105.579 102.423 109.161 101.074 112.082 110.107C117.546 127.007 122.245 141.297 129.656 140.441C145.673 138.585 162.547 72.5443 162.819 55.5409C163.144 35.3057 142.182 16.3035 126.437 18.1206"
          fill="white"
        />
        <path
          d="M85.1491 32.1781C89.64 35.0337 95.0915 35.3452 100.141 33.8395C106.176 32.0354 112.627 27.9727 114.301 22.4304C110.265 24.7798 105.865 27.155 100.335 27.791C89.7698 29.0111 81.9042 24.5202 74.0645 24.7148C77.8285 27.6482 81.0865 29.6082 85.1491 32.1781Z"
          fill="#DDF1FB"
        />
        <path
          d="M143.882 106.63C141.39 111.458 133.901 118.078 127.437 117.105C121.077 116.144 119.545 107.552 117.793 102.632C116.962 100.322 116.106 96.1684 113.406 95.3247C110.706 94.481 107.474 95.0521 105.216 96.7395C100.673 100.127 97.4148 106.344 97.2072 112.03C97.0125 117.338 96.9216 124.84 90.2111 125.762C86.6807 126.242 84.4222 122.569 82.0729 120.414C78.2699 116.923 61.1627 95.8179 46.6644 72.0522C53.6085 94.8574 81.1643 146.049 96.4803 144.284C104.385 143.362 102.529 127.67 104.632 110.342C105.592 102.425 109.174 101.075 112.095 110.109C117.559 127.008 122.258 141.299 129.669 140.442C141.247 139.105 153.721 106.007 159.328 79.2949C154.98 91.6255 149.372 96.0516 143.908 106.63"
          fill="#DDF1FB"
        />
        <path
          d="M126.424 18.1206C118.169 19.0811 111.472 26.5055 100.322 27.8035C89.1728 29.0884 81.0216 23.8576 72.7535 24.8052C56.4251 26.7002 41.2649 50.6606 45.8208 69.0787C50.9088 89.6125 80.4375 146.139 96.4673 144.283C104.372 143.374 102.516 127.669 104.619 110.341C105.579 102.423 109.161 101.074 112.082 110.107C117.546 127.007 122.245 141.297 129.656 140.441C145.673 138.585 162.547 72.5443 162.819 55.5409C163.144 35.3057 142.182 16.3035 126.437 18.1206H126.424Z"
          stroke="#003363"
          strokeMiterlimit="10"
        />
        <path
          d="M112.277 55.5679C111.705 54.1012 110.459 53.1277 109.148 53.2834C107.825 53.4392 106.838 54.6593 106.617 56.2298M98.2845 57.1903C97.7134 55.7236 96.4673 54.7501 95.1564 54.9059C93.8325 55.0616 92.846 56.2817 92.6254 57.8523"
          stroke="#003363"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M105.839 86.459C106.708 86.2903 106.89 86.3941 107.747 86.5629C107.993 86.6148 109.434 87.1859 109.525 87.0561C109.655 86.8744 108.357 86.0307 107.902 85.9009C106.903 85.6024 106.15 85.6413 105.358 85.8749C104.372 86.1735 104.735 86.6797 105.839 86.459Z"
          fill="#001C36"
        />
        <path
          d="M93.3652 69.4434C93.3652 69.4434 93.3652 69.5213 93.3652 69.5603C94.2219 76.9457 100.673 82.2673 107.786 81.4496C114.885 80.6319 119.961 73.9733 119.104 66.5879C119.104 66.549 119.104 66.51 119.091 66.4711L93.3652 69.4434Z"
          fill="#33FF95"
        />
        <path
          d="M90.7563 68.3267C90.7563 68.3267 90.7563 68.4046 90.7563 68.4436C91.6129 75.829 98.0638 81.1506 105.177 80.3329C112.277 79.5152 117.352 72.8566 116.495 65.4712C116.495 65.4323 116.495 65.3933 116.482 65.3544L90.7563 68.3267Z"
          stroke="#003363"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M223.483 27.637C216.136 26.326 208.478 30.9987 198.562 29.2335C188.659 27.4682 182.999 20.8616 175.653 19.5636C161.129 16.9807 141.932 33.6595 141.114 50.6629C140.193 69.6261 151.018 125.789 165.256 128.32C172.291 129.566 174.731 115.6 181.001 101.257C183.856 94.7028 187.296 94.4691 187.477 102.971C187.815 118.897 188.178 132.396 194.759 133.564C208.998 136.095 240.512 83.6961 245.12 69.1589C250.61 51.857 237.488 30.1291 223.496 27.637"
          fill="white"
        />
        <path
          d="M199.484 39.1371C204.195 40.5 208.401 40.0457 213.216 39.5525C217.889 39.0722 222.912 39.176 226.806 42.1873C234.762 48.3267 236.904 60.4107 236.93 69.8729C236.943 75.558 236.644 81.3079 234.827 86.7334C234.269 88.3819 233.594 89.9654 232.828 91.51C237.709 84.1894 243.679 74.4547 245.081 67.5236C246.846 58.7493 245.042 48.5084 240.214 40.3053C237.151 35.1134 229.674 28.7144 223.885 27.8967C216.734 26.8973 215.124 27.6371 206.376 29.5841C197.212 31.6219 192.163 30.272 185.193 26.9882C184.388 26.6117 183.571 26.2873 182.766 26.0147C186.569 32.0372 192.838 37.2161 199.497 39.1371"
          fill="white"
        />
        <path
          d="M184.388 29.0902C187.517 32.6985 192.111 34.3729 196.836 34.3729C202.495 34.3729 209.076 32.5558 211.944 28.2206C207.869 29.194 203.468 30.1026 198.562 29.233C189.165 27.5586 183.571 21.6658 176.782 19.8227C179.261 23.3142 181.559 25.8323 184.388 29.0902Z"
          fill="#DDF1FB"
        />
        <path
          d="M215.695 108.189C212.308 111.693 204.169 115.457 198.861 112.952C193.643 110.499 194.551 102.711 194.305 98.0386C194.188 95.832 194.525 92.0549 192.423 90.6401C190.32 89.2254 187.4 88.8749 185.011 89.7446C180.235 91.4838 175.835 95.9878 174.186 100.816C172.655 105.32 170.643 111.745 164.633 110.811C161.479 110.317 160.48 106.579 159.013 104.113C156.638 100.141 147.383 77.5826 141.049 53.4405C141.14 74.831 151.641 125.906 165.243 128.333C172.278 129.579 174.718 115.613 180.988 101.27C183.843 94.7158 187.283 94.4821 187.465 102.984C187.802 118.91 188.165 132.409 194.746 133.577C205.039 135.407 224.275 110.175 235.969 88.6673C229.064 98.1424 223.107 100.505 215.682 108.189"
          fill="#DDF1FB"
        />
        <path
          d="M209.828 29.5573C209.828 29.5573 209.478 29.6093 208.881 29.7001C208.582 29.752 208.206 29.778 207.804 29.8818C207.401 29.9727 206.934 30.0636 206.441 30.1804C205.428 30.388 204.234 30.6995 202.936 30.9332C202.287 31.05 201.625 31.2058 200.937 31.2966C200.249 31.3875 199.548 31.4783 198.861 31.5562C198.173 31.673 197.459 31.6471 196.771 31.699C196.083 31.712 195.408 31.8028 194.746 31.7509C194.084 31.7249 193.461 31.686 192.851 31.6601C192.254 31.6211 191.683 31.6341 191.164 31.5302C189.087 31.2187 187.711 31.0111 187.711 31.0111C187.711 31.0111 189.1 31.1538 191.177 31.3615C191.696 31.4394 192.267 31.3875 192.864 31.4004C193.461 31.4004 194.084 31.4004 194.746 31.4004C195.395 31.4004 196.07 31.2966 196.745 31.2447C197.42 31.1668 198.121 31.1538 198.796 31.0111C199.484 30.8943 200.159 30.7645 200.833 30.6476C201.508 30.5178 202.157 30.3361 202.78 30.1933C204.052 29.9078 205.208 29.5314 206.233 29.2718C206.752 29.129 207.219 29.0122 207.648 28.8954C208.063 28.7786 208.427 28.7266 208.725 28.6617C209.322 28.5449 209.66 28.4671 209.66 28.4671C209.958 28.4022 210.257 28.5968 210.309 28.9084C210.374 29.2069 210.179 29.5054 209.867 29.5573H209.828Z"
          fill="#001C36"
        />
        <path
          d="M223.483 27.637C216.136 26.326 208.478 30.9987 198.562 29.2335C188.659 27.4682 182.999 20.8616 175.653 19.5636C161.129 16.9807 141.932 33.6595 141.114 50.6629C140.193 69.6261 151.018 125.789 165.256 128.32C172.291 129.566 174.731 115.6 181.001 101.257C183.856 94.7028 187.296 94.4691 187.477 102.971C187.815 118.897 188.178 132.396 194.759 133.564C208.998 136.095 240.512 83.696 245.12 69.1589C250.61 51.857 237.488 30.1291 223.496 27.637H223.483Z"
          stroke="#003363"
          strokeMiterlimit="10"
        />
        <path
          d="M201.69 56.1791C201.573 54.7644 200.756 53.6092 199.587 53.4015C198.419 53.1938 197.251 53.9856 196.654 55.2835M189.243 53.9596C189.126 52.5448 188.308 51.4026 187.14 51.182C185.972 50.9743 184.804 51.766 184.207 53.064"
          stroke="#003363"
          strokeWidth="2.02"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M188.191 81.0485C188.983 81.1264 189.113 81.2692 189.814 81.6326C190.008 81.7364 191.099 82.606 191.216 82.5152C191.371 82.3984 190.476 81.334 190.125 81.1004C189.334 80.5812 188.698 80.4255 187.945 80.4255C187.01 80.4255 187.205 80.9576 188.204 81.0485"
          fill="#001C36"
        />
        <path
          d="M181.87 63.2274C181.87 63.2274 181.857 63.2923 181.844 63.3313C180.676 69.899 184.856 76.1292 191.164 77.2584C197.485 78.3877 203.546 73.9746 204.715 67.3939C204.715 67.355 204.714 67.329 204.727 67.2901L181.857 63.2274H181.87Z"
          fill="#33FF95"
        />
        <path
          d="M179.91 61.605C179.91 61.605 179.897 61.6699 179.884 61.7088C178.716 68.2765 182.896 74.5067 189.204 75.636C195.525 76.7652 201.586 72.3521 202.755 65.7844C202.755 65.7455 202.755 65.7195 202.768 65.6806L179.897 61.618L179.91 61.605Z"
          stroke="#003363"
          strokeWidth="2.02"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M175.529 42.0812L168.78 73.8339L207.846 82.1375L214.595 50.3848L175.529 42.0812Z"
          fill="white"
        />
        <path
          d="M204.104 70.9747C203.391 75.5046 197.472 78.0875 190.943 76.7117C183.012 75.0243 180.442 68.9888 180.871 66.4059C181.689 61.4606 187.166 64.8872 193.747 66.0424C200.314 67.2106 204.74 66.925 204.104 70.9877"
          fill="#33FF95"
        />
        <path
          d="M192.669 64.1344C189.295 63.5373 186.205 62.3821 183.856 62.0187C184.583 63.9656 187.698 65.9645 191.722 66.8601C195.745 67.7557 199.419 67.2754 200.911 65.8217C198.9 65.0819 195.966 64.7184 192.669 64.1344Z"
          fill="white"
        />
        <path
          d="M203.001 69.2623C202.287 73.7921 196.369 76.3751 189.84 74.9993C181.909 73.3119 179.339 67.2764 179.768 64.6934C180.585 59.7482 186.063 63.1748 192.643 64.33C199.211 65.4982 203.637 65.2126 203.001 69.2752"
          stroke="#003363"
          strokeMiterlimit="10"
        />
        <path
          d="M187.348 53.4264C187.205 54.3999 186.283 55.0748 185.31 54.9191C184.336 54.7763 183.661 53.8547 183.817 52.8813C183.96 51.9078 184.882 51.2329 185.855 51.3886C186.828 51.5314 187.503 52.4529 187.348 53.4264Z"
          fill="#003363"
        />
        <path
          d="M185.232 51.934C185.18 52.2844 184.856 52.5181 184.518 52.4661C184.168 52.4142 183.934 52.0897 183.986 51.7523C184.038 51.4018 184.362 51.1682 184.7 51.2201C185.05 51.272 185.284 51.5965 185.232 51.934Z"
          fill="white"
        />
        <path
          d="M203.416 55.8538C203.274 56.8273 202.352 57.5022 201.379 57.3464C200.405 57.2037 199.73 56.2821 199.886 55.3086C200.029 54.3352 200.95 53.6602 201.924 53.816C202.897 53.9587 203.572 54.8803 203.416 55.8538Z"
          fill="#003363"
        />
        <path
          d="M201.301 54.374C201.249 54.7244 200.924 54.9581 200.587 54.9061C200.237 54.8542 200.003 54.5298 200.055 54.1923C200.107 53.8418 200.431 53.6082 200.769 53.6601C201.119 53.712 201.353 54.0365 201.301 54.374Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1036_4227"
          x="0"
          y="0"
          width="310"
          height="174"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1036_4227" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1036_4227"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_1036_4227">
          <rect width="290" height="154" fill="white" transform="translate(10 10)" />
        </clipPath>
      </defs>
    </svg>
  );
}
