import { styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../../theme/breakpoints';

import { StyledReferralCoverImageContainer } from '../ReferralPage.styled';

export const StyledDentoloReferralStatusEmptyContainer = styled('div')`
  text-align: center;
`;

export const StyledDentoloReferralStatusEmptyTitle = styled('div')`
  font-size: 1.25rem;
  font-weight: 700;

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.5rem;
  }
`;

export const StyledDentoloReferralStatusEmptyDescription = styled('div')`
  font-size: 1rem;
  font-weight: 450;
`;

export const StyledDentoloReferralStatusEmptyImageContainer = styled(
  StyledReferralCoverImageContainer
)`
  margin: 1rem auto 2.25rem;
  padding: 0;
  width: 260px;
  height: 100px;
  overflow: hidden;
`;

export const StyledDentoloReferralStatusEmptyImg = styled('img')`
  max-width: 100%;
  width: 100%;
  height: auto;
`;
