import React, { useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';

import { useTrackDtEvent } from '../../DtlTracker';
import { GET_DENTISTS, GET_CUSTOMER, googleMapsKey } from '../../shared';

import { StyledDentistsMapUI } from './styled/DentistsMap.styled';

const setDentistsMarkers = (map, dentists, setDentist, onMarkerClick) => {
  if (!Array.isArray(dentists) || dentists.length < 1) return [];

  const markers = [];
  const locationIconUrl = 'https://cdn.faircare.de/maps/markers/icon.geomarker-64x64@1x.png';

  dentists
    .filter(({ latitude, longitude }) => latitude && longitude)
    .forEach((dentist) => {
      const { latitude: lat, longitude: long } = dentist;

      const latLng = new window.google.maps.LatLng(lat, long);
      const marker = new window.google.maps.Marker({
        map,
        icon: locationIconUrl,
        position: latLng,
      });

      marker.addListener('click', () => {
        if (onMarkerClick) onMarkerClick(dentist);

        map.panTo(marker.getPosition());
        setDentist(dentist);
      });

      markers.push(marker);
    });

  return markers;
};

const setDentistsClusters = (map, markers) => {
  const markerCluster = new window.MarkerClusterer(map, markers, {
    maxZoom: 20,
    imagePath: 'https://cdn.faircare.de/maps/markers/m',
  });

  return markerCluster;
};

const countVisibleMarkers = (map, markers) => {
  const bounds = map.getBounds();

  const count = markers.reduce((acc, marker) => {
    return bounds.contains(marker.getPosition()) ? acc + 1 : acc;
  }, 0);

  return count;
};

const zoomToDentists = (map, markers, currentZoom, onEnd) => {
  map.setZoom(currentZoom);

  setTimeout(() => {
    const visibleDentistsCount = countVisibleMarkers(map, markers);

    if (currentZoom > 5 && visibleDentistsCount <= 0) {
      zoomToDentists(map, markers, currentZoom - 1, onEnd);
    } else if (visibleDentistsCount > 0) {
      setTimeout(() => onEnd(), 800);
    }
  });
};

const centerMapByPostcode = (map, markers, postcode, setReady) => {
  if (!window?.google?.maps?.Geocoder) return;

  const geocoder = new window.google.maps.Geocoder();

  const geoCodeParams = {
    address: postcode,
    componentRestrictions: { country: 'DE' },
  };

  geocoder.geocode(geoCodeParams, (results, status) => {
    if (status === window?.google?.maps?.GeocoderStatus?.OK && results?.length) {
      const { location } = results[0]?.geometry || {};

      if (location) {
        map.panTo(location);
        zoomToDentists(map, markers, 14, () => setReady(true));
      }
    } else {
      setReady(true);
    }
  });
};

const onMapLoad = (client, map, setDentist, setReady, onMarkerClick) => {
  const getCustomer = client.query({ query: GET_CUSTOMER });
  const getDentists = client.query({ query: GET_DENTISTS });

  Promise.all([getDentists, getCustomer])
    .then(([dentistsData, customerData]) => {
      const dentists = dentistsData?.data?.dentists || [];
      const { postcode } = customerData?.data?.customer || {};
      const markers = [...setDentistsMarkers(map, dentists, setDentist, onMarkerClick)];

      setDentistsClusters(map, markers);
      centerMapByPostcode(map, markers, postcode, setReady);
    })
    .catch();
};

const onScriptLoad = (id, options, client, setDentist, setReady, onMarkerClick) => {
  if (!window?.google?.maps) return;

  const map = new window.google.maps.Map(document.getElementById(id), options);
  onMapLoad(client, map, setDentist, setReady, onMarkerClick);
};

const loadMapClusters = (id, options, client, setDentist, setReady, onMarkerClick) => {
  if (typeof window.MarkerClusterer === 'function') {
    onScriptLoad(id, options, client, setDentist, setReady, onMarkerClick);
  } else {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = `https://cdn.faircare.de/maps/markerclustererplus.js`;
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);

    s.addEventListener('load', () => {
      onScriptLoad(id, options, client, setDentist, setReady, onMarkerClick);
    });
  }
};

const loadGoogleMaps = (id, options, client, setDentist, setReady, onMarkerClick) => {
  if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
    loadMapClusters(id, options, client, setDentist, setReady, onMarkerClick);
  } else {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = `https://maps.google.com/maps/api/js?key=${googleMapsKey}`;
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);

    s.addEventListener('load', () => {
      loadMapClusters(id, options, client, setDentist, setReady, onMarkerClick);
    });
  }
};

const DentistsMapUI = (props) => {
  const { id, options, setDentist, setReady, customer, ...rest } = props;

  const client = useApolloClient();
  const [sendUserData] = useTrackDtEvent();
  const trackDtEvent = useCallback(
    (dentist = '') => {
      const trackData = {
        userData: {
          eventType: 'click dentist marker',
          time: new Date().toISOString(),
          url: window?.location?.href,
          uuid: customer?.uuid,
          dentist: dentist?.referenceKey,
        },
      };
      sendUserData(trackData);
    },
    [customer, sendUserData]
  );

  useEffect(() => {
    if (!window.google) {
      loadGoogleMaps(id, options, client, setDentist, setReady, trackDtEvent);
    } else {
      loadMapClusters(id, options, client, setDentist, setReady, trackDtEvent);
    }
  }, [id, options, client, setDentist, setReady, trackDtEvent]);

  return <StyledDentistsMapUI id={id} {...rest} />;
};

DentistsMapUI.propTypes = {
  /** The id of the element that the map will init */
  id: PropTypes.string.isRequired,
  // options: PropTypes.object,
  /** We are using setDentist here to set a dentist on each marker's click event */
  setDentist: PropTypes.func,
  /** Use setReady to hide the Loading component and show the map */
  setReady: PropTypes.func,
  customer: PropTypes.shape({
    uuid: PropTypes.string,
  }),
  options: PropTypes.shape({
    clickableIcons: PropTypes.bool,
    fullscreenControl: PropTypes.bool,
    mapTypeControl: PropTypes.bool,
    mapTypeId: PropTypes.string,
    streetViewControl: PropTypes.bool,
    zoom: PropTypes.number,
    center: PropTypes.shape({
      lat: PropTypes.number,
      lng: PropTypes.number,
    }),
  }),
};

export default DentistsMapUI;
