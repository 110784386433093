import React from 'react';
import { useParams } from 'react-router-dom';

import SelectDocumentCreationDate from './SelectDocumentCreationDate';
import {
  VITOLO_DOCUMENT_DATE,
  useNewVitoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import {
  useSelectDocumentCreationDateVitoloBackButtonLink,
  useSelectDocumentCreationDateVitoloNextButtonLink,
} from './selectDocumentCreationDateVitoloHooks';

const SelectDocumentCreationDateVitolo = () => {
  const { claimId } = useParams();

  const backButtonLink = useSelectDocumentCreationDateVitoloBackButtonLink(claimId);
  const nextButtonLink = useSelectDocumentCreationDateVitoloNextButtonLink(claimId);

  const [documentCreationDate, setDocumentCreationDate] =
    useNewVitoloFormDataValue(VITOLO_DOCUMENT_DATE);

  return (
    <SelectDocumentCreationDate
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      documentCreationDate={documentCreationDate}
      setDocumentCreationDate={setDocumentCreationDate}
      pageTitleId="vitolo.new_claim.select_document_creation_date.page_title"
      formDescription="vitolo.new_claim.select_document_creation_date.form_description"
    />
  );
};

export default SelectDocumentCreationDateVitolo;
