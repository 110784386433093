import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PET_LIABILITY_STEP_DAMAGE_ESTIMATE,
  PET_LIABILITY_STEP_INCIDENT_NOTE,
} from '../../new/newClaimsFormSteps';

export const useDamagedItemPetLiabilityBackButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_INCIDENT_NOTE);
};

export const useDamagedItemPetLiabilityNextButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_DAMAGE_ESTIMATE);
};
