import { styled } from '@mui/material';

export const StyledRadioContainer = styled('div')`
  margin-top: 1rem;
`;

export const StyledListItem = styled('div')`
  display: flex;
  word-break: break-word;
`;
