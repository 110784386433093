import { useEffect, useState } from 'react';

import { APP_ENV } from '../shared';

const useFrontendBranchName = () => {
  const [frontendBranchName, setFrontendBranchName] = useState('');

  useEffect(() => {
    if (APP_ENV !== 'beta') return;

    fetch('/branch-info.json')
      .then((response) => response.json())
      .then((json) => {
        const deployedBranchName = json?.branch_name;

        if (deployedBranchName) {
          setFrontendBranchName(deployedBranchName);
        }
      })
      .catch(() => null);
  }, []);

  return frontendBranchName;
};

export default useFrontendBranchName;
