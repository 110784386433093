import { gql } from '@apollo/client';

export const CREATE_PETOLO_LEAD_WITH_CUSTOMER_DATA = gql`
  mutation {
    createLeadWithCustomerData(input: {}) {
      uuid
      errors
    }
  }
`;

export const CREATE_DENTOLO_LEAD_WITH_CUSTOMER_DATA = gql`
  mutation {
    insureAnotherPerson(input: {}) {
      uuid
      errors
    }
  }
`;
