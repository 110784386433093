const globalStyles = (theme) => `
  * {
    box-sizing: border-box;
    box-shadow: none;
    outline: none;
    font-family: ${theme.typography.fontFamily};
  }

  html {
    height: 100%;
    margin: 0;
    font-size: 16px;
    font-weight: 450;
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
    color: ${theme.palette.main.professional_blue};
    background-color: ${theme.palette.background.viewport};
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    transition: background .2s ease-in-out;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0
  }

  fieldset {
    padding: 0;
    margin: 0;
    border: none;
  }

  textarea {
    resize: none;
  }
`;

export default globalStyles;
