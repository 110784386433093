import {
  DA_DIREKT_REFERRAL_PAGE_LINK_DENTAL,
  DA_DIREKT_REFERRAL_PAGE_LINK_PET,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_HEALTH,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
} from '../../shared';

export const SUBJECT_OPTION_REFERRAL = 'Fragen zum Freund:innen Werbeprogramm';

export const SUBJECTS_DEFAULT = [
  'Fragen zum Vertrag',
  'Monatsbeitrag',
  'Fragen zur Erstattung oder Auszahlung',
  'Abdeckung durch Tarife',
  'Änderung von Kontakt- oder Vertragsdaten',
  'Sonstiges',
];

export const SUBJECTS_DENTAL = [
  'Fragen zum Vertrag',
  'Monatsbeitrag',
  'Fragen zur Erstattung oder Auszahlung',
  'dentolo Zahnärzte',
  'Abdeckung durch Tarife',
  'Änderung von Kontakt- oder Vertragsdaten',
  SUBJECT_OPTION_REFERRAL,
  'Sonstiges',
];

export const SUBJECTS_PET_HEALTH = [
  'Fragen zum Vertrag',
  'Monatsbeitrag',
  'Fragen zur Erstattung oder Auszahlung',
  'Abdeckung durch Tarife',
  'Änderung von Kontakt- oder Vertragsdaten',
  SUBJECT_OPTION_REFERRAL,
  'Sonstiges',
];

export const SUBJECTS_PET_LIABILITY = [...SUBJECTS_DEFAULT];

export const SUBJECTS_HEALTH = [...SUBJECTS_DEFAULT];

export const formSubjectsMap = {
  [INSURANCE_CATEGORY_DENTAL]: SUBJECTS_DENTAL,
  [INSURANCE_CATEGORY_PET_HEALTH]: SUBJECTS_PET_HEALTH,
  [INSURANCE_CATEGORY_PET_LIABILITY]: SUBJECTS_PET_LIABILITY,
  [INSURANCE_CATEGORY_HEALTH]: SUBJECTS_HEALTH,
};

export const DEFAULT_PHONE_ICON = 'icon.telephone.blue.filled';
export const phoneIconMap = {
  [INSURANCE_TYPE_DENTOLO]: 'icon.telephone.blue.filled',
  [INSURANCE_TYPE_PETOLO]: 'icon.telephone.orange.filled',
  [INSURANCE_TYPE_VITOLO]: 'icon.telephone.filled.violet',
};

export const getForwardToDaDirektValue = (subject) => {
  if (subject === SUBJECT_OPTION_REFERRAL) return 'referral';

  return null;
};

export const daDirektReferralPageLinkMap = {
  [INSURANCE_CATEGORY_DENTAL]: DA_DIREKT_REFERRAL_PAGE_LINK_DENTAL,
  [INSURANCE_CATEGORY_PET_HEALTH]: DA_DIREKT_REFERRAL_PAGE_LINK_PET,
};
