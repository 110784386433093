import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import { PET_LIABILITY_STEP_UPLOAD_DOCUMENTS } from '../../new/newClaimsFormSteps';

export const useSubmitClaimFormPetLiabilityBackButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_UPLOAD_DOCUMENTS);
};

export const useEditDocumentsLinkPetLiability = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_UPLOAD_DOCUMENTS);
};
