import { useEffect } from 'react';

function useDynamicLink(links = []) {
  useEffect(() => {
    if (!Array.isArray(links) || links.length < 1) {
      return null;
    }

    // Filter out invalid links
    const validLinks = links.filter(({ href }) => !!href && typeof href === 'string');

    // Add valid links to the document head
    const linksToUse = validLinks.map((item) => {
      const { href, rel = 'stylesheet', ...attributes } = item;

      // Check if the link is already added
      const existingLink = Array.from(document.head.querySelectorAll('link')).find(
        (link) => link.href === href
      );

      // skip duplicate links
      if (existingLink) return null;

      const link = document.createElement('link');
      link.href = href;
      link.rel = rel;

      // Set additional attributes
      Object.entries(attributes).forEach(([key, value]) => {
        link.setAttribute(key, value);
      });

      document.head.appendChild(link);
      return link;
    });

    // Cleanup function to remove all added CSS files
    return () => {
      linksToUse.forEach((link) => {
        if (document.head.contains(link)) {
          document.head.removeChild(link);
        }
      });
    };
  }, [links]); // Re-run only if `links` changes
}

export default useDynamicLink;
