import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  DENTOLO_EDIT_STEP_DENTIST_DETAILS,
  DENTOLO_EDIT_STEP_REVIEW,
  PARAM_CLAIM_ID,
} from '../../edit/editClaimFormSteps';
import { DENTOLO_STEP_DENTIST_DETAILS, DENTOLO_STEP_REVIEW } from '../../new/newClaimsFormSteps';

export const useFillingCountBackButtonLink = (claimId) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (claimId) {
    const backButtonLink = DENTOLO_EDIT_STEP_DENTIST_DETAILS.replace(PARAM_CLAIM_ID, claimId);
    return withSource(backButtonLink);
  }

  return withSource(DENTOLO_STEP_DENTIST_DETAILS);
};

export const useFillingCountNextButtonLink = (claimId) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (claimId) {
    const nextButtonLink = DENTOLO_EDIT_STEP_REVIEW.replace(PARAM_CLAIM_ID, claimId);
    return withSource(nextButtonLink);
  }

  return withSource(DENTOLO_STEP_REVIEW);
};
