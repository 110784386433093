import { useQuery } from '@apollo/client';

import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PETOLO_STEP_CONSULTATION_QUESTION,
  PETOLO_STEP_REVIEW,
  PETOLO_STEP_TREATMENT_PRICE,
  PETOLO_STEP_VIDEO_CONSULTATION,
} from '../../new/newClaimsFormSteps';
import {
  PARAM_CLAIM_ID,
  PETOLO_EDIT_STEP_REVIEW,
  PETOLO_EDIT_STEP_TREATMENT_PRICE,
} from '../../edit/editClaimFormSteps';
import {
  getNewPetoloClaimFormDataValue,
  PETOLO_LINK_VIDEO_CONSULTATION,
  PETOLO_LINK_VIDEO_CONSULTATION_NO,
} from '../../claimFormData/newClaimFormData';
import { GET_VIDEO_CONSULTATIONS } from '../../../../shared';

export const useCustomerNotePetoloBackButtonLink = (claimId) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  const { data: consultationData } = useQuery(GET_VIDEO_CONSULTATIONS);
  const hasVideoConsultations = Array.isArray(consultationData?.videoConsultations)
    ? consultationData.videoConsultations.length > 0
    : false;

  const linkVideoConsultationAnswer = getNewPetoloClaimFormDataValue(
    PETOLO_LINK_VIDEO_CONSULTATION
  );

  if (claimId) {
    const backButtonLink = PETOLO_EDIT_STEP_TREATMENT_PRICE.replace(PARAM_CLAIM_ID, claimId);
    return withSource(backButtonLink);
  }

  if (hasVideoConsultations) {
    if (linkVideoConsultationAnswer === PETOLO_LINK_VIDEO_CONSULTATION_NO) {
      return withSource(PETOLO_STEP_CONSULTATION_QUESTION);
    }

    return withSource(PETOLO_STEP_VIDEO_CONSULTATION);
  }

  return withSource(PETOLO_STEP_TREATMENT_PRICE);
};

export const useCustomerNotePetoloNextButtonLink = (claimId) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (claimId) {
    const nextButtonLink = PETOLO_EDIT_STEP_REVIEW.replace(PARAM_CLAIM_ID, claimId);
    return withSource(nextButtonLink);
  }

  return withSource(PETOLO_STEP_REVIEW);
};
