import React, { useCallback, useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { GET_DENTISTS } from '../shared';
import useConsole from '../hooks/useConsole';

import { DentistDetailsUI } from './ui';
import { BackButton, Container, PageTitle } from './common';

// TODO: update this page (?is it actuall still in use?)
function DentistsDetails() {
  const { id } = useParams();
  const { consoleLog } = useConsole();

  const [dentist, setDentist] = useState(null);
  const client = useApolloClient();

  const getDentistDetails = useCallback(async () => {
    try {
      const res = await client.query({ query: GET_DENTISTS });
      const { dentists } = res?.data || {};

      if (!Array.isArray(dentists)) return;

      const currDentist = dentists.find((dent) => dent.referenceKey === id);

      if (currDentist) {
        setDentist(currDentist);
      }
    } catch (err) {
      consoleLog(err);
    }
  }, [client, consoleLog, id]);

  useEffect(() => {
    if (dentist) return;

    getDentistDetails();
  }, [dentist, getDentistDetails]);

  if (!dentist) return null;

  return (
    <Container>
      <BackButton to="/dentists-map" />

      <PageTitle>{dentist?.practiceName}</PageTitle>

      <DentistDetailsUI dentist={dentist} detailsPage />
    </Container>
  );
}

export default DentistsDetails;
