import React from 'react';
import { FormattedMessage } from 'react-intl';

import useMediaQuery from '../../../hooks/useMediaQuery';
import { MIN_WIDTH_MD } from '../../../theme/breakpoints';
import { FRESSNAPF_FRIENDS_PAGE_LINK_PROMOTION_CAROUSEL, imgLinks } from '../../../shared';

import { Button } from '../../../components/ui';
import {
  StyledFressnapfFriendsCardButtonContainer,
  StyledFressnapfFriendsCardContainer,
  StyledFressnapfFriendsCardContent,
  StyledFressnapfFriendsCardImage,
  StyledFressnapfFriendsCardImageWrapper,
  StyledFressnapfFriendsCardText,
} from './FressnapfFriendsCard.styled';

function FressnapfFriendsCard() {
  const handleGotoFressnapfFriendsPage = () => {
    window.open(FRESSNAPF_FRIENDS_PAGE_LINK_PROMOTION_CAROUSEL, '_blank');
  };

  const isDesktop = useMediaQuery(`(min-width: ${MIN_WIDTH_MD})`);

  const coverImageToUse = isDesktop ? imgLinks['fressnapf.desktop'] : imgLinks['fressnapf.mobile'];

  const renderBTag = (chunk) => <strong>{chunk}</strong>;

  return (
    <StyledFressnapfFriendsCardContainer>
      <StyledFressnapfFriendsCardImageWrapper>
        <StyledFressnapfFriendsCardImage src={coverImageToUse} alt="Fressnapf Friends" />
      </StyledFressnapfFriendsCardImageWrapper>

      <StyledFressnapfFriendsCardContent>
        <StyledFressnapfFriendsCardText>
          <FormattedMessage
            id="promotion.carousel.fressnapf.fn_friends.text"
            values={{
              b: (chunk) => renderBTag(chunk),
            }}
          />
        </StyledFressnapfFriendsCardText>

        <StyledFressnapfFriendsCardButtonContainer>
          <Button onClick={handleGotoFressnapfFriendsPage}>
            <FormattedMessage id="promotion.carousel.fressnapf.fn_friends.button.label" />
          </Button>
        </StyledFressnapfFriendsCardButtonContainer>
      </StyledFressnapfFriendsCardContent>
    </StyledFressnapfFriendsCardContainer>
  );
}

export default FressnapfFriendsCard;
