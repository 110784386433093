import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';

import {
  VITOLO_STEP_TREATMENT_PRICE,
  VITOLO_STEP_UPLOAD_DOCUMENTS,
} from '../../new/newClaimsFormSteps';
import {
  VITOLO_EDIT_STEP_UPLOAD_DOCUMENTS,
  PARAM_CLAIM_ID,
  VITOLO_EDIT_STEP_TREATMENT_PRICE,
} from '../../edit/editClaimFormSteps';

export const useSelectDocumentCreationDateVitoloBackButtonLink = (claimId) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (claimId) {
    const backButtonLink = VITOLO_EDIT_STEP_UPLOAD_DOCUMENTS.replace(PARAM_CLAIM_ID, claimId);
    return withSource(backButtonLink);
  }

  return withSource(VITOLO_STEP_UPLOAD_DOCUMENTS);
};

export const useSelectDocumentCreationDateVitoloNextButtonLink = (claimId) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (claimId) {
    const nextButtonLink = VITOLO_EDIT_STEP_TREATMENT_PRICE;
    const nextButtonLinkWithClaimId = nextButtonLink.replace(PARAM_CLAIM_ID, claimId);
    return withSource(nextButtonLinkWithClaimId);
  }

  const nextButtonLink = VITOLO_STEP_TREATMENT_PRICE;
  return withSource(nextButtonLink);
};
