import React from 'react';
// TODO: Replace with @mui styled.
// It's a bit more complicated to get component selectors working with @mui styled, need to configure it.
import styled from '@emotion/styled/macro';

const StyledRadioSVG = styled('svg')(
  ({ theme }) => `
  fill: ${theme.palette.common.white};
`
);

const StyledRadioOutline = styled('circle')(
  ({ theme }) => `
  fill: none;
  stroke: ${theme.palette.main.professional_light_blue};
  transition: stroke 0.3s ease-in-out;
`
);

const StyledRadioDot = styled('circle')(
  ({ theme }) => `
  opacity: 0;
  fill: ${theme.palette.background.radio};
  transition: opacity 0.2s ease-in-out;
`
);

const StyledRadioInputContainer = styled('div')(
  ({ theme }) => `
  display: flex;
  position: relative;
  margin: auto 0;

  &:hover {
    ${StyledRadioOutline} {
      stroke: ${theme.palette.main.professional_blue};
    }
  }
`
);

const StyledRadioInput = styled('input')(
  ({ theme }) => `
  appearance: none;
  position: absolute;
  box-sizing: border-box;
  margin: 0;
  border: none;
  background: none;
  box-shadow: none;
  font-size: 1em;
  height: 1.5em;
  width: 1.5em;
  cursor: pointer;

  &:focus {
    border: none;
  }

  &:checked + ${StyledRadioSVG} > ${StyledRadioOutline} {
    stroke: ${theme.palette.main.professional_blue};
  }

  &:checked + ${StyledRadioSVG} > ${StyledRadioDot} {
    opacity: 1;
  }

  &:disabled {
    cursor: default;
  }

  &:disabled:hover + ${StyledRadioSVG} > ${StyledRadioOutline} {
    stroke: ${theme.palette.main.professional_light_blue};
  }

  &:disabled:checked + ${StyledRadioSVG} > ${StyledRadioOutline} {
    stroke: ${theme.palette.main.professional_light_blue};
  }

  &:disabled:checked + ${StyledRadioSVG} > ${StyledRadioDot} {
    fill: ${theme.palette.main.professional_light_blue};
  }
`
);

const StyledRadioLabel = styled('label')(
  ({ theme, disabled }) => `
  margin: auto 0 auto 0.25em;
  color: ${disabled ? theme.palette.main.professional_light_blue : theme.palette.main.professional_blue};
  font-weight: 300;
  cursor: pointer;
`
);

function Radio(props) {
  return (
    <StyledRadioInputContainer>
      <StyledRadioInput type="radio" {...props} />

      <StyledRadioSVG
        preserveAspectRatio="xMidYMid meet"
        height="24"
        width="24"
        viewBox="0 0 24 24"
      >
        <StyledRadioOutline cx="12" cy="12" r="10" strokeWidth="2" />
        <StyledRadioDot className="radioDot" cx="12" cy="12" r="7" />
      </StyledRadioSVG>
    </StyledRadioInputContainer>
  );
}

export { Radio, StyledRadioLabel as RadioLabel };
