import { styled, css } from '@mui/material';

import Paragraph from '../ui/Paragraph';

export const StyledDentistDetailsModal = styled('div')(
  ({ theme }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 60;
    background-color: ${theme.palette.background.viewport};
    overflow: auto;
  `
);

export const StyledDentistDetailsCardContainer = styled('div')`
  position: relative;
  overflow: auto;
`;

export const StyledDentistDetailsCardWrapper = styled('div')(
  ({ blurred }) => css`
    ${blurred &&
    css`
      pointer-events: none;
      filter: blur(2px);
    `}
  `
);

export const StyledDentistDetailsCardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  img {
    width: 0.75rem;
    height: 0.75rem;
  }
`;

export const StyledDentistDetailsAvatarContainer = styled('div')`
  display: flex;
  column-gap: 1rem;
  margin: 0.5rem 0;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  width: fit-content;
`;

export const StyledDentistDetailsAvatar = styled('img')(
  ({ theme, size }) => css`
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: 0.063rem solid ${theme.palette.gray.ultra_light_gray};

    ${size === 'sm' &&
    css`
      width: 3.5rem;
      height: 3.5rem;
    `}
  `
);

const dentistDetailsAvatarTextMap = {
  title: css`
    font-weight: 700;
  `,
  subtitle: css`
    font-size: 0.875rem;
  `,
};

export const StyledDentistDetailsAvatarText = styled('div')`
  font-size: 1.25rem;
  line-height: 1.75;
  ${({ type }) => dentistDetailsAvatarTextMap[`${type}`] || null}
`;

export const StyledDentistDetailsCardItem = styled(Paragraph)(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 0.5rem;
    color: ${theme.palette.main.professional_blue};

    & + & {
      margin-top: 0.5rem;
    }

    > img {
      width: 1.3rem;
      height: 1.3rem;
    }

    a {
      color: ${theme.palette.main.professional_blue};
    }
  `
);

export const StyledDentistDetailsCardItemText = styled('label')`
  font-size: 1rem;
  font-weight: ${({ fontWeight }) => `${fontWeight || '450'}`};
`;

export const StyledDentistDetailsOpeningHoursContainer = styled('div')`
  padding-left: 2rem;
  margin-top: 1rem;
`;

export const StyledDentistDetailsOpeningHoursItem = styled('div')`
  display: grid;
  grid-template-columns: 0.4fr 1fr 1fr;
  column-gap: 0.5rem;
  font-weight: ${({ today }) => (today ? 700 : 450)};

  & + & {
    margin-top: 0.5rem;
  }
`;

export const StyledDentistDetailsCtaContainer = styled('div')`
  margin-top: 1.25rem;

  > a,
  > button {
    width: 100%;
    text-decoration: none;
  }
`;

export const StyledDentistFavButton = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 0.5rem;
  margin-top: 1rem;
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;

  > img {
    width: 1.75rem;
    height: 1.75rem;
  }

  ${({ favourite }) =>
    !favourite &&
    css`
      > img {
        padding: 0.25rem;
      }
    `}
`;
