import React from 'react';
import { useParams } from 'react-router-dom';

import {
  VITOLO_CUSTOMER_NOTE,
  useNewVitoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import {
  useCustomerNoteVitoloBackButtonLink,
  useCustomerNoteVitoloNextButtonLink,
} from './CustomerNoteWithUploadVitoloHooks';

import CustomerNote from './CustomerNote';
import UploadOtherTreatmentDocumentVitolo from '../UploadDocument/UploadOtherTreatmentDocumentVitolo';

const CustomerNoteWithUploadVitolo = (props) => {
  const { selectedDocuments, setSelectedDocuments } = props;

  const { claimId } = useParams();
  const [customerNote, setCustomerNote] = useNewVitoloFormDataValue(VITOLO_CUSTOMER_NOTE);

  const backButtonLink = useCustomerNoteVitoloBackButtonLink(claimId);
  const nextButtonLink = useCustomerNoteVitoloNextButtonLink(claimId);

  return (
    <>
      <CustomerNote
        backButtonLink={backButtonLink}
        nextButtonLink={nextButtonLink}
        customerNote={customerNote}
        setCustomerNote={setCustomerNote}
        showNextButton={!customerNote}
        skipText={!customerNote ? 'vitolo.new_claim.treatment_info_text.page_skip_text' : ''}
        customerNoteRequired={false}
        maxWords={80}
        pageTitleId="vitolo.new_claim.treatment_info_text.page_title"
        pageDescriptionId="vitolo.new_claim.treatment_info_text.page_description"
        placeholder="vitolo.new_claim.treatment_info_text.placeholder"
        errorMessageId="vitolo.new_claim.treatment_info_text.error"
      />

      {customerNote && (
        <UploadOtherTreatmentDocumentVitolo
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          nextButtonLink={nextButtonLink}
        />
      )}
    </>
  );
};

export default CustomerNoteWithUploadVitolo;
