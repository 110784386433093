import validTLDs from '../constants/TLD';

const allowedSpecialCharacters = `-!$#%&+_.^`;
const charactersNotAllowedAtTheBeginning = `(?![${allowedSpecialCharacters}])`;
const charactersNotAllowedAtTheEnd = `?[^-.,!?$#%&+_=*\`~^(){}[\\]]`;

const charactersNotAllowedToRepeat = `!$#%&+^`;
const charactersNotAllowedToRepeatRegex = `([${charactersNotAllowedToRepeat}])\\1+`;

const usernameRegex = `${charactersNotAllowedAtTheBeginning}[a-zA-Z0-9${allowedSpecialCharacters}]{1,65}${charactersNotAllowedAtTheEnd}`;
const domainNameRegex = `(?!-)(?!.*--)[A-Za-z0-9-]{1,65}${charactersNotAllowedAtTheEnd}`;
const emailWithoutTLDRegex = `^${usernameRegex}@${domainNameRegex}\\.$`;

const escapedTLDs = validTLDs.map((tld) => `${tld.toLowerCase().replace(/-/g, '\\-')}`);
const topLevelDomainRegex = `^(${escapedTLDs.join('|')})$`;

const firstCharacter = (str) => (typeof str === 'string' ? str.charAt(0) : '');

const getRepeatingSpecialCharacters = (input) => {
  const validatedInput = typeof input === 'string' ? input : '';
  const sortedCharacters = validatedInput.toLowerCase().split('').sort().join('');
  const matchingCharacters = sortedCharacters.match(
    new RegExp(charactersNotAllowedToRepeatRegex, 'g')
  );
  const repeatingSpecialCharacters = Array.isArray(matchingCharacters)
    ? matchingCharacters.map(firstCharacter)
    : [];
  return repeatingSpecialCharacters;
};

const getNumberOfOccurences = (string, character) => {
  if (string) {
    const matches = string.match(new RegExp(character, 'g')) || [];
    return matches.length;
  }
  return 0;
};

const findConsecutivelyOccuringCharacter = (string) => {
  if (string) {
    const disAllowedPatterns = ['..', '__', '--'];

    const repeatingCharactersWithIndex = disAllowedPatterns.reduce((agg, next) => {
      const index = string.indexOf(next);
      if (index > 0) {
        return [...agg, { character: next[0], index }];
      }
      return agg;
    }, []);
    repeatingCharactersWithIndex.sort((a, b) => (a.index > b.index ? 1 : -1));

    const repeatingCharacters = repeatingCharactersWithIndex.reduce(
      (agg, next) => `${agg}${next.character}`,
      ''
    );
    return repeatingCharacters;
  }
  return '';
};

export const EMAIL_ERRORS = {
  NO_ERRORS: 1,
  MISSING_VALUE: 2,
  INCORRECT_FORMAT: 3,
  CONSECUTIVE_SPECIAL_CHARACTERS: 4,
  REPEATING_SPECIAL_CHARACTERS: 5,
  TOO_MANY_UNDERSCORES: 6,
  TOO_MANY_HYPHENS: 7,
};

export const checkValidity = (inputValue) => {
  const validatedInputValue = typeof inputValue === 'string' ? inputValue : '';
  const [username = '', domain = ''] = validatedInputValue.split('@');
  const [domainName = '', tld = ''] = domain.split('.');

  const emailWithoutTLD = `${username}@${domainName}.`;

  const emailMatches = emailWithoutTLD.match(new RegExp(emailWithoutTLDRegex, 'i'));
  const tldMatches = tld.match(new RegExp(topLevelDomainRegex, 'i'));

  if (emailMatches === null || tldMatches === null) {
    // No matches are found here
    if (validatedInputValue.length > 0) {
      // User has typed in a value, but not an email address
      return { tag: EMAIL_ERRORS.INCORRECT_FORMAT, value: null };
    }
    // The input field is empty
    return { tag: EMAIL_ERRORS.MISSING_VALUE, value: null };
  }
  // User has typed in an email value
  const consecutivelyOccuringCharacter = findConsecutivelyOccuringCharacter(username);
  if (consecutivelyOccuringCharacter) {
    // User has typed in consecutive special characters
    return {
      tag: EMAIL_ERRORS.CONSECUTIVE_SPECIAL_CHARACTERS,
      value: consecutivelyOccuringCharacter,
    };
  }
  if (getNumberOfOccurences(username, '_') > 3) {
    // User has typed in too many underscores
    return { tag: EMAIL_ERRORS.TOO_MANY_UNDERSCORES, value: null };
  }
  if (getNumberOfOccurences(username, '-') > 3) {
    // User has typed in too many hyphens
    return { tag: EMAIL_ERRORS.TOO_MANY_HYPHENS, value: null };
  }
  // We almost have a valid email here, but one more check remaianing
  const repeatingSpecialCharacters = getRepeatingSpecialCharacters(username);
  if (repeatingSpecialCharacters.length > 0) {
    // We have some repeating special characters which are not allowed to do so
    return {
      tag: EMAIL_ERRORS.REPEATING_SPECIAL_CHARACTERS,
      value: repeatingSpecialCharacters.join(' '),
    };
  }
  // We have a valid email
  return { tag: EMAIL_ERRORS.NO_ERRORS, value: null };
};

export const isValidEmail = (inputValue) => {
  const validity = checkValidity(inputValue);
  const isValid = validity?.tag === EMAIL_ERRORS.NO_ERRORS;
  return isValid;
};
