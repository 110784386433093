import React from 'react';
import { imgLinks } from '../../shared';

import FressnapfSlide, { slidesConfig } from './FressnapfSlide';
import { StyledDesktopSlideContainer, StyledDesktopSlidesContainer } from './Fressnapf.styled';
import { StyledOnlineVetSliderCounter } from '../OnlineVets/OnlineVets.styled';

const FressnapfDesktopSlides = () => {
  return (
    <StyledDesktopSlidesContainer>
      {slidesConfig.map((card) => (
        <StyledDesktopSlideContainer key={card?.alt}>
          <StyledOnlineVetSliderCounter src={imgLinks[card?.img]} alt={card?.alt || ''} />

          <FressnapfSlide key={card.alt} {...card} />
        </StyledDesktopSlideContainer>
      ))}
    </StyledDesktopSlidesContainer>
  );
};

export default FressnapfDesktopSlides;
