import React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import { GET_REFERRAL_LIST, imgLinks, useReady } from '../../../../shared';

import ReferralList from '../ReferralList';
import ReferralStatusDescription from '../ReferralStatusDescription';
import { Loading } from '../../../../components/ui';
import { PageTitle } from '../../../../components/common';
import { StyledReferralCard } from '../ReferralPage.styled';
import {
  StyledDentoloReferralStatusEmptyContainer,
  StyledDentoloReferralStatusEmptyDescription,
  StyledDentoloReferralStatusEmptyImageContainer,
  StyledDentoloReferralStatusEmptyImg,
  StyledDentoloReferralStatusEmptyTitle,
} from './DentoloReferral.styled';

const referralStatusDescription = [
  {
    id: 1,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.pending"
        values={{ b: (chunks) => <strong>{chunks}</strong> }}
      />
    ),
  },
  {
    id: 2,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.cancelled"
        values={{ product: 'dentolo', b: (chunks) => <strong>{chunks}</strong> }}
      />
    ),
  },
  {
    id: 3,
    formattedDescription: (
      <FormattedMessage
        id="referral.status.description.completed"
        values={{
          amount: 50,
          giftbrand: 'Amazon Gutschein',
          b: (chunks) => <strong>{chunks}</strong>,
        }}
      />
    ),
  },
];

function DentoloReferralStatus() {
  const ready = useReady();

  const transitionTimeout = 500;

  const { data, loading, refetch } = useQuery(GET_REFERRAL_LIST);
  const { referrals } = data || {};

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  if (loading) return <Loading showLogo />;

  if ((!loading && referrals?.length <= 0) || !referrals) {
    return <EmptyDentoloReferralStatus />;
  }

  return (
    <CSSTransition in={ready} timeout={transitionTimeout} classNames="fade" unmountOnExit>
      <StyledReferralCard>
        <PageTitle style={{ fontSize: '1.5rem' }}>
          <FormattedMessage id="referral.page.status.title" />
        </PageTitle>

        <ReferralList referrals={referrals} />

        <ReferralStatusDescription
          titleId="referral.status.description.title"
          statusDescription={referralStatusDescription}
        />
      </StyledReferralCard>
    </CSSTransition>
  );
}

export default DentoloReferralStatus;

const EmptyDentoloReferralStatus = () => {
  return (
    <StyledDentoloReferralStatusEmptyContainer>
      <StyledDentoloReferralStatusEmptyTitle>
        <FormattedMessage id="referral.page.status.empty.title" />
      </StyledDentoloReferralStatusEmptyTitle>

      <StyledDentoloReferralStatusEmptyImageContainer>
        <StyledDentoloReferralStatusEmptyImg
          src={imgLinks['image.cover.referral.dental.lg']}
          alt="dentolo referral"
        />
      </StyledDentoloReferralStatusEmptyImageContainer>

      <StyledDentoloReferralStatusEmptyDescription>
        <FormattedMessage
          id="referral.page.status.empty.description"
          values={{ product: 'dentolo' }}
        />
      </StyledDentoloReferralStatusEmptyDescription>
    </StyledDentoloReferralStatusEmptyContainer>
  );
};
