import { useEffect, useRef } from 'react';

import useConsole from './useConsole';

const useBeforeunload = (handler) => {
  const { consoleError } = useConsole();

  if (!handler || typeof handler !== 'function') {
    consoleError('Expected `handler` to be a function in `useBeforeunload`');
  }

  const eventListenerRef = useRef();

  useEffect(() => {
    const eventListener = (event) => {
      const returnValue = handler?.(event);

      // Handle legacy `event.returnValue` property
      if (typeof returnValue === 'string') {
        event.returnValue = returnValue; // eslint-disable-line no-param-reassign
        return;
      }

      // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
      // instead it requires `event.returnValue` to be set
      if (event.defaultPrevented) {
        event.returnValue = ''; // eslint-disable-line no-param-reassign
      }
    };

    eventListenerRef.current = eventListener;
  }, [handler]);

  useEffect(() => {
    const eventListener = (event) => eventListenerRef.current(event);

    window.addEventListener('beforeunload', eventListener);

    return () => {
      window.removeEventListener('beforeunload', eventListener);
    };
  }, []);
};

export default useBeforeunload;
