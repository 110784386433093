import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import NavMenuSignOutLink from './NavMenuSignOutLink';
import { getInsCategoryImg } from './navMenuUtils';
import { getNameAccordingToInsuranceCategory } from '../../utils/customerUtils';

import { Icon, IconButton, Separator } from '../common';
import {
  StyledNavUser,
  StyledNavUserMenuItem,
  StyledNavUserMenuItemDetails,
  StyledNavUserPopup,
} from './styled/NavUser.styled';

function NavUserItem(props) {
  const { policyName, isCurrentCustomer, isContractEnded, customer, onClick } = props;
  const { insuranceCategory } = customer || {};

  const handleItemClick = () => {
    if (!isCurrentCustomer) {
      onClick();
    }
  };

  return (
    <>
      <StyledNavUserMenuItem inActive={isContractEnded} onClick={handleItemClick}>
        <StyledNavUserMenuItemDetails>
          <img src={getInsCategoryImg(insuranceCategory)} alt={insuranceCategory} />

          <div>
            <div data-title data-hj-suppress="true">
              {getNameAccordingToInsuranceCategory(customer)}
            </div>
            <div data-policy>{policyName}</div>
          </div>
        </StyledNavUserMenuItemDetails>

        {isCurrentCustomer && <Icon icon="icon.menu.blue.mark" alt="current user" />}
      </StyledNavUserMenuItem>

      <Separator style={{ margin: 0 }} />
    </>
  );
}

NavUserItem.defaultProps = {
  policyName: '',
  isCurrentCustomer: false,
  isContractEnded: false,
  onClick: () => {},
};

function NavUser(props) {
  const { customers, customer, clickUserIcon, customerSelect, isCustomerLoading } = props;

  const intl = useIntl();

  const [showPopup, setShowPopup] = useState(false);
  const popupNode = useRef();
  const btnNode = useRef();

  const onCustomerSelect = (uuid) => {
    setShowPopup(false);
    customerSelect(uuid);
  };

  const onPopupShow = () => {
    if (!isCustomerLoading) {
      if (clickUserIcon) clickUserIcon();

      setShowPopup(!showPopup);
    }
  };

  useEffect(() => {
    const handleClick = (e) => {
      if (!showPopup) return;
      if (popupNode?.current?.contains(e.target)) {
        return;
      }
      if (btnNode?.current?.contains(e.target)) {
        return;
      }
      setShowPopup(false);
    };

    document.addEventListener('mousedown', handleClick);
    document.addEventListener('touchstart', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('touchstart', handleClick);
    };
  }, [showPopup]);

  const inactiveContractPlaceholder = intl.formatMessage({
    id: 'contract.placeholder.inactive',
  });

  return (
    <StyledNavUser>
      <IconButton
        ref={btnNode}
        size="sm"
        onClick={onPopupShow}
        aria-label="Open User"
        icon="icon.user"
        alt="user"
        disabled={isCustomerLoading}
      />

      <CSSTransition
        in={showPopup && !isCustomerLoading}
        timeout={150}
        classNames="quick-fade"
        unmountOnExit
      >
        <StyledNavUserPopup ref={popupNode}>
          <div>
            <ul>
              {customers?.map((customerItem) => {
                const { key, policyName, endedContract, uuid } = customerItem;
                const isContractEnded = endedContract === 'true';
                const policyNameToUse = isContractEnded ? inactiveContractPlaceholder : policyName;

                return (
                  <NavUserItem
                    key={key}
                    policyName={policyNameToUse}
                    isCurrentCustomer={customer?.key === key && customers?.length > 1}
                    isContractEnded={isContractEnded}
                    customer={customerItem}
                    onClick={() => onCustomerSelect(uuid)}
                  />
                );
              })}
            </ul>
          </div>

          <div style={{ padding: '1.5rem 1rem 1rem' }}>
            <NavMenuSignOutLink onSignout={() => setShowPopup(false)} />
          </div>
        </StyledNavUserPopup>
      </CSSTransition>
    </StyledNavUser>
  );
}

export default NavUser;

NavUser.defaultProps = {
  customers: [],
  isCustomerLoading: false,
  clickUserIcon: () => {},
  customerSelect: () => {},
};

NavUser.propTypes = {
  isCustomerLoading: PropTypes.bool,
  clickUserIcon: PropTypes.func,
  customerSelect: PropTypes.func,
  customers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      policyName: PropTypes.string,
      endedContract: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
  customer: PropTypes.shape({
    insuranceCategory: PropTypes.string,
  }),
};
