import React, { useState, useRef, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { CSSTransition } from 'react-transition-group';

import useConsole from '../../../../hooks/useConsole';
import { getReferralShareToSocialList } from '../referralPageHelper';
import {
  useReady,
  GET_CUSTOMER,
  DENTOLO_REFERRAL_URL,
  JOIN_REFERRAL_PROGRAM,
  DENTOLO_REFERRAL_TERMS_LINK,
} from '../../../../shared';

import { Container, Separator } from '../../../../components/common';
import { ErrorPlaceholder, Loading } from '../../../../components/ui';
import {
  StyledReferralAdditionalInformation,
  StyledReferralAdditionalInformationContainer,
  StyledReferralCard,
  StyledReferralCardContent,
  StyledReferralCopyLinkButton,
  StyledReferralCopyLinkOrSocialsText,
  StyledReferralFooterText,
  StyledReferralIdBox,
  StyledReferralIdContainer,
  StyledReferralStepItem,
  StyledReferralStepTitle,
  StyledReferralStepsContainer,
  StyledReferralText,
  StyledReferralTitle,
  StyledSocialGroup,
  StyledSocialImg,
  StyledSocialLinksContainer,
} from '../ReferralPage.styled';

const referralSteps = [
  {
    descriptionTestId: 'refertab_textblock1_dentolo',
    titleId: 'referral.page.dentolo.step.one.title',
    descriptionId: 'referral.page.dentolo.step.one.description',
  },
  {
    descriptionTestId: 'refertab_textblock2_dentolo',
    titleId: 'referral.page.dentolo.step.two.title',
    descriptionId: 'referral.page.dentolo.step.two.description',
  },
  {
    descriptionTestId: 'refertab_textblock3_dentolo',
    titleId: 'referral.page.dentolo.step.three.title',
    descriptionId: 'referral.page.dentolo.step.three.description',
    descriptionValues: { giftbrand: 'Amazon Gutschein' },
    additionalInfo: [
      {
        testId: 'refertab_bullet1_dentolo',
        infoId: 'referral.page.dentolo.step.three.additional_info.one',
      },
      {
        testId: 'refertab_bullet2_dentolo',
        infoId: 'referral.page.dentolo.step.three.additional_info.two',
      },
      {
        testId: 'refertab_bullet3_dentolo',
        infoId: 'referral.page.dentolo.step.three.additional_info.three',
      },
    ],
  },
];

const elementIdWithReferralLink = 'element-with-dentolo-referral-link';

function DentoloReferralInformation() {
  const intl = useIntl();
  const ready = useReady();
  const { consoleError } = useConsole();

  const transitionTimeout = 500;

  const [refUrl, setRefUrl] = useState(DENTOLO_REFERRAL_URL);

  const tooltipNodeRef = useRef(null);

  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const customer = customerData?.customer;
  const refId = customer?.referralCode;

  const [
    generateDentalReferralCode,
    { loading: referralCodeLoading, error: referralCodeGenerationError },
  ] = useMutation(JOIN_REFERRAL_PROGRAM, {
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  const shareData = {
    fb: {
      refUrl,
      elementIdWithReferralLink,
    },
    whatsapp: {
      intl,
      refUrl,
      elementIdWithReferralLink,
      waMessageId: 'referral.page.dentolo.whatsapp.message',
    },
    email: {
      intl,
      refUrl,
      elementIdWithReferralLink,
      emailContentIds: {
        to: '',
        subjectId: 'referral.page.dentolo.email.subject',
        bodyId: 'referral.page.dentolo.email.message',
      },
    },
  };

  const socialItemsData = {
    fb: {
      testId: 'referral_facebook_button_dentolo',
      icon: 'icon.user.referral.fb',
    },
    whatsapp: {
      testId: 'referral_whatsapp_button_dentolo',
      icon: 'icon.user.referral.whatsapp',
    },
    email: {
      testId: 'referral_email_button_dentolo',
      icon: 'icon.user.referral.email',
    },
  };

  // Get social items to share the referral link
  const shareSocialItems = getReferralShareToSocialList(socialItemsData);

  // Generate referral code if customer does not have one
  useEffect(() => {
    const hasReferralCode = !!customer?.referralCode;

    if (!hasReferralCode) {
      generateDentalReferralCode();
    }
  }, [customer, customerLoading, generateDentalReferralCode]);

  // Add referral `id` to the referral url
  useEffect(() => {
    if (refId && refUrl.indexOf('&refid=') < 0) {
      setRefUrl(`${refUrl}&refid=${refId}`);
    }
  }, [refId, refUrl]);

  // Copy to clipboard
  const onLinkCopy = async () => {
    if (!window?.navigator?.clipboard) return;

    const { current: btnCopyLink } = tooltipNodeRef || {};
    const linkToCopy = btnCopyLink?.dataset?.referralLink;

    if (!btnCopyLink || !linkToCopy) return;

    try {
      await window.navigator.clipboard.writeText(linkToCopy);

      ReactTooltip.show(btnCopyLink);

      setTimeout(() => ReactTooltip.hide(btnCopyLink), 1200);
    } catch (err) {
      consoleError('Could not copy text: ', err);
    }
  };

  const renderBTag = (chunk) => <strong>{chunk}</strong>;

  const renderAnchorTag = (chunk, link) => (
    <a target="_blank" rel="noopener noreferrer" href={link}>
      {chunk}
    </a>
  );

  if (referralCodeLoading) return <Loading showLogo />;

  if ((!customerLoading && !customer) || referralCodeGenerationError) return <ErrorPlaceholder />;

  if (!customerLoading && !!customer) {
    return (
      <Container>
        <CSSTransition in={ready} timeout={transitionTimeout} classNames="fade" unmountOnExit>
          <StyledReferralCard>
            <StyledReferralTitle>
              <FormattedMessage id="referral.page.information.header" />
            </StyledReferralTitle>

            <StyledReferralStepsContainer>
              {referralSteps?.map((step) => {
                const {
                  titleId,
                  descriptionId,
                  descriptionValues,
                  additionalInfo,
                  descriptionTestId,
                } = step || {};

                return (
                  <StyledReferralStepItem key={titleId}>
                    <StyledReferralStepTitle>
                      <FormattedMessage id={titleId} />
                    </StyledReferralStepTitle>

                    <p data-test-id={descriptionTestId || ''}>
                      <FormattedMessage id={descriptionId} values={descriptionValues} />
                    </p>

                    {additionalInfo && (
                      <StyledReferralAdditionalInformationContainer>
                        {additionalInfo?.map(({ infoId, testId }) => (
                          <StyledReferralAdditionalInformation
                            key={infoId}
                            data-test-id={testId || ''}
                          >
                            <FormattedMessage id={infoId} />
                          </StyledReferralAdditionalInformation>
                        ))}
                      </StyledReferralAdditionalInformationContainer>
                    )}
                  </StyledReferralStepItem>
                );
              })}
            </StyledReferralStepsContainer>

            <StyledReferralCardContent>
              <StyledReferralCopyLinkButton
                variant="primary"
                id={elementIdWithReferralLink}
                data-for="link-copied"
                data-tip
                data-event="none"
                ref={tooltipNodeRef}
                data-referral-link={refUrl}
                data-test-id="referral_button_copylink_dentolo"
                onClick={onLinkCopy}
              >
                <FormattedMessage id="referral.page.copy.button" />
              </StyledReferralCopyLinkButton>

              <ReactTooltip id="link-copied" className="u3-referral-tooltip" effect="solid">
                <FormattedMessage id="referral.page.link.copied" />
              </ReactTooltip>
            </StyledReferralCardContent>

            <StyledReferralCopyLinkOrSocialsText>
              <FormattedMessage id="referral.page.share_in_socials" />
            </StyledReferralCopyLinkOrSocialsText>

            <StyledSocialLinksContainer>
              <StyledSocialGroup>
                {shareSocialItems.map((socialItem) => (
                  <StyledSocialImg
                    key={socialItem.id}
                    src={socialItem.image}
                    alt={socialItem.id}
                    data-test-id={socialItem.testId}
                    onClick={() => socialItem.click(shareData[socialItem.id])}
                  />
                ))}
              </StyledSocialGroup>
            </StyledSocialLinksContainer>

            <StyledReferralIdContainer>
              <p>
                <FormattedMessage
                  id="referral.page.referral_id.description"
                  values={{
                    product: 'dentolo',
                    insurance: 'Zahnzusatzversicherung',
                    b: (chunk) => renderBTag(chunk),
                  }}
                />
              </p>

              <StyledReferralText>
                <FormattedMessage id="referral.page.referral_id.title" />
              </StyledReferralText>

              <StyledReferralIdBox>{refId}</StyledReferralIdBox>
            </StyledReferralIdContainer>

            <Separator />

            <StyledReferralFooterText>
              <FormattedMessage id="referral.page.privacy.text" />
            </StyledReferralFooterText>

            <StyledReferralFooterText>
              <FormattedMessage
                id="referral.page.terms_and_conditions.text"
                values={{
                  a: (chunk) => renderAnchorTag(chunk, DENTOLO_REFERRAL_TERMS_LINK),
                }}
              />
            </StyledReferralFooterText>
          </StyledReferralCard>
        </CSSTransition>
      </Container>
    );
  }

  return null;
}

export default DentoloReferralInformation;
