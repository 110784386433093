import { styled, css } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { MAX_WIDTH_MD, MIN_WIDTH_MD } from '../../../theme/breakpoints';

import { Icon, Paper } from '../../common';
import {
  navMenuBaseStyle,
  navMenuUnderlineActiveBaseStyle,
  navMenuUnderlineBaseStyle,
} from './NavMenuDesktop.styled';

const dropdownMenuItemBaseStyle = ({ theme }) => css`
  ${navMenuBaseStyle({ theme })};

  justify-content: flex-start;
  column-gap: 1rem;
  padding: 0.8rem 1.25rem 0.8rem 1rem;
  font-weight: 450;
  background-color: ${theme.palette.background.viewport};
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  border-bottom: none;
  font-size: 1.25rem;

  > img {
    transition: opacity 0.2s ease-out;
  }

  &:not(:first-child) {
    margin-top: 0.5rem;
  }

  &:hover {
    color: ${theme.palette.main.professional_blue};
    background-color: ${theme.palette.background.viewport};

    > img {
      opacity: 1;
    }
  }

  &.active,
  &:active,
  &:focus {
    color: ${theme.palette.main.professional_light_blue};
    background-color: ${theme.palette.background.viewport};

    > img {
      opacity: 0.4;
    }
  }

  @media (max-width: ${MAX_WIDTH_MD}) {
    font-size: 1rem;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    justify-content: flex-start;
    column-gap: 1rem;
    font-weight: 450;
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    transition:
      background 0.3s ease-out,
      border 0.3s ease-out;

    &:not(:first-child) {
      margin-top: 0;
    }

    > img {
      padding: 0;
      width: 1.75rem;
      max-height: 1.75rem;
      height: auto;
    }

    > span {
      display: block;
      position: relative;

      ${navMenuUnderlineBaseStyle({ theme })};

      &::after {
        bottom: -1px;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.palette.main.product_blue};
    }

    &::after {
      content: none;
    }

    &:hover {
      color: ${theme.palette.main.professional_blue};
      background-color: ${theme.palette.background.viewport};
      border-bottom-color: transparent;

      &.active {
        color: ${theme.palette.main.professional_light_blue};
      }
    }

    &:focus:not(:hover) {
      > span {
        ${navMenuUnderlineActiveBaseStyle};
      }
    }

    &:active {
      color: ${theme.palette.main.professional_light_blue};
    }
  }
`;

// ===  BASE styles above this line  ===

export const StyledNavDropdown = styled('div')`
  position: relative;
  height: 100%;
`;

export const StyledDropdownMenu = styled('button')`
  ${navMenuBaseStyle};

  display: flex;
  align-items: center;
  column-gap: 0.2rem;
  height: 100%;
  background: transparent;
  border: none;
  appearance: none;
  cursor: pointer;

  &::after {
    width: calc(100% - 1.25rem - 0.2rem); // (total_width_% - icon_width - column_gap)
  }

  &:hover {
    img {
      opacity: 0.5;
    }
  }

  &:focus {
    ${navMenuUnderlineActiveBaseStyle};

    img {
      opacity: 0.5;
    }
  }
`;

export const StyledDropdownIcon = styled(Icon)(
  ({ open }) => css`
    width: 1.25rem;
    height: auto;
    transition:
      transform 0.1s ease-out,
      opacity 0.22s ease-out;

    @media (min-width: ${MIN_WIDTH_MD}) {
      width: 1.25rem;
      height: auto;
    }

    ${open &&
    css`
      opacity: 0.4;
      transform: translateY(2px) rotate(180deg);
    `}
  `
);

export const StyledDropdownMenuPopup = styled('div')`
  position: absolute;
  top: 3rem;
  left: -0.75rem;
  padding-top: 0.2rem;
  min-width: 11rem;
  width: max-content;
  z-index: 50;
`;

export const StyledDropdownMenuPopupCard = styled(Paper)`
  padding: 0;
`;

export const StyledDropdownMenuItemExternal = styled('a')`
  ${dropdownMenuItemBaseStyle};
`;

export const StyledDropdownMenuItem = styled(NavLink)`
  ${dropdownMenuItemBaseStyle};
`;
