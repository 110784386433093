import { css } from '@mui/material';
import styled from '@emotion/styled/macro';

import { MIN_WIDTH_LG } from '../../../theme/breakpoints';
import {
  BLOCKED,
  CANCELLED,
  CLOSED,
  DECLINED,
  NEW,
  PARTIALLY_CLOSED,
  REIMBURSEMENT_ACKNOWLEDGED,
  WAITING_FOR_INFORMATION,
  WAITING_FOR_PAYOUT,
} from '../../../shared/claimStatus';

import { Paper } from '../../common';
import { Button } from '../Button';

export const StyledClaimStatusCard = styled(Paper)`
  position: relative;
  width: 100%;
  margin-bottom: 1em;
  padding: 0;

  @media (min-width: ${MIN_WIDTH_LG}) {
    margin-bottom: 1.5em;
  }
`;

export const StyledClaimStatusCardButton = styled(Button)(
  ({ theme, statusUpdated }) => css`
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 0.5em;
    background-color: ${theme.palette.common.white};
    border-radius: 5px;
    cursor: pointer;
    transition: border 0.25s ease-in-out;

    border: ${statusUpdated
      ? `2px solid ${theme.palette.main.professional_blue}`
      : `2px solid ${theme.palette.common.white}`};

    &:hover,
    &:focus,
    &:active {
      border: 2px solid ${theme.palette.main.professional_light_blue};
      box-shadow: none;
    }

    @media (min-width: ${MIN_WIDTH_LG}) {
      padding: 1em;
    }
  `
);

export const StyledArrowIcon = styled('div')(
  ({ theme }) => css`
    position: absolute;
    top: 50%;
    right: 1.15rem;
    transform: translateY(-50%);
    background: ${theme.palette.background.button};
    display: flex;
    border-radius: 7px;
    width: 2rem;
    height: 2rem;
    justify-content: center;
    align-items: center;
  `
);

export const StyledTreatmentDetailsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.5em;
`;

export const StyledTreatmentDetails = styled('h5')(
  ({ theme }) => css`
    margin-bottom: 0.5em;
    font-size: 1.25rem;
    word-break: break-word;
    text-align: left;

    ${StyledClaimStatusCardButton}:hover & {
      color: ${theme.palette.main.professional_light_blue};
    }
  `
);

export const StyledTreatmentOtherInfo = styled('div')(
  ({ theme }) => css`
    color: ${theme.palette.main.professional_light_blue};
    font-size: 1em;
    font-weight: 300;
    line-height: 1.25em;

    ${StyledClaimStatusCardButton}:hover & {
      color: ${theme.palette.main.professional_light_blue};
    }
  `
);

const claimStatusLabelCSS = ({ theme, claimStatus }) => {
  switch (claimStatus) {
    case BLOCKED:
      return css`
        background-color: ${theme.palette.error.main};
        color: ${theme.palette.common.white};
      `;

    case REIMBURSEMENT_ACKNOWLEDGED:
    case WAITING_FOR_PAYOUT:
    case PARTIALLY_CLOSED:
    case CLOSED:
    case CANCELLED:
      return css`
        color: ${theme.palette.common.white};
        background-color: ${theme.palette.success.main};
      `;

    case DECLINED:
      return css`
        border: 1px solid ${theme.palette.main.professional_blue};
      `;

    case NEW:
    case WAITING_FOR_INFORMATION:
    default:
      return css`
        background-color: ${theme.palette.main.product_blue};
        color: ${theme.palette.common.white};
      `;
  }
};

export const StyledClaimStatus = styled('div')(
  ({ theme, claimStatus }) => css`
    align-self: flex-start;
    padding: 0.25em 0.375em;
    font-weight: 700;
    line-height: 1em;
    color: ${theme.palette.main.professional_blue};
    border-radius: 5px;
    ${claimStatusLabelCSS({ theme, claimStatus })};

    span {
      font-size: 0.75em;
    }
  `
);
