import React from 'react';
import { FormattedMessage } from 'react-intl';

import { imgLinks } from '../../shared';
import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import useMediaQuery from '../../hooks/useMediaQuery';

import {
  StyledErrorPlaceholder,
  StyledErrorPlaceholderText,
  StyledErrorPlaceholderTitle,
} from './styled/ErrorPlaceholder.styled';

function ErrorPlaceholder() {
  const supportEmail = 'service@dentolo.de';

  const isDesktop = useMediaQuery(`(min-width: ${MIN_WIDTH_MD})`);

  return (
    <StyledErrorPlaceholder>
      <StyledErrorPlaceholderTitle>
        <FormattedMessage
          id={
            isDesktop ? 'error.message.common.title.desktop' : 'error.message.common.title.mobile'
          }
        />
      </StyledErrorPlaceholderTitle>

      <StyledErrorPlaceholderText type="subtext" style={{ marginBottom: '0.5rem' }}>
        <FormattedMessage
          id={
            isDesktop
              ? 'error.message.common.subtitle.desktop'
              : 'error.message.common.subtitle.mobile'
          }
        />
      </StyledErrorPlaceholderText>

      <img src={imgLinks['icon.error.message.placeholder']} alt="An error occurred" />

      {!isDesktop && (
        <StyledErrorPlaceholderText type="subtext">
          <FormattedMessage id="error.message.common.subtitle.desktop" />
        </StyledErrorPlaceholderText>
      )}

      <StyledErrorPlaceholderText type="subtext">
        <FormattedMessage id="error.message.common.support" />:{' '}
        <label htmlFor="support-email">{supportEmail}</label>
      </StyledErrorPlaceholderText>

      <StyledErrorPlaceholderText type="subtext">
        <FormattedMessage id="error.message.common.reward" />
      </StyledErrorPlaceholderText>

      <StyledErrorPlaceholderText type="subtext" style={{ marginTop: 0 }}>
        <FormattedMessage id="error.message.common.team" />
      </StyledErrorPlaceholderText>
    </StyledErrorPlaceholder>
  );
}

export default ErrorPlaceholder;
