import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import {
  BackButton,
  FormSectionContainer,
  ButtonContainer,
  ErrorMessage,
  DatePicker,
  Checkbox,
  PageTitle,
  Text,
} from '../../../../components/common';
import { Button } from '../../../../components/ui';
import {
  combineFullDateFromInputs,
  getInputFieldValuesFromDate,
  validateMonthRange,
} from '../SelectDocumentCreationDate/dateUtils';
import { StyledCheckboxContainer, StyledHintContainer } from './SelectTreatmentDate.styled';
import { dateDiff, formatDate } from '../../../../shared/utils';
import { VALID_DATE, NOT_VALID_DATE, IN_PAST_DATE } from '../../../../shared/constants';

const SelectTreatmentDate = ({
  backButtonLink,
  nextButtonLink,
  pageTitleId,
  formDescription,
  setTreatmentDate,
  treatmentDate,
  documentCreationDate,
  checkLabelText,
  showWarningText,
  contractAcceptedAt,
}) => {
  const history = useHistory();

  const treatmentDateInputFieldValues = getInputFieldValuesFromDate(treatmentDate);
  const documentCreationDateInputFieldValues = getInputFieldValuesFromDate(documentCreationDate);
  const [date, setDate] = useState(treatmentDateInputFieldValues);

  const { day, month, year } = date || {};
  const setDay = (newDay) => setDate({ day: newDay, month, year });
  const setMonth = (newMonth) => setDate({ day, month: newMonth, year });
  const setYear = (newYear) => setDate({ day, month, year: newYear });

  const newTreatmentDate = combineFullDateFromInputs(day, month, year);

  const [sameDocumentDateChecker, setSameDocumentDateChecker] = useState(true);

  const [dateStatus, setDateStatus] = useState(VALID_DATE); // ['valid','notValid','inPast']

  useEffect(() => {
    const sameDates = newTreatmentDate === documentCreationDate;

    if (sameDocumentDateChecker !== sameDates) {
      setSameDocumentDateChecker(sameDates);
    }

    const newTreatmentDateObject = new Date(newTreatmentDate);
    const currentDate = new Date();

    const isValidDate = !Number.isNaN(Number(newTreatmentDateObject));
    if (isValidDate) {
      setDateStatus(VALID_DATE);
    }

    const notInTheFuture = isValidDate ? newTreatmentDateObject < currentDate : false;
    if (notInTheFuture && validateMonthRange(newTreatmentDate)) {
      setTreatmentDate(newTreatmentDate);
    } else {
      setTreatmentDate('');
    }
  }, [newTreatmentDate, documentCreationDate, sameDocumentDateChecker, setTreatmentDate]);

  const handleCheckboxChange = () => {
    if (sameDocumentDateChecker) {
      setDate({ day: '', month: '', year: '' });
    } else {
      setDate(documentCreationDateInputFieldValues);
    }
    setSameDocumentDateChecker(!sameDocumentDateChecker);
  };

  const handleButtonClick = () => {
    if (!treatmentDate || !validateMonthRange(treatmentDate)) {
      setDateStatus(NOT_VALID_DATE);
    } else if (
      treatmentDate &&
      validateMonthRange(treatmentDate) &&
      dateDiff('year', new Date(treatmentDate)) >= 5
    ) {
      setDateStatus(IN_PAST_DATE);
    } else {
      history.push(nextButtonLink);
    }
  };

  const disableNextButton =
    dateStatus !== VALID_DATE || !day || !month || !year || year.length !== 4;

  const treatmentDateBeforeContractAcceptedDate =
    new Date(contractAcceptedAt) > new Date(newTreatmentDate);

  return (
    <>
      <BackButton to={backButtonLink}>
        <FormattedMessage id="common.button.back" />
      </BackButton>

      <PageTitle>
        <FormattedMessage id={pageTitleId} />
      </PageTitle>

      <FormSectionContainer>
        <Text style={{ fontSize: '1em', marginBottom: '.5em' }}>
          <FormattedMessage id={formDescription} />
        </Text>

        <DatePicker
          day={day}
          month={month}
          year={year}
          setDay={setDay}
          setMonth={setMonth}
          setYear={setYear}
        />

        <StyledCheckboxContainer>
          <Checkbox
            type="checkbox"
            checked={sameDocumentDateChecker}
            onChange={handleCheckboxChange}
            label={<FormattedMessage id={checkLabelText} />}
          />
        </StyledCheckboxContainer>

        {dateStatus === NOT_VALID_DATE && (
          <ErrorMessage data-testid="error-date">
            <FormattedMessage id="new_claim.invalid.date" />
          </ErrorMessage>
        )}
        {dateStatus === IN_PAST_DATE && (
          <ErrorMessage data-testid="error-date">
            <FormattedMessage id="new_claim.invalid.date_past" />
          </ErrorMessage>
        )}
      </FormSectionContainer>

      <Text style={{ fontSize: '1em', marginTop: '1rem' }}>
        <FormattedMessage id="new_claim.select_treatment_creation_date.helper_text" />
      </Text>

      {showWarningText && treatmentDateBeforeContractAcceptedDate && !disableNextButton && (
        <StyledHintContainer>
          <Text style={{ fontSize: '1em' }}>
            <FormattedMessage
              id="new_claim.select_treatment_creation_date.warning_text"
              values={{
                contractAcceptedAt: formatDate(contractAcceptedAt),
              }}
            />
          </Text>
        </StyledHintContainer>
      )}

      <ButtonContainer>
        <Button
          data-testid="next_button"
          variant="primary"
          onClick={handleButtonClick}
          disabled={disableNextButton}
        >
          <FormattedMessage id="common.button.next" />
        </Button>
      </ButtonContainer>
    </>
  );
};

export default SelectTreatmentDate;
