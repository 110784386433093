import React from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import { GET_FAVORITE_DENTISTS, GET_DENTISTS, GET_CUSTOMER } from '../../shared';

import FavoriteDentistBlock from './FavoriteDentistBlock';
import {
  StyledFavoriteDentist,
  StyledFavoriteDentistMessage,
} from './styled/FavoriteDentist.styled';

const FavoriteDentist = ({ maxHeight }) => {
  const getDentistList = useQuery(GET_DENTISTS);
  const getCustomer = useQuery(GET_CUSTOMER);
  const { data: favoriteDentistsData } = useQuery(GET_FAVORITE_DENTISTS);

  const filterFavouriteDentists = (dentists, favourites) => {
    const favouritesKeys = favourites?.map((f) => f?.dentistKey);
    return dentists && favourites
      ? dentists?.filter((dentist) => favouritesKeys?.includes(dentist?.referenceKey))
      : [];
  };

  const favouriteDentists = filterFavouriteDentists(
    getDentistList?.data?.dentists,
    favoriteDentistsData?.customer?.favoriteDentists
  );

  const hasFavourites = favouriteDentists?.length > 0;

  if (!hasFavourites) {
    return (
      <StyledFavoriteDentistMessage>
        <FormattedMessage id="map.dentists.tabs.dentist.favourite.message.no_dentist" />
      </StyledFavoriteDentistMessage>
    );
  }

  return (
    <StyledFavoriteDentist maxHeight={maxHeight}>
      <StyledFavoriteDentistMessage hasShadow={hasFavourites}>
        <FormattedMessage id="map.dentists.tabs.dentist.favourite.message" />
      </StyledFavoriteDentistMessage>

      <FavoriteDentistBlock
        customer={getCustomer?.data?.customer}
        favouriteDentists={favouriteDentists}
      />
    </StyledFavoriteDentist>
  );
};

export default FavoriteDentist;
