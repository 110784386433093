import React from 'react';

import {
  PET_LIABILITY_INCIDENT_DATE,
  PET_LIABILITY_INCIDENT_TIME,
  PET_LIABILITY_INCIDENT_TIME_NOT_AVAILABLE,
  PET_LIABILITY_REASON,
  PET_LIABILITY_REASON_ANOTHER_ANIMAL,
  PET_LIABILITY_REASON_PERSON,
  useNewPetLiabilityFormDataValue,
} from '../../claimFormData/newClaimFormDataPetLiability';
import {
  useIncidentDateTimePetLiabilityBackButtonLink,
  useIncidentDateTimePetLiabilityNextButtonLink,
} from './useIncidentDateTimePetLiability';

import DateAndTime from './DateAndTime';

function IncidentDateTimePetLiability() {
  const [liabilityReason] = useNewPetLiabilityFormDataValue(PET_LIABILITY_REASON);

  const [incidentDate, setIncidentDate] = useNewPetLiabilityFormDataValue(
    PET_LIABILITY_INCIDENT_DATE
  );
  const [incidentTime, setIncidentTime] = useNewPetLiabilityFormDataValue(
    PET_LIABILITY_INCIDENT_TIME
  );
  const [timeNotAvailable, setTimeNotAvailable] = useNewPetLiabilityFormDataValue(
    PET_LIABILITY_INCIDENT_TIME_NOT_AVAILABLE
  );

  const backButtonLink = useIncidentDateTimePetLiabilityBackButtonLink();
  const nextButtonLink = useIncidentDateTimePetLiabilityNextButtonLink();

  const pageTitleId =
    liabilityReason === PET_LIABILITY_REASON_ANOTHER_ANIMAL ||
    liabilityReason === PET_LIABILITY_REASON_PERSON
      ? 'pet_liability.new_claim.incident_date_time.page_title.injury'
      : 'pet_liability.new_claim.incident_date_time.page_title.damage';

  return (
    <DateAndTime
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId={pageTitleId}
      selectedDate={incidentDate}
      selectedTime={incidentTime}
      timeNotAvailable={timeNotAvailable}
      onDateChange={setIncidentDate}
      onTimeChange={setIncidentTime}
      onTimeNotAvailable={setTimeNotAvailable}
    />
  );
}

export default IncidentDateTimePetLiability;
