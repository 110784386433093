import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import useLocalStorage from '../hooks/useLocalStorage';
import useInsuranceInfo from '../hooks/useInsuranceInfo';
import fetchPolicyCoverageInfo from '../actions/PolicyActions';
import { showSelfCancellationMap, showRimburseMap } from '../utils/mappings';
import {
  GET_CUSTOMER,
  GET_PAYMENT,
  useReady,
  formatDate,
  getFailedPayments,
  contractStatus,
  contractInTrialMonth,
  GET_CLAIMS,
} from '../shared';

import LinkToClaimsPage from '../features/policyDetails/LinkToClaimsPage/LinkToClaimsPage';
import PromotionCarousel from '../features/promotions/PromotionCarousel';
import InsureAnotherPetBanner from './Banners/InsureAnotherPetBanner/InsureAnotherPetBanner';
import { Container, PageTitle } from './common';
import { ErrorPlaceholder, PolicyCard, PolicyDetailsCard } from './ui';
import MissingIban from './PolicyInfo/MissingIban';
import CustomerDetailsEditFormWrapper from './CustomerDetails/CustomerDetailsEditFormWrapper';
import BankDetailsEditor from './CustomerDetails/BankDetailsEditor';
import {
  StyledContractCancelLinkParagraph,
  StyledFlexContainer,
  StyledTag,
  StyledMissingIbanBannerContainer,
} from './styled/PolicyInfo.styled';

const parseContractStatusInfo = (startingAt, cancelationDate, status, addonTrialMonth) => {
  const getLabelColor = (statusLabel) =>
    [
      contractStatus.LABEL_START,
      contractStatus.LABEL_WILL_START,
      contractStatus.LABEL_WILL_END,
      contractStatus.LABEL_ACTIVE_TRIAL_MONTH,
    ].includes(statusLabel)
      ? 'green'
      : '';

  const getStatusLabel = () => {
    if (startingAt && cancelationDate) {
      // Widerrufen - (where contract_end_date before contract's starting_at)
      if (cancelationDate && new Date(startingAt) > new Date(cancelationDate)) {
        return contractStatus.LABEL_REVOKED;
      }

      // Beendet - (where contract_end_date has happened and after contract.starting_at)
      if (
        cancelationDate &&
        new Date(startingAt) < new Date(cancelationDate) &&
        new Date() > new Date(cancelationDate)
      ) {
        return contractStatus.LABEL_END;
      }

      // Wird Beendet - (where contract_end_date has not happened and after contract.starting_at)
      if (
        cancelationDate &&
        new Date(startingAt) < new Date(cancelationDate) &&
        new Date() < new Date(cancelationDate)
      ) {
        return contractStatus.LABEL_WILL_END;
      }
    }
    return contractStatus.LABEL_START;
  };

  const getStatusTitleForEnded = () => {
    return cancelationDate && new Date(cancelationDate) > new Date(startingAt)
      ? contractStatus.LABEL_END
      : null;
  };

  // to get `Probemonat aktiv` label when contract is in trial month and contract status is `accepted`. Contract is in `accepted` status during `trial_month`
  const getStatusLabelForAccepted = () => {
    const inTrialMonth = contractInTrialMonth(
      addonTrialMonth?.status,
      addonTrialMonth?.startingAt,
      startingAt
    );

    return inTrialMonth ? contractStatus.LABEL_ACTIVE_TRIAL_MONTH : contractStatus.LABEL_WILL_START;
  };

  // TODO: check mapping for other statuses
  const statusMapping = {
    [contractStatus.ACTIVE]: {
      statusLabel: getStatusLabel(),
      statusTitle: getStatusTitleForEnded(),
      date: cancelationDate,
    },
    [contractStatus.ACCEPTED]: {
      statusLabel: getStatusLabelForAccepted(),
      statusTitle: contractStatus.LABEL_START,
      date: startingAt,
    },
    [contractStatus.ENDED]: {
      statusLabel: getStatusLabel(),
      statusTitle: getStatusTitleForEnded(),
      date: cancelationDate,
    },
  };
  const statusInfo = statusMapping[`${status}`] || {};

  return {
    statusColor: getLabelColor(statusInfo.statusLabel),
    label: statusInfo.statusLabel && (
      <FormattedMessage id={`contract.status.${statusInfo.statusLabel}`} />
    ),
    title: statusInfo.statusTitle && (
      <FormattedMessage id={`contract.status.title.${statusInfo.statusTitle}`} />
    ),
    date: formatDate(statusInfo?.date),
  };
};

const PolicyInfo = () => {
  const ready = useReady();
  const intl = useIntl();

  const [getFromLocalStorage, saveToLocalStorage] = useLocalStorage();
  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const { digitalPaymentMethod, customer } = customerData || {};
  const {
    bankAccountFirstName,
    bankAccountLastName,
    iban,
    key,
    contract: { billingDay } = {},
  } = customer || {};
  const { data: paymentsData, loading: paymentsLoading } = useQuery(GET_PAYMENT);
  const [policyCoverageInfo, setPolicyCoverageInfo] = useState(null);
  const [contractStatusInfo, setContractStatusInfo] = useState({});
  const [failedPayments, setFailedPayments] = useState([]);

  const { data: claimsData, loading: claimsLoading } = useQuery(GET_CLAIMS);

  // TODO: dentalMissingIban when the story of dentolo is ready
  const [missingIban, setMissingIban] = useState(true);

  useEffect(() => {
    const isContractMissingIbanExistsInStorage = getFromLocalStorage(
      `customer-missing-iban-${key}`
    );

    setMissingIban(isContractMissingIbanExistsInStorage);
  }, [getFromLocalStorage, key]);

  const { active: hasActiveDigitalPaymentMethod } = digitalPaymentMethod || {};
  const [showBankDetailsEditor, setShowBankDetailsEditor] = useState(false);
  const { type: insuranceType } = useInsuranceInfo();
  const contractNotCancelled = !customer?.contract?.cancelationDate;

  useEffect(() => {
    const filteredFailedPayments = getFailedPayments(paymentsData?.payments);

    if (filteredFailedPayments?.length) {
      setFailedPayments(filteredFailedPayments);
    }
  }, [paymentsData, paymentsLoading]);

  useEffect(() => {
    if (!customerLoading && !!customer) {
      const locale = intl?.locale;
      const policyCategory = customer?.contract?.policyCategory;
      const imCoverage = customer?.contract?.imCoverage;
      fetchPolicyCoverageInfo(policyCategory, imCoverage, locale).then(setPolicyCoverageInfo);
      const { startingAt, cancelationDate, status, addOnTrialMonth } = customer?.contract || {};
      setContractStatusInfo(
        parseContractStatusInfo(startingAt, cancelationDate, status, addOnTrialMonth)
      );
    }
  }, [customerLoading, customer, intl]);

  if (customerLoading) return null;

  // TODO: dismissDentalMissingIban when the story of dentolo is ready
  const dismissMissingIbanNotification = () => {
    saveToLocalStorage(`customer-missing-iban-${key}`, true);
    setMissingIban(true);
  };

  const showEditIban = () => {
    setShowBankDetailsEditor(true);
  };

  const showReimbursementCard = showRimburseMap[`${insuranceType}`];
  const showContractCancelLink = showSelfCancellationMap[`${insuranceType}`];

  if (customer?.contract) {
    return (
      <>
        <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
          <Container>
            <StyledFlexContainer>
              <PageTitle>
                <FormattedMessage id="policy.info.title" />
              </PageTitle>

              {contractStatusInfo?.label && !failedPayments?.length && (
                <StyledTag type={insuranceType} status={contractStatusInfo?.statusColor}>
                  {contractStatusInfo?.label}
                </StyledTag>
              )}
            </StyledFlexContainer>

            {!iban && hasActiveDigitalPaymentMethod && !missingIban && (
              <StyledMissingIbanBannerContainer>
                <MissingIban
                  onDismiss={dismissMissingIbanNotification}
                  title={intl.formatMessage({ id: 'policy.info.missing.iban.title' })}
                  message1={intl.formatMessage({ id: 'policy.info.missing.iban.message1' })}
                  message2={intl.formatMessage({ id: 'policy.info.missing.iban.message2' })}
                  linkText={intl.formatMessage({ id: 'policy.info.missing.iban.link' })}
                  onClick={showEditIban}
                />
              </StyledMissingIbanBannerContainer>
            )}

            <PolicyCard
              customer={customer}
              addons={policyCoverageInfo?.addons}
              contractStatusInfo={contractStatusInfo}
            />

            {!iban && hasActiveDigitalPaymentMethod && missingIban && (
              <StyledMissingIbanBannerContainer>
                <MissingIban
                  title={intl.formatMessage({ id: 'policy.info.missing.iban.title' })}
                  message1={intl.formatMessage({ id: 'policy.info.missing.iban.message1' })}
                  message2={intl.formatMessage({ id: 'policy.info.missing.iban.message2' })}
                  linkText={intl.formatMessage({ id: 'policy.info.missing.iban.link' })}
                  onClick={showEditIban}
                />
              </StyledMissingIbanBannerContainer>
            )}

            {showReimbursementCard &&
              !claimsLoading &&
              Array.isArray(claimsData.claims) &&
              claimsData.claims.length <= 0 && <LinkToClaimsPage />}

            <PromotionCarousel />

            <PageTitle style={{ marginTop: '2.5rem' }}>
              <FormattedMessage id="policy.covergae_info.title" />
            </PageTitle>

            <PolicyDetailsCard policyCoverageInfo={policyCoverageInfo} customer={customer} />

            {showContractCancelLink && contractNotCancelled && (
              <StyledContractCancelLinkParagraph>
                <FormattedMessage id="policy.cancellation.text1" />{' '}
                <Link to="/contract-cancellation">
                  <FormattedMessage id="policy.cancellation.here_link" />
                </Link>
                <FormattedMessage id="policy.cancellation.text2" />
              </StyledContractCancelLinkParagraph>
            )}
          </Container>
        </CSSTransition>

        <InsureAnotherPetBanner customer={customer} />

        {!iban && (
          <CustomerDetailsEditFormWrapper
            isOpen={showBankDetailsEditor}
            title={intl.formatMessage({ id: 'bank.details.add.missed.iban.modal.title' })}
            missingIban
            hasActiveDigitalPaymentMethod={hasActiveDigitalPaymentMethod}
            onClose={() => setShowBankDetailsEditor(false)}
            data={{
              firstName: bankAccountFirstName,
              lastName: bankAccountLastName,
              iban,
              billingDay,
            }}
            formComponent={<BankDetailsEditor />}
          />
        )}
      </>
    );
  }
  return <ErrorPlaceholder />;
};

export default PolicyInfo;
