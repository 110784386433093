import React from 'react';

import {
  PET_LIABILITY_INCIDENT_NOTE,
  PET_LIABILITY_REASON,
  useNewPetLiabilityFormDataValue,
} from '../../claimFormData/newClaimFormDataPetLiability';
import {
  useIncidentNotePetLiabilityBackButtonLink,
  useIncidentNotePetLiabilityNextButtonLink,
} from './useIncidentNotePetLiability';

import CustomerNote from './CustomerNote';

const IncidentNotePetLiability = () => {
  const [liabilityReason] = useNewPetLiabilityFormDataValue(PET_LIABILITY_REASON);
  const [incidentNote, setIncidentNote] = useNewPetLiabilityFormDataValue(
    PET_LIABILITY_INCIDENT_NOTE
  );

  const backButtonLink = useIncidentNotePetLiabilityBackButtonLink();
  const nextButtonLink = useIncidentNotePetLiabilityNextButtonLink(liabilityReason);

  return (
    <CustomerNote
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId="pet_liability.new_claim.incident_note.page_title"
      pageDescriptionId="pet_liability.new_claim.incident_note.page_description"
      placeholder="pet_liability.new_claim.incident_note.placeholder"
      customerNote={incidentNote}
      setCustomerNote={setIncidentNote}
      maxWords={150}
      errorMessageId="pet_liability.new_claim.incident_note.placeholder"
    />
  );
};

export default IncidentNotePetLiability;
