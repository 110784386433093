import { SIGN_IN, SWITCH_CUSTOMER } from './mutations';
import {
  GET_CLAIMS,
  GET_CUSTOMER,
  GET_CUSTOMERS,
  GET_DOCUMENTS,
  GET_PAYMENT,
  GET_CUSTMER_ASSIGNMENT_COUNT,
  GET_CCDOCUMENTS,
  GET_VIDEO_CONSULTATIONS,
} from './queries';
import { INVALID_TOKEN } from '../configs/apollo.config';
import { useTrackZurichCustomer } from './commonHooks';

export const identifyCustomer = (client, history) => {
  return client
    .query({ query: GET_CUSTOMER })
    .then((res) => {
      if (res.data && Object.keys(res.data.customer).length) {
        const contract = res?.data?.customer?.contract;
        if (contract?.riskCarrier === 'zurich') {
          useTrackZurichCustomer();
        }

        if (contract?.signed) {
          history.push('/dashboard');
        } else {
          history.push('/confirm-documents');
        }
      }
    })
    .catch(() => {
      history.push('/dashboard');
    });
};

const catchInvalidError = (history, error) => {
  const { message } = error || {};
  if (message?.includes(INVALID_TOKEN)) {
    history.push('/login?invalid=true');
    return;
  }
  history.push('/login');
};

export const signInUser = (client, rememberMe, jwt, uuid, dateOfBirth) => {
  return client.mutate({
    mutation: SIGN_IN,
    variables: {
      dateOfBirth,
      jwt,
      rememberMe: rememberMe === 'true',
      uuid,
    },
    refetchQueries: [{ query: GET_CUSTOMERS }, { query: GET_CUSTOMER }, { query: GET_PAYMENT }],
  });
};

export const switchCustomer = (client, history) => (uuid) => {
  return client
    .mutate({
      mutation: SWITCH_CUSTOMER,
      variables: {
        uuid,
      },
      refetchQueries: [
        { query: GET_CUSTOMERS },
        { query: GET_CUSTOMER },
        { query: GET_PAYMENT },
        { query: GET_CLAIMS },
        { query: GET_DOCUMENTS },
        { query: GET_CUSTMER_ASSIGNMENT_COUNT },
        { query: GET_CCDOCUMENTS },
        { query: GET_VIDEO_CONSULTATIONS },
      ],
    })
    .then((res) => {
      if (res?.data && !res?.data?.switchCustomer?.errors?.length) {
        return identifyCustomer(client, history);
      }

      return null;
    })
    .catch((error) => {
      catchInvalidError(history, error);
    });
};
