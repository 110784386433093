import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  StyledReferralStatusDescription,
  StyledReferralStatusDescriptionContainer,
  StyledReferralTitle,
} from './ReferralPage.styled';

function ReferralStatusDescription(props) {
  const { titleId, statusDescription } = props;

  return (
    <StyledReferralStatusDescriptionContainer>
      <StyledReferralTitle>
        <FormattedMessage id={titleId} />
      </StyledReferralTitle>

      {statusDescription.map((description) => (
        <StyledReferralStatusDescription key={description?.id}>
          {description?.formattedDescription}
        </StyledReferralStatusDescription>
      ))}
    </StyledReferralStatusDescriptionContainer>
  );
}

export default ReferralStatusDescription;
