import { styled, css } from '@mui/material';
import PropTypes from 'prop-types';

const variantStyles = (theme, variant) =>
  ({
    info: css`
      color: ${theme.palette.common.white};
      background-color: ${theme.palette.main.product_blue};
    `,
    success: css`
      color: ${theme.palette.common.white};
      background-color: ${theme.palette.success.main};
    `,
    error: css`
      color: ${theme.palette.common.white};
      background-color: ${theme.palette.error.main};
    `,
    transparent: css`
      border: 1px solid ${theme.palette.main.professional_blue};
      background-color: transparent;
    `,
    pending: css`
      color: ${theme.palette.common.white};
      background-color: ${theme.palette.main.product_blue};
    `,
    cancelled: css`
      color: ${theme.palette.common.white};
      background-color: ${theme.palette.error.main};
    `,
    sent: css`
      color: ${theme.palette.common.white};
      background-color: ${theme.palette.success.main};
    `,
  })[`${variant}`];

const StyledTag = styled('span')(
  ({ theme, variant, disabled }) => css`
    display: inline-flex;
    align-items: center;
    padding: 0 6px;
    height: 1.375rem;
    font-size: 0.75rem;
    font-weight: 700;
    color: ${theme.palette.main.professional_blue};
    border-radius: 5px;

    ${variantStyles(theme, variant, disabled)};
  `
);

export default StyledTag;

StyledTag.defaultProps = {
  variant: 'info',
};

StyledTag.propTypes = {
  variant: PropTypes.string,
};
