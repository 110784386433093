import React from 'react';

import {
  VITOLO_DOCUMENT_TYPE,
  VITOLO_DOCUMENT_TYPE_INVOICE,
  VITOLO_DOCUMENT_TYPE_TREATMENT_PLAN,
  useNewVitoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import {
  useSelectDocumentTypeVitoloBackButtonLink,
  useSelectDocumentTypeVitoloNextButtonLink,
} from './selectDocumentTypeVitoloHooks';

import ListWithRadioButton from '../common/ListWithRadioButton';

const documentTypeConfig = [
  {
    label: 'vitolo.new_claim.select_document_type.invoice',
    value: VITOLO_DOCUMENT_TYPE_INVOICE,
  },
  {
    label: 'vitolo.new_claim.select_document_type.treatment_plan',
    value: VITOLO_DOCUMENT_TYPE_TREATMENT_PLAN,
  },
];

const SelectDocumentTypeVitolo = () => {
  const [documentType, setDocumentType] = useNewVitoloFormDataValue(VITOLO_DOCUMENT_TYPE);

  const backButtonLink = useSelectDocumentTypeVitoloBackButtonLink();
  const nextButtonLink = useSelectDocumentTypeVitoloNextButtonLink();

  const setDocumentTypeWithExtraSteps = (newDocumentType) => {
    setDocumentType(newDocumentType);
  };

  return (
    <ListWithRadioButton
      name="document_type_vitolo"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId="vitolo.new_claim.select_document_type.page_title"
      listConfig={documentTypeConfig}
      selected={documentType}
      setSelected={setDocumentTypeWithExtraSteps}
    />
  );
};

export default SelectDocumentTypeVitolo;
