import React from 'react';

import { imgLinks, usePreloadTimeout } from '../../shared';

const inlineStyles = {
  opacity: 0,
  pointerEvents: 'none',
  position: 'absolute',
  left: 0,
  top: 0,
  overflow: 'hidden',
  width: '0.0625rem',
  height: '0.0625rem',
};

const PreloadAssets = () => {
  const [preload] = usePreloadTimeout();

  return (
    <div className="preload" style={inlineStyles}>
      {preload && Object.values(imgLinks).map((img) => <img src={img} key={img} alt="" />)}
    </div>
  );
};

export default PreloadAssets;
