import React from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { PropTypes } from 'prop-types';

import { GET_CUSTOMERS, useSelectCustomer, useJwt } from '../../shared';

const NavMenuChangeCustomerLink = (props) => {
  const { location } = props;

  const urlValues = queryString.parse(location.search);
  const jwt = useJwt(location);

  const getCustomers = useQuery(GET_CUSTOMERS, { variables: { jwt } });
  const numOfCustomers =
    getCustomers.data.customers && getCustomers.data.customers.length
      ? getCustomers.data.customers.length
      : 0;

  if (useSelectCustomer(location.pathname) || urlValues.auth || numOfCustomers <= 1) {
    return null;
  }

  return (
    <Link {...props} variant="nostyle" to="/select-customer">
      <div className="small">
        <FormattedMessage id="sign.out.change.user" />
      </div>
    </Link>
  );
};

NavMenuChangeCustomerLink.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
};

export default NavMenuChangeCustomerLink;
