import { css, styled } from '@mui/material';

import Input from '../Input';

export const StyledInputCurrencyContainer = styled('div')`
  position: relative;
`;

export const StyledInputCurrency = styled(Input)`
  padding-right: 2.5rem;
`;

export const StyledSymbolSuffix = styled('span')(
  ({ theme }) => css`
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    font-size: 1rem;
    font-weight: 450;
    color: ${theme.palette.main.professional_blue};
  `
);
