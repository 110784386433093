import React from 'react';

import Footer from '../../features/footer/Footer';
import PleaseSignIn from '../ui/PleaseSignIn';
import HeaderWithActions from '../Header/HeaderWithActions';
import StyledPageContent from './Page.styled';

const PageWithFullHeader = ({ children }) => {
  return (
    <>
      <StyledPageContent>
        <HeaderWithActions />
        <PleaseSignIn>{children}</PleaseSignIn>
      </StyledPageContent>

      <Footer />
    </>
  );
};

export default PageWithFullHeader;
