import React from 'react';

import useInsuranceInfo from '../../../hooks/useInsuranceInfo';
import {
  imgLinks,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
} from '../../../shared';

import LandingPage from './LandingPage';

export default function LandingPageIndex() {
  const { type: insuranceType } = useInsuranceInfo();

  switch (insuranceType) {
    case INSURANCE_TYPE_DENTOLO:
      return (
        <LandingPage
          pageTitleId="cancellation.contract.title"
          pageDesktopImage={imgLinks['icon.user.dental.cancellation.contract.desktop']}
          pageMobileImage={imgLinks['icon.user.dental.cancellation.contract.mobile']}
          paper
        />
      );
    case INSURANCE_TYPE_PETOLO:
      return (
        <LandingPage
          pageTitleId="cancellation.contract.title"
          pageDesktopImage={imgLinks['icon.user.pet.cancellation.contract.desktop']}
          pageMobileImage={imgLinks['icon.user.pet.cancellation.contract.mobile']}
          paper
        />
      );
    case INSURANCE_TYPE_VITOLO:
      return (
        <LandingPage
          pageTitleId="cancellation.contract.title"
          pageDesktopImage={imgLinks['icon.user.health.cancellation.contract.desktop']}
          pageMobileImage={imgLinks['icon.user.health.cancellation.contract.mobile']}
          paper={false}
        />
      );
    default:
      return null;
  }
}
