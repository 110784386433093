import React from 'react';

import {
  useNewPetoloFormDataValue,
  PETOLO_IS_EXISTING_CLAIM_ANSWER,
  PETOLO_IS_EXISTING_CLAIM_ANSWER_YES,
  PETOLO_IS_EXISTING_CLAIM_ANSWER_NO,
} from '../../claimFormData/newClaimFormData';
import {
  useExistingClaimQuestionPetoloBackButtonLink,
  useExistingClaimQuestionPetoloNextButtonLink,
} from './existingClaimQuestionPetoloHooks';

import ListWithRadioButton from '../common/ListWithRadioButton';

const existingClaimQuestionConfig = [
  {
    label: 'petolo.new_claim.existing_claim_question.option.yes',
    value: PETOLO_IS_EXISTING_CLAIM_ANSWER_YES,
  },
  {
    label: 'petolo.new_claim.existing_claim_question.option.no',
    value: PETOLO_IS_EXISTING_CLAIM_ANSWER_NO,
  },
];

export default function ExistingClaimQuestionPetolo() {
  const [isExistingClaim, setIsExistingClaim] = useNewPetoloFormDataValue(
    PETOLO_IS_EXISTING_CLAIM_ANSWER
  );

  const backButtonLink = useExistingClaimQuestionPetoloBackButtonLink();
  const nextButtonLink = useExistingClaimQuestionPetoloNextButtonLink(isExistingClaim);

  return (
    <ListWithRadioButton
      name="is_existing_claim_question"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId="petolo.new_claim.existing_claim_question.page.title"
      listConfig={existingClaimQuestionConfig}
      selected={isExistingClaim}
      setSelected={setIsExistingClaim}
    />
  );
}
