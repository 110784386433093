import {
  CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT,
  useClaimUploadFlowSource,
} from '../../claimUploadFlowSource';
import { DENTOLO_EXISTING_CLAIM_OPTION_NOT_SELECTED } from '../../claimFormData/newClaimFormData';
import { DENTOLO_EDIT_STEP_UPLOAD_DOCUMENTS, PARAM_CLAIM_ID } from '../../edit/editClaimFormSteps';
import {
  DENTOLO_STEP_EXISTING_CLAIM_QUESTION,
  DENTOLO_STEP_UPLOAD_DOCUMENTS,
} from '../../new/newClaimsFormSteps';

export const useSelectExistingClaimDentoloBackButtonLink = () => {
  const [source, withSource] = useClaimUploadFlowSource();

  if (source === CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT) {
    return '/user-claims-info';
  }

  return withSource(DENTOLO_STEP_EXISTING_CLAIM_QUESTION);
};

export const useSelectExistingClaimDentoloNextButtonLink = (selectedClaimId) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (!!selectedClaimId && selectedClaimId !== DENTOLO_EXISTING_CLAIM_OPTION_NOT_SELECTED) {
    const nextButtonLink = DENTOLO_EDIT_STEP_UPLOAD_DOCUMENTS.replace(
      PARAM_CLAIM_ID,
      selectedClaimId
    );
    return withSource(nextButtonLink);
  }

  return withSource(DENTOLO_STEP_UPLOAD_DOCUMENTS);
};
