import { Link } from 'react-router-dom';
import { css } from '@mui/material';
import styled from '@emotion/styled/macro';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

import { Icon, Paper, Tag } from '../common';
import { navMenuUnderlineBaseStyle } from '../NavMenu/styled/NavMenuDesktop.styled';

const paymentDetailsLinkBaseStyle = ({ theme }) => css`
  position: relative;
  width: fit-content;
  font-size: 1rem;
  text-decoration: none;

  ${navMenuUnderlineBaseStyle({ theme })};

  &::after {
    transform: scaleX(1);
    bottom: 0;
    transform-origin: bottom left;
  }

  &:hover,
  &:focus {
    &::after {
      transform: scaleX(0);
      transform-origin: bottom right;
    }
  }
`;

export const StyledPaymentStatusDocument = styled(Paper)`
  margin-bottom: 1.5rem;

  > h4 {
    margin-bottom: 1.5rem;
  }
`;

export const StyledPaymentStatusDocumentLink = styled(Link)(
  ({ theme }) => css`
    ${paymentDetailsLinkBaseStyle({ theme })};

    color: ${theme.palette.common.white};

    &::after {
      background-color: ${theme.palette.common.white};
    }

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.125rem;
    }
  `
);

export const StyledPaymentStatusBlock = styled(Paper)`
  position: relative;

  & + & {
    margin-top: 1rem;
  }
`;

export const StyledPaymentStatusBlockRepayLink = styled('a')(
  ({ theme }) => css`
    ${paymentDetailsLinkBaseStyle({ theme })};

    display: block;
    margin-top: 1rem;
    font-weight: 700;
    color: ${theme.palette.main.professional_blue};
    cursor: pointer;

    &::after {
      background-color: ${theme.palette.main.professional_blue};
    }

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.25rem;
    }
  `
);

export const StyledPaymentStatusBlockIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  width: 1.5rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 2rem;
    height: 2rem;
  }
`;

export const StyledPaymentStatusBlockTag = styled(Tag)`
  display: flex;
  margin-top: 1rem;
  width: fit-content;
`;
