import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import IBAN from 'iban';
import { styled } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';

import { getBillingDays, UPDATE_BANK_DETAILS, GET_CUSTOMER } from '../../shared';
import useForm from '../../shared/useForm';
import { sepaCheck } from '../../utils/iban';
import { MAX_WIDTH_SM } from '../../theme/breakpoints';

import { ErrorMessage } from '../common/FormElements';
import { DropdownSearch, Button, Paragraph } from '../ui';
import { FormLabel, Input } from '../common';
import {
  StyledForm,
  StyledFormLabel,
  StyledFormGroup,
  StyledButtonContainer,
} from './CustomerDetailsEditor.styled';

const StyledFormControl = styled('div')`
  & + & {
    margin-top: 1.5rem;
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: ${MAX_WIDTH_SM}) {
    width: 100%;
  }
`;

function validateForm(values, dirty) {
  const errors = {};

  if (!values?.firstName) {
    errors.firstName = 'bank.details.form.error.firstname';
  }

  if (!values?.lastName) {
    errors.lastName = 'bank.details.form.error.lastname';
  }

  if (dirty?.iban) {
    if (values?.iban === '') {
      errors.iban = 'bank.details.form.error.iban_empty';
    } else if (!IBAN.isValid(values?.iban)) {
      errors.iban = 'bank.details.form.error.iban_invalid';
    } else if (!sepaCheck(values.iban)) {
      errors.iban = 'bank.details.form.error.iban_not_sepa';
    }
  }

  if (!values?.billingDay) {
    errors.billingDay = 'bank.details.form.error.billingDay';
  }

  return errors;
}

function BankDetailsEditor({
  data,
  hasActiveDigitalPaymentMethod,
  missingIban,
  onSuccess,
  onError,
}) {
  const intl = useIntl();

  const [isProcessing, setIsProcessing] = useState(false);

  const {
    values,
    errors: formErrors,
    dirty,
    isDirty,
    handleChange,
    handleCustomChange,
    handleSubmit,
    updateValue,
  } = useForm(data, handleFormSubmit, validateForm);

  const handleIbanFocus = () => {
    if (data?.iban !== values?.iban) return;

    updateValue('iban', '');
  };

  const handleIbanBlur = () => {
    if (!dirty?.iban && values?.iban === '') {
      updateValue('iban', data?.iban);
    }
  };

  const [updateBankDetails] = useMutation(UPDATE_BANK_DETAILS, {
    variables: {
      ...values,
      iban: !dirty?.iban ? null : values?.iban,
    },
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  async function handleFormSubmit(ev) {
    if (ev) ev.preventDefault();

    try {
      setIsProcessing(true);

      const res = await updateBankDetails();
      const { data: respData } = res || {};

      if (respData?.updateBankDetails.status !== 'success')
        throw new Error(respData?.updateBankDetails.errors);

      onSuccess();
    } catch (err) {
      onError();
    } finally {
      setIsProcessing(false);
    }
  }

  const billingDays = getBillingDays(intl);
  const isBtnDisabled = !isDirty || Object.keys(formErrors).length > 0 || isProcessing;

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormGroup>
        <StyledFormLabel htmlFor="firstName">
          <FormattedMessage id="common.first.name" />
        </StyledFormLabel>

        <Input
          id="firstName"
          name="firstName"
          placeholder="Alex"
          value={values?.firstName || ''}
          onChange={handleChange}
        />
        {formErrors?.firstName && (
          <ErrorMessage>
            <FormattedMessage id={formErrors?.firstName} />
          </ErrorMessage>
        )}
      </StyledFormGroup>

      <StyledFormGroup>
        <StyledFormLabel htmlFor="lastName">
          <FormattedMessage id="common.last.name" />
        </StyledFormLabel>

        <Input
          id="lastName"
          name="lastName"
          placeholder="Müller"
          value={values?.lastName || ''}
          onChange={handleChange}
        />
        {formErrors?.lastName && (
          <ErrorMessage>
            <FormattedMessage id={formErrors?.lastName} />
          </ErrorMessage>
        )}
      </StyledFormGroup>

      <StyledFormGroup>
        <StyledFormLabel htmlFor="iban">
          <FormattedMessage id="policy.details.billing.info.iban" />
        </StyledFormLabel>

        <Input
          id="iban"
          name="iban"
          placeholder="DE12 1111 2222 3333 4444 55"
          value={values?.iban}
          onChange={handleChange}
          onFocus={handleIbanFocus}
          onBlur={handleIbanBlur}
        />
        {formErrors?.iban && (
          <ErrorMessage>
            <FormattedMessage id={formErrors?.iban} />
          </ErrorMessage>
        )}
      </StyledFormGroup>

      {!hasActiveDigitalPaymentMethod ? (
        <>
          <StyledFormControl>
            <FormLabel htmlFor="contribution_type">
              <FormattedMessage id="policy.details.billing.info.billing_day" />
            </FormLabel>
            <DropdownSearch
              id="contribution_type"
              options={billingDays}
              onSelect={(val) => handleCustomChange('billingDay', val.value)}
              defaultValue={billingDays.find((val) => val.value === values?.billingDay)}
            />
            {formErrors?.billingDay && (
              <ErrorMessage>
                <FormattedMessage id={formErrors?.billingDay} />
              </ErrorMessage>
            )}
          </StyledFormControl>

          <Paragraph type="subtext" style={{ marginTop: '1.5rem' }}>
            <FormattedMessage id="bank.details.form.note_1" />
          </Paragraph>

          <Paragraph type="subtext" style={{ marginTop: '1rem' }}>
            <FormattedMessage id="bank.details.form.note_2" />
          </Paragraph>
        </>
      ) : (
        <Paragraph type="subtext" style={{ marginTop: '1.5rem' }}>
          {missingIban ? (
            <FormattedMessage id="bank.details.add.missed.iban.modal.desc" />
          ) : (
            <FormattedMessage id="bank.details.edit_refund_account.modal.desc" />
          )}
        </Paragraph>
      )}

      <StyledButtonContainer style={{ justifyContent: 'center' }}>
        <StyledButton type="submit" disabled={isBtnDisabled}>
          <FormattedMessage id="bank.details.form.submit_button" />
        </StyledButton>
      </StyledButtonContainer>
    </StyledForm>
  );
}

export default BankDetailsEditor;
