import React, { useCallback, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import queryString from 'query-string';
import { Route, useHistory, matchPath, Prompt } from 'react-router-dom';

import useBeforeunload from '../../hooks/useBeforeunload';
import { GET_CLAIMS, GET_VIDEO_CONSULTATIONS } from '../../shared';
import {
  clearNewPetoloClaimFormData,
  relevantPetHealthClaimsForList,
} from './claimFormData/newClaimFormData';
import { PETOLO_STEP_EXISTING_CLAIMS, PETOLO_STEP_MAIN } from './new/newClaimsFormSteps';
import { PARAM_CLAIM_ID, PETOLO_EDIT_STEP_MAIN } from './edit/editClaimFormSteps';
import {
  CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT,
  CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT,
} from './claimUploadFlowSource';

import NewPetoloClaim from './new/NewPetoloClaim';
import EditPetoloClaim from './edit/EditPetoloClaim';

export default function PetoloClaims() {
  const intl = useIntl();
  const history = useHistory();

  const routeMatch = matchPath(history?.location?.pathname, {
    path: `${PETOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}`,
  });

  const claimId =
    routeMatch && routeMatch.params[`${PARAM_CLAIM_ID.substring(1)}`]
      ? routeMatch.params[`${PARAM_CLAIM_ID.substring(1)}`]
      : '';

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [claimSubmitted, setClaimSubmitted] = useState(false);
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

  // Preload video consultations so that the data is already available when the SelectVideoConsultationPetolo page is loaded
  useQuery(GET_VIDEO_CONSULTATIONS);

  const { data: claimsData } = useQuery(GET_CLAIMS);

  const relevantClaims = relevantPetHealthClaimsForList(claimsData?.claims || []);

  // Prompt for page refresh
  const alertUser = useCallback((ev) => {
    ev.preventDefault();
    ev.returnValue = ''; // eslint-disable-line no-param-reassign
  }, []);

  useBeforeunload(alertUser);

  useEffect(() => {
    if (isInitialPageLoad) {
      setIsInitialPageLoad(false);
      setSelectedDocuments([]);
      clearNewPetoloClaimFormData();

      const newClaimPageUrl = PETOLO_STEP_MAIN;
      const isNewClaimPage = history?.location?.pathname === newClaimPageUrl;

      const selectExistingClaimPageUrl = PETOLO_STEP_EXISTING_CLAIMS;
      const isSelectExistingClaimPage = history?.location?.pathname === selectExistingClaimPageUrl;

      const existingPageUrl = `${PETOLO_EDIT_STEP_MAIN}/${claimId}`;
      const isExistingClaimPage = history?.location?.pathname === existingPageUrl;

      const params = queryString.parse(history?.location?.search);
      const source = params?.source;

      if (!isNewClaimPage && !isExistingClaimPage && !isSelectExistingClaimPage) {
        if (!!claimId && source === CLAIM_UPLOAD_FLOW_SOURCE_UPLOAD_DOCUMENT) {
          history.push(`${existingPageUrl}${history?.location?.search || ''}`);
        } else if (source === CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT) {
          history.push(`${selectExistingClaimPageUrl}${history?.location?.search || ''}`);
        } else if (source) {
          history.push(`${newClaimPageUrl}${history?.location?.search || ''}`);
        } else {
          history.push('/user-claims-info');
        }
      }
    }
  }, [isInitialPageLoad, history, claimId]);

  const showPrompt = !(claimSubmitted || selectedDocuments?.length < 1);

  return (
    <>
      {/* Prompt user data will be lost if navigate outside of the claims flow */}
      <Prompt
        when={showPrompt}
        message={({ pathname }) =>
          matchPath(pathname, { path: PETOLO_STEP_MAIN }) ||
          matchPath(pathname, { path: `${PETOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}` })
            ? true
            : intl.formatMessage({ id: 'new_claim.prompt.message' })
        }
      />

      <Route path={PETOLO_STEP_MAIN}>
        <NewPetoloClaim
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          setClaimSubmitted={setClaimSubmitted}
          relevantClaims={relevantClaims}
        />
      </Route>

      <Route path={`${PETOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}`}>
        <EditPetoloClaim
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          setClaimSubmitted={setClaimSubmitted}
          relevantClaims={relevantClaims}
        />
      </Route>
    </>
  );
}
