import { css, styled } from '@mui/material';

export const StyledFooterContainer = styled('footer')(
  ({ theme }) => css`
    flex-shrink: 0;
    padding: 1.5rem 1rem;
    background-color: ${theme.palette.background.footer};
  `
);

export const StyledFooterWrapper = styled('div')`
  max-width: 960px;
  margin: auto;
`;

export const StyledFooterLinksWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 0.5rem;
    font-size: 1rem;
    font-weight: 450;

    > button {
      margin: 0;
      padding: 0;
      font-size: 1rem;
      font-weight: 450;
      background: transparent;
      border: none;
    }

    > a,
    > button {
      position: relative;
      color: ${theme.palette.text.footerLink};
      text-decoration: none;
      transition: color 0.2s ease-out;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        height: 2px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: ${theme.palette.text.footerLink};
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.2s ease-out;
      }

      &:hover,
      &:active,
      &:focus {
        color: ${theme.palette.text.footerLinkHover};
      }

      &:active,
      &:focus {
        &::after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  `
);

export const StyledFooterLinkSeparator = styled('span')(
  ({ theme }) => css`
    margin: 0 1rem;
    font-size: 1.25rem;
    color: ${theme.palette.text.footerLink};
  `
);

export const StyledFooterCopyright = styled('div')(
  ({ theme }) => css`
    margin-top: 1.5rem;
    font-size: 1rem;
    font-weight: 450;
    color: ${theme.palette.text.copyright};
  `
);

export const StyledBranchName = styled('div')(
  ({ theme }) => css`
    margin-top: 1em;
    color: ${theme.palette.gray.gray};
    text-align: right;
  `
);
