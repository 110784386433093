import { styled, css } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

export const StyledNotificationTitle = styled('p')(
  ({ theme }) => css`
    font-size: 1rem;
    font-weight: 700;
    color: ${theme.palette.main.professional_blue};
    line-height: 1.75rem;
    text-align: center;
    margin-bottom: 1rem;

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1rem;
      line-height: 24px;
    }
  `
);

export const StyledNotificationDescription = styled(StyledNotificationTitle)`
  font-weight: 450;
`;

export const StyledNotificationButton = styled('p')`
  font-size: 1rem;
`;

export const StyledPaymentStatusContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const StyledPStatusIconContainer = styled('div')`
  margin-bottom: 1rem;
`;
