import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { datadogLogs } from '@datadog/browser-logs';

import { DATADOG_404_ERROR_MESSAGE, INSURANCE_TYPE_PETOLO, imgLinks } from '../../../shared';

import { Container } from '../../../components/common';
import { Button } from '../../../components/ui';
import {
  StyledButtonContainer,
  StyledImg,
  StyledBackButton,
  StyledText,
} from './Petolo404Page.styled';

const Petolo404Page = () => {
  const history = useHistory();
  const intl = useIntl();

  useEffect(() => {
    datadogLogs.logger.error(DATADOG_404_ERROR_MESSAGE, { product: INSURANCE_TYPE_PETOLO });
  }, []);

  return (
    <Container>
      <StyledBackButton to="/dashboard" label={<FormattedMessage id="common.button.back.home" />} />

      <StyledImg src={imgLinks['petolo.404.page']} alt="404" />
      <StyledText>
        {intl.formatMessage({
          id: 'petolo.404.page.message',
        })}
      </StyledText>
      <StyledButtonContainer>
        <Button variant="primary" onClick={() => history.push('/dashboard')}>
          <FormattedMessage id="common.button.back.home" />
        </Button>
      </StyledButtonContainer>
    </Container>
  );
};

export default Petolo404Page;
