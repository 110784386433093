import React from 'react';

function PetoloDocumentIllustration() {
  return (
    <svg
      width="102"
      height="111"
      viewBox="0 0 102 111"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.1011 2.14461L3.74207 17.7215C2.60427 17.977 1.88905 19.1065 2.14458 20.2443L21.5771 106.771C21.8326 107.909 22.9621 108.624 24.0999 108.369L93.4589 92.792C94.5967 92.5365 95.3119 91.407 95.0564 90.2692L75.6239 3.7421C75.3684 2.6043 74.2389 1.88908 73.1011 2.14461ZM3.43361 16.348C1.53728 16.7739 0.345247 18.6564 0.771131 20.5528L20.2036 107.08C20.6295 108.976 22.512 110.168 24.4084 109.742L93.7674 94.1655C95.6637 93.7396 96.8557 91.857 96.4299 89.9607L76.9974 3.43365C76.5715 1.53731 74.6889 0.345279 72.7926 0.771164L3.43361 16.348Z"
        fill="#003264"
      />
      <path
        d="M17.2323 48.4899L16.248 44.1069L14.7892 44.4346L14.5783 43.4954C15.0194 43.3892 15.3569 43.1861 15.5907 42.8861C15.8308 42.5847 15.9434 42.2553 15.9287 41.8979L17.0414 41.648L18.5134 48.2021L17.2323 48.4899Z"
        fill="#003264"
      />
      <path
        d="M20.1301 44.402C20.2072 44.7453 20.3032 45.0562 20.4181 45.3345C20.5315 45.606 20.6693 45.8261 20.8316 45.9948C20.9614 46.1354 21.1125 46.2394 21.2848 46.3067C21.4556 46.3674 21.65 46.3732 21.8682 46.3242C22.0927 46.2738 22.266 46.1854 22.3882 46.0589C22.5089 45.9258 22.5978 45.7679 22.6551 45.5853C22.7359 45.362 22.7695 45.1034 22.7559 44.8095C22.7407 44.5088 22.6946 44.1867 22.6175 43.8434C22.5404 43.5 22.4451 43.1925 22.3317 42.921C22.2169 42.6427 22.0751 42.4199 21.9066 42.2527C21.7768 42.1121 21.6296 42.0108 21.4651 41.9487C21.299 41.8799 21.1038 41.8707 20.8794 41.9211C20.6612 41.9701 20.4886 42.0619 20.3617 42.1965C20.2332 42.3244 20.1404 42.4796 20.0832 42.6622C20.0086 42.884 19.9789 43.1453 19.994 43.446C20.0076 43.74 20.053 44.0586 20.1301 44.402ZM18.8397 44.6918C18.7445 44.2676 18.6904 43.8519 18.6775 43.4446C18.6631 43.0306 18.7202 42.6288 18.8488 42.2392C18.9757 41.8713 19.1817 41.5492 19.4667 41.273C19.7502 40.9901 20.1289 40.7955 20.6027 40.6891C21.0765 40.5827 21.4989 40.5974 21.8699 40.7333C22.2457 40.8611 22.5696 41.0642 22.8416 41.3425C23.1245 41.6397 23.3479 41.9785 23.5119 42.3589C23.6806 42.7312 23.8126 43.1294 23.9079 43.5536C24.0032 43.9777 24.0549 44.3975 24.063 44.8129C24.0759 45.2202 24.0181 45.6186 23.8895 46.0082C23.7626 46.3762 23.5573 46.7016 23.2738 46.9845C22.995 47.2593 22.6187 47.4499 22.1449 47.5563C21.6712 47.6627 21.2464 47.652 20.8706 47.5242C20.4934 47.3897 20.1687 47.1833 19.8966 46.9049C19.6138 46.6078 19.3911 46.2723 19.2286 45.8987C19.0646 45.5182 18.935 45.1159 18.8397 44.6918Z"
        fill="#003264"
      />
      <path
        d="M25.4343 45.7567C25.3768 45.5009 25.4095 45.2673 25.5323 45.0558C25.6598 44.8362 25.8419 44.6998 26.0788 44.6466C26.1973 44.62 26.3148 44.6184 26.4313 44.6417C26.5479 44.665 26.6534 44.7121 26.7479 44.7828C26.8471 44.8454 26.9313 44.929 27.0007 45.0337C27.0685 45.1316 27.1168 45.2445 27.1455 45.3724C27.1742 45.5003 27.1796 45.6264 27.1617 45.7507C27.1423 45.8682 27.1018 45.9798 27.0405 46.0856C26.9838 46.1831 26.9078 46.2674 26.8124 46.3383C26.717 46.4093 26.6101 46.458 26.4916 46.4846C26.2547 46.5378 26.0325 46.4958 25.8248 46.3586C25.6219 46.2132 25.4918 46.0126 25.4343 45.7567Z"
        fill="#003264"
      />
      <path
        d="M28.8692 42.4393C28.9463 42.7827 29.0423 43.0935 29.1572 43.3718C29.2706 43.6434 29.4084 43.8635 29.5707 44.0321C29.7005 44.1727 29.8516 44.2767 30.0239 44.3441C30.1946 44.4048 30.3891 44.4106 30.6073 44.3616C30.8317 44.3112 31.0051 44.2227 31.1273 44.0963C31.248 43.9631 31.3369 43.8052 31.3942 43.6226C31.475 43.3994 31.5086 43.1408 31.4949 42.8468C31.4798 42.5461 31.4337 42.2241 31.3566 41.8807C31.2794 41.5374 31.1842 41.2299 31.0708 40.9583C30.9559 40.68 30.8142 40.4573 30.6457 40.29C30.5159 40.1494 30.3687 40.0481 30.2042 39.9861C30.0381 39.9173 29.8429 39.9081 29.6185 39.9585C29.4003 40.0075 29.2277 40.0993 29.1008 40.2339C28.9723 40.3617 28.8795 40.5169 28.8223 40.6995C28.7477 40.9214 28.718 41.1826 28.7331 41.4833C28.7467 41.7773 28.7921 42.096 28.8692 42.4393ZM27.5788 42.7291C27.4835 42.305 27.4295 41.8893 27.4166 41.482C27.4022 41.068 27.4593 40.6662 27.5879 40.2766C27.7148 39.9086 27.9208 39.5866 28.2058 39.3104C28.4893 39.0275 28.868 38.8328 29.3418 38.7264C29.8155 38.62 30.2379 38.6348 30.609 38.7707C30.9847 38.8984 31.3087 39.1015 31.5807 39.3799C31.8636 39.677 32.087 40.0158 32.251 40.3962C32.4197 40.7685 32.5517 41.1668 32.647 41.5909C32.7422 42.0151 32.794 42.4349 32.8021 42.8503C32.815 43.2576 32.7572 43.656 32.6285 44.0456C32.5016 44.4135 32.2964 44.739 32.0129 45.0219C31.7341 45.2966 31.3578 45.4872 30.884 45.5936C30.4102 45.7 29.9855 45.6894 29.6097 45.5616C29.2324 45.4271 28.9078 45.2206 28.6357 44.9423C28.3529 44.6451 28.1302 44.3097 27.9677 43.936C27.8037 43.5556 27.674 43.1533 27.5788 42.7291Z"
        fill="#003264"
      />
      <path
        d="M33.4228 41.8516L35.1455 37.5821L36.7352 37.2251L37.6242 41.1839L38.6248 40.9592L38.8924 42.1508L37.8919 42.3755L38.2071 43.7793L36.9354 44.0649L36.6201 42.6611L33.7494 43.3058L33.4228 41.8516ZM35.79 38.9649L34.6226 41.858L36.3525 41.4695L35.79 38.9649Z"
        fill="#003264"
      />
      <path
        d="M39.7529 42.541C39.6954 42.2852 39.7281 42.0515 39.8508 41.8401C39.9783 41.6205 40.1605 41.4841 40.3974 41.4309C40.5159 41.4043 40.6334 41.4027 40.7499 41.426C40.8665 41.4493 40.972 41.4964 41.0665 41.5671C41.1657 41.6297 41.2499 41.7133 41.3193 41.818C41.3871 41.9159 41.4354 42.0288 41.4641 42.1567C41.4928 42.2846 41.4982 42.4107 41.4803 42.535C41.4608 42.6525 41.4204 42.7641 41.359 42.8698C41.3024 42.9674 41.2264 43.0517 41.131 43.1226C41.0356 43.1935 40.9286 43.2423 40.8102 43.2689C40.5733 43.3221 40.3511 43.2801 40.1434 43.1428C39.9405 42.9975 39.8103 42.7969 39.7529 42.541Z"
        fill="#003264"
      />
      <path
        d="M41.7298 38.7236C41.7068 38.6793 41.6792 38.6148 41.6471 38.53C41.6212 38.4439 41.5992 38.3605 41.581 38.2797C41.516 37.9902 41.5039 37.7029 41.5446 37.418C41.5854 37.133 41.6766 36.8685 41.8183 36.6245C41.9599 36.3806 42.1543 36.1672 42.4014 35.9844C42.6485 35.8016 42.9497 35.6703 43.305 35.5905C43.6416 35.5149 43.9568 35.5042 44.2506 35.5585C44.5506 35.6113 44.816 35.7144 45.0469 35.8676C45.2825 36.0127 45.4789 36.202 45.6361 36.4354C45.7933 36.6689 45.9036 36.927 45.9671 37.2098C46.0624 37.6339 46.0457 38.0408 45.9171 38.4303C45.787 38.8132 45.5629 39.1711 45.2448 39.5042L44.2483 40.5343C44.148 40.6416 44.0617 40.753 43.9893 40.8682C43.9232 40.9821 43.8796 41.1086 43.8585 41.2477L46.7666 40.5946L47.0365 41.7963L42.5481 42.8044C42.483 42.5149 42.4467 42.2366 42.4392 41.9696C42.4301 41.6958 42.452 41.4292 42.5051 41.1697C42.5644 40.9089 42.6617 40.6572 42.7972 40.4146C42.9311 40.1653 43.1155 39.9223 43.3505 39.6857L44.2059 38.8146C44.4299 38.5875 44.5753 38.3745 44.6419 38.1757C44.7133 37.9687 44.7233 37.7508 44.6719 37.5219C44.619 37.2862 44.4942 37.095 44.2975 36.9482C44.1071 36.8001 43.8591 36.7603 43.5537 36.8289C43.2295 36.9017 43.0098 37.0606 42.8946 37.3057C42.7856 37.5494 42.7659 37.8262 42.8355 38.1359C42.8627 38.257 42.9055 38.3747 42.9639 38.4889L41.7298 38.7236Z"
        fill="#003264"
      />
      <path
        d="M47.8858 37.532L49.1444 35.7006L46.4887 36.297L46.2188 35.0952L50.5109 34.1313L50.7626 35.2523L49.5092 37.0189C49.7429 36.9806 49.9763 36.9848 50.2094 37.0314C50.4425 37.0781 50.6598 37.1707 50.8612 37.3094C51.0611 37.4413 51.235 37.6179 51.3829 37.8393C51.5371 38.0593 51.6482 38.3207 51.7162 38.6237C51.7798 38.9065 51.788 39.191 51.741 39.4774C51.694 39.7638 51.5934 40.0304 51.4393 40.2771C51.2837 40.5172 51.0761 40.73 50.8165 40.9156C50.557 41.1012 50.2433 41.2353 49.8755 41.3179C49.5202 41.3977 49.1894 41.4118 48.8832 41.3604C48.5769 41.3089 48.3029 41.2114 48.0611 41.0677C47.8177 40.9172 47.6089 40.7307 47.4345 40.5082C47.2648 40.2776 47.1396 40.0264 47.0589 39.7545L48.2436 39.2126C48.3317 39.5465 48.4997 39.7987 48.7475 39.9693C49.0017 40.1386 49.2877 40.1875 49.6056 40.1161C49.9298 40.0433 50.1611 39.8923 50.2995 39.6632C50.4365 39.4274 50.4739 39.1714 50.4119 38.8954C50.3424 38.5857 50.1952 38.3677 49.9704 38.2414C49.7518 38.1137 49.496 38.0827 49.203 38.1485C49.0721 38.1779 48.9653 38.2125 48.8825 38.2523C48.806 38.2907 48.7365 38.3311 48.674 38.3734L47.8858 37.532Z"
        fill="#003264"
      />
      <path
        d="M77.8897 49.1815C77.7145 49.3991 77.4875 49.5997 77.2086 49.7832C76.9359 49.9654 76.6167 50.0976 76.251 50.1797C75.928 50.2522 75.6077 50.2796 75.2901 50.2618C74.9724 50.2441 74.6703 50.1814 74.3837 50.0739C74.0971 49.9665 73.8297 49.8165 73.5817 49.6239C73.3383 49.424 73.1271 49.1818 72.948 48.8974L72.0979 49.0884L71.906 48.234L72.5733 48.0841C72.5481 48.0007 72.5322 47.9438 72.5254 47.9135C72.5172 47.8771 72.5084 47.8378 72.4988 47.7953C72.4907 47.759 72.4818 47.7196 72.4723 47.6772C72.4628 47.6348 72.4529 47.5765 72.4427 47.5024L71.7754 47.6523L71.5835 46.7979L72.4336 46.607C72.4739 46.2734 72.5643 45.9634 72.7049 45.6773C72.8455 45.3911 73.0216 45.1351 73.2334 44.9094C73.4512 44.6822 73.6969 44.4934 73.9704 44.3429C74.2486 44.1849 74.5461 44.0703 74.863 43.9992C75.1982 43.9239 75.4988 43.8946 75.765 43.9112C76.0373 43.9264 76.292 43.9774 76.5292 44.0642L76.2773 45.1232C76.1154 45.0577 75.9352 45.025 75.7367 45.025C75.5369 45.019 75.3273 45.0406 75.1079 45.0899C74.7971 45.1597 74.5172 45.2957 74.2682 45.498C74.0253 45.699 73.8556 45.9694 73.7591 46.3093L76.1357 45.7756L75.9254 46.7203L73.7133 47.2171C73.716 47.2865 73.7222 47.3424 73.7317 47.3848C73.7413 47.4272 73.7515 47.4726 73.7623 47.5211C73.7691 47.5514 73.7766 47.5847 73.7848 47.6211C73.793 47.6575 73.8127 47.7167 73.8439 47.7988L75.8184 47.3553L75.6172 48.298L74.3009 48.5936C74.5219 48.8367 74.7815 48.9949 75.0795 49.0679C75.3776 49.141 75.6851 49.142 76.002 49.0708C76.2519 49.0147 76.4698 48.9307 76.656 48.8189C76.8469 48.6997 77.0018 48.563 77.1208 48.409L77.8897 49.1815Z"
        fill="#003264"
      />
      <path
        d="M79.8291 57.8161C79.6539 58.0336 79.4269 58.2342 79.1481 58.4177C78.8753 58.5999 78.5561 58.7321 78.1905 58.8142C77.8675 58.8867 77.5472 58.9141 77.2295 58.8964C76.9119 58.8786 76.6097 58.816 76.3231 58.7085C76.0365 58.601 75.7692 58.451 75.5211 58.2585C75.2778 58.0585 75.0665 57.8163 74.8874 57.532L74.0373 57.7229L73.8455 56.8685L74.5127 56.7187C74.4876 56.6352 74.4716 56.5783 74.4648 56.548C74.4567 56.5117 74.4478 56.4723 74.4383 56.4299C74.4301 56.3935 74.4213 56.3541 74.4118 56.3117C74.4022 56.2693 74.3923 56.211 74.3821 56.137L73.7148 56.2868L73.5229 55.4325L74.3731 55.2415C74.4133 54.9079 74.5038 54.598 74.6444 54.3118C74.7849 54.0256 74.9611 53.7697 75.1729 53.5439C75.3907 53.3167 75.6364 53.1279 75.9098 52.9774C76.188 52.8194 76.4856 52.7049 76.8024 52.6337C77.1376 52.5584 77.4383 52.5291 77.7045 52.5457C77.9768 52.5609 78.2315 52.6119 78.4686 52.6987L78.2167 53.7577C78.0548 53.6923 77.8746 53.6595 77.6762 53.6595C77.4764 53.6535 77.2668 53.6751 77.0474 53.7244C76.7366 53.7942 76.4567 53.9302 76.2077 54.1326C75.9647 54.3335 75.795 54.6039 75.6985 54.9439L78.0752 54.4101L77.8649 55.3548L75.6527 55.8516C75.6555 55.921 75.6617 55.9769 75.6712 56.0193C75.6807 56.0617 75.6909 56.1072 75.7018 56.1556C75.7086 56.1859 75.7161 56.2193 75.7243 56.2556C75.7324 56.292 75.7521 56.3512 75.7834 56.4333L77.7578 55.9899L77.5566 56.9325L76.2403 57.2281C76.4614 57.4713 76.7209 57.6294 77.019 57.7025C77.3171 57.7756 77.6246 57.7765 77.9415 57.7053C78.1913 57.6492 78.4093 57.5653 78.5954 57.4535C78.7863 57.3342 78.9413 57.1976 79.0603 57.0435L79.8291 57.8161Z"
        fill="#003264"
      />
      <path
        d="M81.7676 66.4491C81.5924 66.6667 81.3654 66.8672 81.0866 67.0508C80.8138 67.233 80.4946 67.3651 80.129 67.4473C79.806 67.5198 79.4857 67.5472 79.168 67.5294C78.8503 67.5116 78.5482 67.449 78.2616 67.3415C77.975 67.234 77.7077 67.084 77.4596 66.8915C77.2162 66.6916 77.005 66.4494 76.8259 66.165L75.9758 66.3559L75.7839 65.5016L76.4512 65.3517C76.4261 65.2682 76.4101 65.2114 76.4033 65.1811C76.3951 65.1447 76.3863 65.1053 76.3768 65.0629C76.3686 65.0266 76.3598 64.9872 76.3502 64.9448C76.3407 64.9023 76.3308 64.8441 76.3206 64.77L75.6533 64.9199L75.4614 64.0655L76.3115 63.8746C76.3518 63.5409 76.4423 63.231 76.5828 62.9448C76.7234 62.6587 76.8996 62.4027 77.1113 62.1769C77.3292 61.9498 77.5748 61.761 77.8483 61.6104C78.1265 61.4525 78.424 61.3379 78.7409 61.2668C79.0761 61.1915 79.3768 61.1621 79.643 61.1787C79.9152 61.194 80.1699 61.245 80.4071 61.3317L80.1552 62.3908C79.9933 62.3253 79.8131 62.2926 79.6147 62.2926C79.4148 62.2865 79.2053 62.3082 78.9859 62.3574C78.6751 62.4272 78.3952 62.5633 78.1461 62.7656C77.9032 62.9666 77.7335 63.237 77.637 63.5769L80.0137 63.0432L79.8033 63.9878L77.5912 64.4847C77.594 64.554 77.6001 64.6099 77.6097 64.6524C77.6192 64.6948 77.6294 64.7402 77.6403 64.7887C77.6471 64.819 77.6546 64.8523 77.6627 64.8887C77.6709 64.925 77.6906 64.9843 77.7218 65.0664L79.6963 64.6229L79.4951 65.5656L78.1788 65.8612C78.3998 66.1043 78.6594 66.2624 78.9575 66.3355C79.2556 66.4086 79.563 66.4096 79.8799 66.3384C80.1298 66.2823 80.3478 66.1983 80.5339 66.0865C80.7248 65.9673 80.8797 65.8306 80.9988 65.6766L81.7676 66.4491Z"
        fill="#003264"
      />
      <path
        d="M85.8223 84.5006C85.6471 84.7182 85.4201 84.9188 85.1412 85.1023C84.8685 85.2845 84.5493 85.4167 84.1836 85.4988C83.8607 85.5713 83.5403 85.5987 83.2227 85.5809C82.905 85.5632 82.6029 85.5005 82.3163 85.393C82.0297 85.2856 81.7624 85.1355 81.5143 84.943C81.2709 84.7431 81.0597 84.5009 80.8806 84.2165L80.0305 84.4074L79.8386 83.5531L80.5059 83.4032C80.4808 83.3198 80.4648 83.2629 80.458 83.2326C80.4498 83.1962 80.441 83.1568 80.4315 83.1144C80.4233 83.0781 80.4144 83.0387 80.4049 82.9963C80.3954 82.9539 80.3855 82.8956 80.3753 82.8215L79.708 82.9714L79.5161 82.117L80.3662 81.9261C80.4065 81.5924 80.4969 81.2825 80.6375 80.9964C80.7781 80.7102 80.9543 80.4542 81.166 80.2284C81.3839 80.0013 81.6295 79.8125 81.903 79.6619C82.1812 79.504 82.4787 79.3894 82.7956 79.3183C83.1308 79.243 83.4315 79.2137 83.6976 79.2303C83.9699 79.2455 84.2246 79.2965 84.4618 79.3833L84.2099 80.4423C84.048 80.3768 83.8678 80.3441 83.6693 80.3441C83.4695 80.3381 83.2599 80.3597 83.0406 80.4089C82.7298 80.4787 82.4498 80.6148 82.2008 80.8171C81.9579 81.0181 81.7882 81.2885 81.6917 81.6284L84.0684 81.0947L83.858 82.0394L81.6459 82.5362C81.6487 82.6056 81.6548 82.6615 81.6643 82.7039C81.6739 82.7463 81.6841 82.7917 81.695 82.8402C81.7018 82.8705 81.7092 82.9038 81.7174 82.9402C81.7256 82.9765 81.7453 83.0358 81.7765 83.1179L83.751 82.6744L83.5498 83.6171L82.2335 83.9127C82.4545 84.1558 82.7141 84.314 83.0122 84.387C83.3102 84.4601 83.6177 84.4611 83.9346 84.3899C84.1845 84.3338 84.4025 84.2498 84.5886 84.138C84.7795 84.0188 84.9344 83.8821 85.0535 83.7281L85.8223 84.5006Z"
        fill="#003264"
      />
      <rect
        x="18.1494"
        y="58.5984"
        width="50.6756"
        height="2"
        rx="1"
        transform="rotate(-12.6576 18.1494 58.5984)"
        fill="#003264"
      />
      <rect
        x="20.0879"
        y="67.5984"
        width="50.6756"
        height="2"
        rx="1"
        transform="rotate(-12.6576 20.0879 67.5984)"
        fill="#003264"
      />
      <rect
        x="22.0254"
        y="76.5984"
        width="50.6756"
        height="2"
        rx="1"
        transform="rotate(-12.6576 22.0254 76.5984)"
        fill="#003264"
      />
      <rect
        x="55.2969"
        y="88.0769"
        width="20.9137"
        height="2"
        rx="1"
        transform="rotate(-12.6576 55.2969 88.0769)"
        fill="#003264"
      />
      <path
        d="M33.2245 30.5078L31.8228 24.2668L30.6628 31.0831L29.2187 31.4074L25.2667 25.792L26.657 31.9827L24.9995 32.355L23 23.4519L25.2854 22.9386L29.404 28.7672L30.572 21.7514L32.9328 21.2212L34.9322 30.1242L33.2245 30.5078Z"
        fill="#003264"
      />
      <path
        d="M36.0879 28.0972C36.0258 27.8209 36.0174 27.5678 36.0624 27.3378C36.114 27.0976 36.2057 26.8792 36.3374 26.6825C36.4691 26.4858 36.6334 26.317 36.8304 26.1761C37.0357 26.0332 37.2635 25.9117 37.5136 25.8116L38.9823 25.2443C39.1524 25.1797 39.2625 25.1022 39.3126 25.0118C39.3607 24.9131 39.3726 24.8093 39.3482 24.7005C39.2993 24.4828 39.1691 24.3142 38.9576 24.1946C38.7544 24.0732 38.477 24.0519 38.1254 24.1309C37.7571 24.2136 37.495 24.378 37.3392 24.624C37.1918 24.8682 37.1356 25.127 37.1707 25.4005L35.6184 25.4194C35.589 25.171 35.6098 24.9113 35.6809 24.6403C35.7519 24.3693 35.8747 24.1131 36.0491 23.8717C36.2235 23.6303 36.4566 23.4153 36.7484 23.2266C37.0383 23.0296 37.3925 22.8841 37.8111 22.7901C38.2967 22.681 38.7261 22.6549 39.0994 22.7118C39.4708 22.7603 39.7856 22.8699 40.0438 23.0405C40.3085 23.2009 40.5188 23.4131 40.6748 23.677C40.8392 23.9391 40.9562 24.225 41.0257 24.5348L41.7082 27.5736C41.7458 27.741 41.7946 27.9191 41.8546 28.1079C41.9127 28.2883 41.9623 28.4311 42.0035 28.5362L40.4715 28.8802C40.436 28.8003 40.3939 28.691 40.3452 28.5524C40.3029 28.4036 40.2687 28.2706 40.2424 28.1534C40.1378 28.4319 39.9556 28.6971 39.6955 28.949C39.4439 29.1989 39.0879 29.3756 38.6274 29.479C38.3009 29.5524 37.9915 29.5647 37.6992 29.516C37.4171 29.4738 37.1652 29.3897 36.9434 29.2637C36.7198 29.1292 36.5333 28.9644 36.3839 28.7694C36.2411 28.564 36.1424 28.3399 36.0879 28.0972ZM38.6998 28.1569C38.8756 28.1174 39.0384 28.0589 39.188 27.9813C39.3442 27.8935 39.4728 27.7811 39.574 27.644C39.6732 27.4986 39.7408 27.3296 39.7766 27.1368C39.8106 26.9357 39.7966 26.6971 39.7345 26.4208L39.6725 26.1445L38.3266 26.6711C38.1164 26.7534 37.9501 26.8743 37.8276 27.0337C37.7116 27.1829 37.6809 27.3789 37.7354 27.6216C37.7768 27.8058 37.8814 27.9582 38.0492 28.0788C38.2151 28.191 38.432 28.2171 38.6998 28.1569Z"
        fill="#003264"
      />
      <path
        d="M44.3717 24.7462L41.4832 22.1632L43.4673 21.7177C43.5363 21.7901 43.6492 21.901 43.8058 22.0505C43.9607 22.1916 44.1215 22.3402 44.2885 22.4961C44.4554 22.6521 44.6172 22.8048 44.7739 22.9543C44.9287 23.0954 45.0406 23.2022 45.1096 23.2746L45.9159 21.1677L47.8121 20.7419L46.3319 24.2532L49.2749 26.9031L47.316 27.3431L45.5968 25.7374C45.557 25.8343 45.4982 25.9838 45.4205 26.1859C45.3428 26.388 45.2595 26.6046 45.1707 26.8356C45.08 27.0582 44.9958 27.2706 44.9181 27.4727C44.8403 27.6748 44.7858 27.8234 44.7543 27.9184L42.8707 28.3414L44.3717 24.7462Z"
        fill="#003264"
      />
      <path
        d="M98.4824 18.8842C100.496 26.6608 95.463 35.0338 87.61 37.0182C79.9342 38.9631 71.4359 34.0971 69.5109 26.7189C67.2921 18.1282 72.0647 9.62753 80.486 7.79515C89.2393 5.88869 96.4514 11.1346 98.4824 18.8842ZM91.5588 17.2222C91.4794 16.4498 90.9994 16.0689 90.3079 15.7817C89.3113 15.3766 88.613 15.788 88.1044 16.5289C87.0307 18.0697 86.0653 19.6794 85.0187 21.2374C84.0802 22.6551 83.3981 24.8057 82.1095 25.2791C80.9045 25.7106 79.6383 23.5764 78.3239 22.6846C77.1941 21.891 76.0325 21.2671 75.0326 22.7218C74.0891 24.1174 74.9608 25.108 76.0562 25.9557C77.6586 27.2016 79.3791 28.3514 80.8735 29.7375C82.5401 31.2711 83.7009 30.8495 84.7572 29.1267C86.8232 25.7843 88.8941 22.4641 90.9601 19.1217C91.3213 18.5532 91.7267 17.9748 91.5416 17.2492L91.5588 17.2222Z"
        fill="#003264"
      />
      <path
        d="M91.5603 17.2207C91.7504 17.9684 91.34 18.5247 90.9838 19.1154C88.9178 22.4577 86.8469 25.7779 84.7809 29.1203C83.7246 30.8431 82.5638 31.2647 80.8972 29.7311C79.4028 28.345 77.6873 27.2173 76.0799 25.9494C74.9845 25.1016 74.1128 24.111 75.0563 22.7154C76.0562 21.2607 77.2178 21.8846 78.3476 22.6782C79.6669 23.5922 80.9331 25.7263 82.1332 25.2727C83.4218 24.7993 84.1088 22.6709 85.0424 21.231C86.0619 19.6558 87.0544 18.0634 88.1281 16.5225C88.6416 15.8038 89.335 15.3702 90.3316 15.7753C91.0231 16.0625 91.5031 16.4434 91.5825 17.2158L91.5603 17.2207Z"
        fill="#FFA445"
      />
      <path
        d="M12.5115 30.2366C12.9416 29.8105 12.7821 29.0063 12.1553 28.4403C11.5284 27.8744 10.6716 27.761 10.2416 28.1871C9.81151 28.6132 9.97102 29.4174 10.5979 29.9834C11.2247 30.5494 12.0815 30.6627 12.5115 30.2366Z"
        fill="#003264"
      />
      <path
        d="M20.8658 27.9845C21.1707 27.2318 20.9385 26.448 20.3472 26.2338C19.7559 26.0196 19.0295 26.4561 18.7247 27.2087C18.4199 27.9613 18.6521 28.7451 19.2433 28.9594C19.8346 29.1736 20.561 28.7371 20.8658 27.9845Z"
        fill="#003264"
      />
      <path
        d="M18.2215 25.7391C18.2962 24.8333 17.7733 24.056 17.0535 24.0029C16.3337 23.9498 15.6896 24.641 15.6149 25.5468C15.5402 26.4526 16.0632 27.2299 16.783 27.283C17.5028 27.3361 18.1468 26.6449 18.2215 25.7391Z"
        fill="#003264"
      />
      <path
        d="M14.1413 27.8311C14.7562 27.4864 14.8535 26.5666 14.3585 25.7766C13.8635 24.9866 12.9638 24.6255 12.3489 24.9701C11.734 25.3148 11.6368 26.2346 12.1317 27.0246C12.6267 27.8147 13.5264 28.1757 14.1413 27.8311Z"
        fill="#003264"
      />
      <path
        d="M19.027 29.6921C18.3959 29.0292 17.6042 28.5492 16.7326 28.3012C16.3464 28.1774 15.9353 28.1591 15.54 28.2483H15.5293C15.1381 28.3376 14.777 28.5313 14.4828 28.8098C13.8147 29.4072 13.3211 30.182 13.0567 31.0484C12.7837 31.8604 12.8155 32.7472 13.1458 33.5367C13.1863 33.6307 13.2447 33.7155 13.3175 33.7863C13.3903 33.857 13.4761 33.9123 13.57 33.949C13.6638 33.9856 13.7638 34.0028 13.8642 33.9996C13.9646 33.9964 14.0634 33.9729 14.1548 33.9305C14.4846 33.7773 14.7913 33.5804 15.1175 33.4219C15.5068 33.2173 15.9067 33.0347 16.3155 32.875C16.4147 32.8391 16.5159 32.8093 16.6185 32.7856C16.7209 32.7627 16.8244 32.7463 16.9287 32.7364C17.3688 32.702 17.8104 32.6923 18.2515 32.7073C18.6081 32.7073 18.9806 32.7547 19.3443 32.7474C19.4436 32.7476 19.5419 32.7269 19.6331 32.6867C19.7243 32.6465 19.8065 32.5876 19.8745 32.5136C19.9425 32.4396 19.9949 32.3521 20.0286 32.2565C20.0622 32.161 20.0762 32.0594 20.0699 31.958C20.0043 31.0979 19.6334 30.2919 19.027 29.6921Z"
        fill="#003264"
      />
    </svg>
  );
}

export default PetoloDocumentIllustration;
