import {
  CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT,
  useClaimUploadFlowSource,
} from '../../claimUploadFlowSource';
import { PETOLO_EXISTING_CLAIM_OPTION_NOT_SELECTED } from '../../claimFormData/newClaimFormData';
import { PETOLO_EDIT_STEP_UPLOAD_DOCUMENTS, PARAM_CLAIM_ID } from '../../edit/editClaimFormSteps';
import {
  PETOLO_STEP_EXISTING_CLAIM_QUESTION,
  PETOLO_STEP_UPLOAD_DOCUMENTS,
} from '../../new/newClaimsFormSteps';

export const useSelectExistingClaimPetoloBackButtonLink = () => {
  const [source, withSource] = useClaimUploadFlowSource();

  if (source === CLAIM_UPLOAD_FLOW_SOURCE_EXISTING_CLAIM_DOCUMENT) {
    return '/user-claims-info';
  }
  return withSource(PETOLO_STEP_EXISTING_CLAIM_QUESTION);
};

export const useSelectExistingClaimPetoloNextButtonLink = (selectedClaimId) => {
  const [, withSource] = useClaimUploadFlowSource();

  if (!!selectedClaimId && selectedClaimId !== PETOLO_EXISTING_CLAIM_OPTION_NOT_SELECTED) {
    const nextButtonLink = PETOLO_EDIT_STEP_UPLOAD_DOCUMENTS.replace(
      PARAM_CLAIM_ID,
      selectedClaimId
    );

    return withSource(nextButtonLink);
  }

  return withSource(PETOLO_STEP_UPLOAD_DOCUMENTS);
};
