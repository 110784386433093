import { styled, css, alpha, hexToRgb } from '@mui/material';
import { MAX_WIDTH_SM, MIN_WIDTH_MD } from '../../../theme/breakpoints';
import { IconButton, Paper } from '../../common';

const sizeStyles = (size) =>
  ({
    lg: css`
      max-width: 1020px;
    `,
    md: css`
      max-width: 625px;
    `,
    sm: css`
      max-width: 480px;
    `,
  })[`${size}`];

export const StyledModalContainer = styled(Paper)`
  position: relative;
  margin: 4rem auto 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 100%;
  min-height: 5em;
  z-index: 1002;
  transition: transform 0.2s ease-in-out;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 5rem;
    padding: 1.5rem;
    ${({ size }) => sizeStyles(size)};
  }
`;

export const StyledModalOverlay = styled('div')(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${alpha(hexToRgb(theme.palette.main.professional_blue), 0.5)};
    overflow-y: auto;
    z-index: 1001;
    transition: opacity 0.15s ease-in-out;

    @media (max-width: ${MAX_WIDTH_SM}) {
      padding: 0 1.5rem;
    }

    &.modal-animation-enter {
      opacity: 0;

      ${StyledModalContainer} {
        transform: scale(1.05);
      }
    }

    &.modal-animation-enter-active {
      opacity: 1;

      ${StyledModalContainer} {
        transform: scale(1);
      }
    }

    &.modal-animation-exit-active {
      opacity: 0;

      ${StyledModalContainer} {
        transform: scale(1.05);
      }
    }
  `
);

export const StyledModalTitle = styled('h4')`
  margin-bottom: 1.5rem;
  padding: 0 3rem 0 0;
  font-size: 1.5rem;
  font-weight: 700;
`;

export const StyledModalCloseButton = styled(IconButton)`
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  width: 1.625rem;
  height: 1.625rem;
`;

// #TODO: Animations with styled components

// .quick-fade-enter {
//   .u3-modal-container {
//     transform: scale(1.05);
//   }
// }

// .quick-fade-enter-active {
//   .u3-modal-container {
//     transform: scale(1);
//     transition: all 0.15s ease-in;
//   }
// }

// .quick-fade-exit-active {
//   .u3-modal-container {
//     transform: scale(1.05);
//     transition: all 0.15s ease-in;
//   }
// }
