import React from 'react';

import PageWithFullHeader from './PageWithFullHeader';
import { PaymentNotification } from '../ui';

const PageWithFullHeaderAndFailedPaymentNotification = ({ children }) => {
  return (
    <PageWithFullHeader>
      <PaymentNotification />
      {children}
    </PageWithFullHeader>
  );
};

export default PageWithFullHeaderAndFailedPaymentNotification;
