import { css } from '@mui/material';
import styled from '@emotion/styled/macro';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

import { Tag } from '../common';
import { Paragraph } from '../ui';

export const StyledFlexContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `}

  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${marginBottom};
    `}
`;

export const StyledTag = styled(Tag)(
  ({ theme, status }) => css`
    padding: 1rem 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.1rem;
    background-color: ${theme.palette.background.tag};

    ${!status &&
    css`
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      font-size: 0.75rem;
      font-weight: 700;
      background: transparent;
      border: 1px solid ${theme.palette.main.professional_blue};
    `}
  `
);

export const StyledPolicyCardText = styled('div')(
  ({ theme }) => css`
    font-size: 1.1rem;
    font-weight: 450;
    color: ${theme.palette.common.white};
  `
);

export const StyledPolicyCardTextThemed = styled(StyledPolicyCardText)``;

export const StyledPolicyCardItem = styled('div')(
  ({ theme, type }) => css`
    & + & {
      margin-top: 1rem;
    }

    ${StyledPolicyCardTextThemed} {
      color: ${theme.palette.background.radio};

      ${type === 'title' &&
      css`
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.5rem;

        color: ${theme.palette.common.white};

        ${StyledPolicyCardText} {
          font-size: 1.25rem;
          font-weight: 700;
        }

        ${StyledPolicyCardTextThemed} {
          font-size: 1.25rem;
          font-weight: 700;
        }
      `};
    }
  `
);

export const StyledPolicyAmount = styled('div')`
  display: inline-block;
  margin-right: 1rem;
  min-width: 4rem;
`;

export const StyledContractCancelLinkParagraph = styled(Paragraph)`
  margin-top: 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 1.5rem;
  }
`;

export const StyledMissingIbanBannerContainer = styled('div')`
  margin-bottom: 1rem;
`;
