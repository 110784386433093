import { styled, css } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';
import { Paper } from '../common';
import { navMenuUnderlineBaseStyle } from '../NavMenu/styled/NavMenuDesktop.styled';

export const StyledDownloadContributionConfirmationWrapper = styled(Paper)(
  ({ theme, hasContribution }) => css`
    margin-top: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
    color: ${theme.palette.main.professional_blue};

    ${!hasContribution &&
    css`
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      text-align: center;
    `};
  `
);

export const PaymentOverviewHelpContent = styled(Paper)(
  ({ theme }) => css`
    margin-top: 1.25rem;
    text-align: right;

    > h4 {
      margin-bottom: 1.5rem;
      color: ${theme.palette.common.white};
      text-align: left;
    }
  `
);

export const PaymentOverviewHelpCenterLink = styled('a')(
  ({ theme }) => css`
    position: relative;
    font-size: 1rem;
    color: ${theme.palette.common.white};
    text-align: right;
    text-decoration: none;
    cursor: pointer;

    h4 {
      color: ${theme.palette.common.white};
    }

    ${navMenuUnderlineBaseStyle({ theme })};

    &::after {
      background-color: ${theme.palette.common.white};
      bottom: 0;
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    &:hover,
    &:focus {
      &::after {
        transform: scaleX(0);
        transform-origin: bottom right;
      }
    }

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.25rem;
    }
  `
);
