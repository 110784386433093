import { styled, css } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

import { Button } from '../../components/ui';

export const StyledIllustrationContainerDesktop = styled('div')`
  width: 100%;
  display: none;

  @media (min-width: ${MIN_WIDTH_MD}) {
    display: block;
  }

  & > svg {
    width: 100%;
    height: auto;
  }
`;

export const StyledIllustrationContainerMobile = styled(StyledIllustrationContainerDesktop)`
  display: block;

  @media (min-width: ${MIN_WIDTH_MD}) {
    display: none;
  }
`;

export const StyledContractPendingApprovalContent = styled('p')(
  ({ theme }) => css`
    color: ${theme.palette.main.professional_blue};
    margin-bottom: 1em;
    text-align: center;

    @media (min-width: ${MIN_WIDTH_MD}) {
      margin-bottom: 1.5em;
    }
  `
);

export const StyledContractPendingApprovalButton = styled(Button)`
  margin: 0 auto;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'inherit')};

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: inherit;
  }
`;
