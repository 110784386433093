import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import { imgLinks, useReady } from '../../shared';

import FirstVetPromoSlider from './FirstVetPromoSlider';
import { Container, PageTitle, Text } from '../common';
import { Button } from '../ui';
import {
  StyledOnlineVetCoverImage,
  StyledOnlineVetFlexContainer,
} from '../OnlineVets/OnlineVets.styled';

const FirstVet = () => {
  const ready = useReady();
  const firstvetAccount = 'https://account.firstvet.com/de?insurance=petolo';
  const googlePlayLink =
    'https://play.google.com/store/apps/details?id=com.firstvet.firstvetlocalized&hl=de&gl=US';
  const appleStoreLink = 'https://apps.apple.com/app/id1349241101';

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <PageTitle>
          <FormattedMessage id="first.vet.online.vets" />
        </PageTitle>

        <StyledOnlineVetFlexContainer>
          <StyledOnlineVetCoverImage src={imgLinks['icon.petolo.firstvet']} alt="FirstVet" />

          <Button onClick={() => window.open(firstvetAccount, '_blank')}>
            <FormattedMessage id="first.vet.go.to.link" />
          </Button>
        </StyledOnlineVetFlexContainer>

        <FirstVetPromoSlider />

        <StyledOnlineVetFlexContainer>
          <Text style={{ marginTop: '2.5rem', marginBottom: '1rem' }}>
            <FormattedMessage id="first.vet.app.find" />
          </Text>

          <a href={googlePlayLink} target="_blank" rel="noopener noreferrer">
            <img src={imgLinks['icon.google.play']} style={{ width: '11rem' }} alt="Google Play" />
          </a>

          <a href={appleStoreLink} target="_blank" rel="noopener noreferrer">
            <img src={imgLinks['icon.apple.store']} style={{ width: '10rem' }} alt="Apple Store" />
          </a>
        </StyledOnlineVetFlexContainer>
      </Container>
    </CSSTransition>
  );
};

export default FirstVet;
