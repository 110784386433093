import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import { PET_LIABILITY_AFFECTED_PARTY_OPTION_OTHER } from '../../claimFormData/newClaimFormDataPetLiability';
import {
  PET_LIABILITY_STEP_AFFECTED_PARTY,
  PET_LIABILITY_STEP_RELATION_WITH_AFFECTED_PARTY,
  PET_LIABILITY_STEP_UPLOAD_DOCUMENTS,
} from '../../new/newClaimsFormSteps';

const getBackButtonLinkForNewClaim = (affectedParty) => {
  if (affectedParty === PET_LIABILITY_AFFECTED_PARTY_OPTION_OTHER) {
    return PET_LIABILITY_STEP_RELATION_WITH_AFFECTED_PARTY;
  }

  return PET_LIABILITY_STEP_AFFECTED_PARTY;
};

export const useMedicalExamPetLiabilityBackButtonLink = (affectedParty) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  const backButtonLink = getBackButtonLinkForNewClaim(affectedParty);
  return withSource(backButtonLink);
};

export const useMedicalExamPetLiabilityNextButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_UPLOAD_DOCUMENTS);
};
