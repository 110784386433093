import { css, styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';
import { promotionCarouselSliderHeight } from '../PromotionCarousel/PromotionCarousel.styled';
import { Href } from '../../../components/ui';
import { PALETTE } from '../../../theme/palette';

export const StyledVitoloBetterDocCard = styled('div')`
  display: flex;
  background: ${PALETTE.PERIWINKLE};
  overflow: hidden;
`;

export const StyledVitoloBetterDocCardImageContainer = styled('div')`
  margin-right: 0;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-right: 1rem;
  }
`;

export const StyledVitoloBetterDocCardImageMobile = styled('img')`
  display: block !important;
  height: ${promotionCarouselSliderHeight.mobile};
  position: relative;
  right: 10px;

  @media (min-width: ${MIN_WIDTH_MD}) {
    display: none !important;
  }
`;

export const StyledVitoloBetterDocCardImageDesktop = styled('img')`
  display: none !important;
  height: 25.75rem;
  position: relative;
  bottom: 84px;
  @media (min-width: ${MIN_WIDTH_MD}) {
    display: block !important;
  }
`;

export const StyledVitoloBetterDocCardTitle = styled('div')(
  ({ theme }) => css`
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    color: ${theme.palette.main.professional_blue};
    margin-top: 0.5rem;

    @media (min-width: ${MIN_WIDTH_MD}) {
      width: 355px;
      font-size: 1.25rem;
      margin-top: 1rem;
    }
  `
);

export const StyledVitoloBetterDocCardText = styled('div')(
  ({ theme }) => css`
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${theme.palette.main.professional_blue};
    margin-top: 0;

    @media (min-width: ${MIN_WIDTH_MD}) {
      width: 340px;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
  `
);

export const StyledVitoloBetterDocCardButtonContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const StyledVitoloBetterDocCardButton = styled(Href)(
  ({ theme }) => css`
    padding: none;
    color: ${theme.palette.common.white};
    text-decoration: none;
    background-color: ${theme.palette.main.professional_blue};

    @media (min-width: ${MIN_WIDTH_MD}) {
      padding: 0rem 4rem;
      margin-top: 1rem;
    }
  `
);
