// eslint-disable-next-line import/prefer-default-export
export const muiButtonComponent = {
  defaultProps: {
    disableRipple: true,
    variant: 'contained',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      background: theme.palette.background.button,
      border: `2px solid ${theme.palette.background.button}`,
      borderRadius: '8px',
      boxShadow: 'none',
      color: theme.palette.text.button,
      display: 'flex',
      padding: '0.875rem',
      textTransform: 'none',
      transition: 'none',
      width: '100%',

      [`&:hover`]: {
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), ${theme.palette.background.button}`,
        boxShadow: 'none',
      },

      [`&:focus`]: {
        boxShadow: theme.shadows[4],
      },

      [`&:active`]: {
        background: theme.palette.background.button,
        color: theme.palette.text.button,
      },

      [`&.Mui-disabled`]: {
        background: theme.palette.background.button,
        color: theme.palette.text.button,
        opacity: '0.6',
      },
    }),
    outlined: ({ theme }) => ({
      background: 'transparent',
      color: theme.palette.main.professional_blue,

      [`&:hover`]: {
        background: 'transparent',
        border: `2px solid ${theme.palette.main.professional_blue}`,
      },

      [`&:active`]: {
        background: 'transparent',
        color: theme.palette.main.professional_blue,
      },

      [`&.Mui-disabled`]: {
        background: 'transparent',
        border: `2px solid ${theme.palette.main.professional_blue}`,
        color: theme.palette.main.professional_blue,
      },
    }),
  },
};
