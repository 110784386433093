import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { StyledPromotionCarouselSlider } from './PromotionCarousel.styled';

const PromotionCarouselSlides = forwardRef((props, ref) => {
  const { settings, slides, ...rest } = props;

  return (
    <StyledPromotionCarouselSlider ref={ref} {...settings} {...rest}>
      {slides.map((slide) => (
        <React.Fragment key={slide.id}>{slide?.card}</React.Fragment>
      ))}
    </StyledPromotionCarouselSlider>
  );
});

export default PromotionCarouselSlides;

PromotionCarouselSlides.propTypes = {
  settings: PropTypes.shape({
    dots: PropTypes.bool,
    arrows: PropTypes.bool,
    infinite: PropTypes.bool,
    speed: PropTypes.number,
    slidesToShow: PropTypes.number,
    slidesToScroll: PropTypes.number,
  }),
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      card: PropTypes.node,
      hidden: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    })
  ).isRequired,
};
