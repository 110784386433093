import React from 'react';
import { useParams } from 'react-router-dom';

import {
  PETOLO_DOCUMENT_DATE,
  PETOLO_DOCUMENT_TYPE,
  useNewPetoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import SelectDocumentCreationDate from './SelectDocumentCreationDate';
import {
  useSelectDocumentCreationDatePetoloBackButtonLink,
  useSelectDocumentCreationDatePetoloNextButtonLink,
} from './selectDocumentCreationDatePetoloHooks';

const SelectDocumentCreationDatePetolo = () => {
  const { claimId } = useParams();

  const [documentType] = useNewPetoloFormDataValue(PETOLO_DOCUMENT_TYPE);

  const backButtonLink = useSelectDocumentCreationDatePetoloBackButtonLink(claimId);
  const nextButtonLink = useSelectDocumentCreationDatePetoloNextButtonLink(claimId, documentType);

  const [documentCreationDate, setDocumentCreationDate] =
    useNewPetoloFormDataValue(PETOLO_DOCUMENT_DATE);

  return (
    <SelectDocumentCreationDate
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      documentCreationDate={documentCreationDate}
      setDocumentCreationDate={setDocumentCreationDate}
      pageTitleId="petolo.new_claim.select_document_creation_date.page_title"
      formDescription="petolo.new_claim.select_document_creation_date.form_description"
    />
  );
};

export default SelectDocumentCreationDatePetolo;
