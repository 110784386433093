// carousel settings
export const carouselSettings = {
  dots: false,
  arrows: false,
  // set `infinite: true` from the component to avoid the carousel looping with only one slide
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

// filter slides if they need to be hidden from some `policy_categories`
export const filterValidSlides = (slides, policyCategory, riskCarrier) => {
  if (!Array.isArray(slides) || slides.length < 1) return [];

  const filtered = slides.filter((slide) => {
    const { hidden } = slide || {};

    if (!Array.isArray(hidden) || hidden.length < 1) return true;

    return !hidden.includes(policyCategory) && !hidden.includes(riskCarrier);
  });

  return filtered;
};
