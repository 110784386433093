import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import { Button, Link, Typography } from '@mui/material';

import {
  AKUTSCHUTZ_CANCELLATION_AFTER_MINIMUM_DURATION,
  AKUTSCHUTZ_CANCELLATION_WITHIN_MINIMUM_DURATION,
  AKUTSCHUTZ_CANCELLATION_WITHIN_WITHDRAW_PERIOD,
} from './akutschutzCancellationConstants';
import {
  StyledAkutschutzCancellationButtonContainer,
  StyledAkutschutzCancellationContentContainer,
  StyledAkutschutzCancellationDate,
  StyledAkutschutzCancellationDateContainer,
} from './AkutschutzCancellation.styled';
import { DENTOLO_AKUTSCHUTZ_HELPCENTER_LINK } from '../../shared';

function AkutschutzCancellationWithinWithdrawPeriodContent(props) {
  const history = useHistory();

  const { handleCancelAkutschutz, disableButton } = props;

  const renderAnchorTagForHelpcenterArticle = (chunk) => (
    <Link target="_blank" rel="noopener noreferrer" href={DENTOLO_AKUTSCHUTZ_HELPCENTER_LINK}>
      {chunk}
    </Link>
  );

  return (
    <>
      <Typography variant="h1">
        <FormattedMessage id="akutschutz_cancellation.contract_within_withdraw_period.title" />
      </Typography>

      <StyledAkutschutzCancellationContentContainer>
        <Typography variant="p">
          <FormattedMessage id="akutschutz_cancellation.contract_within_withdraw_period.content_1" />
        </Typography>

        <Typography variant="p">
          <FormattedMessage
            id="akutschutz_cancellation.contract_within_withdraw_period.content_2"
            values={{
              a: (chunk) => renderAnchorTagForHelpcenterArticle(chunk),
            }}
          />
        </Typography>
      </StyledAkutschutzCancellationContentContainer>

      <StyledAkutschutzCancellationButtonContainer>
        <Button type="button" onClick={handleCancelAkutschutz} disabled={disableButton}>
          <Typography variant="buttons">
            <FormattedMessage id="akutschutz_cancellation.contract_within_withdraw_period.confirm_cancel_button" />
          </Typography>
        </Button>
        <Button
          variant="outlined"
          type="button"
          onClick={() => history.push('/akutschutz-cancellation/abort?withdraw=true')}
          disabled={disableButton}
        >
          <Typography variant="buttons">
            <FormattedMessage id="akutschutz_cancellation.contract_within_withdraw_period.abort_cancel_button" />
          </Typography>
        </Button>
      </StyledAkutschutzCancellationButtonContainer>
    </>
  );
}

function AkutschutzCancellationWithinMinimumDurationContent(props) {
  const history = useHistory();

  const { handleCancelAkutschutz, disableButton, earliestAvailableDate } = props;

  return (
    <>
      <Typography variant="h1">
        <FormattedMessage id="akutschutz_cancellation.contract_within_minimum_duration.title" />
      </Typography>

      <StyledAkutschutzCancellationContentContainer>
        <Typography variant="p">
          <FormattedMessage id="akutschutz_cancellation.contract_within_minimum_duration.content_1" />
        </Typography>

        <Typography variant="p">
          <FormattedMessage id="akutschutz_cancellation.contract_within_minimum_duration.content_2" />
        </Typography>
      </StyledAkutschutzCancellationContentContainer>

      <StyledAkutschutzCancellationDateContainer>
        <Typography variant="h3">
          <FormattedMessage id="akutschutz_cancellation.contract_within_minimum_duration.next_possible_cancellation_date" />
        </Typography>

        <StyledAkutschutzCancellationDate>
          <Typography variant="h3">
            {earliestAvailableDate ? dayjs(earliestAvailableDate).format('DD.MM.YYYY') : ''}
          </Typography>
        </StyledAkutschutzCancellationDate>
      </StyledAkutschutzCancellationDateContainer>

      <StyledAkutschutzCancellationButtonContainer>
        <Button type="button" onClick={handleCancelAkutschutz} disabled={disableButton}>
          <Typography variant="buttons">
            <FormattedMessage id="akutschutz_cancellation.contract_within_minimum_duration.confirm_cancel_button" />
          </Typography>
        </Button>
        <Button
          variant="outlined"
          type="button"
          onClick={() => history.push('/akutschutz-cancellation/abort?withdraw=false')}
          disabled={disableButton}
        >
          <Typography variant="buttons">
            <FormattedMessage id="akutschutz_cancellation.contract_within_minimum_duration.abort_cancel_button" />
          </Typography>
        </Button>
      </StyledAkutschutzCancellationButtonContainer>
    </>
  );
}

function AkutschutzCancellationAfterMinimumDurationContent(props) {
  const history = useHistory();

  const { handleCancelAkutschutz, disableButton, earliestAvailableDate } = props;

  return (
    <>
      <Typography variant="h1">
        <FormattedMessage id="akutschutz_cancellation.contract_outside_minimum_duration.title" />
      </Typography>

      <StyledAkutschutzCancellationContentContainer>
        <Typography variant="p">
          <FormattedMessage id="akutschutz_cancellation.contract_outside_minimum_duration.content_1" />
        </Typography>

        <Typography variant="p">
          <FormattedMessage id="akutschutz_cancellation.contract_outside_minimum_duration.content_2" />
        </Typography>
      </StyledAkutschutzCancellationContentContainer>

      <StyledAkutschutzCancellationDateContainer>
        <Typography variant="h3">
          <FormattedMessage id="akutschutz_cancellation.contract_outside_minimum_duration.next_possible_cancellation_date" />
        </Typography>

        <StyledAkutschutzCancellationDate>
          <Typography variant="h3">
            {earliestAvailableDate ? dayjs(earliestAvailableDate).format('DD.MM.YYYY') : ''}
          </Typography>
        </StyledAkutschutzCancellationDate>
      </StyledAkutschutzCancellationDateContainer>

      <StyledAkutschutzCancellationButtonContainer>
        <Button type="button" onClick={handleCancelAkutschutz} disabled={disableButton}>
          <Typography variant="buttons">
            <FormattedMessage id="akutschutz_cancellation.contract_outside_minimum_duration.confirm_cancel_button" />
          </Typography>
        </Button>
        <Button
          variant="outlined"
          type="button"
          onClick={() => history.push('/akutschutz-cancellation/abort?withdraw=false')}
          disabled={disableButton}
        >
          <Typography variant="buttons">
            <FormattedMessage id="akutschutz_cancellation.contract_outside_minimum_duration.abort_cancel_button" />
          </Typography>
        </Button>
      </StyledAkutschutzCancellationButtonContainer>
    </>
  );
}

export default function AkutschutzCancellationContent(props) {
  const {
    akutschutzCancellationPageKey,
    handleCancelAkutschutz,
    disableButton,
    earliestAvailableDate,
  } = props;

  switch (akutschutzCancellationPageKey) {
    case AKUTSCHUTZ_CANCELLATION_WITHIN_WITHDRAW_PERIOD:
      return (
        <AkutschutzCancellationWithinWithdrawPeriodContent
          handleCancelAkutschutz={handleCancelAkutschutz}
          disableButton={disableButton}
        />
      );
    case AKUTSCHUTZ_CANCELLATION_WITHIN_MINIMUM_DURATION:
      return (
        <AkutschutzCancellationWithinMinimumDurationContent
          handleCancelAkutschutz={handleCancelAkutschutz}
          disableButton={disableButton}
          earliestAvailableDate={earliestAvailableDate}
        />
      );
    case AKUTSCHUTZ_CANCELLATION_AFTER_MINIMUM_DURATION:
      return (
        <AkutschutzCancellationAfterMinimumDurationContent
          handleCancelAkutschutz={handleCancelAkutschutz}
          disableButton={disableButton}
          earliestAvailableDate={earliestAvailableDate}
        />
      );
    default:
      return null;
  }
}
