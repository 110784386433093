import React from 'react';

import { Separator } from '../common';
import { StyledFlexContainer } from '../styled/PolicyInfo.styled';
import {
  StyledPolicyDetailsSectionTitle,
  StyledPolicyDetailsTextLg,
  StyledPolicyDetailsText,
} from '../styled/PolicyDetails.styled';

const MainDetails = ({ details }) => {
  if (!Array.isArray(details)) return null;

  return details.map((item) => (
    <div key={item?.title || item?.subText}>
      <StyledPolicyDetailsSectionTitle>{item?.title}</StyledPolicyDetailsSectionTitle>

      {Array.isArray(item?.rates) &&
        item.rates.map((rate) => (
          <StyledFlexContainer marginBottom="1rem" key={rate?.name}>
            <StyledPolicyDetailsText>{rate?.name}</StyledPolicyDetailsText>

            <StyledPolicyDetailsTextLg textAlign="right">{rate?.value}</StyledPolicyDetailsTextLg>
          </StyledFlexContainer>
        ))}

      {!!item?.subText && <StyledPolicyDetailsText>{item.subText}</StyledPolicyDetailsText>}

      <Separator />
    </div>
  ));
};

export default MainDetails;
