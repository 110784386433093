import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage } from 'react-intl';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import useForm from '../../shared/useForm';
import { GET_CUSTOMER, UPDATE_INSURED_PERSON_DETAILS } from '../../shared';
import { getServiceEmail } from '../../utils/customerUtils';

import { Input, Radio, RadioLabel } from '../common';
import { ErrorMessage } from '../common/FormElements';
import { Button } from '../ui';
import {
  StyledForm,
  StyledFormLegend,
  StyledFormLabel,
  StyledFormFieldset,
  StyledGenderRadioInputs,
  StyledGenderRadioInputAndLabel,
  StyledFormGroup,
  StyledInputGroup,
  StyledStreetInput,
  StyledHouseNumberInput,
  StyledPostCodeInput,
  StyledCityInput,
  StyledAdditionalDetailsText,
  StyledButtonContainer,
} from './CustomerDetailsEditor.styled';

function validateForm(values) {
  const errors = {};

  if (!values?.streetName) {
    errors.streetName = 'insured.person.details.form.address.error.message';
  }

  if (!values?.houseNumber) {
    errors.houseNumber = 'insured.person.details.form.address.error.message';
  }

  if (!values?.postcode || !(values?.postcode.length === 5)) {
    errors.postcode = 'insured.person.details.form.address.error.message';
  }

  if (!values?.city) {
    errors.city = 'insured.person.details.form.address.error.message';
  }

  return errors;
}

function InsuredPersonDetailsEditor({ data, onSuccess, onError }) {
  const [isProcessing, setIsProcessing] = useState(false);

  const {
    values,
    errors: formErrors,
    isDirty,
    handleChange,
    handleSubmit,
  } = useForm(data, handleFormSubmit, validateForm);

  const { firstName, lastName, ...otherValues } = values;

  const [updateInsuredPersonDetails] = useMutation(UPDATE_INSURED_PERSON_DETAILS, {
    variables: {
      ...otherValues,
    },
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  async function handleFormSubmit(ev) {
    if (ev) ev.preventDefault();

    try {
      setIsProcessing(true);

      const res = await updateInsuredPersonDetails();
      const { insuredPerson } = res?.data || {};

      if (insuredPerson?.status !== 'success') throw new Error(insuredPerson?.errors);

      onSuccess();
    } catch (err) {
      onError();
    } finally {
      setIsProcessing(false);
    }
  }

  const isBtnDisabled = !isDirty || Object.keys(formErrors).length > 0 || isProcessing;
  const { type: insuranceType } = useInsuranceInfo();

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormFieldset>
        <StyledFormLegend>
          <FormattedMessage id="insured.person.details.form.gender.label" />
        </StyledFormLegend>

        <StyledGenderRadioInputs>
          <StyledGenderRadioInputAndLabel>
            <Radio
              id="radio_gender_male"
              name="gender"
              onChange={(e) => {
                e.stopPropagation();
                handleChange(e);
              }}
              value="male"
              checked={values?.gender === 'male'}
            />

            <RadioLabel htmlFor="radio_gender_male">
              <FormattedMessage id="insured.person.details.form.gender.male.label" />
            </RadioLabel>
          </StyledGenderRadioInputAndLabel>

          <StyledGenderRadioInputAndLabel>
            <Radio
              id="radio_gender_female"
              name="gender"
              onChange={(e) => {
                e.stopPropagation();
                handleChange(e);
              }}
              value="female"
              checked={values?.gender === 'female'}
            />

            <RadioLabel htmlFor="radio_gender_female">
              <FormattedMessage id="insured.person.details.form.gender.female.label" />
            </RadioLabel>
          </StyledGenderRadioInputAndLabel>
        </StyledGenderRadioInputs>
      </StyledFormFieldset>

      <StyledFormGroup>
        <StyledFormLabel htmlFor="firstName" disabled>
          <FormattedMessage id="common.first.name" />
        </StyledFormLabel>

        <Input
          id="firstName"
          name="firstName"
          placeholder="Alex"
          value={values?.firstName || ''}
          onChange={handleChange}
          disabled
        />
      </StyledFormGroup>

      <StyledFormGroup>
        <StyledFormLabel disabled htmlFor="lastName">
          <FormattedMessage id="common.last.name" />
        </StyledFormLabel>

        <Input
          id="lastName"
          name="lastName"
          placeholder="Müller"
          value={values?.lastName || ''}
          onChange={handleChange}
          disabled
        />
      </StyledFormGroup>

      <StyledFormFieldset>
        <StyledFormLegend>
          <FormattedMessage id="insured.person.details.form.street.house.number.label" />
        </StyledFormLegend>

        <StyledInputGroup>
          <StyledStreetInput
            id="streetName"
            name="streetName"
            placeholder="Sonnenstraße"
            value={values?.streetName || ''}
            onChange={handleChange}
          />

          <StyledHouseNumberInput
            id="houseNumber"
            name="houseNumber"
            placeholder="5"
            value={values?.houseNumber || ''}
            onChange={handleChange}
          />
        </StyledInputGroup>
      </StyledFormFieldset>

      <StyledFormFieldset>
        <StyledFormLegend>
          <FormattedMessage id="insured.person.details.form.postcode.city.label" />
        </StyledFormLegend>

        <StyledInputGroup>
          <StyledPostCodeInput
            id="postcode"
            name="postcode"
            placeholder="10234"
            value={values?.postcode || ''}
            onChange={handleChange}
          />

          <StyledCityInput
            id="city"
            name="city"
            placeholder="Berlin"
            value={values?.city || ''}
            onChange={handleChange}
          />
        </StyledInputGroup>

        {(formErrors?.streetName ||
          formErrors?.houseNumber ||
          formErrors?.postcode ||
          formErrors?.city) && (
          <ErrorMessage>
            <FormattedMessage id="insured.person.details.form.address.error.message" />
          </ErrorMessage>
        )}
      </StyledFormFieldset>

      <StyledAdditionalDetailsText>
        <FormattedMessage
          id="insured.person.details.form.additional.details"
          values={{ serviceEmail: getServiceEmail(insuranceType) }}
        />
      </StyledAdditionalDetailsText>

      <StyledButtonContainer>
        <Button type="submit" variant="primary" disabled={isBtnDisabled}>
          <FormattedMessage id="insured.person.details.form.submit.button" />
        </Button>
      </StyledButtonContainer>
    </StyledForm>
  );
}

export default InsuredPersonDetailsEditor;
