import { css, styled } from '@mui/material';

import { MAX_WIDTH_XS, MIN_WIDTH_LG, MIN_WIDTH_MD } from '../../theme/breakpoints';
import { Button } from '../ui';
import { StyledOnlineVetSlider } from '../OnlineVets/OnlineVets.styled';

export const StyledFressnapfSlideContainer = styled('div')`
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin: 0 auto;

  @media (min-width: ${MIN_WIDTH_LG}) {
    width: 60rem;
  }
`;

export const StyledDesktopSlidesContainer = styled('div')`
  display: none;
  margin-top: 5em;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    display: flex;
    justify-content: center;
    align-items: stretch;
    column-gap: 2rem;
  }
`;

export const StyledDesktopSlideContainer = styled('div')(
  ({ theme }) => css`
    position: relative;
    text-align: center;
    background-color: ${theme.palette.common.white};
    border-radius: 2em;

    padding: 2.5rem 1rem 1.5rem;
  `
);

export const StyledFressnapfMobileButton = styled(Button)`
  display: block;
  margin-top: 1.5em;
  margin-bottom: 1.5em;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    display: none;
  }
`;

export const StyledFressnapfDesktopButton = styled(Button)`
  display: none;
  margin: 3em auto;

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    display: block;
  }
`;

export const StyledMobileSlidesContainer = styled(StyledOnlineVetSlider)`
  @media only screen and (max-width: ${MAX_WIDTH_XS}) {
    margin-bottom: 4.5em;
  }

  @media only screen and (min-width: ${MIN_WIDTH_MD}) {
    display: none;
  }
`;
