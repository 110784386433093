import { styled } from '@mui/material';

import { MIN_WIDTH_LG } from '../../../theme/breakpoints';

import { Paper } from '../../common';

export const StyledClaimStatusHistoryCard = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 1em;

  @media (min-width: ${MIN_WIDTH_LG}) {
    margin-bottom: 1.5em;
  }
`;

export const StyledClaimStatusHistoryTitle = styled('div')`
  margin-bottom: 1em;

  h5 {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }

  @media (min-width: ${MIN_WIDTH_LG}) {
    margin-bottom: 1.5em;

    h5 {
      line-height: 1.5rem;
    }
  }
`;

export const StyledClaimStatusHistoryItems = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;
