import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { datadogLogs } from '@datadog/browser-logs';

import { DATADOG_404_ERROR_MESSAGE, INSURANCE_TYPE_DENTOLO, imgLinks } from '../../../shared';

import { Button } from '../../../components/ui';
import { Container } from '../../../components/common';
import { StyledButtonContainer, StyledImg, StyledBackButton } from './Dentolo404Page.styled';

const Dentolo404Page = () => {
  const history = useHistory();

  useEffect(() => {
    datadogLogs.logger.error(DATADOG_404_ERROR_MESSAGE, { product: INSURANCE_TYPE_DENTOLO });
  }, []);

  return (
    <Container>
      <StyledBackButton to="/dashboard" label={<FormattedMessage id="common.button.back.home" />} />

      <StyledImg src={imgLinks['dentolo.404.page']} alt="404" />
      <StyledButtonContainer>
        <Button variant="primary" onClick={() => history.push('/dashboard')}>
          <FormattedMessage id="common.button.back.home" />
        </Button>
      </StyledButtonContainer>
    </Container>
  );
};

export default Dentolo404Page;
