import React from 'react';
import PropTypes from 'prop-types';

import { imgLinks } from '../../../shared';

import OnboardCard from './OnboardCard';
import {
  StyledOnboardCardParent,
  StyledOnboardCardWrapper,
  StyledCardImage,
  StyledStarsImagesContainer,
  StyledStarImage,
  StyledStarImage2,
  StyledStarImage3,
} from './HomeOnboardPetolo.styled';

const HomeOnboardCard4 = ({ titleHtml, bodyText }) => {
  return (
    <OnboardCard titleHtml={titleHtml} bodyText={bodyText}>
      <StyledOnboardCardParent>
        <StyledOnboardCardWrapper>
          <StyledCardImage src={imgLinks['icon.petolo.card.no.stars']} alt="" />
          <StyledStarsImagesContainer>
            <StyledStarImage src={imgLinks['icon.petolo.star.one']} alt="" />
            <StyledStarImage2 src={imgLinks['icon.petolo.star.two']} alt="" />
            <StyledStarImage3 src={imgLinks['icon.petolo.star.three']} alt="" />
          </StyledStarsImagesContainer>
        </StyledOnboardCardWrapper>
      </StyledOnboardCardParent>
    </OnboardCard>
  );
};

HomeOnboardCard4.propTypes = {
  titleHtml: PropTypes.string,
  bodyText: PropTypes.string,
};

export default HomeOnboardCard4;
