import React from 'react';
import { useParams } from 'react-router-dom';
import {
  PETOLO_DOCUMENT_TYPE,
  PETOLO_TREATMENT_AMOUNT,
  PET_HEALTH_TREATMENT_CATEGORIES,
  useNewPetoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import {
  useTreatmentAmountPetoloBackButtonLink,
  useTreatmentAmountPetoloNextButtonLink,
} from './treatmentAmountPetoloHooks';
import TreatmentAmount from './TreatmentAmount';

export default function TreatmentAmountPetolo() {
  const { claimId } = useParams();

  const [treatmentCategories] = useNewPetoloFormDataValue(PET_HEALTH_TREATMENT_CATEGORIES);
  const [documentType] = useNewPetoloFormDataValue(PETOLO_DOCUMENT_TYPE);

  const [treatmentAmount, setTreatmentAmount] = useNewPetoloFormDataValue(PETOLO_TREATMENT_AMOUNT);

  const backButtonLink = useTreatmentAmountPetoloBackButtonLink(claimId, documentType);
  const nextButtonLink = useTreatmentAmountPetoloNextButtonLink(claimId, treatmentCategories);

  return (
    <TreatmentAmount
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId="petolo.new_claim.treatment_amount.page.title"
      inputLabelId="petolo.new_claim.treatment_amount.form.label.amount"
      treatmentAmount={treatmentAmount}
      setTreatmentAmount={setTreatmentAmount}
      errorMessageId="petolo.new_claim.treatment_amount.form.error.invalid_treatment_amount"
      hintTextId="petolo.new_claim.treatment_amount.form.hint_text"
    />
  );
}
