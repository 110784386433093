import React from 'react';
import { FormattedMessage } from 'react-intl';

import { imgLinks } from '../../../../shared';

import { StyledDaDirektHealthCrossSellingCardImage } from './DaDirektHealthCard.styled';
import {
  StyledDaDirektCrossSellingCard,
  StyledDaDirektCrossSellingCardButton,
  StyledDaDirektCrossSellingCardContent,
  StyledDaDirektCrossSellingCardDaDirektLogo,
  StyledDaDirektCrossSellingCardLogoAndImage,
  StyledDaDirektCrossSellingCardText,
  StyledDaDirektCrossSellingCardTitle,
} from '../DaDirektCrossSellingCard.styled';

const DA_DIREKT_LOGO_LINK = imgLinks['icon.menu.dadirekt.dark'];
const DA_DIREKT_HEALTH_BG_IMAGE_DESKTOP = imgLinks['image-da-health-cross-selling-desktop'];

function DaDirektHealthCardDesktop({ onGoToCrossSellingLink }) {
  return (
    <StyledDaDirektCrossSellingCard product="health">
      <StyledDaDirektCrossSellingCardContent>
        <StyledDaDirektCrossSellingCardTitle>
          <FormattedMessage id="promotion.carousel.da_direkt.health.cross_selling.title.desktop" />
        </StyledDaDirektCrossSellingCardTitle>

        <div>
          <StyledDaDirektCrossSellingCardText>
            <FormattedMessage id="promotion.carousel.da_direkt.health.cross_selling.text.desktop" />
          </StyledDaDirektCrossSellingCardText>

          <StyledDaDirektCrossSellingCardButton onClick={onGoToCrossSellingLink}>
            <FormattedMessage id="promotion.carousel.da_direkt.cross_selling.button.label" />
          </StyledDaDirektCrossSellingCardButton>
        </div>
      </StyledDaDirektCrossSellingCardContent>

      <StyledDaDirektCrossSellingCardLogoAndImage>
        <StyledDaDirektCrossSellingCardDaDirektLogo
          src={DA_DIREKT_LOGO_LINK}
          alt="DA Direkt logo"
        />

        <StyledDaDirektHealthCrossSellingCardImage
          src={DA_DIREKT_HEALTH_BG_IMAGE_DESKTOP}
          alt="DA Direkt health"
        />
      </StyledDaDirektCrossSellingCardLogoAndImage>
    </StyledDaDirektCrossSellingCard>
  );
}

export default DaDirektHealthCardDesktop;
