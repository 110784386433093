import React from 'react';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import useDynamicLink from '../../../../hooks/useDynamicLink';
import { MIN_WIDTH_MD } from '../../../../theme/breakpoints';
import { DA_DIREKT_CROSS_SELLING_HEALTH_URL } from '../../../../shared';

import DaDirektHealthCardDesktop from './DaDirektHealthCardDesktop';
import DaDirektHealthCardMobile from './DaDirektHealthCardMobile';

const daFont = [
  {
    href: 'https://www.da-direkt.de/-/media/Themes/DADirekt/Global/DADirektTheme/fonts/centrano2-medium-woff2.woff2',
    rel: 'preload',
    as: 'font',
    type: 'font/woff2',
  },
];

function DaDirektHealthCard({ trackingparams }) {
  useDynamicLink(daFont);

  const isDesktop = useMediaQuery(`(min-width: ${MIN_WIDTH_MD})`);

  const healthCrossSellingUrl = `${DA_DIREKT_CROSS_SELLING_HEALTH_URL}?${trackingparams}`;

  const goToCrossSellingLink = () => {
    window.open(healthCrossSellingUrl, '_blank');
  };

  return isDesktop ? (
    <DaDirektHealthCardDesktop onGoToCrossSellingLink={goToCrossSellingLink} />
  ) : (
    <DaDirektHealthCardMobile onGoToCrossSellingLink={goToCrossSellingLink} />
  );
}

export default DaDirektHealthCard;
