import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import { PETOLO_IS_EXISTING_CLAIM_ANSWER_YES } from '../../claimFormData/newClaimFormData';
import {
  PETOLO_STEP_DOCUMENT_TYPE,
  PETOLO_STEP_EXISTING_CLAIMS,
  PETOLO_STEP_UPLOAD_DOCUMENTS,
} from '../../new/newClaimsFormSteps';

const getNextButtonLink = (isExistingClaim) =>
  isExistingClaim === PETOLO_IS_EXISTING_CLAIM_ANSWER_YES
    ? PETOLO_STEP_EXISTING_CLAIMS
    : PETOLO_STEP_UPLOAD_DOCUMENTS;

export const useExistingClaimQuestionPetoloBackButtonLink = () => {
  const [, withSource] = useClaimUploadFlowSource();

  return withSource(PETOLO_STEP_DOCUMENT_TYPE);
};

export const useExistingClaimQuestionPetoloNextButtonLink = (isExistingClaim) => {
  const [, withSource] = useClaimUploadFlowSource();
  const nextButtonLink = getNextButtonLink(isExistingClaim);

  return withSource(nextButtonLink);
};
