import { useLocation } from 'react-router-dom';

const extractQueryParamsFromString = (queryParamString) => {
  if (typeof queryParamString === 'string') {
    const queryParamArray = queryParamString.split('&');
    const queryParams = queryParamArray.reduce((agg, next) => {
      const [key, value] = next.split('=');
      if (!!key && !!value) {
        return { ...agg, [key]: value };
      }
      return agg;
    }, {});
    return queryParams;
  }
  return {};
};

const getQueryParamsString = (searchString) => {
  const search = searchString || '';
  const queryParamString = search.length && search[0] === '?' ? search.substring(1) : '';
  return queryParamString;
};

const useQueryParams = () => {
  const { search } = useLocation();
  const queryParamString = getQueryParamsString(search);
  return extractQueryParamsFromString(queryParamString);
};

export default useQueryParams;
