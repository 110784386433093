import React from 'react';
import { FormattedMessage } from 'react-intl';

import { formatDate } from '../../../../shared';
import {
  useNewPetoloFormDataValue,
  PETOLO_SELECTED_EXISTING_CLAIM,
  PETOLO_EXISTING_CLAIM_OPTION_NOT_SELECTED,
  PETOLO_CUSTOMER_NOTE,
} from '../../claimFormData/newClaimFormData';
import {
  useSelectExistingClaimPetoloBackButtonLink,
  useSelectExistingClaimPetoloNextButtonLink,
} from './selectExistingClaimPetoloHooks';

import SelectExistingClaim from './SelectExistingClaim';

export default function SelectExistingClaimPetolo({ claims }) {
  const [selectedClaimId, setSelectedClaimId] = useNewPetoloFormDataValue(
    PETOLO_SELECTED_EXISTING_CLAIM
  );
  const [, /* customerNote */ setCustomerNote] = useNewPetoloFormDataValue(PETOLO_CUSTOMER_NOTE);

  const backButtonLink = useSelectExistingClaimPetoloBackButtonLink();
  const nextButtonLink = useSelectExistingClaimPetoloNextButtonLink(selectedClaimId);

  const claimsToList =
    Array.isArray(claims) &&
    claims.map((claim) => ({
      id: claim?.id,
      label: claim?.treatmentCategory,
      labelId: `treatment.category.${claim?.treatmentCategory || 'pet_null'}`,
      subLabel: formatDate(claim?.lossDate),
    }));

  const onSelectClaim = (newSelectedClaimId) => {
    if (newSelectedClaimId !== PETOLO_EXISTING_CLAIM_OPTION_NOT_SELECTED) {
      setCustomerNote('');
    }
    setSelectedClaimId(newSelectedClaimId);
  };

  return (
    <SelectExistingClaim
      name="existing_petolo_claim"
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      pageTitleId="petolo.new_claim.existing_claims.page.title"
      claims={claimsToList}
      selectedClaimId={selectedClaimId}
      onSelectClaim={onSelectClaim}
      existingClaimNotSelectedKey={PETOLO_EXISTING_CLAIM_OPTION_NOT_SELECTED}
      checkboxLabel={
        <FormattedMessage id="petolo.new_claim.existing_claims.form.label.not_selected" />
      }
    />
  );
}
