import React from 'react';

import {
  PETOLO_DOCUMENT_TYPE,
  PETOLO_DOCUMENT_TYPE_INVOICE,
  PET_HEALTH_TREATMENT_CATEGORIES,
  PETOLO_TREATMENT_CATEGORY_OTHERS,
  PET_HEALTH_TREATMENT_CATEGORY_CASTRATION_OR_STERILIZATION,
  PET_HEALTH_TREATMENT_CATEGORY_FLEAS_AND_TICKS,
  PET_HEALTH_TREATMENT_CATEGORY_VACCINATION,
  PET_HEALTH_TREATMENT_CATEGORY_WORMING,
  useNewPetoloFormDataValue,
  PET_HEALTH_TREATMENT_CATEGORY_GASTROINTESTINAL_DISEASE,
} from '../../claimFormData/newClaimFormData';
import {
  useSelectTreatmentCategoriesPetHealthBackButtonLink,
  useSelectTreatmentCategoriesPetHealthNextButtonLink,
} from './selectTreatmentCategoriesPetHealthHooks';

import SelectTreatmentCategories from './SelectTreatmentCategories';

const treatmentCategoryConfig = [
  {
    label: 'treatment.category.pet_vaccination',
    value: PET_HEALTH_TREATMENT_CATEGORY_VACCINATION,
  },
  {
    label: 'treatment.category.pet_castration_or_sterilization',
    value: PET_HEALTH_TREATMENT_CATEGORY_CASTRATION_OR_STERILIZATION,
  },
  {
    label: 'treatment.category.pet_worming',
    value: PET_HEALTH_TREATMENT_CATEGORY_WORMING,
  },
  {
    label: 'treatment.category.pet_fleas_and_ticks',
    value: PET_HEALTH_TREATMENT_CATEGORY_FLEAS_AND_TICKS,
  },
  {
    label: 'treatment.category.pet_gastrointestinal_disease',
    value: PET_HEALTH_TREATMENT_CATEGORY_GASTROINTESTINAL_DISEASE,
  },
  {
    label: 'pet_health.new_claim.select_treatment_category.other',
    value: PETOLO_TREATMENT_CATEGORY_OTHERS,
  },
];

const SelectTreatmentCategoriesPetHealth = () => {
  const [, /* documentType */ setDocumentType] = useNewPetoloFormDataValue(PETOLO_DOCUMENT_TYPE);

  const [treatmentCategories, setTreatmentCategories] = useNewPetoloFormDataValue(
    PET_HEALTH_TREATMENT_CATEGORIES
  );

  const backButtonLink = useSelectTreatmentCategoriesPetHealthBackButtonLink();
  const nextButtonLink = useSelectTreatmentCategoriesPetHealthNextButtonLink(treatmentCategories);

  const setTreatmentCategoryAndDocumentTypeIfNeeded = (selectedTreatmentCategories) => {
    setTreatmentCategories(selectedTreatmentCategories);

    if (Array.isArray(selectedTreatmentCategories) && selectedTreatmentCategories.length === 1) {
      const selectedTreatmentCategory = selectedTreatmentCategories[0];

      if (selectedTreatmentCategory !== PETOLO_TREATMENT_CATEGORY_OTHERS) {
        setDocumentType(PETOLO_DOCUMENT_TYPE_INVOICE);
      } else {
        setDocumentType('');
      }
    } else {
      setDocumentType('');
    }
  };

  return (
    <SelectTreatmentCategories
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      treatmentCategories={treatmentCategories}
      setTreatmentCategories={setTreatmentCategoryAndDocumentTypeIfNeeded}
      pageTitleId="pet_health.new_claim.select_treatment_category.page_title"
      treatmentCategoryConfig={treatmentCategoryConfig}
    />
  );
};

export default SelectTreatmentCategoriesPetHealth;
