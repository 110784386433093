import React from 'react';
import PropTypes from 'prop-types';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import {
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_HEALTH,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
} from '../../shared';

import { Button as StyledButton, Link, Href } from './Button';
import { Icon } from '../common';
import {
  InfoWindowButtonGroup,
  StyledInfoWindow,
  StyledInfoWindowParagraph,
  StyledInfoWindowTitle,
} from './styled/InfoWindow.styled';

const icons = {
  [INSURANCE_CATEGORY_DENTAL]: {
    'success': 'info.success',
    'failure': 'info.warning',
    'claim-success': 'info.success',
    'claim-warning': 'info.warning',
  },
  [INSURANCE_CATEGORY_PET_HEALTH]: {
    'success': 'info.success',
    'failure': 'info.warning',
    'claim-success': 'info.success',
    'claim-warning': 'info.warning',
  },
  [INSURANCE_CATEGORY_PET_LIABILITY]: {
    'success': 'info.success',
    'failure': 'info.warning',
    'claim-success': 'info.success',
    'claim-warning': 'info.warning',
  },
  [INSURANCE_CATEGORY_HEALTH]: {
    'success': 'info.success',
    'failure': 'info.warning',
    'claim-success': 'info.success',
    'claim-warning': 'info.warning',
  },
};

const Button = ({ text, func, href, to, target, style }) => {
  if (href)
    return (
      <Href variant={style || 'outline'} href={href} target={target}>
        {text}
      </Href>
    );
  if (to)
    return (
      <Link variant={style || 'outline'} to={to}>
        {text}
      </Link>
    );
  return (
    <StyledButton variant={style || 'outline'} onClick={func}>
      {text}
    </StyledButton>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  func: PropTypes.func,
  href: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  style: PropTypes.string,
};

const InfoWindow = (props) => {
  const { mode, titleHtml, textHtml, button, buttons } = props;

  const { category: insuranceCategory } = useInsuranceInfo();
  const iconToUse = icons[`${insuranceCategory}`][`${mode}`];

  return (
    <StyledInfoWindow>
      {mode && iconToUse && <Icon icon={iconToUse} alt="" />}

      {titleHtml && <StyledInfoWindowTitle dangerouslySetInnerHTML={{ __html: titleHtml || '' }} />}

      {textHtml && (
        <StyledInfoWindowParagraph textAlign="center">{textHtml}</StyledInfoWindowParagraph>
      )}

      {button && (
        <InfoWindowButtonGroup>
          <Button
            text={button.text}
            func={button.func}
            style={button.style}
            href={button.href}
            to={button.to}
            target={button.target}
          />
        </InfoWindowButtonGroup>
      )}

      {buttons && (
        <InfoWindowButtonGroup>
          {buttons.map((btn) => (
            <Button
              key={btn.text}
              text={btn.text}
              func={btn.func}
              style={btn.style}
              href={btn.href}
              to={btn.to}
              target={btn.target}
            />
          ))}
        </InfoWindowButtonGroup>
      )}
    </StyledInfoWindow>
  );
};

const buttonPropTypes = {
  text: PropTypes.string,
  func: PropTypes.func,
  href: PropTypes.string,
  to: PropTypes.string,
  target: PropTypes.string,
  style: PropTypes.string,
};

InfoWindow.propTypes = {
  mode: PropTypes.string,
  titleHtml: PropTypes.string,
  textHtml: PropTypes.string,
  button: PropTypes.shape(buttonPropTypes),
  buttons: PropTypes.arrayOf(PropTypes.shape(buttonPropTypes)),
};

export default InfoWindow;
