import React from 'react';
import { FormattedMessage } from 'react-intl';
import { imgLinks } from '../../../shared';
import {
  StyledClaimContactPersonInfo,
  StyledClaimContactPersonDetail,
  StyledClaimDetailsHeading,
  StyledClaimDetailsInformationText,
  StyledClaimDetailsPaper,
  StyledPageDetailsHeadingContainer,
  StyledContentText,
} from './ClaimContactPerson.styled';

function ClaimContactPerson({ claim }) {
  return (
    <StyledClaimDetailsPaper>
      <StyledPageDetailsHeadingContainer>
        <img src={imgLinks[`icon.menu.orange.paw`]} alt="Paw" />
        <StyledClaimDetailsHeading>
          <FormattedMessage id="claim.contact.person.title" />
        </StyledClaimDetailsHeading>
      </StyledPageDetailsHeadingContainer>

      <StyledClaimDetailsInformationText>
        <FormattedMessage
          id="claim.contact.person.text"
          values={{
            external_reference_number: claim?.externalContact?.externalClaimId || '--',
          }}
        />
      </StyledClaimDetailsInformationText>

      <StyledClaimContactPersonDetail>
        <img src={imgLinks[`icon.menu.person`]} alt="Name" />
        <StyledContentText>{claim?.externalContact?.name || '--'}</StyledContentText>
      </StyledClaimContactPersonDetail>

      <StyledClaimContactPersonInfo>
        <StyledClaimContactPersonDetail>
          <img src={imgLinks[`icon.menu.phone`]} alt="Phone" />
          <StyledContentText>{claim?.externalContact?.phoneNumber || '--'}</StyledContentText>
        </StyledClaimContactPersonDetail>

        <StyledClaimContactPersonDetail>
          <img src={imgLinks[`icon.menu.email`]} alt="Email" />
          <StyledContentText>{claim?.externalContact?.email || '--'}</StyledContentText>
        </StyledClaimContactPersonDetail>
      </StyledClaimContactPersonInfo>
    </StyledClaimDetailsPaper>
  );
}

export default ClaimContactPerson;
