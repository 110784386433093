import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  PET_LIABILITY_STEP_AFFECTED_PARTY,
  PET_LIABILITY_STEP_DAMAGED_ITEM,
} from '../../new/newClaimsFormSteps';

export const useDamageEstimatePetLiabilityBackButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_DAMAGED_ITEM);
};

export const useDamageEstimatePetLiabilityNextButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  return withSource(PET_LIABILITY_STEP_AFFECTED_PARTY);
};
