import { css, styled } from '@mui/material';
import { TabList } from 'react-tabs';

import { MAX_WIDTH_SM, MIN_WIDTH_MD, MIN_WIDTH_SM } from '../../../theme/breakpoints';
import { Paper } from '../../../components/common';
import { Button, Paragraph } from '../../../components/ui';

export const StyledReferralTabList = styled(TabList)(
  ({ theme }) => css`
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    column-gap: 1rem;
    font-size: 1rem;
    font-weight: 700;

    & [role='tab'] {
      flex-basis: 50%;
      padding: 0.5rem 0;
      font-size: 1rem;
      font-weight: 700;
      color: ${theme.palette.main.professional_light_blue};
      text-align: center;
      line-height: 22px;
      border-bottom: 1px solid ${theme.palette.main.professional_light_blue};
      cursor: pointer;
      transition:
        color 0.3s ease-in-out,
        border-bottom-color 0.3s ease-in-out;

      &.selected-referral-tab,
      &:not(.selected-referral-tab):hover {
        color: ${theme.palette.main.professional_blue};
        border-bottom-color: ${theme.palette.main.professional_blue};
      }
    }

    + [role='tabpanel'] {
      margin-top: 1.5rem;

      @media (max-width: ${MAX_WIDTH_SM}) {
        margin-top: 2rem;
      }
    }
  `
);

export const StyledReferralCoverImageContainer = styled(Paper)`
  margin-bottom: 1rem;
  padding: 0;
  overflow: hidden;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-bottom: 1.5rem;
  }
`;

export const StyledReferralCardContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledReferralCard = styled(Paper)`
  & + & {
    margin-top: 1rem;
  }
`;

export const StyledSocialLinksContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  align-items: center;
`;

export const StyledSocialGroup = styled('div')`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (min-width: ${MIN_WIDTH_SM}) {
    width: 60%;
  }
`;

export const StyledSocialImg = styled('img')`
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
`;

export const StyledReferralStepsContainer = styled('div')`
  counter-reset: list-number;
`;

export const StyledReferralStepItem = styled('div')`
  font-size: 1rem;
  font-weight: 450;
  line-height: 22px;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }
`;

export const StyledReferralStepItemContent = styled('div')`
  display: inline-block;
  font-size: 1rem;
  font-weight: 450;
`;

export const StyledReferralStepTitle = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 0.5rem;
  font-size: 1rem;
  font-weight: 700;

  &::before {
    content: counter(list-number) '.';
    counter-increment: list-number;
    font-size: inherit;
    font-weight: 700;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    font-size: 1.25rem;
  }
`;

export const StyledReferralAdditionalInformationContainer = styled('div')`
  margin-top: 0.5rem;
`;

export const StyledReferralAdditionalInformation = styled('div')(
  ({ theme }) => css`
    font-size: 1rem;
    font-weight: 450;
    line-height: 22px;

    &::before {
      content: '';
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      background-color: ${theme.palette.background.radio};
      border-radius: 50%;
      margin-right: 0.625rem;
    }
  `
);

export const StyledReferralCopyLinkButton = styled(Button)`
  margin-top: 1.5rem;
  width: 100%;

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: auto;
  }
`;

export const StyledReferralText = styled('p')(
  ({ theme, textAlign }) => css`
    color: ${theme.palette.main.professional_blue};
    margin: 1rem 0;
    font-size: 1rem;
    font-weight: 450;
    line-height: 22px;
    text-align: ${textAlign || 'center'};
  `
);

export const StyledReferralCopyLinkOrSocialsText = styled(StyledReferralText)`
  font-weight: 700;
  text-align: left;

  @media (min-width: ${MIN_WIDTH_MD}) {
    text-align: center;
  }
`;

export const StyledReferralIdContainer = styled('div')(
  ({ theme }) => css`
    margin-top: 1rem;
    padding: 1rem;
    border-radius: 3px;
    background-color: ${theme.palette.gray.ultra_light_gray};

    > p {
      line-height: 22px;
    }
  `
);

export const StyledReferralHighlightBox = styled('div')(
  ({ theme }) => css`
    margin-top: 1.5rem;
    padding: 0.5rem;
    width: 100%;
    border-radius: 3px;
    background-color: ${theme.palette.gray.ultra_light_gray};
  `
);

export const StyledReferralIdBox = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem auto 0;
    padding: 0.5rem;
    width: 100%;
    max-width: 300px;
    border: 1px solid ${theme.palette.main.professional_light_blue};
    border-radius: 3px;
  `
);

export const StyledReferralFooterText = styled(Paragraph)`
  font-size: 0.75rem;
  text-align: left;
  line-height: 16px;

  & + & {
    margin-top: 1rem;
  }

  a::after {
    bottom: 0;
  }
`;

export const StyledReferralTitle = styled('h3')`
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
`;

export const StyledReferralList = styled('div')``;

export const StyledReferralListContainer = styled('div')(
  ({ theme }) => css`
    padding: 1rem 0;
    border-bottom: 1px solid ${theme.palette.gray.light_gray};
  `
);

export const StyledReferralListItem = styled('div')`
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
`;

export const StyledReferralListItemTitle = styled('div')`
  font-size: 1.25rem;
  font-weight: 700;
`;

export const StyledReferraRewardBox = styled('div')(
  ({ theme }) => css`
    margin: 1rem auto 0;
    padding: 0.3rem 0;
    width: 100%;
    border-radius: 5px;
    border: 1px dashed ${theme.palette.main.professional_light_blue};
    text-align: center;
  `
);

export const StyledReferraRewardText = styled('div')`
  font-size: 0.75rem;
  font-weight: 450;

  & + & {
    margin-top: 0.5rem;
  }
`;

export const StyledReferralStatusDescriptionContainer = styled('div')`
  margin-top: 2rem;
`;

export const StyledReferralStatusDescription = styled(Paragraph)`
  font-size: 1rem;

  & + & {
    margin-top: 1rem;
  }
`;

export const StyledReferralMoreInformationContainer = styled(Paper)`
  margin-top: 1rem;
`;

export const StyledReferralMoreInformationTitle = styled('div')`
  font-size: 1.25rem;
  font-weight: 700;
`;

export const StyledReferralMoreInformationText = styled('div')`
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 450;
`;
