import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { CSSTransition } from 'react-transition-group';

import useInsuranceInfo from '../../hooks/useInsuranceInfo';
import useConsole from '../../hooks/useConsole';
import { CONTACT_US, useReady, GET_CUSTOMER } from '../../shared';
import {
  DEFAULT_SUPPORT_PHONE_NUMBER,
  showSelfCancellationMap,
  supportPhoneNumberMap,
} from '../../utils/mappings';
import {
  DEFAULT_PHONE_ICON,
  SUBJECT_OPTION_REFERRAL,
  getForwardToDaDirektValue,
  phoneIconMap,
} from './contactUtils';

import ContactForm from './ContactForm';
import { ErrorPlaceholder, Paragraph } from '../../components/ui';
import { Container, PageTitle } from '../../components/common';
import { StyledAccordion, StyledContent, StyledWorkingTimes } from './Contact.styled';

function Contact() {
  const history = useHistory();
  const intl = useIntl();
  const { consoleError } = useConsole();

  const { type: insuranceType, partner: insurancePartner } = useInsuranceInfo();
  const { data: customerData, loading: customerLoading } = useQuery(GET_CUSTOMER);
  const { customer } = customerData || {};

  const ready = useReady();

  const [formLoading, setFormLoading] = useState(false);
  const [body, setBody] = useState('');
  const [subject, setSubject] = useState('');

  const isDaDirektCustomer = insurancePartner === 'da-direkt';

  const forwardToDaDirekt = isDaDirektCustomer ? getForwardToDaDirektValue(subject) : null;

  const [contactUs] = useMutation(CONTACT_US, {
    variables: {
      subject,
      body,
      forwardToDaDirekt,
    },
  });

  const isSubjectDaDirektReferral = subject === SUBJECT_OPTION_REFERRAL && isDaDirektCustomer;

  const submitForm = async () => {
    setFormLoading(true);

    try {
      const resp = await contactUs();

      if (resp.data.contactUs && Object.keys(resp.data.contactUs).length) {
        const { errors } = resp?.data?.contactUs || {};

        let status = 'failure';
        if (!errors.length) {
          status = 'success';
        }

        history.push(`/contact/${status}`, {
          selectedSubject: subject,
          isDaDirektCustomer,
        });
        window.scrollTo(0, 0);
      }
    } catch (err) {
      consoleError(err);
    }

    setFormLoading(false);
  };

  const contractNotCancelled = !customer?.contract?.cancelationDate;
  const showContractCancelLink = showSelfCancellationMap[`${insuranceType}`];

  const customerFullName = `${customer?.firstName} ${customer?.lastName}`;

  const contactPhoneNumber =
    supportPhoneNumberMap[`${insuranceType}`] || DEFAULT_SUPPORT_PHONE_NUMBER;
  const phoneIcon = phoneIconMap[`${insuranceType}`] || DEFAULT_PHONE_ICON;

  if (!customerLoading && !!customer && !!Object.keys(customer).length) {
    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Container>
          <PageTitle>
            <FormattedMessage id="contact.page.main.title" />
          </PageTitle>

          <Paragraph>
            <FormattedMessage id="contact.page.main.description" />
          </Paragraph>

          {showContractCancelLink && contractNotCancelled && (
            <Paragraph>
              <FormattedMessage id="contact.page.main.cancellation.text1" />
              &nbsp;
              <Link to="/contract-cancellation">
                <FormattedMessage id="contact.page.main.cancellation.here_link" />
              </Link>
              &nbsp;
              <FormattedMessage id="contact.page.main.cancellation.text2" />
              &nbsp;
            </Paragraph>
          )}

          <ContactForm
            maxLength="10000"
            fullName={customerFullName}
            email={customer?.email}
            onFormSubmit={submitForm}
            formLoading={formLoading}
            body={body}
            setBody={setBody}
            subject={subject}
            setSubject={setSubject}
            isSubjectDaDirektReferral={isSubjectDaDirektReferral}
          />

          <StyledAccordion
            title={intl.formatMessage({ id: 'contact.page.main.phone' })}
            titleIcon={phoneIcon}
            expandText="Expand"
            collapseText="Collapse"
          >
            <StyledContent>
              <StyledWorkingTimes>
                <span>
                  <FormattedMessage id="contact.page.main.working.days" />
                  :&nbsp;
                </span>
                <span>
                  <FormattedMessage id="contact.page.main.working.hours" />
                </span>
              </StyledWorkingTimes>

              <span>{contactPhoneNumber}</span>
            </StyledContent>
          </StyledAccordion>
        </Container>
      </CSSTransition>
    );
  }

  if (!customerLoading) {
    return (
      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <ErrorPlaceholder />
      </CSSTransition>
    );
  }

  return null;
}

export default Contact;
