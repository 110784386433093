import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { withRouter } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { CSSTransition } from 'react-transition-group';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import JWTDecode from 'jwt-decode';
import utc from 'dayjs/plugin/utc';

import { TRIGGER_WEBHOOK, useReady } from '../../shared';

import { InputField, Paragraph } from '../ui';
import { Paper } from '../common';
import { AuthContainer, PageTitle, DateContainer, Button } from './Login.styled';

dayjs.extend(utc);

const SecondAuth = ({ jwt, setBirthDate, wrongDateOfBirth, setWrongDateOfBirth }) => {
  const theme = useTheme();

  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const [disableForm, setDisableForm] = useState(false);
  const ready = useReady();
  const intl = useIntl();

  const dayInputRef = useRef(null);
  const monthInputRef = useRef(null);
  const yearInputRef = useRef(null);
  const submitBtnRef = useRef(null);

  const decodedJwt = jwt ? JWTDecode(jwt) : {};
  const uuid = decodedJwt?.uuid;
  const email = decodedJwt?.email;

  const [triggerWebhook] = useMutation(TRIGGER_WEBHOOK, {
    variables: {
      uuid: uuid || '',
    },
  });

  useEffect(() => {
    if (!!email && !!uuid) {
      triggerWebhook();
    }
  }, [email, uuid, triggerWebhook]);

  const flushDateInput = useCallback(() => {
    setDay('');
    setMonth('');
    setYear('');
    setBirthDate('');
    setWrongDateOfBirth(false);
    if (dayInputRef) {
      dayInputRef.current.focus();
    }
  }, [setWrongDateOfBirth, setBirthDate]);

  useEffect(() => {
    if (wrongDateOfBirth) {
      setDisableForm(false);
      setIsInvalid(true);
      flushDateInput();
    }
  }, [wrongDateOfBirth, flushDateInput]);

  const onDayInputChange = (e) => {
    const val = e.target.value;
    const inputDay = val?.toString()?.slice(0, 2);

    if (inputDay?.length === 2 && monthInputRef) {
      monthInputRef.current.focus();
    }

    setDay(inputDay);
    setIsInvalid(false);
  };

  const onMonthInputChange = (e) => {
    const val = e.target.value;
    const inputMonth = val?.toString()?.slice(0, 2);

    if (inputMonth?.length === 2 && yearInputRef) {
      yearInputRef.current.focus();
    }

    setMonth(inputMonth);
    setIsInvalid(false);
  };

  const onYearInputChange = (e) => {
    const val = e.target.value;
    const inputYear = val?.toString()?.slice(0, 4);

    if (inputYear?.length === 4 && submitBtnRef) {
      submitBtnRef.current.focus();
    }

    setYear(inputYear);
    setIsInvalid(false);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    setDisableForm(true);

    const monthNum = month.length === 1 ? `0${month}` : month;
    const dayNum = day.length === 1 ? `0${day}` : day;
    const dateISOString = `${year}-${monthNum}-${dayNum}T00:00:00.000Z`;

    if (dayjs(dateISOString).isValid()) {
      setBirthDate(dateISOString);
    } else {
      setDisableForm(false);
      setIsInvalid(true);
      flushDateInput();
    }
  };

  return (
    <AuthContainer>
      <PageTitle>
        <FormattedMessage id="second.auth.title" />
      </PageTitle>

      <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
        <Paper style={{ padding: '2rem' }}>
          <form onSubmit={(e) => onFormSubmit(e)}>
            <fieldset disabled={disableForm} aria-busy={disableForm}>
              <DateContainer>
                <InputField
                  id="second-auth-day"
                  name="second-auth-day"
                  placeholder="22"
                  labelText={intl.formatMessage({
                    id: 'second.auth.input.label.day',
                  })}
                  ref={dayInputRef}
                  labelStyle={{ fontWeight: 700 }}
                  type="number"
                  value={day}
                  onChange={onDayInputChange}
                  required
                />

                <InputField
                  id="second-auth-month"
                  name="second-auth-month"
                  placeholder="10"
                  labelText={intl.formatMessage({
                    id: 'second.auth.input.label.month',
                  })}
                  ref={monthInputRef}
                  labelStyle={{ fontWeight: 700 }}
                  type="number"
                  value={month}
                  onChange={onMonthInputChange}
                  required
                />

                <InputField
                  id="second-auth-year"
                  name="second-auth-year"
                  placeholder="1975"
                  labelText={intl.formatMessage({
                    id: 'second.auth.input.label.year',
                  })}
                  ref={yearInputRef}
                  labelStyle={{ fontWeight: 700 }}
                  type="number"
                  value={year}
                  onChange={onYearInputChange}
                  required
                />
              </DateContainer>

              <CSSTransition in={isInvalid} timeout={600} classNames="fade" unmountOnExit>
                <div style={{ marginTop: '0.5rem', color: theme.palette.error.main }}>
                  <FormattedMessage id="second.auth.wrong.details" />
                </div>
              </CSSTransition>

              <Paragraph style={{ margin: '1.5rem 0' }}>
                {intl.formatMessage({ id: 'second.auth.description' })}
              </Paragraph>

              <div>
                <Button ref={submitBtnRef} type="submit">
                  <FormattedMessage id="second.auth.login.btn" />
                </Button>

                <p style={{ marginTop: '1.5rem' }}>
                  <span style={{ fontWeight: 700, color: theme.palette.main.product_blue }}>*</span>{' '}
                  <FormattedMessage id="second.auth.fields.required" />
                </p>
              </div>
            </fieldset>
          </form>
        </Paper>
      </CSSTransition>
    </AuthContainer>
  );
};

SecondAuth.propTypes = {
  jwt: PropTypes.string,
  setBirthDate: PropTypes.func,
  wrongDateOfBirth: PropTypes.bool,
  setWrongDateOfBirth: PropTypes.func,
};

export default withRouter(SecondAuth);
