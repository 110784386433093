import React from 'react';
import { css, styled } from '@mui/material';

import { Input as InputField } from '../../../../components/common';

const StyledInputContainer = styled('div')`
  position: relative;
`;

const StyledInput = styled(InputField)`
  padding-right: 2.5rem;
`;

const StyledSuffix = styled('span')(
  ({ theme }) => css`
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    font-size: 1rem;
    font-weight: 450;
    color: ${theme.palette.main.professional_blue};
  `
);

export const Input = (props) => {
  const { suffix, ...rest } = props;

  return (
    <StyledInputContainer>
      <StyledSuffix>{suffix}</StyledSuffix>
      <StyledInput {...rest} />
    </StyledInputContainer>
  );
};

export const StyledHintContainer = styled('div')(
  ({ theme }) => css`
    background-color: ${theme.palette.gray.light_gray};
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 4px;
  `
);
