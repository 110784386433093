export const NEW = 'new'; // Neu
export const WAITING_FOR_INFORMATION = 'waiting_for_information'; // Wartend
export const REIMBURSEMENT_ACKNOWLEDGED = 'reimbursement_acknowledged'; // Deckungszusage
export const WAITING_FOR_PAYOUT = 'waiting_for_payout'; // Auszahlung
export const PARTIALLY_CLOSED = 'partially_closed'; // > obsolete status
export const CLOSED = 'closed'; // Ausgezahlt
export const DECLINED = 'declined'; // Abgelehnt
export const BLOCKED = 'blocked'; // Blockiert
export const CANCELLED = 'cancelled'; // Storniert
export const UPDATED = 'updated'; // Aktualisiert

// Sub status
export const INVOICE_MISSING = 'invoice_missing';
export const TREATMENT_PLAN_MISSING = 'treatment_or_costplan_missing';
