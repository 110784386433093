import { useQuery } from '@apollo/client';

import {
  DA_DIREKT_POLICY_CATEGORIES,
  DEFAULT_INSURANCE_TYPE,
  DOMAIN_DENTOLO,
  DOMAIN_PETOLO,
  DOMAIN_VITOLO,
  GET_CUSTOMER,
  INSURANCE_CATEGORY_DENTAL,
  INSURANCE_CATEGORY_HEALTH,
  INSURANCE_CATEGORY_PET_HEALTH,
  INSURANCE_CATEGORY_PET_LIABILITY,
  INSURANCE_TYPE_DENTOLO,
  INSURANCE_TYPE_PETOLO,
  INSURANCE_TYPE_VITOLO,
  NODE_ENV,
  PARTNER_DA_DIREKT,
  PARTNER_FRESSNAPF,
  SPECIAL_TARIFF_POLICY_CATEGORIES,
} from '../shared';

const getInsuranceTypeFromURL = () => {
  const hostname = window.location.host;

  if (hostname.includes(DOMAIN_DENTOLO)) {
    return INSURANCE_TYPE_DENTOLO;
  }

  if (hostname.includes(DOMAIN_PETOLO)) {
    return INSURANCE_TYPE_PETOLO;
  }

  if (hostname.includes(DOMAIN_VITOLO)) {
    return INSURANCE_TYPE_VITOLO;
  }

  return INSURANCE_TYPE_DENTOLO;
};

const getInsuranceTypeFromInsuranceCategory = (customerInsuranceCategory) => {
  if (customerInsuranceCategory === INSURANCE_CATEGORY_DENTAL) {
    return INSURANCE_TYPE_DENTOLO;
  }

  if (
    customerInsuranceCategory === INSURANCE_CATEGORY_PET_HEALTH ||
    customerInsuranceCategory === INSURANCE_CATEGORY_PET_LIABILITY
  ) {
    return INSURANCE_TYPE_PETOLO;
  }

  if (customerInsuranceCategory === INSURANCE_CATEGORY_HEALTH) {
    return INSURANCE_TYPE_VITOLO;
  }

  return INSURANCE_TYPE_DENTOLO;
};

const getPartnerFromPolicyCategoryOrSignupSource = (policyCategory, signupSource) => {
  if (DA_DIREKT_POLICY_CATEGORIES?.includes(policyCategory)) {
    return PARTNER_DA_DIREKT;
  }

  if (signupSource?.includes('fressnapf')) {
    return PARTNER_FRESSNAPF;
  }

  return null;
};

const getPartnerForSpecialTariff = (signupSource) => {
  if (signupSource?.includes('da_direkt')) {
    return PARTNER_DA_DIREKT;
  }

  if (signupSource?.includes('fressnapf')) {
    return PARTNER_FRESSNAPF;
  }

  return null;
};

const getPartnerName = (customer) => {
  const policyCategory = customer?.contract?.policyCategory;
  const signupSource = customer?.signUpSource?.key;

  if (!policyCategory) return null;

  const partnerName = SPECIAL_TARIFF_POLICY_CATEGORIES.includes(policyCategory)
    ? getPartnerForSpecialTariff(signupSource)
    : getPartnerFromPolicyCategoryOrSignupSource(policyCategory, signupSource);

  return partnerName || null;
};

const getInsuranceType = (customerInsuranceCategory) => {
  const insuranceTypeFromUrl =
    NODE_ENV === 'development' ? DEFAULT_INSURANCE_TYPE : getInsuranceTypeFromURL();

  const insuranceTypeFromCustomer =
    getInsuranceTypeFromInsuranceCategory(customerInsuranceCategory);

  const insuranceType = customerInsuranceCategory
    ? insuranceTypeFromCustomer
    : insuranceTypeFromUrl;

  return insuranceType;
};

const useInsuranceInfo = () => {
  const { data: customerData } = useQuery(GET_CUSTOMER);
  const { customer } = customerData || {};

  const category = customer?.insuranceCategory;
  const type = getInsuranceType(category);
  const partner = getPartnerName(customer);
  const riskCarrier = customer?.contract?.riskCarrier;

  return { type, category, partner, riskCarrier };
};

export default useInsuranceInfo;
