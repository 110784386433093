import { css, styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

export const StyledDentistsMap = styled('div')`
  position: relative;
  width: 100vw;
  height: 100%;
`;

export const StyledDentistsMapOverlay = styled('div')(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: ${theme.palette.background.viewport};
    overflow: hidden;
    z-index: 30;
  `
);

export const StyledDentistsMapWrapper = styled('div')(
  ({ theme }) => css`
    position: relative;
    background: transparent;
    overflow: hidden;
    appearance: none;
    height: calc(100vh - ${theme.shape.headerMobile});

    @media (min-width: ${MIN_WIDTH_MD}) {
      height: calc(100vh - ${theme.shape.headerDesktop} - ${theme.shape.navMenuDesktop});
    }
  `
);

export const StyledDentistsMapUI = styled('div')`
  width: 100%;
  height: 100%;
`;

export const StyledDentistsMapTitleContainer = styled('div')`
  position: absolute;
  top: 1rem;
  left: 50%;
  padding: 0 1rem;
  width: 100%;
  max-width: 480px;
  transform: translateX(-50%);
  z-index: 10;

  @media (min-width: ${MIN_WIDTH_MD}) {
    left: 0;
    transform: translateX(0);
  }
`;
