import React from 'react';
import { useParams } from 'react-router-dom';

import SelectDocumentCreationDate from './SelectDocumentCreationDate';
import {
  DENTOLO_DOCUMENT_DATE,
  DENTOLO_DOCUMENT_TYPE,
  useNewDentoloFormDataValue,
} from '../../claimFormData/newClaimFormData';
import {
  useSelectDocumentCreationDateDentoloBackButtonLink,
  useSelectDocumentCreationDateDentoloNextButtonLink,
} from './selectDocumentCreationDateDentoloHooks';

const SelectDocumentCreationDateDentolo = () => {
  const { claimId } = useParams();

  const [documentType] = useNewDentoloFormDataValue(DENTOLO_DOCUMENT_TYPE);

  const backButtonLink = useSelectDocumentCreationDateDentoloBackButtonLink(claimId);
  const nextButtonLink = useSelectDocumentCreationDateDentoloNextButtonLink(claimId, documentType);

  const [documentCreationDate, setDocumentCreationDate] =
    useNewDentoloFormDataValue(DENTOLO_DOCUMENT_DATE);

  return (
    <SelectDocumentCreationDate
      backButtonLink={backButtonLink}
      nextButtonLink={nextButtonLink}
      documentCreationDate={documentCreationDate}
      setDocumentCreationDate={setDocumentCreationDate}
      pageTitleId="dentolo.new_claim.select_document_creation_date.page_title"
      formDescription="dentolo.new_claim.select_document_creation_date.form_description"
    />
  );
};

export default SelectDocumentCreationDateDentolo;
