import { styled } from '@mui/material';

import { FormSectionContainer } from '../../../../../components/common';

export const PersonalDetailsFormContainer = styled(FormSectionContainer)`
  > label {
    &:not(:first-of-type) {
      margin-top: 1rem;
    }
  }
`;

export const StyledAddressContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  column-gap: 0.5rem;

  > input {
    &:first-of-type {
      flex-basis: 70%;
    }
    &:last-of-type {
      flex-basis: 30%;
    }
  }
`;

export const StyledPostcodeContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  column-gap: 0.5rem;

  > input {
    &:first-of-type {
      flex-basis: 30%;
    }
    &:last-of-type {
      flex-basis: 70%;
    }
  }
`;
