import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from '../common';
import { Paragraph } from '../ui';
import {
  StyledMissingIbanBannerContainer,
  StyledMissingIbanBannerBody,
  StyledMissingIbanBannerTitle,
  StyledMissingIbanBannerText,
  StyledDissmissButton,
  StyledMissingIbanBannerIcon,
} from './MissingIban.styled';

const MissingIban = (props) => {
  const { title, message1, message2, linkText, onClick, onDismiss } = props;

  return (
    <StyledMissingIbanBannerContainer dismissable={!!onDismiss}>
      <StyledMissingIbanBannerBody dismissable={!!onDismiss}>
        <StyledMissingIbanBannerIcon icon="icon.menu.orange.doc" />

        <div>
          {title && <StyledMissingIbanBannerTitle>{title}</StyledMissingIbanBannerTitle>}

          <Paragraph>
            <StyledMissingIbanBannerText dismissable={!!onDismiss}>
              {message1}

              <a onClick={onClick} href>
                {linkText}
              </a>

              {message2}
            </StyledMissingIbanBannerText>
          </Paragraph>
        </div>
      </StyledMissingIbanBannerBody>

      {!!onDismiss && (
        <StyledDissmissButton type="button" onClick={onDismiss}>
          <Icon icon="icon.x" alt="Dismiss button" />
        </StyledDissmissButton>
      )}
    </StyledMissingIbanBannerContainer>
  );
};

export default MissingIban;

MissingIban.propTypes = {
  title: PropTypes.string,
  message1: PropTypes.string,
  message2: PropTypes.string,
  linkText: PropTypes.string,
};
