import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { StyledButton, StyledLink, StyledHref } from './styled/Button.styled';

const defaultButtonProps = {
  variant: 'primary',
  disabled: false,
};

const buttonPropTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(['primary', 'outline', 'nostyle']),
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export const Button = forwardRef((props, ref) => {
  const { children, label, onClick, ...rest } = props;

  return (
    <StyledButton ref={ref} onClick={onClick} {...rest}>
      {children || label}
    </StyledButton>
  );
});

Button.defaultProps = {
  ...defaultButtonProps,
  type: 'button',
  disabled: false,
};

Button.propTypes = {
  ...buttonPropTypes,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

export const Link = forwardRef((props, ref) => {
  const { children, label, to, ...rest } = props;

  return (
    <StyledLink ref={ref} to={to} {...rest}>
      {children || label}
    </StyledLink>
  );
});

Link.defaultProps = {
  ...defaultButtonProps,
};

Link.propTypes = {
  ...buttonPropTypes,
  to: PropTypes.string,
};

export const Href = forwardRef((props, ref) => {
  const { children, label, href, target, ...rest } = props;

  return (
    <StyledHref ref={ref} href={href} target={target} {...rest}>
      {children || label}
    </StyledHref>
  );
});

Href.defaultProps = {
  ...defaultButtonProps,
  target: '_self',
};

Href.propTypes = {
  ...buttonPropTypes,
  href: PropTypes.string,
  target: PropTypes.string,
};
