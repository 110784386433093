import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Button } from './Button';
import { Icon, Text } from '../common';
import { StyledDentistDetailsAvatarContainer } from '../styled/DentistsDetails.styled';
import {
  StyledDentistFloatCard,
  StyledDentistFloatCardAvatar,
  StyledDentistFloatCardItem,
  StyledDentistFloatCardButtonContainer,
  StyledDentistFloatCardWrapper,
} from './styled/DentiststFloatCard.styled';

const DentistsFloatCard = ({ dentist, setDentist, setShowDetails }) => {
  const intl = useIntl();

  const {
    avatar,
    city,
    dentoloPartner,
    houseNumber,
    name,
    postcode,
    practiceName,
    streetName,
    title,
  } = dentist || {};

  const avatarSrc = avatar;
  const address = `${streetName} ${houseNumber}, ${postcode} ${city}`;

  const avatarContainerAlignItems = dentoloPartner ? 'flex-start' : 'center';

  if (!dentist) return null;

  return (
    <StyledDentistFloatCard>
      <Text fontSize="1.15rem">{practiceName}</Text>

      <StyledDentistFloatCardWrapper>
        <StyledDentistDetailsAvatarContainer alignItems={avatarContainerAlignItems}>
          {avatarSrc && <StyledDentistFloatCardAvatar src={avatarSrc} alt="avatar" />}

          <div>
            <Text fontSize="1.25rem" fontWeight="700">
              {title} {name}
            </Text>

            <Text fontSize="1rem" lineHeight="1rem">
              {intl.formatMessage({ id: 'dentist.details.dentolo.dentist' })}
            </Text>
          </div>
        </StyledDentistDetailsAvatarContainer>

        <StyledDentistFloatCardItem>
          <Icon icon="icon.pin" alt="address" />
          <label htmlFor="address">{address}</label>
        </StyledDentistFloatCardItem>

        <StyledDentistFloatCardButtonContainer>
          <Button variant="outline" onClick={() => setDentist(null)}>
            <FormattedMessage id="common.button.close" />
          </Button>

          <Button onClick={() => setShowDetails(true)}>Details</Button>
        </StyledDentistFloatCardButtonContainer>
      </StyledDentistFloatCardWrapper>
    </StyledDentistFloatCard>
  );
};

export default DentistsFloatCard;

DentistsFloatCard.propTypes = {
  setDentist: PropTypes.func,
  setShowDetails: PropTypes.func,
  dentist: PropTypes.shape({
    avatar: PropTypes.string,
    city: PropTypes.string,
    dentoloPartner: PropTypes.bool,
    houseNumber: PropTypes.string,
    name: PropTypes.string,
    postcode: PropTypes.string,
    practiceName: PropTypes.string,
    streetName: PropTypes.string,
    title: PropTypes.string,
  }),
};
