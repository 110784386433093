import React from 'react';
import PropTypes from 'prop-types';

import { imgLinks } from '../../../shared';

import OnboardCard from './OnboardCard';
import {
  StyledClaimOnBoardCardParent,
  StyledClaimOnBoardCardWrapper,
  StyledClaimOnBoardImageCoinListContainer,
  StyledClaimOnBoardImageCoin,
  StyledClaimOnBoardImageCoins,
  StyledClaimOnBoardImageStars,
} from './UserClaimsOnboardDentolo.styled';

const UserClaimsOnboardCard4 = ({ titleHtml, bodyText }) => {
  return (
    <OnboardCard titleHtml={titleHtml} bodyText={bodyText}>
      <StyledClaimOnBoardCardParent>
        <StyledClaimOnBoardCardWrapper>
          <StyledClaimOnBoardImageCoinListContainer>
            {[1, 2, 3, 4, 5].map((num) => (
              <StyledClaimOnBoardImageCoin
                src={imgLinks['icon.user.claim.onboard.coin']}
                key={num}
                alt=""
              />
            ))}
          </StyledClaimOnBoardImageCoinListContainer>

          <StyledClaimOnBoardImageCoins src={imgLinks['icon.user.claim.onboard.coins']} alt="" />
          <StyledClaimOnBoardImageStars src={imgLinks['icon.user.claim.onboard.stars']} alt="" />
        </StyledClaimOnBoardCardWrapper>
      </StyledClaimOnBoardCardParent>
    </OnboardCard>
  );
};

UserClaimsOnboardCard4.propTypes = {
  titleHtml: PropTypes.string,
  bodyText: PropTypes.string,
};

export default UserClaimsOnboardCard4;
