import { css, styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../../theme/breakpoints';
import { Paper } from '../../../components/common';

export const StyledClaimDetailsPaper = styled(Paper)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 0.5em;
`;

export const StyledPageDetailsHeadingContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  column-gap: 1em;
`;

export const StyledClaimDetailsHeading = styled('h5')`
  font-size: 1.25rem;
  line-height: 1.25rem;
`;

export const StyledClaimDetailsInformationText = styled('div')(
  ({ theme }) => css`
    font-size: 1em;
    font-weight: 300;
    line-height: 1.25em;
    color: ${theme.palette.main.product_blue};
    opacity: 0.9;
    word-break: break-word;
  `
);

export const StyledClaimContactPersonDetail = styled('div')`
  display: flex;
  margin-top: 1em;
`;

export const StyledClaimContactPersonInfo = styled('div')`
  @media (min-width: ${MIN_WIDTH_MD}) {
    display: flex;
  }
`;

export const StyledContentText = styled('p')(
  ({ theme }) => css`
    color: ${theme.palette.main.professional_blue};
    font-size: 1rem;
    margin-left: 0.5rem;
    word-break: break-word;

    @media (min-width: ${MIN_WIDTH_MD}) {
      margin-right: 1.5rem;
    }
  `
);
