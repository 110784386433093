import { styled, css } from '@mui/material';
import { Paper } from '../../components/common';
import { MIN_WIDTH_MD } from '../../theme/breakpoints';

export const StyledChooseCancellationContainer = styled(Paper)`
  margin-top: 1rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 1.5rem;
  }
`;

export const StyledChooseCancellationRadioInputs = styled('div')`
  row-gap: 1rem;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
`;

export const StyledChooseCancellationRadioInputAndLabel = styled('div')`
  display: flex;
`;

export const StyledChooseCancellationRadioLabel = styled('label')(
  ({ theme, disabled }) => css`
    cursor: pointer;
    margin-left: 0.5rem;

    & > h3,
    & > p {
      color: ${disabled ? theme.palette.main.product_blue : theme.palette.main.professional_blue};
    }
  `
);

export const StyledChooseCancellationButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
`;
