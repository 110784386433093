import React from 'react';
import PropTypes from 'prop-types';

import { StyledNavMenuMobileUserInfo } from './styled/NavMenuMobile.styled';

function NavMenuMobileUserInfo({ name, policyName }) {
  return (
    <StyledNavMenuMobileUserInfo>
      <h5 data-hj-suppress="true">{name}</h5>
      <p>{policyName}</p>
    </StyledNavMenuMobileUserInfo>
  );
}

export default NavMenuMobileUserInfo;

NavMenuMobileUserInfo.defaultProps = {
  name: '',
  policyName: '',
};

NavMenuMobileUserInfo.propTypes = {
  name: PropTypes.string,
  policyName: PropTypes.string,
};
