import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useIntl, FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { CSSTransition } from 'react-transition-group';

import useConsole from '../../../../hooks/useConsole';
import { getReferralShareToSocialList } from '../referralPageHelper';
import {
  useReady,
  GET_CUSTOMER,
  PETOLO_REFERRAL_URL,
  JOIN_REFERRAL_PROGRAM,
  PETOLO_REFERRAL_TERMS_LINK,
} from '../../../../shared';

import { Separator } from '../../../../components/common';
import { Button, ErrorPlaceholder, Loading } from '../../../../components/ui';
import {
  StyledReferralAdditionalInformation,
  StyledReferralAdditionalInformationContainer,
  StyledReferralCard,
  StyledReferralCardContent,
  StyledReferralCopyLinkButton,
  StyledReferralCopyLinkOrSocialsText,
  StyledReferralFooterText,
  StyledReferralHighlightBox,
  StyledReferralIdBox,
  StyledReferralIdContainer,
  StyledReferralStepItem,
  StyledReferralStepTitle,
  StyledReferralStepsContainer,
  StyledReferralText,
  StyledReferralTitle,
  StyledSocialGroup,
  StyledSocialImg,
  StyledSocialLinksContainer,
} from '../ReferralPage.styled';

const referralSteps = [
  {
    id: 1,
    titleId: 'referral.page.petolo.step.one.title',
    descriptionId: 'referral.page.petolo.step.one.description',
  },
  {
    id: 2,
    titleId: 'referral.page.petolo.step.two.title',
    descriptionId: 'referral.page.petolo.step.two.description',
  },
  {
    id: 3,
    titleId: 'referral.page.petolo.step.three.title',
    descriptionId: 'referral.page.petolo.step.three.description',
    descriptionValues: { giftbrand: 'Fressnapf-Gutschein' },
    additionalInfo: [
      { infoId: 'referral.page.petolo.step.three.additional_info.one' },
      { infoId: 'referral.page.petolo.step.three.additional_info.two' },
      { infoId: 'referral.page.petolo.step.three.additional_info.three' },
    ],
  },
];

const transitionTimeout = 500;
const elementIdWithReferralLink = 'element-with-petolo-referral-link';

function PetoloReferralInformation(props) {
  const { customer, customerLoading, onReferralInfoLinkClick } = props;
  const { referralCode: refId } = customer || {};

  const intl = useIntl();
  const ready = useReady();
  const { consoleError } = useConsole();

  const [refUrl, setRefUrl] = useState(PETOLO_REFERRAL_URL);

  const tooltipNodeRef = useRef(null);

  const [
    generatePetHealthReferralCode,
    { loading: referralCodeLoading, error: referralCodeGenerationError },
  ] = useMutation(JOIN_REFERRAL_PROGRAM, {
    refetchQueries: [{ query: GET_CUSTOMER }],
  });

  const shareData = {
    fb: {
      refUrl,
      elementIdWithReferralLink,
    },
    whatsapp: {
      intl,
      refUrl,
      elementIdWithReferralLink,
      waMessageId: 'referral.page.petolo.whatsapp.message',
    },
    email: {
      intl,
      refUrl,
      elementIdWithReferralLink,
      emailContentIds: {
        to: '',
        subjectId: 'referral.page.petolo.email.subject',
        bodyId: 'referral.page.petolo.email.message',
      },
    },
  };

  const socialItemsData = {
    fb: {
      icon: 'icon.user.referral.pet.fb',
    },
    whatsapp: {
      icon: 'icon.user.referral.pet.whatsapp',
    },
    email: {
      icon: 'icon.user.referral.pet.email',
    },
  };

  // Get social items to share the referral link
  const shareSocialItems = getReferralShareToSocialList(socialItemsData);

  // Generate referral code if customer does not have one
  useEffect(() => {
    const hasReferralCode = !!customer?.referralCode;

    if (!hasReferralCode) {
      generatePetHealthReferralCode();
    }
  }, [customer, generatePetHealthReferralCode]);

  // Add referral `id` to the referral url
  useEffect(() => {
    if (refId && refUrl.indexOf('&refid=') < 0) {
      setRefUrl(`${refUrl}&refid=${refId}`);
    }
  }, [refId, refUrl]);

  // Copy to clipboard
  const onLinkCopy = async () => {
    if (!window?.navigator?.clipboard || !refUrl) return;

    try {
      await window.navigator.clipboard.writeText(refUrl);

      if (!tooltipNodeRef?.current) return;

      ReactTooltip.show(tooltipNodeRef.current);

      setTimeout(() => {
        ReactTooltip.hide(tooltipNodeRef.current);
      }, 1200);
    } catch (err) {
      consoleError('Could not copy text: ', err);
    }
  };

  const renderBTag = (chunk) => <strong>{chunk}</strong>;

  const renderAnchorTagForMoreInfo = (chunk, fn) => (
    <Button variant="nostyle" onClick={fn}>
      {chunk}
    </Button>
  );

  const renderAnchorTagForTerms = (chunk, link) => (
    <a target="_blank" rel="noopener noreferrer" href={link}>
      {chunk}
    </a>
  );

  if (referralCodeLoading || customerLoading) return <Loading showLogo />;

  if ((!customerLoading && !customer) || referralCodeGenerationError) return <ErrorPlaceholder />;

  if (!customerLoading && !!customer) {
    return (
      <CSSTransition in={ready} timeout={transitionTimeout} classNames="fade" unmountOnExit>
        <StyledReferralCard>
          <StyledReferralTitle>
            <FormattedMessage id="referral.page.information.header" />
          </StyledReferralTitle>

          <StyledReferralStepsContainer>
            {referralSteps?.map((item) => {
              const { titleId, descriptionId, descriptionValues, additionalInfo } = item || {};

              return (
                <StyledReferralStepItem key={titleId}>
                  <StyledReferralStepTitle>
                    <FormattedMessage id={titleId} />
                  </StyledReferralStepTitle>

                  <p>
                    <FormattedMessage id={descriptionId} values={descriptionValues} />
                  </p>

                  {additionalInfo && (
                    <StyledReferralAdditionalInformationContainer>
                      {additionalInfo?.map(({ infoId }) => (
                        <StyledReferralAdditionalInformation key={infoId}>
                          <FormattedMessage id={infoId} />
                        </StyledReferralAdditionalInformation>
                      ))}
                    </StyledReferralAdditionalInformationContainer>
                  )}
                </StyledReferralStepItem>
              );
            })}
          </StyledReferralStepsContainer>

          <StyledReferralHighlightBox>
            <FormattedMessage
              id="referral.page.petolo.more_info.text"
              values={{
                a: (chunk) => renderAnchorTagForMoreInfo(chunk, onReferralInfoLinkClick),
              }}
            />
          </StyledReferralHighlightBox>

          <StyledReferralCardContent>
            <StyledReferralCopyLinkButton
              variant="primary"
              data-for="link-copied"
              data-tip
              data-event="none"
              ref={tooltipNodeRef}
              onClick={onLinkCopy}
            >
              <FormattedMessage id="referral.page.copy.button" />
            </StyledReferralCopyLinkButton>

            <ReactTooltip id="link-copied" className="u3-referral-tooltip" effect="solid">
              <FormattedMessage id="referral.page.link.copied" />
            </ReactTooltip>
          </StyledReferralCardContent>

          <StyledReferralCopyLinkOrSocialsText>
            <FormattedMessage id="referral.page.share_in_socials" />
          </StyledReferralCopyLinkOrSocialsText>

          <StyledSocialLinksContainer>
            <StyledSocialGroup>
              {shareSocialItems?.map((socialItem) => (
                <StyledSocialImg
                  key={socialItem?.id}
                  src={socialItem?.image}
                  alt={socialItem?.id}
                  data-test-id={socialItem.testId}
                  onClick={() => socialItem?.click(shareData[socialItem.id])}
                />
              ))}
            </StyledSocialGroup>
          </StyledSocialLinksContainer>

          <StyledReferralIdContainer>
            <p>
              <FormattedMessage
                id="referral.page.referral_id.description"
                values={{
                  product: 'petolo',
                  insurance: 'Tierversicherung',
                  b: (chunk) => renderBTag(chunk),
                }}
              />
            </p>

            <StyledReferralText>
              <FormattedMessage id="referral.page.referral_id.title" />
            </StyledReferralText>

            <StyledReferralIdBox>{refId}</StyledReferralIdBox>
          </StyledReferralIdContainer>

          <Separator />

          <StyledReferralFooterText>
            <FormattedMessage id="referral.page.privacy.text" />
          </StyledReferralFooterText>

          <StyledReferralFooterText>
            <FormattedMessage
              id="referral.page.terms_and_conditions.text"
              values={{
                a: (chunk) => renderAnchorTagForTerms(chunk, PETOLO_REFERRAL_TERMS_LINK),
              }}
            />
          </StyledReferralFooterText>
        </StyledReferralCard>
      </CSSTransition>
    );
  }

  return null;
}

export default PetoloReferralInformation;
