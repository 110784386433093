import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { StyledAccordionContainer } from './styled/Accordion.styled';

function AccordionContainer(props) {
  const { children, collapsible, id, ...rest } = props;

  const [clickedIdx, setClickedIdx] = useState(null);

  const updatedChildrenWithProps = React.Children.map(children, (el, idx) => {
    return React.cloneElement(el, {
      ...rest,
      accordionIdx: idx,
      clickedIdx,
      setExpandedAccordionIndex: () => setClickedIdx(idx),
    });
  });

  return <StyledAccordionContainer>{updatedChildrenWithProps}</StyledAccordionContainer>;
}

export default AccordionContainer;

AccordionContainer.propTypes = {
  children: PropTypes.node,
  collapsible: PropTypes.bool,
  id: PropTypes.string,
};
