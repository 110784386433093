import React from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import { useReady } from '../../../shared';
import {
  PET_LIABILITY_STEP_ACCOMPANIED_BY,
  PET_LIABILITY_STEP_AFFECTED_PARTY,
  PET_LIABILITY_STEP_AFFECTED_PARTY_DETAILS,
  PET_LIABILITY_STEP_DAMAGED_ITEM,
  PET_LIABILITY_STEP_DAMAGE_CAUSED_BY,
  PET_LIABILITY_STEP_DAMAGE_ESTIMATE,
  PET_LIABILITY_STEP_INCIDENT_DATE_TIME,
  PET_LIABILITY_STEP_INCIDENT_LOCATION,
  PET_LIABILITY_STEP_INCIDENT_NOTE,
  PET_LIABILITY_STEP_LIABILITY_REASON,
  PET_LIABILITY_STEP_MEDICAL_EXAM,
  PET_LIABILITY_STEP_RELATION_WITH_AFFECTED_PARTY,
  PET_LIABILITY_STEP_REVIEW,
  PET_LIABILITY_STEP_UPLOAD_DOCUMENTS,
} from './newClaimsFormSteps';

import { Container } from '../../../components/common';
import LiabilityReasonPet from '../components/LiabilityReason/LiabilityReasonPetLiability';
import DamageCausedByPetLiability from '../components/DamageCausedBy/DamageCausedByPetLiability';
import IncidentDateTimePetLiability from '../components/IncidentDateTime/IncidentDateTimePetLiability';
import IncidentLocationPetLiability from '../components/IncidentLocation/IncidentLocationPetLiability';
import AccompaniedByPetLiability from '../components/AccompaniedBy/AccompaniedByPetLiability';
import IncidentNotePetLiability from '../components/CustomerNote/IncidentNotePetLiability';
import DamagedItemPetLiability from '../components/DamagedItem/DamagedItemPetLiability';
import DamageEstimatePetLiability from '../components/DamageEstimate/DamageEstimatePetLiability';
import AffectedPartyPetLiability from '../components/AffectedParty/AffectedPartyPetLiability';
import DetailsOfAffectedPartyPetLiability from '../components/DetailsOfAffectedParty/DetailsOfAffectedPartyPetLiability';
import RelationWithAffectedPartyPetLiability from '../components/RelationWithAffectedParty/RelationWithAffectedPartyPetLiability';
import MedicalExamPetLiability from '../components/MedicalExam/MedicalExamPetLiability';
import UploadDocumentPetLiability from '../components/UploadDocument/UploadDocumentPetLiability';
import SubmitClaimFormPetLiability from '../components/SubmitClaimForm/SubmitClaimFormPetLiability';

function NewPetLiabilityClaim(props) {
  const { petName, tarif, selectedDocuments, setSelectedDocuments, setClaimSubmitted } = props;

  const ready = useReady();
  const { path } = useRouteMatch();
  const location = useLocation();

  return (
    <CSSTransition in={ready} timeout={600} classNames="slow-fade" unmountOnExit>
      <Container>
        <Switch location={location}>
          <Route path={path} exact>
            <Redirect
              to={{
                pathname: PET_LIABILITY_STEP_LIABILITY_REASON,
                search: location?.search,
              }}
            />
          </Route>

          <Route path={PET_LIABILITY_STEP_LIABILITY_REASON} exact>
            <LiabilityReasonPet />
          </Route>

          <Route path={PET_LIABILITY_STEP_DAMAGE_CAUSED_BY} exact>
            <DamageCausedByPetLiability petName={petName} />
          </Route>

          <Route path={PET_LIABILITY_STEP_INCIDENT_DATE_TIME} exact>
            <IncidentDateTimePetLiability />
          </Route>

          <Route path={PET_LIABILITY_STEP_INCIDENT_LOCATION} exact>
            <IncidentLocationPetLiability />
          </Route>

          <Route path={PET_LIABILITY_STEP_ACCOMPANIED_BY} exact>
            <AccompaniedByPetLiability petName={petName} />
          </Route>

          <Route path={PET_LIABILITY_STEP_INCIDENT_NOTE} exact>
            <IncidentNotePetLiability />
          </Route>

          <Route path={PET_LIABILITY_STEP_DAMAGED_ITEM} exact>
            <DamagedItemPetLiability />
          </Route>

          <Route path={PET_LIABILITY_STEP_DAMAGE_ESTIMATE} exact>
            <DamageEstimatePetLiability />
          </Route>

          <Route path={PET_LIABILITY_STEP_AFFECTED_PARTY} exact>
            <AffectedPartyPetLiability />
          </Route>

          <Route path={PET_LIABILITY_STEP_AFFECTED_PARTY_DETAILS} exact>
            <DetailsOfAffectedPartyPetLiability />
          </Route>

          <Route path={PET_LIABILITY_STEP_RELATION_WITH_AFFECTED_PARTY} exact>
            <RelationWithAffectedPartyPetLiability />
          </Route>

          <Route path={PET_LIABILITY_STEP_MEDICAL_EXAM} exact>
            <MedicalExamPetLiability />
          </Route>

          <Route path={PET_LIABILITY_STEP_UPLOAD_DOCUMENTS} exact>
            <UploadDocumentPetLiability
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
            />
          </Route>

          <Route path={PET_LIABILITY_STEP_REVIEW} exact>
            <SubmitClaimFormPetLiability
              tarif={tarif}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
              setClaimSubmitted={setClaimSubmitted}
            />
          </Route>
          <Route path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
      </Container>
    </CSSTransition>
  );
}

export default NewPetLiabilityClaim;

NewPetLiabilityClaim.propTypes = {
  petName: PropTypes.string.isRequired,
  tarif: PropTypes.string.isRequired,
  selectedDocuments: PropTypes.instanceOf(Array),
  setSelectedDocuments: PropTypes.func,
  setClaimSubmitted: PropTypes.func,
};
