import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import { DENTOLO_STEP_DOCUMENT_TYPE } from '../../new/newClaimsFormSteps';

export const useSelectTreatmentCategoryDentoloBackButtonLink = () => {
  return '/user-claims-info';
};

export const useSelectTreatmentCategoryDentoloNextButtonLink = () => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();
  return withSource(DENTOLO_STEP_DOCUMENT_TYPE);
};
