import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { alpha, css, hexToRgb, styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

import Paper from './Paper';
import IconButton from './IconButton';

const sizeStyles = (size) =>
  ({
    lg: css`
      width: 1020px;
    `,
    md: css`
      width: 625px;
    `,
    sm: css`
      width: 480px;
    `,
  })[`${size}`];

const ModalOverlay = styled('div')(
  ({ theme }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: ${alpha(hexToRgb(theme.palette.main.professional_blue), 0.5)};
    overflow-y: auto;
    z-index: 1001;

    display: flex;
    justify-content: center;
    align-items: center;
  `
);

const ModalContainer = styled(Paper)`
  position: relative;
  min-height: 10rem;

  ${({ size }) => sizeStyles(size)}
`;

const ModalCloseButton = styled(IconButton)`
  background: transparent;
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 1rem;
  height: 1rem;
`;

const ModalTitle = styled('h4')(
  ({ theme }) => css`
    margin-bottom: 1.5rem;
    font-size: 1.25rem;
    color: ${theme.palette.main.professional_blue};

    @media (min-width: ${MIN_WIDTH_MD}) {
      font-size: 1.5rem;
    }
  `
);

const AnimatedModal = ({ isOpen, timeout, ...rest }) => {
  return (
    <CSSTransition in={isOpen} timeout={timeout} classNames="quick-fade" unmountOnExit>
      <Modal isOpen={isOpen} {...rest} />
    </CSSTransition>
  );
};

export default AnimatedModal;

AnimatedModal.defaultProps = {
  timeout: 400,
};

const Modal = (props) => {
  const { children, isOpen, title, showClose, size, onClose, enableCloseOnOverlayClick, ...rest } =
    props;

  const portalRoot = document.getElementById('portal');

  const handleOverlayClick = (ev) => {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }

    if (enableCloseOnOverlayClick) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <ModalOverlay onClick={handleOverlayClick} role="dialog" {...rest}>
      <ModalContainer size={size} {...rest}>
        {title && <ModalTitle>{title}</ModalTitle>}

        {showClose && <ModalCloseButton icon="icon.x" onClick={onClose} />}

        {children}
      </ModalContainer>
    </ModalOverlay>,
    portalRoot
  );
};

Modal.defaultProps = {
  size: 'md',
  enableCloseOnOverlayClick: false,
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  showClose: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  onClose: PropTypes.func,
  enableCloseOnOverlayClick: PropTypes.bool,
};
