import React from 'react';
import { imgLinks } from '../../shared';

export default function IllustrationMobileVitolo() {
  return (
    <div style={{ alignItems: 'center', display: 'flex', height: '9.625rem' }}>
      <img
        src={imgLinks['illustration.pending_approval.vitolo.mobile']}
        alt="Vitolo"
        style={{ height: 'fit-content', margin: '0 auto' }}
      />
    </div>
  );
}
