export const PARAM_CLAIM_ID = ':claimId';

// `DENTOLO` edit claim form steps
export const DENTOLO_EDIT_STEP_MAIN = '/user-claims/dentolo/edit';
export const DENTOLO_EDIT_STEP_UPLOAD_DOCUMENTS = `${DENTOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/upload-documents`;
export const DENTOLO_EDIT_STEP_DOCUMENT_DATE = `${DENTOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/document-date`;
export const DENTOLO_EDIT_STEP_TREATMENT_DATE = `${DENTOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/treatment-date`;
export const DENTOLO_EDIT_STEP_TREATMENT_PRICE = `${DENTOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/treatment-price-amount`;
export const DENTOLO_EDIT_STEP_DENTIST_DETAILS = `${DENTOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/dentist-details`;
export const DENTOLO_EDIT_STEP_FILLING_COUNT = `${DENTOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/filling-count`;
export const DENTOLO_EDIT_STEP_REVIEW = `${DENTOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/review-details`;

// `PETOLO` edit claim form steps
export const PETOLO_EDIT_STEP_MAIN = '/user-claims/petolo/edit';
export const PETOLO_EDIT_STEP_UPLOAD_DOCUMENTS = `${PETOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/upload-documents`;
export const PETOLO_EDIT_STEP_DOCUMENT_DATE = `${PETOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/document-date`;
export const PETOLO_EDIT_STEP_TREATMENT_DATE = `${PETOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/treatment-date`;
export const PETOLO_EDIT_STEP_TREATMENT_PRICE = `${PETOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/treatment-price-amount`;
export const PETOLO_EDIT_STEP_CUSTOMER_NOTE = `${PETOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/treatment-necessary-feedback`;
export const PETOLO_EDIT_STEP_REVIEW = `${PETOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/review-details`;

// `Health` edit claim form steps
export const VITOLO_EDIT_STEP_MAIN = '/user-claims/health/edit';
export const VITOLO_EDIT_STEP_UPLOAD_DOCUMENTS = `${VITOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/upload-documents`;
export const VITOLO_EDIT_STEP_DOCUMENT_DATE = `${VITOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/document-date`;
export const VITOLO_EDIT_STEP_TREATMENT_PRICE = `${VITOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/treatment-price-amount`;
export const VITOLO_EDIT_STEP_CUSTOMER_NOTE = `${VITOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/treatment-feedback`;
export const VITOLO_EDIT_STEP_REVIEW = `${VITOLO_EDIT_STEP_MAIN}/${PARAM_CLAIM_ID}/review-details`;
