import { useClaimUploadFlowSource } from '../../claimUploadFlowSource';
import {
  DENTOLO_EDIT_STEP_FILLING_COUNT,
  DENTOLO_EDIT_STEP_REVIEW,
  DENTOLO_EDIT_STEP_TREATMENT_PRICE,
  PARAM_CLAIM_ID,
} from '../../edit/editClaimFormSteps';
import { DENTOLO_TREATMENT_CATEGORY_FILLING } from '../../claimFormData/newClaimFormData';
import {
  DENTOLO_STEP_FILLING_COUNT,
  DENTOLO_STEP_REVIEW,
  DENTOLO_STEP_TREATMENT_PRICE,
} from '../../new/newClaimsFormSteps';

export const useDentistDetailsBackButtonLink = (claimId) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (claimId) {
    const backButtonLink = DENTOLO_EDIT_STEP_TREATMENT_PRICE.replace(PARAM_CLAIM_ID, claimId);
    return withSource(backButtonLink);
  }

  return withSource(DENTOLO_STEP_TREATMENT_PRICE);
};

const getNextButtonLinkForExistingClaim = (treatmentCategory) => {
  switch (treatmentCategory) {
    case DENTOLO_TREATMENT_CATEGORY_FILLING:
      return DENTOLO_EDIT_STEP_FILLING_COUNT;
    default:
      return DENTOLO_EDIT_STEP_REVIEW;
  }
};

const getNextButtonLinkForNewClaim = (treatmentCategory) => {
  switch (treatmentCategory) {
    case DENTOLO_TREATMENT_CATEGORY_FILLING:
      return DENTOLO_STEP_FILLING_COUNT;
    default:
      return DENTOLO_STEP_REVIEW;
  }
};

export const useDentistDetailsNextButtonLink = (claimId, treatmentCategory) => {
  const [, /* source */ withSource] = useClaimUploadFlowSource();

  if (claimId) {
    const nextButtonLink = getNextButtonLinkForExistingClaim(treatmentCategory);
    const nextButtonLinkWithClaimId = nextButtonLink.replace(PARAM_CLAIM_ID, claimId);
    return withSource(nextButtonLinkWithClaimId);
  }

  const nextButtonLink = getNextButtonLinkForNewClaim(treatmentCategory);
  return withSource(nextButtonLink);
};
