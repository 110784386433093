import { styled } from '@mui/material';

import { MIN_WIDTH_MD } from '../../theme/breakpoints';

import { Paper, Separator } from '../common';

export const StyledSignDocsSeparator = styled(Separator)`
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;

export const StyledSignDocsPaper = styled(Paper)`
  margin-top: 1.5rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 2rem;
  }
`;

export const StyledButtonContainer = styled('div')`
  margin-top: 1.5rem;

  > button {
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 2rem;

    > button {
      margin-right: 0;
    }
  }
`;
