import { css, styled } from '@mui/material';

export const StyledCardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const StyledCheckBoxContainer = styled('div')(
  ({ theme }) => css`
    margin-top: 1.5em;

    a {
      position: relative;
      font-size: 1rem;
      text-decoration: none;
      color: ${theme.palette.main.professional_blue};

      &::after {
        content: '';
        position: absolute;
        height: 2px;
        bottom: -1px;
        left: 0;
        right: 0;
        width: 100%;
        background-color: ${theme.palette.main.professional_blue};
        transform-origin: bottom left;
        transition:
          transform 0.2s ease-out,
          color 0.2s ease-out;
      }

      &:hover {
        color: ${theme.palette.main.professional_light_blue};

        &:after {
          transform: scaleX(0);
          transform-origin: bottom right;
        }
      }

      &:focus,
      &:active {
        color: ${theme.palette.main.professional_light_blue};

        &::after {
          background-color: ${theme.palette.main.professional_light_blue};
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  `
);
