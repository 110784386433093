import React from 'react';

import {
  StyledHeaderBurgerMenu,
  StyledHeaderBurgerMenuButton,
  StyledHeaderBurgerMenuLine,
} from './Header.styled';

function HeaderBurgerMenu(props) {
  const { isOpen, toggleNavMenu, isCustomerLoading } = props;

  const handleToggle = () => {
    if (!isCustomerLoading) {
      toggleNavMenu();
    }
  };
  return (
    <StyledHeaderBurgerMenu>
      <StyledHeaderBurgerMenuButton
        type="button"
        onClick={handleToggle}
        aria-label="Mobile Menu"
        disabled={isCustomerLoading}
      >
        <StyledHeaderBurgerMenuLine open={isOpen} />
        <StyledHeaderBurgerMenuLine open={isOpen} />
        <StyledHeaderBurgerMenuLine open={isOpen} />
      </StyledHeaderBurgerMenuButton>
    </StyledHeaderBurgerMenu>
  );
}

export default HeaderBurgerMenu;
