import { css } from '@mui/material';
import { MIN_WIDTH_MD } from '../breakpoints';

// eslint-disable-next-line import/prefer-default-export
export const muiLinkComponent = {
  defaultProps: {
    component: 'a',
    underline: 'none',
  },
  styleOverrides: {
    root: ({ theme }) => css`
      color: ${theme.palette.main.professional_blue};
      font-family: ${theme.typography.fontFamily};
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 450;
      line-height: 1.5rem;

      @media (min-width: ${MIN_WIDTH_MD}) {
        font-size: 1.25rem;
        line-height: 1.625rem;
      }

      cursor: pointer;
      text-decoration: underline;

      &:focus,
      &.Mui-focusVisible {
        box-shadow: ${theme.shadows[4]};
      }

      &[disabled] {
        opacity: 0.4;
        pointer-events: none;
      }
    `,
  },
};
